import React, { Component } from "react";
import axios from "axios";
import '../../App.css';
import Top from '../../includes/top'
import Sidebar from '../../includes/sidebar'
import MyDatePicker from "../mydatepicker";

export default class DialysisRegister extends Component {
    state = {id: 0, list: [], msg: '', error: '' , showSubmit: true, loader: false}
    componentDidMount() {
        this.getList()
    }
   
    getList() {
        this.setLoader(true)
        axios.get(localStorage.getItem('host') + '/patientmanagement/dialysisregister/' + sessionStorage.getItem('companyId'), {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(
            res => {               
                this.setState({ list: res.data, loader: false });
            }, err => {
                console.log(".....")
            }
        )
    }
    save() {
        this.setLoader(true)
        
        axios.post(localStorage.getItem('host') + '/patientmanagement/dialysisregisterform', this.state.list, {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(
            res => {
                console.log(res.data)
                this.setState({msg: res.data})
                this.getList()
            }, err => {
                console.log(".....")
                this.setState({ error: err + "" })
                this.setLoader(false)
            }
        )
    }
    
    setRowValue(index, e, name) {
        let list = this.state.list.slice();
        switch (name) {
         case "month":
                        list[index].month = e;
                        break;
         case "year":
                        list[index].year = e;
                        break;
         case "patientName":
                        list[index].patientName = e;
                        break;
         case "medicalAid":
                        list[index].medicalAid = e;
                        break;
         case "dialysisUnit":
                        list[index].dialysisUnit = e;
                        break;
         case "typeOfHD":
                        list[index].typeOfHD = e;
                        break;
         case "startDate":
                        list[index].startDate = e;
                        break;
         case "endDate":
                        list[index].endDate = e;
                        break;
         case "sessions":
                        list[index].sessions = e;
                        break;
         case "comments":
                        list[index].comments = e;
                        break;
         case "status":
                        list[index].status = e;
                        break;
         case "ripDate":
                        list[index].ripDate = e;
                        break;
         case "claimAmount":
                        list[index].claimAmount = e;
                        break;
         case "claimCurrency":
                        list[index].claimCurrency = e;
                        break;
         case "claimSubmissionDate":
                        list[index].claimSubmissionDate = e;
                        break;
         case "paidAmount":
                        list[index].paidAmount = e;
                        break;
         case "shortfallAmount":
                        list[index].shortfallAmount = e;
                        break;
         case "shortfallCode":
                        list[index].shortfallCode = e;
                        break;
         case "remittanceDate":
                        list[index].remittanceDate = e;
                        break;
         case "claimComment":
                        list[index].claimComment = e;
                        break;
         case "check1":
                        list[index].check1 = e;
                        break;
         case "days":
                        list[index].days = e;
                        break;
                 default:
                         console.log("default")
                          break;

                      }       
        this.setState({ list: list })
      }

    addLine() {
        let list = this.state.list.slice();
        list.push({ id: 0, companyId: sessionStorage.getItem('companyId'), month: '', year: 2023, patientName: '', medicalAid: '', dialysisUnit: '', typeOfHD: '', startDate: '', endDate: '', sessions: '', comments: '', status: '', ripDate: '', claimAmount: 0.00, claimCurrency: '', claimSubmissionDate: '', paidAmount: 0.00 , shortfallAmount: 0.00, shortfallCode: '', remittanceDate: '', claimComment: '', check1: '', days: 0});
        this.setState({ list: list })
    } 
    setLoader(loader)
    {
     this.setState({loader: loader})
    }
    render() {
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        }
        if (this.state.error) {
            divMsg = (<div className='alert alert-danger'>{this.state.error}</div>)
        }
        let loaderDiv = ''
        if(this.state.loader)
           {
            loaderDiv = (  <div id="loader" className="loader-overlay"><div align="center" className="loader"></div></div>)
           }
        return ( <div className="d-flex" id="wrapper">
        <Sidebar />
        <div id="page-content-wrapper">
            <Top />

            <div className="container-fluid">
                                {divMsg}
                        <br /><input type="button" className="btn btn-success" value="Insert Line" onClick={() => { this.addLine() }} />
                        <div class="table-responsive">
                            
                                <table className="table table-striped" >
                                <tr>
                                                        <th>Month</th>
                                                        <th>Year</th>
                                                        <th>Patient Name</th>
                                                        <th>Medical Aid</th>
                                                        <th>Dialysis Unit</th>
                                                        <th>Type of HD</th>
                                                        <th colSpan="2">Start Date</th>
                                                        <th colSpan="2">End Date</th>
                                                        <th>Sessions</th>
                                                        <th>Comments</th>
                                                        <th>Status</th>
                                                        <th colSpan="2">RIP Date</th>
                                                        <th>Claim Amount</th>
                                                        <th>Claim Currency</th>
                                                        <th colSpan="2">Claim Submission Date</th>
                                                        <th>Paid Amount</th>
                                                        <th>Shortfall Amount</th>
                                                        <th>Shortfall Code</th>
                                                        <th colSpan="2">Remittance Date</th>
                                                        <th>Claim Comment</th>
                                                        <th>Check 1</th>
                                                        <th>Days</th>
                                                    </tr>
                                                    <tbody>                                               
                                                     {this.state.list.map((item, index) => (
                                                        <tr>
                                                           <td><input type="text" className="form-control" value={item.month} onChange={e => { this.setRowValue(index, e.target.value, "month")  }} /></td>
                                                    <td><input type="text" className="form-control" value={item.year} onChange={e => { this.setRowValue(index, e.target.value, "year")  }} /></td>
                                                    <td><input type="text" className="form-control" value={item.patientName} onChange={e => {this.setRowValue(index, e.target.value, "patientName")  }} /></td>
                                                    <td><input type="text" className="form-control" value={item.medicalAid} onChange={e => { this.setRowValue(index, e.target.value, "medicalAid")  }} /></td>
                                                    <td><input type="text" className="form-control" value={item.dialysisUnit} onChange={e => { this.setRowValue(index, e.target.value, "dialysisUnit")  }} /></td>
                                                    <td><input type="text" className="form-control" value={item.typeOfHD} onChange={e => {this.setRowValue(index, e.target.value, "typeOfHD")  }} /></td>
                                                    <td><input type="text" className="form-control" value={item.startDate} onChange={e => { this.setRowValue(index, e.target.value, "startDate")  }} /></td><td><MyDatePicker onChange={(d)=> { this.setRowValue(index, d, "startDate") }} /></td>
                                                    <td><input type="text" className="form-control" value={item.endDate} onChange={e => { this.setRowValue(index, e.target.value, "endDate")  }} /></td><td><MyDatePicker onChange={(d)=> { this.setRowValue(index, d, "endDate") }} /></td>
                                                    <td><input type="text" className="form-control" value={item.sessions} onChange={e => {this.setRowValue(index, e.target.value, "sessions")  }} /></td>
                                                    <td><input type="text" className="form-control" value={item.comments} onChange={e => { this.setRowValue(index, e.target.value, "comments")  }} /></td>
                                                    <td><input type="text" className="form-control" value={item.status} onChange={e => { this.setRowValue(index, e.target.value, "status")  }} /></td>
                                                    <td><input type="text" className="form-control" value={item.ripDate} onChange={e => {this.setRowValue(index, e.target.value, "ripDate")  }} /></td><td><MyDatePicker onChange={(d)=> { this.setRowValue(index, d, "ripDate") }} /></td>
                                                    <td><input type="text" className="form-control" value={item.claimAmount} onChange={e => { this.setRowValue(index, e.target.value, "claimAmount")  }} /></td>
                                                    <td><input type="text" className="form-control" value={item.claimCurrency} onChange={e => { this.setRowValue(index, e.target.value, "claimCurrency")  }} /></td>
                                                    <td><input type="text" className="form-control" value={item.claimSubmissionDate} onChange={e => {this.setRowValue(index, e.target.value, "claimSubmissionDate")  }} /></td><td><MyDatePicker onChange={(d)=> { this.setRowValue(index, d, "claimSubmissionDate") }} /></td>
                                                    <td><input type="text" className="form-control" value={item.paidAmount} onChange={e => { this.setRowValue(index, e.target.value, "paidAmount")  }} /></td>
                                                    <td><input type="text" className="form-control" value={item.shortfallAmount} onChange={e => { this.setRowValue(index, e.target.value, "shortfallAmount")  }} /></td>
                                                    <td><input type="text" className="form-control" value={item.shortfallCode} onChange={e => {this.setRowValue(index, e.target.value, "shortfallCode")  }} /></td>
                                                    <td><input type="text" className="form-control" value={item.remittanceDate} onChange={e => { this.setRowValue(index, e.target.value, "remittanceDate")  }} /></td><td><MyDatePicker onChange={(d)=> { this.setRowValue(index, d, "remittanceDate") }} /></td>
                                                    <td><input type="text" className="form-control" value={item.claimComment} onChange={e => { this.setRowValue(index, e.target.value, "claimComment")  }} /></td>
                                                    <td><input type="text" className="form-control" value={item.check1} onChange={e => {this.setRowValue(index, e.target.value, "check1")  }} /></td>
                                                    <td><input type="text" className="form-control" value={item.days} onChange={e => {this.setRowValue(index, e.target.value, "days")  }} /></td>
                                                        </tr>
                                                    ))}
                                                    </tbody>
                                </table>
                                </div>
                        <div className="row">
                            <div className="col" align="center">{this.state.showSubmit === true && (<input type="button" className="btn btn-primary" onClick={() => this.save()} value="Save" />)}</div>
                                </div>
                        </div>
                             </div>
                             {loaderDiv}
                </div>
        )

    }
}    