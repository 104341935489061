import React, { Component } from 'react'
import axios from 'axios'
export default class UserRoleCompany extends Component {
state = { error: '', userRoleCompanys: [], index: 0, username: '', password: '', ipAddress: '', country: '', isp: '' }

openUserRoleCompany(userRoleCompanys, userId)
  {
   if(userRoleCompanys.length > 0)
      {
       this.setState({userRoleCompanys: userRoleCompanys})
       document.querySelector('#userRoleCompanyAction').style = 'display:block'
       this.props.setLoader(false)
      }
    else
      {
        axios.get(localStorage.getItem('host') + '/auth/userrolecompany/'+userId,  {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ userRoleCompanys: res.data });
                document.querySelector('#userRoleCompanyAction').style = 'display:block'
                this.props.setLoader(false)
            }, err => {
                console.log(".....")
                 this.setState({ error: err+'' })
                 this.props.setLoader(false)
            }
        )
      }  
  }
  update() {
    const urc = this.state.userRoleCompanys[this.state.index];
    sessionStorage.setItem('companyId', urc.companyId);
    sessionStorage.setItem('speciality', urc.speciality);
    sessionStorage.setItem('roleId', urc.roleId);
    sessionStorage.setItem('companyName', urc.companyName);
    this.closePopup();
    window.location = "/dashboard";
  }
  closePopup() {
    document.querySelector('#userRoleCompanyAction').style = 'display:none'
    console.log('......close');
  }
 render() {
    return (
<div className="page-popup page-popup-overlay" id="userRoleCompanyAction">
              <div className="card">
                <div className="card-header bg-primary text-light">
                  User Role Company
                </div>
                <div className="card-body">
                  <table className="table">
                    <tr>
                      <th>Action</th>
                      <th>Company</th>
                      <th>Role</th>
                      <th>Speciality</th>
                    </tr>
                    <tbody>
                      {this.state.userRoleCompanys.map((userRoleCompany, i) => (
                        <tr key={i}>
                          <td><input type="radio" name="urc" checked={this.state.index === i}  onChange={() => this.setState({ index: i })} /></td>
                          <td  onClick={() => this.setState({ index: i })}>{userRoleCompany.companyName}</td>
                          <td  onClick={() => this.setState({ index: i })}>{userRoleCompany.roleName}</td>
                          <td  onClick={() => this.setState({ index: i })}>{userRoleCompany.speciality}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="row">
                    <div className="col" align="right"><input type="button" className="btn btn-primary"  onClick={() => this.update()} value="Proceed" /></div>
                    <div className="col"><input type="button" className="btn btn-primary"  onClick={() => this.closePopup()} value="Close" /></div>
                  </div>
                </div></div>
          </div>
    )
 }
}