import React, { Component } from "react";
import axios from "axios";
import '../../App.css';
import Select from 'react-select';
import MyDatePicker from "../mydatepicker";

function checkZero(num) {
    return (num + "").length === 1 ? "0" + num : num;
}
export default class Grv extends Component {
    state = { name: '', rate: 1, companyId: '', companys: [], companyIdError: '', currencyName: '', remarks: '', locationId: 0, locations: [], productOptions: [], products: [], manufacturers: [], suppliers: [], grvItems: [], grvItemsError: '', totalAmount: 0.00 , productTypes: [ "ACCESSORIES", "ANAESTHETIC_DRUG", "CONSUMABLES", "DRUG", "LENSES", "FRAMES",  "OTC","STATIONERY","SUNDRIES"]}
  
    componentDidMount() {
    }
    openGrv(id) {
        document.getElementById('grvAction').style = 'display:block' 
        this.setState({ id: id, rate: 1, currencyId: 0, companyId: '', companys: [], companyIdError: '', remarks: '', locations: [], productOptions: [], products: [],  manufacturers: [], suppliers: [], grvItems: [], msg: '', error: '', totalAmount: 0.00, productTypes: [ "ACCESSORIES", "ANAESTHETIC_DRUG", "CONSUMABLES", "DRUG", "LENSES", "FRAMES",  "OTC","STATIONERY","SUNDRIES"] });
        this.props.setLoader(true)
        axios.get(localStorage.getItem('host') + '/stockmanagement/getgrv/' + id + '/' + sessionStorage.getItem('companyId'), {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ companys: res.data.companys, remarks: res.data.remarks, grvItems: res.data.grvItems, totalAmount: res.data.totalAmount });
                this.props.setLoader(false)
                this.doValidate()
            }, err => {
                console.log(".....")
                this.setState({ error: err + "" })
                this.props.setLoader(false)
            }
        )
    }
    save() {
        this.props.setLoader(true)
        var data = { id: this.state.id, orderNumber: this.state.orderNumber, invoiceNumber: this.state.invoiceNumber, companyId: this.state.companyId, invoiceDate: this.state.invoiceDate, remarks: this.state.remarks, totalAmount: this.state.totalAmount, grvItems: this.state.grvItems }
        axios.post(localStorage.getItem('host') + '/stockmanagement/savegrv', data, {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(
            res => {
                console.log(res.data)
                this.close()
                this.props.getList()
                this.props.setMsg(res.data)
            }, err => {
                console.log(".....")
                this.setState({ error: err + "" })
                this.props.setLoader(false)
            }
        )
    }
    setProductItem(item) {
        let list = this.state.grvItems.slice();
        list.push({
            id: 0, productItemId: item.id, name: item.name, form: item.form, type: item.type, tariffCode: item.tariffCode, packSize: item.packSize, maxQuantity: item.quantity, quantity: 0, quantityError: '',
            uom: item.uom, cost: item.sellingPrice,  bulk: 0, totalCost: 0.00, instruction: '', storedQuantity: 0.0, activeStatus: 1
        });
        this.closePopup()
        this.validate(list)
    }
    close() {
        document.getElementById('grvAction').style = 'display:none'
    } 
    add() {
        let list = this.state.grvItems.slice();
        list.push({
            id: 0, locationId: '', locationIdError: '', type: '', typeError: '', productItemId: '', productItemIdError: '', category: '', code: '', quantity: 1, quantityError: '',
            buyingPrice: 0.0, buyingPriceError: '', bulk: 0, batchNumber: '', expiryDate: '', manufacturer: '', supplier: '', activeStatus: 1,companyId: sessionStorage.getItem('companyId') });
        this.validate(list)
    }
    doValidate() {
        setTimeout(() => {
            this.validate(this.state.grvItems.slice());
        }, 1000);
    }
    setRowValue(index, e, name) {
        let list = this.state.grvItems.slice();
        switch (name) {
            case "locationId":
                for(var j in this.state.locations)
                    {
                     if(this.state.locations[j].id === Number(e.target.value))
                        {
                            list[index].locationId = this.state.locations[j].id;
                            list[index].locationName = this.state.locations[j].name;
                        }
                    }
                break;
            case "type":
                list[index].type = e.target.value;
                break;
            case "product":
                for(var i in this.state.products)
                  {
                    if(this.state.products[i].id === Number(e.value))
                    { 
                        list[index].productItemId = this.state.products[i].id;
                        list[index].name = this.state.products[i].name;
                        list[index].code = this.state.products[i].code;
                        list[index].category = this.state.products[i].category;
                        list[index].type = this.state.products[i].type;
                        list[index].bulkQuantity = this.state.products[i].bulkQuantity;
                    }
                }
                break;
            case "quantity":
                list[index].quantity = e.target.value;
                break;
            case "buyingPrice":
                list[index].buyingPrice = e.target.value;
                break;
            case "bulk":
                list[index].bulk = e.target.value;
                break;
            case "batchNumber":
                list[index].batchNumber = e.target.value;
                break;
            case "expiryDate":
                list[index].expiryDate = this.processDate(e)
                console.log(this.processDate(e))
                break;
            case "manufacturer":
                list[index].manufacturer = e.target.value;
                break;
            case "supplier":
                list[index].supplier = e.target.value;
                break;
            case "activeStatus":
                list[index].activeStatus = e.target.value;
                break;
            default:
                console.log("default")
                break;
        }
        this.validate(list)
    }
    validate(grvItems) {
        var totalAmount = 0;
        var error = false;
        if (this.state.companyId === "") {
            this.setState({companyIdError: "Company cannot be empty"})
            error = true
        }
        else {
            this.setState({companyIdError : ""})
        }
        for (var index in grvItems) {
            if(grvItems[index].activeStatus === 1){
          
                totalAmount += Number(grvItems[index].buyingPrice)
            if (grvItems[index].locationId === "") {
                grvItems[index].locationIdError = "Location cannot be empty"
                error = true
            }
            else {
                grvItems[index].locationIdError = "";
            }
            if (grvItems[index].productItemId === "") {
                grvItems[index].productItemIdError = "Product cannot be empty"
                error = true
            }
            else {
                grvItems[index].productItemIdError = "";
            }
            if (grvItems[index].quantity === "") {
                grvItems[index].quantityError = "Quantity cannot be empty"
                error = true
            }
            else if (grvItems[index].quantity > 0) {
                grvItems[index].quantityError = "";
            }
            else {
                grvItems[index].quantityError = "Quantity should be a number greater than zero"
                error = true
            }
            if (grvItems[index].buyingPrice === "") {
                grvItems[index].buyingPriceError = "Buying Price cannot be empty"
                error = true
            }
            else if (grvItems[index].buyingPrice > 0) {
                grvItems[index].buyingPriceError = "";
            }
            else {
                grvItems[index].buyingPriceError = "Buying Price should be a number greater than zero"
                error = true
            }
        }
        }
        console.log(" error" + error)
        this.setState({ grvItems: grvItems , totalAmount: totalAmount})
        if (error === true) {
            this.setState({ error: "One or more of the mandatory field(s) is not filled in", showSubmit: false })
        }
        else {
            this.setState({ error: "", showSubmit: true })
        }
    }
    currencyFormat(amount) {
        let val = (amount / 1).toFixed(2)
        return val.toString().split(/(?=(?:\d{3})+(?:\.|$))/g).join(" ").replace("-,", "-");
    }
    setLocations(id) {
        for (var i in this.state.companys) {
            if (Number(this.state.companys[i].id) === Number(id)) {
                var locations = this.state.companys[i].locations;
                var products = this.state.companys[i].products;
                var productOptions = [];
                for(var v in products)
                  {
                    productOptions.push({ value: products[v].id, label:products[v].code+" - "+products[v].name})
                  }
                this.setState({ productOptions: productOptions,locations: locations, products: products })
            }
        }
    }
    sortDate(date) {
        if (date === "" || date === null)
            return "";
        return new Date(date);
    }
    processDate(date)
      {
        const now = new Date(date);
        const todayY = now.getFullYear();
        const todayM = checkZero(now.getMonth() + 1);
        const todayD = checkZero(now.getDate());
        var date1 = todayY + '-' + todayM + '-' + todayD;
        console.log(date1)
       return date1;
      }
    reload() {
        window.location = './grvaction'
    }
    render() {
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        }
        if (this.state.error) {
            divMsg = (<div className='alert alert-danger'>{this.state.error}</div>)
        }
        return (
                        <div id="grvAction" className="page-popup page-popup-overlay"   >
                        <div className="card" >
                            <div className="card-header bg-info">Grv Action</div>
                            <div className="card-body" >
                                {divMsg}
                        <div className="card card-green">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col">
                                        <div className="input-group"><span className="input-group-text">Company</span>
                                            <select className={this.state.companyIdError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.companyId} onChange={e => { this.setState({ companyId: e.target.value }); this.setLocations(e.target.value); this.doValidate(); }}  >
                                                <option value="0">Choose Company</option>
                                                {this.state.companys.map((item, index) => (
                                                    <option key={index} value={item.id} >{item.name}</option>
                                                ))}
                                            </select><div className="invalid-feedback">{this.state.companyIdError}</div></div>
                                    </div>
                                    <div className="col">
                                        <div className="input-group"><span className="input-group-text">Invoice Date</span>
                                            <input type="text" className="form-control" value={this.state.invoiceDate} onChange={e => { this.setState({ invoiceDate: e.target.value }) }} /> <MyDatePicker onChange={(d)=> {
                                                        this.setState({invoiceDate: d})
                                                    }} /></div>
                                    </div>
                                    <div className="col">
                                        <div className="input-group"><span className="input-group-text">Invoice Number</span>
                                            <input type="text" className="form-control" value={this.state.invoiceNumber} onChange={e => { this.setState({ invoiceNumber: e.target.value }) }} /></div>
                                    </div>
                                    <div className="col">
                                        <div className="input-group"><span className="input-group-text">Order Number</span>
                                            <input type="text" className="form-control" value={this.state.orderNumber} onChange={e => { this.setState({ orderNumber: e.target.value }) }} /></div>
                                    </div>
                                </div>
                            </div></div>     
                                               
                        <div className="row">
                            <div className="col"> <div className="input-group"><span className="input-group-text">Remarks</span>
                                            <textarea className="form-control" value={this.state.remarks} onChange={e => { this.setState({ remarks: e.target.value }) }} ></textarea></div> </div>       
                            </div>           
                        <div className="row">
                            <div className="col"><span className="fw-bold">Total:</span> {this.currencyFormat(this.state.totalAmount)}</div>
                            <div className="col"></div>
                            <div className="col"></div>
                        </div>
                        <br />
                        <div className="card card-green">
                            <div className="card-header">Items</div>
                            <div className="card-body">
                            <button type="button" className="btn btn-success" title="Insert" onClick={() => { this.add() }}  ><i className="bi bi-plus-square"></i></button>
                                <table className="table table-striped" >
                                <tr>
                                                        <th>Location</th>
                                                        <th>Name</th>
                                                        <th>Quantity</th>
                                                        <th>Bulk</th>
                                                        <th>Buying Price</th>
                                                        <th>Batch Number</th>
                                                        <th colSpan="2">Expiry Date (YYYY-MM-DD)</th>
                                                        <th>Manufacturer</th>
                                                        <th>Supplier</th>
                                                    </tr>
                                                    <tbody>                                               
                                                     {this.state.grvItems.map((item, index) => (
                                                        <tr>
                                                            <td><div className="input-group has-validation"><select className={item.locationIdError !== "" ? "form-control is-invalid" : "form-control"} value={item.locationId} onChange={e => { this.setRowValue(index, e, "locationId") }}  >
                                                                <option value="0">Choose Location</option>
                                                                {this.state.locations.map((l, index) => (
                                                                    <option key={index} value={l.id} >{l.name}</option>
                                                                ))}
                                                            </select><div className="invalid-feedback">{item.locationIdError}</div></div></td>
                                                            <td><div className="input-group has-validation"><Select className={item.productItemIdError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.product} onChange={e => { this.setRowValue(index, e, "product") }}  options={this.state.productOptions} />
                                                              <div className="invalid-feedback">{item.productItemIdError}</div></div></td>
                                                                    <td><div className="input-group has-validation"><input type="text" className={item.quantityError !== "" ? "form-control is-invalid" : "form-control"} value={item.quantity} onChange={e => { this.setRowValue(index, e, "quantity") }} />
                                                                <div className="invalid-feedback">{item.quantityError}</div></div></td>
                                                            <td><select className="form-control" value={item.bulk} onChange={e => { this.setRowValue(index, e, "bulk") }}  >
                                                                <option value="1" >Yes</option>
                                                                <option value="0" >No</option>
                                                            </select></td>
                                                            <td><div className="input-group has-validation"><input type="text" className={item.buyingPriceError !== "" ? "form-control is-invalid" : "form-control"} value={item.buyingPrice} onChange={e => { this.setRowValue(index, e, "buyingPrice") }} />
                                                                <div className="invalid-feedback">{item.buyingPriceError}</div></div></td>
                                                            <td><input type="text" className="form-control" value={item.batchNumber} onChange={e => { this.setRowValue(index, e, "batchNumber") }} /></td>
                                                            <td><input type="text" className="form-control" value={item.expiryDate} onChange={d => { this.setRowValue(index, d, "expiryDate"); }}  /></td><td><MyDatePicker onChange={(d)=> {
                                                        this.setRowValue(index, d, "expiryDate")
                                                    }} /></td>
                                                            <td><input type="text" list={'manufacturerList' + index} className="form-control" value={item.manufacturer} onChange={e => { this.setRowValue(index, e, "manufacturer") }} />
                                                            <datalist id={'manufacturerList' + index}>
                                                                {this.state.manufacturers.map((m, index) => (
                                                                    <option key={index} value={m}>{m}</option>
                                                                ))}
                                                            </datalist> </td>
                                                            <td><input type="text" list={'supplierList' + index} className="form-control" value={item.supplier} onChange={e => { this.setRowValue(index, e, "supplier") }} />
                                                            <datalist id={'supplierList' + index}>
                                                                {this.state.suppliers.map((m, index) => (
                                                                    <option key={index} value={m}>{m}</option>
                                                                ))}
                                                            </datalist> </td>
                                                        </tr>
                                                    ))}
                                                    </tbody>
                                </table>
                                <div className="row">
                                    <div className="input-group">
                                        <input type="hidden" className={this.state.grvItemsError !== "" ? "form-control is-invalid" : "form-control"} />
                                        <div className="invalid-feedback">{this.state.grvItemsError}</div> </div></div>  </div></div>
                        <div className="row">
                            <div className="col" align="right">{this.state.showSubmit === true && (<input type="button" className="btn btn-primary" onClick={() => this.save()} value="Save" />)}</div>
                            <div className="col"><input type="button" className="btn btn-primary" onClick={() => this.close()} value="Cancel" /></div>
                        </div>
                        </div>
                </div ></div >
        )

    }
}    