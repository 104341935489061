import React, { Component } from "react";
import axios from "axios";
import '../../App.css';
import Top from '../../includes/top'
import Sidebar from '../../includes/sidebar'
import { MDBDataTable } from 'mdbreact';


export default class WhatsappNotification extends Component {
    state = { list: [], id: 0, birthday: '', appointment: '', review: '', result: '', companyId: 0, companys: [], msg: '', error: '' }
    componentDidMount() {
        this.getList()
    }
    licenceAction(e) {
        var id = e.currentTarget.id;
        console.log("......." + id + " .... " + e.currentTarget.id)
        this.showForm(id);
    }
    showForm(id) {
        this.setState({id: 0, birthday: '', appointment: '', review: '', result: '', companyId: 0, companys: [], msg: '', error: ''}) 
            axios.get(localStorage.getItem('host') + '/auth/whatsappnotificationaction/' + id, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            }).then(
                res => {
                    this.setState({ id: res.data.id, birthday: res.data.birthday, appointment: res.data.appointment, review: res.data.review, result: res.data.result, companyId: res.data.companyId, companys: res.data.companys });
                }, err => {
                    console.log(".....")
                }
            )
        document.querySelector('#licenceAction').style = 'display:block'
    }
    closePopup() {
        document.querySelector('#licenceAction').style = 'display:none'
        console.log('......close');
    }
    saveLicence() {
        var data = { id: this.state.id, birthday: this.state.birthday, appointment: this.state.appointment, review: this.state.review, result: this.state.result, companyId: this.state.companyId }
        axios.post(localStorage.getItem('host') + '/auth/whatsappnotificationform', data, {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(
            res => {
                console.log(res.data)
                this.setState({ msg: res.data });
                this.getList();
                this.closePopup();
                setTimeout(() => {
                    this.setState({ msg: '' });
                }, 3000);
            }, err => {
                console.log(".....")
            }
        )
    }
    getList() {
        axios.get(localStorage.getItem('host') + '/auth/whatsappnotification', {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(
            res => {
                for (var index in res.data) {
                    res.data[index].action = <span className="link" title="Edit" id={res.data[index].id} onClick={e => this.licenceAction(e)}><i className="bi bi-pencil-square"></i></span>
                }
                this.setState({ list: res.data });
            }, err => {
                console.log(".....")
            }
        )
    }
    render() {
        const data = {
            columns: [
                
                {
                    label: 'Action',
                    field: 'action',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Name',
                    field: 'name',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Birthday',
                    field: 'birthday',
                    sort: 'asc',
                    width: 100
                },
                {
                    label: 'Appointment',
                    field: 'appointment',
                    sort: 'asc',
                    width: 100
                },
                {
                    label: 'Review',
                    field: 'review',
                    sort: 'asc',
                    width: 100
                }
            ],
            rows: this.state.list
        };
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        }
        return (
            <div className="d-flex" id="wrapper">
            <Sidebar />
            <div id="page-content-wrapper">
                <Top />

                <div className="container-fluid">
                <button className="btn btn-primary" onClick={() => this.showForm(0)} title="Add" ><i className="bi bi-plus-square"></i></button>
                      
                        {divMsg}
                        <MDBDataTable
                            button="true"
                            striped
                            bordered
                            small
                            data={data}
                        />
                        <div className="page-popup page-popup-overlay" id="licenceAction">
                                <div className="card">
                                    <div className="card-header bg-info">
                                        Whatsapp Notification Action
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col">
                                            <div className="input-group"><span className="input-group-text">Name</span>
                                                <select className="form-control" value={this.state.companyId} onChange={e => { this.setState({ companyId: e.target.value }) }}  >
                                                {this.state.companys.map((item, index) => (
                                                            <option key={index} value={item.id} >{item.name}</option>
                                                        ))}
                                                </select></div> 
                                            </div>
                                            <div className="col">
                                            <div className="input-group"><span className="input-group-text">Birthday</span>
                                                <input type="text" className="form-control" value={this.state.birthday} onChange={e => { this.setState({ birthday: e.target.value }) }} /></div> 
                                            </div></div>
                                        <div className="row">
                                            <div className="col">
                                            <div className="input-group"><span className="input-group-text">Appointment</span>
                                                <input type="text" className="form-control" value={this.state.appointment} onChange={e => { this.setState({ appointment: e.target.value }) }} /></div> 
                                            </div>
                                            <div className="col"> <div className="input-group"><span className="input-group-text">Review</span>
                                                <input type="text" className="form-control" value={this.state.review} onChange={e => { this.setState({ review: e.target.value }) }} /></div> </div>
                                            </div>
                                        <div className="row">
                                            <div className="col">
                                            <div className="input-group"><span className="input-group-text">Result</span>
                                                <input type="text" className="form-control" value={this.state.result} onChange={e => { this.setState({ result: e.target.value }) }} /></div> 
                                            </div>
                                            <div className="col">  </div>
                                            </div>
                                        <div className="row">
                                            <div className="col" align="right"><input type="button" className="btn btn-primary" onClick={() => this.saveLicence()} value="Save" /></div>
                                            <div className="col"><input type="button" className="btn btn-primary" onClick={() => this.closePopup()} value="Close" /></div>
                                        </div>
                                    </div></div>
                        </div>
                    </div>
                </div></div>
        )

    }
}    