import React, { Component } from "react";
import axios from "axios";
import '../../App.css';
import Top from '../../includes/top'
import Sidebar from '../../includes/sidebar'


export default class UnlockVisit extends Component {



state = { id: 0, firstname: '', surname: '', dateOfBirth: '', list: [], companyId: sessionStorage.getItem('companyId'), reloadPatientList: false, duplicateCount: 0, visitId: 0, visit: '', loader:false , clusterCompanys: []}    
componentDidMount() {
    this.getCluster();
   }
getCluster()
{
    axios.get(localStorage.getItem('host') + '/auth/getclustercompanys/'+sessionStorage.getItem('companyId'), {
        headers: {
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        }
    }).then(
        res => {
            this.setState({clusterCompanys: res.data});
        }, err => {
            console.log(".....")
             this.setState({error: err+""})
            this.props.setLoader(false)
        }
    )
}
getPatientList() {

    this.setLoader(true)
    var data = { firstname: this.state.firstname, surname: this.state.surname, dateOfBirth: this.state.dateOfBirth, companyId: this.state.companyId }
    axios.post(localStorage.getItem('host') + '/bill/patientlist', data, {
        headers: {
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        }
    }).then(
        res => {

            this.setLoader(false)
            for (var index in res.data) {
                res.data[index].action = <button className="btn btn-our" style={{ backgroundColor: '#36BEEE', color: '#FFF' }} id={res.data[index].id} onClick={e => this.action(e)}>Edit</button>
            }
            this.setState({ list: res.data });
        }, err => {
            console.log(".....")
             this.setState({error: err+""})
            this.props.setLoader(false)
        }
    )
}
setLoader(loader)
{
 this.setState({loader: loader})
}
lock(patientId, value, companyId)
      {
        this.setLoader(true)
       axios.get(localStorage.getItem('host') + '/bill/updatelock/'+value+'/' +patientId+'/'+companyId, {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({msg: res.data});
                window.scrollTo(0, 0);
                   this.getPatientList()
            }, err => {
                 this.setState({error: err+""})
                 window.scrollTo(0, 0);
                this.setLoader(false)
            }
        )

      }
render() {
    let divMsg = ''
    if (this.state.msg) {
        divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
    }
    let loaderDiv = ''
    if(this.state.loader)
       {
        loaderDiv = (  <div id="loader" className="loader-overlay"><div align="center" className="loader"></div></div>)
       }
    return (
        <div className="d-flex" id="wrapper">
            <Sidebar />
            <div id="page-content-wrapper">
                <Top />
                <div className="container-fluid">
                    {divMsg}
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col"><div className="input-group"><span className="input-group-text">Firstname</span> <input type="text" className="form-control" value={this.state.firstname} onChange={e => { this.setState({ firstname: e.target.value }) }} /></div></div>
                                <div className="col"><div className="input-group"><span className="input-group-text">Surname</span> <input type="text" className="form-control" value={this.state.surname} onChange={e => { this.setState({ surname: e.target.value }) }} /></div></div>
                                <div className="col"><div className="input-group"><span className="input-group-text">Date Of Birth</span> <input type="text" className="form-control" value={this.state.dateOfBirth} onChange={e => { this.setState({ dateOfBirth: e.target.value }) }} /></div></div>
                                <div className="col"><div className="input-group"><span className="input-group-text">Company</span><select className="form-control" onChange={(e) => this.setState({ companyId: e.target.value })}  >
                                                {this.state.clusterCompanys.map((item, index) => (
                                                    <option key={index} value={item.id} >{item.name}</option>
                                                ))}
                                            </select>  </div></div>
                                <div className="col"><button className="btn btn-success" onClick={() => this.getPatientList()} >Search</button></div>
                            </div>

                        </div></div>
                    <table className="table table-striped">
                        <tr>
                            <th>Action</th>
                            <th>Current Status</th>
                            <th>Fullname</th>
                            <th>Number</th>
                            <th>Gender</th>
                            <th>Date Of Birth</th>
                            <th>File Number</th>
                        </tr>
                        <tbody>
                            {this.state.list.map((item, index) => (
                                <tr key={index}>
                                    <td>{ item.allowSave === 1 ?(<span className="link" title="Lock" id={item.id} onClick={e => this.lock(e.currentTarget.id, 0, item.companyId)}><i class="bi bi-unlock-fill"></i></span>)
                                        :(<span className="link" title="Unlock" id={item.id} onClick={e => this.lock(e.currentTarget.id, 1, item.companyId)}><i class="bi bi-lock-fill"></i></span>)}
                                    </td>
                                    <td>{item.allowSave === 1 ?(<span>Unlocked</span>):(<span>Locked</span>)}</td>
                                    <td>{item.fullname}</td>
                                    <td>{item.number}</td>
                                    <td>{item.gender}</td>
                                    <td>{item.dateOfBirth}</td>
                                    <td>{item.fileNumber}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                   {loaderDiv}
                </div>
            </div ></div >
    )

}
}    