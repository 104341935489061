import React, { Component } from "react";
import axios from "axios";
import '../../App.css';
import Top from '../../includes/top'
import Sidebar from '../../includes/sidebar'
import DatePicker from "react-datepicker";

import DateFnsUtils from '@date-io/date-fns';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import fileDownload from 'js-file-download'


const now = new Date();
const todayY = now.getFullYear();
const todayM = checkZero(now.getMonth() + 1);
const todayD = checkZero(now.getDate());
var date1 = todayY + '-' + todayM + '-' + todayD;
function checkZero(num) {
    return (num + "").length === 1 ? "0" + num : num;
}
export default class ReportView extends Component {

    state = {  id: 0, columns: [], rows: 0, filters: [], list: [], name: '', dataResult: '', dataList: -1, loader: false, startDate: new Date(date1+' 00:00:00'), endDate: new Date(), required1: false, required2: false, required3: false, required4: false,required5: false, required6: false,required7: false,nameFilter1: '',nameFilter2: '', nameFilter3: '', nameFilter4: '', nameFilter5: '', nameFilter6: '', nameFilter7: '', errorMsg: '' }

    componentDidMount() {

        this.getList()
    }
    getList() {
       // this.setLoader(true)
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let id = Number(params.get('id'));

        axios.get(localStorage.getItem('host') + '/report/generatereportbyid/' + id+'/'+sessionStorage.getItem('companyId')+'/'+sessionStorage.getItem('roleId')+'/'+sessionStorage.getItem('userId')+'/'+sessionStorage.getItem('speciality'), {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(
            res => {
             //   this.setLoader(false)
             let required1 = false
             let required2 = false 
             let required3 = false 
             let required4 = false
             let required5 = false 
             let required6 = false
             let required7 = false 
             let nameFilter1 = ''
             let nameFilter2 = ''
             let nameFilter3 = '' 
             let nameFilter4 = ''
             let nameFilter5 = '' 
             let nameFilter6 = ''
             let nameFilter7 = '' 
             for(let i in res.data.filters)
                 {
                  if(res.data.filters[i].parameterName === "parameter1")
                     {                      
                        required1 = res.data.filters[i].required === 1
                        nameFilter1 = res.data.filters[i].label
                     }
                  if(res.data.filters[i].parameterName === "parameter2")
                     {                      
                        required2 = res.data.filters[i].required === 1
                        nameFilter2 = res.data.filters[i].label
                     }
                  if(res.data.filters[i].parameterName === "parameter3")
                     {                      
                        required3 = res.data.filters[i].required === 1
                        nameFilter3 = res.data.filters[i].label
                     }
                  if(res.data.filters[i].parameterName === "parameter4")
                     {                      
                        required4 = res.data.filters[i].required === 1
                        nameFilter4 = res.data.filters[i].label
                     }
                  if(res.data.filters[i].parameterName === "parameter5")
                     {                      
                        required5 = res.data.filters[i].required === 1
                        nameFilter5 = res.data.filters[i].label
                     }
                  if(res.data.filters[i].parameterName === "parameter6")
                     {                      
                        required6 = res.data.filters[i].required === 1
                        nameFilter6 = res.data.filters[i].label
                     }
                  if(res.data.filters[i].parameterName === "parameter7")
                     {                      
                        required7 = res.data.filters[i].required === 1
                        nameFilter7 = res.data.filters[i].label
                     }
                 }
                this.setState({ filters: res.data.filters, id: id, name: res.data.name, required1, required2, required3, required4,required5, required6,required7, nameFilter1,nameFilter2, nameFilter3, nameFilter4, nameFilter5, nameFilter6, nameFilter7});
            }, err => {
                this.setState({ error: err + "" })
                this.props.setLoader(false)
            }
        )
    }
    search() {
        let data = this.getParameterData("")
        if(data.allow === true)
        {
            this.setLoader(true)
            this.setState({dataList: -1, error: ''})
        axios.post(localStorage.getItem('host') + '/report/getreportdata', data, {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setLoader(false)
                this.setState({ name: res.data.name, dataResult: res.data.dataResult, dataList:res.data.dataList });
            }, err => {
                this.setState({ error: err + "" })
                this.setLoader(false)
            }
        )
       }
     else
       {
        this.setState({error: this.state.errorMsg})
       }  
    }
    getParameterData(exportType) {
        var parameter1 = "";
        var parameter2 = "";
        var parameter3 = "";
        var parameter4 = "";
        var parameter5 = "";
        var parameter6 = "";
        var parameter7 = "";
        if (document.getElementById("parameter1") instanceof Object && document.getElementById("parameter1") !== undefined) {
            parameter1 = document.getElementById("parameter1").value;
        }
        if (document.getElementById("parameter2") instanceof Object && document.getElementById("parameter2") !== undefined) {
            parameter2 = document.getElementById("parameter2").value;
        }
        if (document.getElementById("parameter3") instanceof Object && document.getElementById("parameter3") !== undefined) {
            parameter3 = document.getElementById("parameter3").value;
        }
        if (document.getElementById("parameter4") instanceof Object && document.getElementById("parameter4") !== undefined) {
            parameter4 = document.getElementById("parameter4").value;
        }
        if (document.getElementById("parameter5") instanceof Object && document.getElementById("parameter5") !== undefined) {
            parameter5 = document.getElementById("parameter5").value;
        }
        if (document.getElementById("parameter6") instanceof Object && document.getElementById("parameter6") !== undefined) {
            parameter6 = document.getElementById("parameter6").value;
        }
        if (document.getElementById("parameter7") instanceof Object && document.getElementById("parameter7") !== undefined) {
            parameter7 = document.getElementById("parameter7").value;
        }
        let allow = true
        let errorMsg = ''
        console.log(this.state.required1+' '+this.state.required2+'.......... required')
        if(this.state.required1 === true && parameter1 === '')
           {
            allow = false
            errorMsg = this.state.nameFilter1+' cannot be empty. Please enter'
           }
        if(this.state.required2 === true && parameter2 === '')
           {
            allow = false
            errorMsg = this.state.nameFilter2+' cannot be empty. Please enter'
           }
        if(this.state.required3 === true && parameter3 === '')
           {
            allow = false
            errorMsg = this.state.nameFilter3+' cannot be empty. Please enter'
           }
        if(this.state.required4 === true && parameter4 === '')
           {
            allow = false
            errorMsg = this.state.nameFilter4+' cannot be empty. Please enter'
           }
        if(this.state.required5 === true && parameter5 === '')
           {
            allow = false
            errorMsg = this.state.nameFilter5+' cannot be empty. Please enter'
           }
        if(this.state.required6 === true && parameter6 === '')
           {
            allow = false
            errorMsg = this.state.nameFilter6+' cannot be empty. Please enter'
           }
        if(this.state.required7 === true && parameter7 === '')
           {
            allow = false
            errorMsg = this.state.nameFilter7+' cannot be empty. Please enter'
           }
        this.setState({errorMsg})   
        return { companyId: sessionStorage.getItem('companyId'), roleId: sessionStorage.getItem('roleId'), userId: sessionStorage.getItem('userId'), speciality: sessionStorage.getItem('speciality'), fullname: sessionStorage.getItem('fullname'), companyName: sessionStorage.getItem('companyName'),  parameterMapper: { parameter1: parameter1, parameter2: parameter2, parameter3: parameter3, parameter4: parameter4, parameter5: parameter5, parameter6: parameter6, parameter7: parameter7 }, reportId: this.state.id, exportType: exportType,allow }
    }
    downloadExcel() {
        this.setLoader(true)
       axios.get(localStorage.getItem('host') + '/report/exportexcel/' + this.state.id+'/'+this.state.name+'/'+this.state.dataResult+'/'+sessionStorage.getItem('companyId'), { responseType: 'blob', 
       headers: {
           'Authorization': `Bearer ${sessionStorage.getItem('token')}`
       }})
      .then((res) => {
        this.setLoader(false)
        fileDownload(res.data, this.state.name+".xlsx")
      })
    }
    downloadPdf() {
        this.setLoader(true)
       axios.get(localStorage.getItem('host') + '/report/pdfview/' + this.state.id+'/'+this.state.name+'/'+this.state.dataResult+'/'+sessionStorage.getItem('companyId'), { responseType: 'blob', 
       headers: {
           'Authorization': `Bearer ${sessionStorage.getItem('token')}`
       }})
      .then((res) => {
        this.setLoader(false)
        fileDownload(res.data, this.state.name+".pdf")
      })
    }
    downloadCsv() {
        this.setLoader(true)
       axios.get(localStorage.getItem('host') + '/report/exportcvs/' + this.state.id+'/'+this.state.name+'/'+this.state.dataResult+'/'+sessionStorage.getItem('companyId'), { responseType: 'blob', 
       headers: {
           'Authorization': `Bearer ${sessionStorage.getItem('token')}`
       }})
      .then((res) => {
        this.setLoader(false)
        fileDownload(res.data, this.state.name+".csv")
      })
    }
    processDates(startDate, endDate)
       {
        const now = new Date(startDate);
        const now1 = new Date(endDate);
        var date1 = now.getFullYear() + '-' + checkZero(now.getMonth() + 1) + '-' + checkZero(now.getDate())+' '+checkZero(now.getHours())+':'+checkZero(now.getMinutes())+':'+checkZero(now.getMinutes());
        var date2 = now1.getFullYear() + '-' + checkZero(now1.getMonth() + 1) + '-' + checkZero(now1.getDate())+' '+checkZero(now1.getHours())+':'+checkZero(now1.getMinutes())+':'+checkZero(now1.getMinutes());
        return date1+','+date2 ;
       } 
       processDate(date)
         {
           const now = new Date(date);
           const todayY = now.getFullYear();
           const todayM = checkZero(now.getMonth() + 1);
           const todayD = checkZero(now.getDate());
           var date1 = todayY + '-' + todayM + '-' + todayD;
           console.log(date1)
          return date1;
         }
    setLoader(loader)
    {
     this.setState({loader: loader})
    }
    render() {
        let download = ''
        if (this.state.dataList > 0) {
            download = (<div className='row'><div className="col"><span className="link" style={{backgroundColor: '#36BEEE', color: '#FFF', fontSize: 2+'em', paddingTop: 10}} title="Download Excel" onClick={() => { this.downloadExcel(); }} ><i class="bi bi-file-earmark-spreadsheet-fill"></i></span></div>
                <div className="col"><span className="link" style={{backgroundColor: '#36BEEE', color: '#FFF', fontSize: 2+'em', paddingTop: 10}} title="Download Pdf" onClick={() => { this.downloadPdf(); }} ><i class="bi bi-file-earmark-pdf-fill"></i></span></div>
                <div className="col"><span className="link" style={{backgroundColor: '#36BEEE', color: '#FFF', fontSize: 2+'em', paddingTop: 10}} title="Download CSV" onClick={() => { this.downloadCsv(); }} ><i class="bi bi-filetype-csv"></i></span></div>
            </div>)
        }
        else if(this.state.dataList === 0){
            download = <div className='row'><div className="col"><div className="alert alert-info">No data found for the search criteria</div></div></div>   
        }
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        }
        if (this.state.error) {
            divMsg = (<div className='alert alert-danger'>{this.state.error}</div>)
        }
        let loaderDiv = ''
        if(this.state.loader)
           {
            loaderDiv = (  <div id="loader" className="loader-overlay"><div align="center" className="loader"></div></div>)
           }
        return (
            <div className="d-flex" id="wrapper">
            <Sidebar />
            <div id="page-content-wrapper">
                <Top />
                
                <div className="container-fluid">
                        <div className="row">
                        <div className="col"><h3 className="text-dark">{this.state.name}</h3></div>
                         </div>
                         <div className="row">
                            <div className="col"><div className="alert alert-warning">Any filter with <i style={{color: 'red'}} class="bi bi-asterisk"></i> is a required field</div></div>
                        </div>
                       <div className="card">
                       
                        <div className='row'>
                            {this.state.filters.map((filter, index) => (
                                <div className="col" key={index} >{filter.required === 1 && <i style={{color: 'red'}} class="bi bi-asterisk"></i>} 
                                <div className="input-group"><span className="input-group-text" dangerouslySetInnerHTML={{ __html: filter.label }}></span>   
                                    { filter.filterType === 'DATE_RANGE' && <span>
                                 <MuiPickersUtilsProvider  utils={DateFnsUtils}>  <DateTimePicker
                                    format="yyyy-MM-dd HH:mm:ss"
                                    variant="inline"
                                    ampm={false}
                                    value={this.state.startDate}
                                    maxDate={this.state.endDate}
                                    onChange={(e)=>{ this.setState({startDate: e}); document.getElementById(filter.parameterName).value = this.processDates(e, this.state.endDate);  }}
                                    /> 
                                    <DateTimePicker
                                    format="yyyy-MM-dd HH:mm:ss"
                                    variant="inline"
                                    ampm={false}
                                    value={this.state.endDate}
                                    minDate={this.state.startDate}
                                    maxDate={new Date()}
                                    onChange={(e)=>{ this.setState({endDate: e}); document.getElementById(filter.parameterName).value = this.processDates(this.state.startDate, e);   }}
                                    /> 
                                    </MuiPickersUtilsProvider>
                                 </span>}
                                 { (filter.filterType === 'REPLACE_SINGLE_DATE' || filter.filterType === 'SINGLE_DATE' )&& (<span>
                                    <DatePicker className="form-control" selected={new Date(this.state.startDate)} onChange={d => { this.setState({ startDate: this.processDate(d) }); document.getElementById(filter.parameterName).value = this.processDate(d)} } dateFormat="yyyy-MM-dd" />
                                 </span>)}<div dangerouslySetInnerHTML={{ __html: filter.inputField }} /></div></div>

                            ))}
                            <div className="col"><button className="btn btn-success" onClick={() => this.search()} ><i class="bi bi-search"></i></button></div>
                        </div>
                        </div> 
                        <br />
                        {download}
                        {divMsg}
                        {loaderDiv}
                    </div>
                </div></div>
        )

    }
}    