import React, { Component } from "react";
import axios from "axios";
import '../../App.css';

export default class StockUpdate extends Component {
    state = {id: 0,  number: '', locationName: '', bookStockItems: [], msg: '', error: '' , showSubmit: true}
  
   
    openStockUpdate(id, number, locationName) {
        document.getElementById('stockUpdateAction').style = 'display:block' 
        this.setState({ id: id, number: number, locationName: locationName, bookStockItems: [], msg: '', error: '', showSubmit: true });
        this.props.setLoader(true)
        axios.get(localStorage.getItem('host') + '/stockmanagement/getbookstock/' + id , {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(
            res => {
                for(var i in res.data)
                   {
                    res.data[i].countedQuantityError  = ""; 
                    res.data[i].countedQuantity = res.data[i].countedQuantity === -1?"":res.data[i].countedQuantity
                   }
                this.setState({  bookStockItems: res.data });
                this.props.setLoader(false)
            }, err => {
                console.log(".....")
                this.setState({ error: err + "" })
                this.props.setLoader(false)
            }
        )
    }
    save() {
        this.props.setLoader(true)
        var data = { id: this.state.id, bookStockItems: this.state.bookStockItems}
        axios.post(localStorage.getItem('host') + '/stockmanagement/savestockupdate', data, {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(
            res => {
                console.log(res.data)
                this.close()
                this.props.getList()
                this.props.setMsg(res.data)
            }, err => {
                console.log(".....")
                this.setState({ error: err + "" })
                this.props.setLoader(false)
            }
        )
    }
    close() {
        document.getElementById('stockUpdateAction').style = 'display:none'
    } 
    currencyFormat(amount) {
        let val = (amount / 1).toFixed(2)
        return val.toString().split(/(?=(?:\d{3})+(?:\.|$))/g).join(" ").replace("-,", "-");
    }
    setCountedQuantity(index, e) {
        var value = e.target.value
        var showSubmit = false
        this.setState({showSubmit: false})   
        var list = this.state.bookStockItems 
      if(value >= 0)
         {
            list[index].countedQuantity = value
            list[index].countedQuantityError = "";
         }
      else
         {
            list[index].countedQuantity = ""
            list[index].countedQuantityError = "Wrong quantity value";
         }  
      for(var i in list)
         {
            console.log("........"+showSubmit+" "+list[i].countedQuantity )
           if( list[i].countedQuantity !== "" && Number( list[i].countedQuantity) >= 0)
              {
                showSubmit = true
                console.log("........"+showSubmit)
              }
         }   
      this.setState({bookStockItems: list, showSubmit: showSubmit})    
    }
    render() {
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        }
        if (this.state.error) {
            divMsg = (<div className='alert alert-danger'>{this.state.error}</div>)
        }
        return (
                        <div id="stockUpdateAction" className="page-popup page-popup-overlay"   >
                        <div className="card" >
                            <div className="card-header bg-info">Stock Update Action</div>
                            <div className="card-body" >
                                {divMsg}
                        <div className="card card-green">
                            <div className="card-body">
                            <div className="row">
                                    <div className="col"><span className="fw-bold">Number:</span> {this.state.number}</div>
                                    <div className="col"><span className="fw-bold">Location:</span> {this.state.locationName}</div>
                                </div>
                            </div></div>   
                        <br />
                        <div className="card card-green">
                            <div className="card-header">Items</div>
                            <div className="card-body">
                            
                                <table className="table table-striped" >
                                <tr>
                                                         <th>Code</th>
                                                        <th>Name</th>
                                                        <th>Category</th>
                                                        <th>Type</th>
                                                        <th>Buying Price</th>
                                                        <th>Dispensing Fee</th>
                                                        <th>Batch Number</th>
                                                        <th>Expiry Date</th>
                                                        <th>Manufacturer</th>
                                                        <th>Supplier</th>
                                                        <th>Counted Quantity</th>
                                                    </tr>
                                                    <tbody>                                               
                                                     {this.state.bookStockItems.map((item, index) => (
                                                        <tr>
                                                           <td>{item.code}</td>
                                                            <td>{item.name}</td>
                                                            <td>{item.category}</td>
                                                            <td>{item.type}</td>
                                                            <td>{this.currencyFormat(item.buyingPrice)}</td>
                                                            <td>{item.dispensingFee}</td>
                                                            <td>{item.batchNumber}</td>
                                                            <td>{item.expiryDate}</td>
                                                            <td>{item.manufacturer} </td>
                                                            <td>{item.supplier}</td>
                                                            <td>{item.countedQuantity}</td>
                                                          
                                                        </tr>
                                                    ))}
                                                    </tbody>
                                </table>
                                <div className="row">
                                    <div className="input-group">
                                        <input type="hidden" className={this.state.stockUpdateItemsError !== "" ? "form-control is-invalid" : "form-control"} />
                                        <div className="invalid-feedback">{this.state.stockUpdateItemsError}</div> </div></div>  </div></div>
                        <div className="row">
                            <div className="col" align="right">{this.state.showSubmit === true && (<input type="button" className="btn btn-primary" onClick={() => this.save()} value="Save" />)}</div>
                            <div className="col"><input type="button" className="btn btn-primary" onClick={() => this.close()} value="Cancel" /></div>
                        </div>
                        </div>
                </div ></div >
        )

    }
}    