import React, { Component } from "react";
import axios from "axios";
import '../../App.css';



export default class SmsAction extends Component {
   state = {smsCount: 0, sms: '', visitId: 0, mobile: '' , companyId: 0}
    openSmsAction(visitId) {
        this.setState({ sms: '', visitId: visitId, fullname: '', mobile: '' , contact: '', companyId: 0})
        axios.get(localStorage.getItem('host') + '/sms/getresultsms/' + visitId, {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(
            res => {
                var sms = 'Good day. Results for '+res.data.fullname+' are out may you please make an appointment through our reception on '+res.data.contact+' for review. Thank you.'
                var smsCount  = sms.length
                this.setState({ sms: sms, smsCount: smsCount, mobile: res.data.mobile, companyId: res.data.companyId});
                this.props.setLoader(false)

            }, err => {
                console.log(".....")
                this.setState({ error: err + "" })
                this.props.setLoader(false)
            }
        )

    }
    closePopup() {
        document.getElementById('smsAction').style = 'display:none'
    }

    save() {
        this.props.setLoader(true)
        var data = {companyId: this.state.companyId, message: this.state.sms, smsType: 'RESULTS', mobile: this.state.mobile}
        axios.post(localStorage.getItem('host') + '/sms/singlesmssave', data, {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(
            res => {this.props.setLoader(false)
                this.setState({ msg: res.data });
                setTimeout(() => {
                    this.setState({ msg: '' });
                }, 3000);
            }, err => {
                this.setState({ error: err+""})
                this.props.setLoader(false)
            }
        )
    }
    setSms(value)
     {
      value = value === null? '':value;
      var smsCount = value.length
      this.setState({sms: value, smsCount: smsCount})   
     }
    render() {
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        }
        return (
            <div id="smsAction" className="page-popup page-popup-overlay"   >
                <div className="card" >
                    <div className="card-header bg-info">Sms Action</div>
                    <div className="card-body" >
                        {divMsg}
                        <div className="row">
                            <div className="col"><div className="input-group"><span className="input-group-text">Sms</span> <textarea value={this.state.sms} onChange={e => this.setSms( e.target.value)} className="form-control" ></textarea></div></div>
                            <div className="col"><div className="input-group"><span className="input-group-text">Character Count</span> <input type="text"  readOnly="readOnly" className="form-control" value={this.state.smsCount} /></div></div>
                        </div>
                        <div className="row">
                            <div className="col" align="right">{ this.state.smsCount > 10 && this.state.smsCount < 161?(<input type="submit" value="Submit" onClick={() => this.save()} className="btn btn-primary" />):(<span></span>)}</div>
                            <div className="col" align="left"><button onClick={() => this.closePopup()} className="btn btn-primary">Cancel</button></div>
                        </div>
                    </div>
                </div>
            </div>
        )

    }
}    