import React, { Component } from "react";
import axios from "axios";
import '../../App.css';
import 'react-quill/dist/quill.snow.css';

export default class LisReport extends Component {
    constructor(props) {
        super(props);
        //this.setPatientValue = this.setPatientValue.bind(this);
        this.editorRef = React.createRef()
    }
    state = {id: 0, visitId: 0, requestVisitId: 0, contentType: '', name: '', report: '', lisStatus: '', rejectReason: '', rejectedBy: '', approvedBy: '', reportedBy: '',  fullname: '', gender: '', dateOfBirth: '', age: '', images: [], msg: '', error: '', showButtons: false, serviceClass: '', parameterLiss: [], cultures: [], organisms: [], incubations: [], incubationCounts: [], additionals: [], lisTemplates: [], lisTemplate: '',generalHistologyT: true, grossAppearanceT: false, microscopicAppearanceT: false,interpretationT: false, comment: '', attendingDoctor: '', generalHistology: '', grossAppearance: '', microscopicAppearance: '', interpretation: '', verification: false, clinicalData: ''}
    componentDidMount() {

    }
    openLisReportAction(lis, verification) {
        this.setState({requestVisitId: 0, verification: verification, id: lis.id, visitId: lis.visitId, name: '', contentType: '', report: '', lisStatus: '', rejectReason: '', rejectedBy: '', approvedBy: '', reportedBy: '', fullname: lis.fullname, gender: lis.gender, dateOfBirth: lis.dateOfBirth, age: lis.stringAge, images: [], msg: '', error: '', showButtons: true, serviceClass: '', parameterLiss: [], cultures: [], organisms: [], incubations: [], incubationCounts: [], additionals: [], lisTemplates: [], lisTemplate: '',generalHistologyT: true, grossAppearanceT: false, microscopicAppearanceT: false,interpretationT: false, comment: '', attendingDoctor: '', generalHistology: '', grossAppearance: '', microscopicAppearance: '', interpretation: '', clinicalData: ''})
        axios.get(localStorage.getItem('host') + '/is/lis/' + lis.id+'/'+sessionStorage.getItem('companyId'), {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({
                    requestVisitId: res.data.requestVisitId, name: res.data.serviceName, age: res.data.stringAge, report: res.data.report, lisStatus: res.data.lisStatus, rejectReason: res.data.rejectReason, rejectedBy: res.data.rejectedBy,  serviceClass: res.data.serviceClass, parameterLiss: res.data.parameterLiss, cultures: res.data.cultures, organisms: res.data.organisms, incubations: res.data.incubations, incubationCounts: res.data.incubationCounts, additionals: res.data.additionals, lisTemplates: res.data.lisTemplates, comment: res.data.comment, attendingDoctor: res.data.attendingDoctor, generalHistology: res.data.generalHistology, grossAppearance: res.data.grossAppearance, microscopicAppearance: res.data.microscopicAppearance, interpretation: res.data.interpretation, clinicalData: res.data.clinicalData});
                this.props.setLoader(false)
                window.scrollTo(0, 0);
            }, err => {
                console.log(".....")
                this.setState({ error: err })
                window.scrollTo(0, 0);
                this.props.setLoader(false)
            }
        )
    }
    save(lisStatus) {
        this.props.setLoader(true)
        var error = "";
        this.setState({error: error, showButtons: false}) 
        if(lisStatus === "REJECT" && (this.state.rejectReason === "" || this.state.rejectReason.length < 3))
        {
        error = "Reject Reason cannot be empty";
        }
        if(error === "")
          {
        var data = {id: this.state.id, visitId: this.state.visitId, requestVisitId: this.state.requestVisitId, fullname: this.state.fullname, gender: this.state.gender, dateOfBirth: this.state.dateOfBirth, stringAge: this.state.age, report: this.state.report, cultures: this.state.cultures, parameterLiss: this.state.parameterLiss, lisStatus: lisStatus , reportedBy: sessionStorage.getItem('fullname'), serviceClass: this.state.serviceClass, comment: this.state.comment, attendingDoctor: this.state.attendingDoctor, generalHistology: this.state.generalHistology, grossAppearance: this.state.grossAppearance, microscopicAppearance: this.state.microscopicAppearance, interpretation: this.state.interpretation, speciality: sessionStorage.getItem('speciality')}
        axios.post(localStorage.getItem('host') + '/is/savelis', data, {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.props.setLoader(false)
                this.setState({ msg: res.data });
                window.scrollTo(0, 0);
                this.props.getList()
            }, err => {
                console.log(".....")
                this.setState({ error: err })
                window.scrollTo(0, 0);
                this.props.setLoader(false)
            }
        )
          }
    }
    closePopup() {
        document.getElementById('lisReport').style = 'display:none';
        window.scrollTo(0, 0);
    }
    setRowValue(index, e, name) {
        let list = this.state.parameterLiss.slice();
        switch (name) {
         case "result":
                        list[index].result = e.target.value;
                        break;
         case "comment":
                        list[index].comment = e.target.value;
                        break;
         case "activeStatus":
                        list[index].activeStatus = e.target.value;
                        break;
               default:
                       console.log("default")
                        break;
                      }       
        this.setState({ parameterLiss: list })
      } 
      setCultureRowValue(index, e, name) {
        let list = this.state.cultures.slice();
        switch (name) {
         case "organismId":
                        list[index].organismId = e.target.value;
                        break;
         case "incubationId":
                        list[index].incubationId = e.target.value;
                        break;
         case "incubationCountId":
                        list[index].incubationCountId = e.target.value;
                        break;
         case "additionalId":
                        list[index].additionalId = e.target.value;
                        break;
         case "sensitivity":
                        list[index].sensitivity = e.target.value;
                        break;
         case "notes":
                        list[index].notes = e.target.value;
                        break;
         case "activeStatus":
                        list[index].activeStatus = e.target.value;
                        break;
               default:
                       console.log("default")
                        break;
                      }       
        this.setState({ cultures: list })
      }
      add()
    {
      let list = this.state.cultures.slice();
      list.push({id:0, organismId: '', incubationId: '', incubationCountId: '', additionalId: '', sensitivity: '', notes: '',  activeStatus: 1});
      this.setState({cultures:list })
    }
    toggleTabs(name) {
        switch (name) {
            case "grossAppearanceT":
                this.setState({ generalHistologyT: false, grossAppearanceT: true, microscopicAppearanceT: false , interpretationT: false})
                break
            case "microscopicAppearanceT":
                this.setState({ generalHistologyT: false, grossAppearanceT: false, microscopicAppearanceT: true, interpretationT: false })
                break
            case "interpretationT":
                this.setState({ generalHistologyT: false, grossAppearanceT: false, microscopicAppearanceT: false, interpretationT: true})
                break
            default: 
                this.setState({ generalHistologyT: true, grossAppearanceT: false, microscopicAppearanceT: false,interpretationT: false})
                break

        }
    }
    render() {
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        } //v-bind:style="'height: '+heightDuplicateRecords+'px;max-height: '+heightDuplicateRecords+'px;overflow: auto;overflow-x: hidden;'"
        if (this.state.error) {
            divMsg = (<div className='alert alert-danger'>{this.state.error}</div>)
        }
        return (<div id="lisReport" className="page-popup page-popup-overlay"   >
            <div className="card" >
            <div className="card-header  bg-info">Report Action</div>
            <div className="card-body" >
                {divMsg}
                <div id="showFileNurseAction" className="page-popup page-popup-overlay" >
                    <div className="card" >
                        <div className="card-header  bg-info">File View</div>
                        <div className="card-body" >
                            <img title="File View" alt={this.state.contentType} src={'data:'+this.state.contentType+';base64,' + this.state.fileData}  style={{ width: 100 + '%' }} />
                            <div className="row">
                                <div className="col" align="center" ><button onClick={() => this.fileNurseClosePopup()} className="btn btn-primary">Close</button></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="card border-success">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col"><span className="fw-bold">Fullname:</span> {this.state.fullname}</div>
                                    <div className="col"><span className="fw-bold">Gender:</span> {this.state.gender}</div>
                                    <div className="col"><span className="fw-bold">DOB:</span> {this.state.dateOfBirth}</div>
                                 </div>
                                 <div className="row">   
                                    <div className="col"><span className="fw-bold">Age:</span> <span dangerouslySetInnerHTML={{ __html: this.state.age }}></span></div>
                                    <div className="col"><span className="fw-bold">Rejected By:</span> {this.state.rejectedBy}</div>
                                    <div className="col"><span className="fw-bold">Reject Reason:</span> {this.state.rejectReason}</div>
                                </div>
                            </div>
                        </div>
                        <div className="card border-success">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col"><span className="fw-bold">Name:</span> {this.state.name}</div>
                                    <div className="col"><span className="fw-bold">Status:</span> {this.state.lisStatus}</div>
                                 </div>
                                 <div className="row">
                                    <div className="col"><span className="fw-bold">Clinical Data:</span> {this.state.clinicalData}</div>
                                 </div>
                            </div>
                        </div>
                        <div className="row">
                            { this.state.showButtons === true && this.state.verification === false &&<div className="col" align="center"><button onClick={() => this.save('REPORTED')} className="btn btn-primary">Save</button></div>}
                            { this.state.showButtons === true && this.state.verification === true &&<div className="col" align="center"><button onClick={() => this.save('APPROVED')} className="btn btn-primary">Approve</button></div>}
                            { this.state.showButtons === true && this.state.verification === true &&<div className="col" align="center"><button onClick={() => this.save('REJECTED')} className="btn btn-primary">Reject</button></div>}
                            <div className="col" align="center"><button onClick={() => this.closePopup()} className="btn btn-primary">Close</button></div>
                        </div>
                        <br />
                       {this.state.verification === true && 
                        <div className="row">
                            <div className="col"><div className="input-group">
                                <span className="input-group-text">Rejection Reason</span>
                                <textarea className="form-control" value={this.state.rejectReason} onChange={e => { this.setState({ rejectReason: e.target.value });} } ></textarea>
                            </div></div>
                            <div className="col"></div>
                        </div>   } 
                        <br />
                     { this.state.serviceClass === "QUANTITATIVE" && <div className="row">

           <div className="col">
           <table class="table table-striped">
                 <tr>
                     <th>Name</th>
                     <th>Interpretation</th>
                     <th>Result</th>
                     <th>Unit</th>
                     <th>Range</th>
                     <th>Comment</th>
                 </tr> 
                 <tbody>
                 {this.state.parameterLiss.map((item, index) => (
                 <tr key={index}>
                     <td>{item.printName}</td>
                     <td>{item.interpretation}</td>
                    { item.parameterType === 'HEADER' && <td></td> }
                    { item.parameterType === 'PREDEFINED' &&  <td ><select class="form-control" value={item.result} onChange={e => { this.setRowValue(index, e, "result") }}>
                     <option value="">Choose</option>
                              {item.predefineds.map((item1, index1) => (
                             <option key={index1} value={item1}>{item1}</option>
                              ))}
                         </select></td> }
                    { item.parameterType === 'NUMERIC' && <td><input type="text" class="form-control" value={item.result} onChange={e => { this.setRowValue(index, e, "result") }} /></td>}
                    { item.parameterType === 'TEXT' &&  <td  colspan="3"><textarea class="form-control" value={item.result} onChange={e => { this.setRowValue(index, e, "result") }} ></textarea></td>}
                    { item.parameterType !== 'TEXT' &&  <td>{item.unit}</td>}
                    { item.parameterRange === 1 && <td>{item.minRange} - {item.maxRange}</td>}
                    { item.parameterRange === 0 && item.parameterType !== 'TEXT' && <td></td>}
                    { item.parameterType !== 'HEADER' && <td><input type="text" class="form-control" value={item.comment} onChange={e => { this.setRowValue(index, e, "comment") }} /></td>}
                    { item.parameterType === 'HEADER' &&  <td v-if="item.parameterType == 'HEADER'"></td>}
                                   </tr>))}
                 </tbody>
             </table>
           </div>
               </div> }   
               { this.state.serviceClass === "QUALITATIVE" && <div className="row"><div className="col">
               <table class="table">
                 <tr>
                     <th>Organism</th>
                     <th>Count</th>
                     <th>Period</th>
                     <th>Additional</th>
                     <th>Sensitivity</th>
                     <th>Notes</th>
                     <th>Active Status</th>
                 </tr>
                 {this.state.cultures.map((item, index) => (
                 <tr key={index}>
                     <td><select class="form-control" value={item.organismId} onChange={e => { this.setCultureRowValue(index, e, "organismId") }}>
                     <option value="">Choose</option>
                          {this.state.organisms.map((item1, index1) => (
                             <option key={index1} value={item1.id}>{item1.name}</option>
                              ))}
                         </select></td>
                     <td><select class="form-control" value={item.incubationCountId} onChange={e => { this.setCultureRowValue(index, e, "incubationCountId") }}>
                     <option value="">Choose</option>
                              {this.state.incubationCounts.map((item1, index1) => (
                             <option key={index1} value={item1.id}>{item1.name}</option>
                              ))}
                         </select></td>
                     <td><select class="form-control" value={item.incubationId} onChange={e => { this.setCultureRowValue(index, e, "incubationId") }}>
                     <option value="">Choose</option>
                              {this.state.incubations.map((item1, index1) => (
                             <option key={index1} value={item1.id}>{item1.name}</option>
                              ))}
                         </select></td>
                     <td><select class="form-control" value={item.additionalId} onChange={e => { this.setCultureRowValue(index, e, "additionalId") }}>
                     <option value="">Choose</option>
                              {this.state.additionals.map((item1, index1) => (
                             <option key={index1} value={item1.id}>{item1.name}</option>
                              ))}
                         </select></td>
                     <td><select class="form-control" value={item.sensitivity} onChange={e => { this.setCultureRowValue(index, e, "sensitivity") }} >
                     <option value="">Choose</option>
                            <option value="SENSITIVE">Sensitive</option>
                            <option value="INTERMEDIATE">Intermediate</option>
                            <option value="RESISTANT">Resistant</option>
                        </select></td>
                        <td><input type="text" class="form-control" value={item.notes} onChange={e => { this.setCultureRowValue(index, e, "notes") }} /></td>
                     <td><select class="form-control" value={item.activeStatus} onChange={e => { this.setCultureRowValue(index, e, "activeStatus") }} >
                            <option value="false">No</option>
                            <option value="true">Yes</option>
                        </select></td>
                 </tr>
                 ))}
             </table>
             <button class="btn btn-success" onClick={() => { this.add() }} ><i className="bi bi-plus-square"></i></button> 
                
                </div></div> }             
                { this.state.serviceClass === "HISTOLOGY" && <div className="row">
                    <div className="col">
                    <ul  className="nav nav-tabs" role="tablist">
                    <li className="nav-item"><span className={this.state.generalHistologyT ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("generalHistologyT") }}>General</span></li>
                    <li className="nav-item"><span className={this.state.grossAppearanceT ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("grossAppearanceT") }}>Gross Appearance</span></li>
                    <li className="nav-item"><span className={this.state.microscopicAppearanceT ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("microscopicAppearanceT") }}>Microscopic Appearance</span></li>
                    <li className="nav-item"><span className={this.state.interpretationT ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("interpretationT") }}>Interpretation</span></li>
             </ul>  
             <div class="tab-content">
                 <div  id="generalHistology" className={this.state.generalHistologyT ? "tab-pane fade show active" : "tab-pane fade"} ><div className="row">
                          <div className="col-lg-2" align="right">General</div>
                         <div className="col-lg-10 ">  <textarea rows="10" class="form-control" value={this.state.generalHistology} onChange={(e)=> this.setState({generalHistology: e.target.value})} ></textarea> </div>
                 </div> </div>   
                 <div id="grossAppearance" className={this.state.grossAppearanceT ? "tab-pane fade show active" : "tab-pane fade"} ><div className="row">
                          <div className="col-lg-2" align="right">Gross Appearance</div>
                    <div className="col-lg-10 ">  <textarea rows="10" class="form-control" value={this.state.grossAppearance} onChange={(e)=> this.setState({grossAppearance: e.target.value})} ></textarea> </div>
                 </div> </div>   
                 <div id="microscopicAppearance" className={this.state.microscopicAppearanceT ? "tab-pane fade show active" : "tab-pane fade"} ><div className="row">
                          <div className="col-lg-2" align="right">Microscopic Appearance</div>
                    <div className="col-lg-10 ">  <textarea rows="10" className="form-control" value={this.state.microscopicAppearance} onChange={(e)=> this.setState({microscopicAppearance: e.target.value})} ></textarea> </div>
                 </div> </div>   
                 <div id="interpretation" className={this.state.interpretationT ? "tab-pane fade show active" : "tab-pane fade"} ><div className="row">
                          <div className="col-lg-2" align="right">Interpretation</div>
                    <div className="col-lg-10 ">  <textarea rows="10" className="form-control" value={this.state.interpretation} onChange={(e)=> this.setState({interpretation: e.target.value})} ></textarea> </div>
                 </div> </div>                
             </div>    
                    </div>
                    </div>}    
                    { this.state.serviceClass === "HISTOLOGY" && <div className="row">
                    <div className="col">    
                    <div class="row">
                    <div class="col" align="right"><select class="form-control" value={this.state.lisTemplate} onChange={(e)=> this.setState({lisTemplate: e.target.value})} >
                    <option value="">Choose</option>
                              {this.state.lisTemplates.map((item1, index1) => (
                            <option key={index1} value={item1.data}>{item1.name}</option>
                              ))}
                        </select></div>  
                 <div class="col"><input type="button" class="btn btn-success" onClick={() => { this.setState({report: this.state.lisTemplate}) }} value="Insert" />
                    </div>
                  </div>  
           <div class="row">
          <div class="col">             
              <textarea class="form-control" rows="10" className="form-control" value={this.state.report} onChange={(e)=> this.setState({report: e.target.value})} ></textarea>        
         </div></div>    
                    </div>
                    </div>}     
             <div className="row">
                 <div className="col"><div className="input-group"><span className="input-group-text"> Comment </span><textarea rows="4" className="form-control" value={this.state.comment} onChange={(e)=> this.setState({comment: e.target.value})} ></textarea> </div></div>
             
                </div>
            </div >  </div>
            </div >
        </div >
        </div >)

    }
}      