import React from "react"
import { Navigate, useLocation } from "react-router-dom"
const PrivateElement = ({ children }) => {
    let location = useLocation()
    return sessionStorage.getItem('token') ? (
      children
    ) : (
      <Navigate to="/" state={{ from: location }} />
    )
  }
  
  export default PrivateElement