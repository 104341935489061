import React, { Component } from "react";
import axios from "axios";
import '../../App.css';
import Top from '../../includes/top'
import Sidebar from '../../includes/sidebar'
import PrintOut from '../patient/printout'
import NurseAction from './nurseaction'
import ResultAction from "./resultaction";
import SmsAction from './smsaction'
import DatePicker from "react-datepicker";
import CalendarNurseView from "./calendarnurse";
import "react-datepicker/dist/react-datepicker.css";
import WhatsappAction from './whatsappaction'

const now = new Date();
const todayY = now.getFullYear();
const todayM = checkZero(now.getMonth() + 1);
const todayD = checkZero(now.getDate());
function checkZero(num) {
    return (num + "").length === 1 ? "0" + num : num;
}

export default class NurseListAll extends Component {
    constructor(props) {
        super(props);
    this.setLoader = this.setLoader.bind(this)
    this.getResultAction = this.getResultAction.bind(this)
    this.updateAfterSave = this.updateAfterSave.bind(this)
    this.printOutRef = React.createRef()
    this.nurseActionRef = React.createRef()
    this.resultActionRef = React.createRef()
    this.smsActionRef = React.createRef()
    this.calendarNurseViewRef = React.createRef()
    this.whatsappActionRef = React.createRef()
    }
    state = { id: 0, firstname: '', surname: '', startDate: todayY + '-' + todayM + '-' + todayD, endDate: todayY + '-' + todayM + '-' + todayD , companyId: sessionStorage.getItem('companyId'), list: [], clusterCompanys: [], visitType: 'OUTPATIENT' }
    componentDidMount() {
        axios.get(localStorage.getItem('host') + '/auth/getclustercompanys/'+sessionStorage.getItem('companyId'), {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({clusterCompanys: res.data});
                this.getList()
            }, err => {
                console.log(".....")
                 this.setState({ error: err.Error })
            }
        )
    }
    action(e) {
        var id = e.target.id;
        console.log("......." + id + " .... " + e.target.id)
        this.showForm(id);
    }
    showForm(id) {
        this.setState({ id: 0, name: '', activeStatus: 1 })
        if (id > 0) {
            axios.get(localStorage.getItem('host') + '/master/appointmenttimeaction/' + id, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            }).then(
                res => {
                    this.setState({ name: res.data.name, id: res.data.id, activeStatus: res.data.activeStatus });
                }, err => {
                    console.log(".....")
                }
            )
        }
        document.querySelector('#action').style = 'display:block'
    }
    closePopup() {
        document.querySelector('#action').style = 'display:none'
        console.log('......close');
    }
    save() {
        var data = { id: this.state.id, activeStatus: this.state.activeStatus, name: this.state.name, companyId: sessionStorage.getItem('companyId') }
        axios.post(localStorage.getItem('host') + '/master/appointmenttimeform', data, {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(
            res => {
                console.log(res.data)
                this.setState({ msg: res.data });
                this.getList();
                this.closePopup();
                setTimeout(() => {
                    this.setState({ msg: '' });
                }, 3000);
            }, err => {
                console.log(".....")
            }
        )
    }
    getList() {
        this.setLoader(true)
        var data = { firstname: this.state.firstname, surname: this.state.surname, dateRange: this.state.startDate+","+this.state.endDate, companyId: this.state.companyId, visitType: this.state.visitType }
        axios.post(localStorage.getItem('host') + '/patientmanagement/nurselist', data, {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ list: res.data });
                this.setLoader(false)
            }, err => {
                console.log(".....")
                 this.setState({ error: err.Error })
                this.setLoader(false)
            }
        )
    } 
    getPrintOut(id, type, printouts, companyId)
      {
        this.setState({loader: true})  
        this.printOutRef.current.getPrintOut(id, type, printouts, companyId)
        document.getElementById('printOutAction').style = 'display:block'
      }
    setLoader(loader)
    {
     this.setState({loader: loader})
    }
    openNurseAction(visitId)
    {
    this.nurseActionRef.current.openNurseAction(visitId)
    document.getElementById('nurseAction').style = 'display:block'   
    }
    getResultAction(visitId)
     {
        this.setState({loader: true})
        this.resultActionRef.current.getResultAction(visitId)
        document.getElementById('resultAction').style = 'display:block'     
     }
     openSmsAction(visitId)
     {
        this.setState({loader: true})
        this.smsActionRef.current.openSmsAction(visitId)
        document.getElementById('smsAction').style = 'display:block'   
     } 
     openWhatsappAction(visitId)
     {
        this.setState({loader: true})
        this.whatsappActionRef.current.openWhatsappAction(visitId)
        document.getElementById('whatsappAction').style = 'display:block'   
     } 
     updateAfterSave(visitId, results)
     {

     }  
     processDate(date)
       {
         const now = new Date(date);
         const todayY = now.getFullYear();
         const todayM = checkZero(now.getMonth() + 1);
         const todayD = checkZero(now.getDate());
         var date1 = todayY + '-' + todayM + '-' + todayD;
         console.log(date1)
        return date1;
       }
    render() {
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        }
        let loaderDiv = ''
        if(this.state.loader)
           {
            loaderDiv = (  <div id="loader" className="loader-overlay"><div align="center" className="loader"></div></div>)
           }
        return (
            <div className="d-flex" id="wrapper">
                <Sidebar />
                <div id="page-content-wrapper">
                    <Top />

                    <div className="container-fluid">
                       {divMsg}
                        <div className="row">
                        <div className="col"><button onClick={() => this.calendarNurseViewRef.current.calendar()} className="btn btn-success">Calendar</button></div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card border-primary">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col"><div className="input-group"><span className="input-group-text">Firstname </span> <input type="text" className="form-control" onChange={(e) => this.setState({ firstname: e.target.value })} /></div></div>
                                            <div className="col"><div className="input-group"><span className="input-group-text">Surname</span><input type="text" className="form-control" onChange={(e) => this.setState({ surname: e.target.value })} /></div></div>
                                            <div className="col"><div className="input-group"><span className="input-group-text">Start Date</span> <DatePicker className="form-control" selected={new Date(this.state.startDate)} maxDate={new Date(this.state.endDate)} onChange={d => { this.setState({ startDate: this.processDate(d) });} } dateFormat="yyyy-MM-dd" /></div></div>
                                            <div className="col"><div className="input-group"><span className="input-group-text">End Date</span> <DatePicker className="form-control" selected={new Date(this.state.endDate)}  minDate={new Date(this.state.startDate)} maxDate={new Date()}  onChange={d => { this.setState({ endDate: this.processDate(d) }); } } dateFormat="yyyy-MM-dd" /></div></div>
                                            <div className="col"><div className="input-group"><span className="input-group-text">Visit Type</span> <select className="form-control" value={this.state.visitType} onChange={d => { this.setState({ visitType: d.target.value }); } } ><option value="INPATIENT">INPATIENT</option><option value="OUTPATIENT">OUTPATIENT</option><option value="VACCINATION">VACCINATION</option></select></div></div>
                                           <div className="col">    <button className="btn btn-success" onClick={() => this.getList()} ><i class="bi bi-search"></i></button></div>
                                        </div></div></div>
                            </div>
                        </div>
                        <div>
                            <table className="table table-striped table-hover">
                                <tr>
                                    <th>Action</th>
                                    <th>Number</th>
                                    <th>Name</th>
                                    <th>Date of Birth</th>
                                    <th>Gender</th>
                                    <th>Date</th>
                                    <th>Bill Category</th>
                                    <th>Consultant</th>
                                    <th>Status</th>
                                </tr>
                                <tbody>
                                {this.state.list.map((item, index) => (
                                    <tr key={index} style={{backgroundColor:item.color}}>
                                        <td >
                                            <span className="link" onClick={() => this.openNurseAction(item.id)} title="Edit"><i className="bi bi-pencil-square"></i></span>
                                            {(item.visitStatus === "COMPLETED" || item.visitStatus === "NURSE_ORDERS") && ( <span className="link" onClick={() => this.getPrintOut(item.id, 'NURSE', [], item.companyId)} title="Print" ><i className="bi bi-printer-fill"></i></span>)}
                                            <span className="link" onClick={() => this.openSmsAction(item.id)} title="Send Result Collect Sms" ><i className="bi bi-chat-left-text-fill"></i></span>
                                            { item.showWhatsapp === 1 && <span className="link" onClick={() => this.openWhatsappAction(item.id)} title="Send Result Collect Whatsapp" ><i className="bi bi-whatsapp"></i></span> }
                                             <span className="link" onClick={() => this.getResultAction(item.id)} title="Upload Patient Results" ><i className="bi bi-cloud-upload-fill"></i></span>
                                        </td>
                                        <td>{item.number}</td>
                                        <td>{item.fullname}</td>
                                        <td>{item.dateOfBirth}</td>
                                        <td>{item.gender}</td>
                                        <td>{item.creationDateTime}</td>
                                        <td>{item.billCategory}</td>
                                        <td>{item.consultant}</td>
                                        <td>{item.visitStatus}</td>
                                       </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                        <CalendarNurseView ref={this.calendarNurseViewRef} setLoader={this.setLoader}  />
                        <NurseAction ref={this.nurseActionRef}  setLoader={this.setLoader} updateAfterSave={this.updateAfterSave} />
                        <PrintOut ref={this.printOutRef} setLoader={this.setLoader} />
                        <ResultAction ref={this.resultActionRef} setLoader={this.setLoader} updateAfterSave={this.updateAfterSave} />
                        <SmsAction ref={this.smsActionRef} setLoader={this.setLoader} />
                        <WhatsappAction ref={this.whatsappActionRef} setLoader={this.setLoader} />
                       {loaderDiv}
                    </div>
                </div ></div >
        )

    }
}    