import React, { Component } from "react";
import axios from "axios";
import '../../App.css';
import Top from '../../includes/top'
import Sidebar from '../../includes/sidebar'
import { MDBDataTable } from 'mdbreact';


export default class PrinterNames extends Component {
    state = { id: 0, name: '',generalPrinter: '', generalHeight: '', generalWidth: '', generalPrinterType: '', labelName: '', labelHeight: '', labelWidth: '', labelPrinterType: '', receiptName: '', receiptHeight:'', receiptWidth:'', receiptPrinterType: '', activeStatus: 1, list: [] }
    componentDidMount() {
        this.getList()
    }
    action(e) {
        var id = e.currentTarget.id;
        console.log("......." + id + " .... " + e.currentTarget.id)
        this.showForm(id);
    }
    showForm(id) {
        this.setState({ id: 0, name: '',generalPrinter: '', generalHeight: '', generalWidth: '', generalPrinterType: '', labelName: '', labelHeight: '', labelWidth: '', labelPrinterType: '', receiptName: '', receiptHeight:'', receiptWidth:'', receiptPrinterType: '', activeStatus: 1 })
        if (id > 0) {
            axios.get(localStorage.getItem('host') + '/setting/printernamesaction/' + id, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            }).then(
                res => {
                    this.setState({ name: res.data.name, id: res.data.id,generalPrinter: res.data.generalPrinter, generalHeight: res.data.generalHeight, generalWidth: res.data.generalWidth, generalPrinterType: res.data.generalPrinterType, labelName: res.data.labelName, labelHeight: res.data.labelHeight, labelWidth: res.data.labelWidth, labelPrinterType: res.data.labelPrinterType, receiptName: res.data.receiptName, receiptHeight:res.data.receiptHeight, receiptWidth:res.data.receiptWidth, receiptPrinterType: res.data.receiptPrinterType, activeStatus: res.data.activeStatus });
                }, err => {
                    console.log(".....")
                }
            )
        }
        document.querySelector('#action').style = 'display:block'
    }
    closePopup() {
        document.querySelector('#action').style = 'display:none'
        console.log('......close');
    }
    save() {
        var data = { id: this.state.id, activeStatus: this.state.activeStatus,generalPrinter: this.state.generalPrinter, generalHeight: this.state.generalHeight, generalWidth: this.state.generalWidth, generalPrinterType: this.state.generalPrinterType, labelName: this.state.labelName, labelHeight: this.state.labelHeight, labelWidth: this.state.labelWidth, labelPrinterType: this.state.labelPrinterType, receiptName: this.state.receiptName, receiptHeight:this.state.receiptHeight, receiptWidth: this.state.receiptWidth, receiptPrinterType: this.state.receiptPrinterType, name: this.state.name, companyId: sessionStorage.getItem('companyId') }
        axios.post(localStorage.getItem('host') + '/setting/printernamesform', data, {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(
            res => {
                console.log(res.data)
                this.setState({ msg: res.data });
                this.getList();
                this.closePopup();
                setTimeout(() => {
                    this.setState({ msg: '' });
                }, 3000);
            }, err => {
                console.log(".....")
            }
        )
    }
    getList() {
        axios.get(localStorage.getItem('host') + '/setting/printernames/' + sessionStorage.getItem('companyId'), {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(
            res => {
                for (var index in res.data) {
                    res.data[index].action = <span className="link" title="Edit" id={res.data[index].id} onClick={e => this.action(e)}><i className="bi bi-pencil-square"></i></span>
                }
                this.setState({ list: res.data });
            }, err => {
                console.log(".....")
            }
        )
    }

    render() {
        const data = {
            columns: [
                
                {
                    label: 'Action',
                    field: 'action',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'General Printer',
                    field: 'generalPrinter',
                    sort: 'asc',
                    width: 150
                },{
                    label: 'Label Name',
                    field: 'labelName',
                    sort: 'asc',
                    width: 150
                },{
                    label: 'Receipt Name',
                    field: 'receiptName',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Active Status',
                    field: 'stringStatus',
                    sort: 'asc',
                    width: 100
                }
            ],
            rows: this.state.list
        };
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        }
        return (
            <div className="d-flex" id="wrapper">
                <Sidebar />
                <div id="page-content-wrapper">
                    <Top />

                    <div className="container-fluid">
                       <button className="btn btn-primary" onClick={() => this.showForm(0)} ><i className="bi bi-plus-square"></i></button>
                        {divMsg}
                        <MDBDataTable
                            button="true"
                            striped
                            bordered
                            small
                            data={data}
                        />
                        <div className="page-popup page-popup-overlay" id="action">
                                <div className="card">
                                    <div className="card-header bg-info">
                                        Action
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col">
                                                 <div className="input-group"><span className="input-group-text">General Printer</span>
                                                <input type="text" className="form-control" value={this.state.generalPrinter} onChange={e => { this.setState({ generalPrinter: e.target.value }) }} /></div>
                                            </div>
                                            <div className="col">
                                                 <div className="input-group"><span className="input-group-text">Active Status</span>
                                                <select className="form-control" value={this.state.activeStatus} onChange={e => this.setState({ activeStatus: e.target.value })}  >
                                                    <option value="0">No</option>
                                                    <option value="1">Yes</option>
                                                </select></div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                 <div className="input-group"><span className="input-group-text">General Height</span>
                                                <input type="text" className="form-control" value={this.state.generalHeight} onChange={e => { this.setState({ generalHeight: e.target.value }) }} /></div>
                                            </div>
                                            <div className="col">
                                                 <div className="input-group"><span className="input-group-text">General Width</span>
                                                <input type="text" className="form-control" value={this.state.generalWidth} onChange={e => { this.setState({ generalWidth: e.target.value }) }} /></div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                             <div className="input-group"><span className="input-group-text">General Printer type</span>
                                                <input type="text" className="form-control" value={this.state.generalPrinterType} onChange={e => { this.setState({ generalPrinterType: e.target.value }) }} /></div>
                                            </div>
                                            <div className="col">
                                             <div className="input-group"><span className="input-group-text">Label Name</span>
                                                <input type="text" className="form-control" value={this.state.labelName} onChange={e => { this.setState({ labelName: e.target.value }) }} /></div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                             <div className="input-group"><span className="input-group-text">Label Height</span>
                                                <input type="text" className="form-control" value={this.state.labelHeight} onChange={e => { this.setState({ labelHeight: e.target.value }) }} /></div>
                                            </div>
                                            <div className="col">
                                             <div className="input-group"><span className="input-group-text">Label Width</span>
                                                <input type="text" className="form-control" value={this.state.labelWidth} onChange={e => { this.setState({ labelWidth: e.target.value }) }} /></div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                             <div className="input-group"><span className="input-group-text">Label Printer Type</span>
                                                <input type="text" className="form-control" value={this.state.labelPrinterType} onChange={e => { this.setState({ labelPrinterType: e.target.value }) }} /></div>
                                            </div>
                                            <div className="col">
                                             <div className="input-group"><span className="input-group-text">Receipt Name</span>
                                                <input type="text" className="form-control" value={this.state.receiptName} onChange={e => { this.setState({ receiptName: e.target.value }) }} /></div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                             <div className="input-group"><span className="input-group-text">Receipt Height</span>
                                                <input type="text" className="form-control" value={this.state.receiptHeight} onChange={e => { this.setState({ receiptHeight: e.target.value }) }} /></div>
                                            </div>
                                            <div className="col">
                                             <div className="input-group"><span className="input-group-text">Receipt Width</span>
                                                <input type="text" className="form-control" value={this.state.receiptWidth} onChange={e => { this.setState({ receiptWidth: e.target.value }) }} /></div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                             <div className="input-group"><span className="input-group-text">Receipt Printer Type</span>
                                                <input type="text" className="form-control" value={this.state.receiptPrinterType} onChange={e => { this.setState({ receiptPrinterType: e.target.value }) }} /></div>
                                            </div>
                                            <div className="col">
                                             </div>
                                        </div>
                                        <div className="row">
                                            <div className="col" align="right"><input type="button" className="btn btn-primary" onClick={() => this.save()} value="Save" /></div>
                                            <div className="col"><input type="button" className="btn btn-primary" onClick={() => this.closePopup()} value="Close" /></div>
                                        </div>
                                    </div></div>
                        </div>
                    </div>
                </div ></div >
        )

    }
}    