import React, { Component } from "react";
import axios from "axios";
import '../../App.css';

export default class Email extends Component {
    state = { companyId: 0, visitId: 0, speciality: '', email: '', emailError: '' , printoutDtos: [], printoutDtosError: '', showSubmit: false, msg: '', error: '' }
     toggleTabs(index) {
        let list = this.state.printIndex.slice();
        for(var i  in list)
          {
            list[i] = Number(i) === Number(index)?true:false;  
            //console.log((i == index?true:false)+'...'+i+'....'+index) 
          }
        this.setState({ printIndex: list })
        
    }
    getEmail(id, type) {
        this.setState({ printIndex: [] , printoutDtos: [] })
            axios.get(localStorage.getItem('host') + '/interchange/getemailemr/'+id+'/'+type, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            }).then(
                res => {
                    this.setState({ companyId: res.data.companyId, visitId: res.data.visitId, speciality: res.data.speciality, printoutDtos: res.data.printOuts, accountSid: res.data.accountSid, authToken: res.data.authToken, senderMobile: res.data.senderMobile, mobiles: res.data.mobiles })
                    this.props.setLoader(false)
                }, err => {
                     this.setState({error: err+""})
                    this.props.setLoader(false)
                }
            )
    }
    
    closePopup() {
        document.getElementById('emailAction').style = 'display:none'
    }
    setItem(value, index)
    {
        let list = this.state.printoutDtos.slice();
        list[index].selected = value
        this.setState({ printoutDtos: list });
        this.doValidate()
    }
    doValidate() {
        setTimeout(() => {
            this.validate();
        }, 1000);
    }
    validate() {

        var error = false;
        if (this.state.email === null) {
            this.setState({ emailError: "Email cannot be empty" });
            error = true
        }
        else if (this.state.email === "") {
            this.setState({ emailError: "Email cannot be empty" });
            error = true
        }
        else if (!(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.state.email))) {
            this.setState({ emailError: "Wrong email address" });
            error = true
        }
        else {
            this.setState({ emailError: "" })
        }
        var selected = 0;
          for(var i  in this.state.printoutDtos.slice())
        {
            if(this.state.printoutDtos[i].selected === "1")
            {
                selected++
            }   
        }
        if (selected === 0) {
            this.setState({ printoutDtosError: "Please select one print out to send" })
            error = true
        }
        else
           {
            this.setState({ printoutDtosError: "" })
           }
        if(error === false)
        {
            this.setState({showSubmit: true}) 
           }   
    }
    save()
    {
        this.props.setLoader(true)
        var printOuts = []
        for(var i  in this.state.printoutDtos.slice())
        {
            if(this.state.printoutDtos[i].selected === "1")
            {
                printOuts.push(this.state.printoutDtos[i])
            }   
        }
        var data = {companyId: this.state.companyId, visitId: this.state.visitId, email: this.state.email, printOuts: printOuts}
        axios.post(localStorage.getItem('host') + '/interchange/sendemail', data, {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.props.setLoader(false)
                console.log(res.data)
                if(res.data.error)
                  {
                    this.setState({ error: res.data.error , msg: ''});  
                  }
                  else
                  {
                    this.setState({msg: res.data.msg, error: ''})  
                  }
            }, err => {
                 this.setState({error: err+""})
                this.props.setLoader(false)
            }
        )
    }
    render() {
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        } 
        if (this.state.error) {
            divMsg = (<div className='alert alert-danger'>{this.state.error}</div>)
        }
        return (<div id="emailAction" className="page-popup page-popup-overlay" >
        <div className="card" >
            <div className="card-header bg-info">Email Action</div>
            <div className="card-body" >
            {divMsg}
            <div className="row">
                                        <div className="col"><div className="input-group has-validation"><span className="input-group-text"> Email </span><input type="text" list="mobileList" value={this.state.email} onChange={e => { this.setState({ email: e.target.value }); this.doValidate() }} className={this.state.emailError !== "" ? "form-control is-invalid" : "form-control"} />
                                         <div className="invalid-feedback">{this.state.emailError}</div>
                                        </div></div>
                                        <div className="col" align="right"><div className="input-group"><span className="input-group-text">Message </span><textarea value={this.state.body} onChange={e => this.setState({ body: e.target.value })} className="form-control" rows="10" ></textarea></div></div>
                                     </div>
                                     <table className="table table-striped">
                                                <tr>
                                                    <th>Action</th>
                                                    <th>Option</th>
                                                </tr>
                                                <tbody>
                                                {this.state.printoutDtos.map((item, index) => (
                                                    <tr key={index}>
                                                        <td><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={item.selected === "1"} onChange={e => this.setItem(e.target.checked === true ? "1" : "0" , index)} /></td>
                                                        <td>{item.name}</td>
                                                    </tr>))}
                                                    </tbody>
                                            </table>
                                            <div className="row">
                                            <div className="input-group">
                                                        <input type="hidden" className={this.state.printoutDtosError !== "" ? "form-control is-invalid" : "form-control"} />
                                                        <div className="invalid-feedback">{this.state.printoutDtosError}</div> </div></div>
                <div className="row">
                <div className="col" align="center">{this.state.showSubmit === true ?(<input type="button" className="btn btn-primary" onClick={() => { this.save() }} value="Save" />):(<span></span>)}</div>
                <div className="col" align="center" ><button onClick={() => this.closePopup()} className="btn btn-primary">Close</button></div> 
                </div>
            </div>
        </div>
    </div>)

    }
}    