import React from 'react'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from './pages/login'
import Dashboard from './pages/dashboard'
import PrivateRoute from "./PrivateRoute"
//AUTH
import Users from './pages/auth/users'
import Role from './pages/auth/roles'
import MenuGroups from './pages/auth/menugroups'
import Clusters from './pages/auth/clusters'
import Departments from './pages/auth/departments'
import Locations from './pages/auth/locations'
import Licences from './pages/auth/licences'
import Companys from './pages/auth/companys'
import ChangePassword from './pages/auth/changepassword'
import EmailSetting from './pages/auth/emailsetting';
import ClusterEmrs from './pages/auth/clusteremrs';
import WhatsappNotification from './pages/auth/whatsappnotification'
import EmrSources from './pages/auth/emrsource'
//REPORTS
import Reports from './pages/report/reports'
import ReportView from './pages/report/reportview'
//MASTER
import Additional from './pages/master/additional';
import AppointmentTime from './pages/master/appointmenttime'
import AppointmentType from './pages/master/appointmenttype';
import AttendingFrom from './pages/master/attendingfrom';
import Cipher from './pages/master/cipher';
import ColorRange from './pages/master/colorrange'
import Country from './pages/master/country'
import Currency from './pages/master/currency'
import Diagnosis from './pages/master/diagnosis'
import Doctor from './pages/master/doctor'
import Duration from './pages/master/duration'
import Form from './pages/master/form';
import Formulation from './pages/master/formulation'
import Frequency from './pages/master/frequency'
import JobOrderStatus from './pages/master/joborderstatus';
import Incubation from './pages/master/incubation';
import IncubationCount from './pages/master/incubationcount';
import Institution from './pages/master/institution'
import InstitutionCompany from './pages/master/institutioncompany'
import LisTemplate from './pages/master/listemplate';
import MedicalCertificateType from './pages/master/medicalcertificatetype'
import Organism from './pages/master/organism';
import Parameter from './pages/master/parameter';
import PaymentType from './pages/master/paymenttype'
import Pcp from './pages/master/pcp';
import PdfTemplate from './pages/master/pdftemplate'
import Price from './pages/master/price';
import PrinterNames from './pages/master/printernames'
import Product from './pages/master/product';
import ProductMapping from './pages/master/productmapping';
import Relationship from './pages/master/relationship'
import RisTemplate from './pages/master/ristemplate';
import RouteMaster from './pages/master/route'
import Salutation from './pages/master/salutation'
import SampleType from './pages/master/sampletype';
import Service from './pages/master/service'
import ServiceCost from './pages/master/servicecost'
import SmsTemplate from './pages/master/smstemplate'
import Suffix from './pages/master/suffix'
import Ward from './pages/master/ward';
//NURSE
import NurseList from './pages/nurse/nurse'
import HospitalNurseList from './pages/nurse/hospitalnurselist';
import RenalList from './pages/nurse/renallist';
import NephrologistNurseList from './pages/nurse/nephrologistnurselist';
import CasualtyNurseList from './pages/nurse/casualtynurselist';
import NurseListAll from './pages/nurse/nurseall'
//PATIENT
import Patient from './pages/patient/patient'
import SampleList from './pages/patient/samplelist';
import VisitOpeningRequest from './pages/patient/visitopeningrequest'
//BILL
import AdvancedReceiptList from './pages/bill/advancedreceipt'
import AdvancedReceiptPending from './pages/bill/advancedreceiptpending'
import Bill from './pages/bill/bill'
import BillOpeningApproval from './pages/bill/billopeningapproval'
import Receipt from './pages/bill/receipt'
import Settlement from './pages/bill/settlement'
import UnlockVisit from './pages/bill/unlockvisit';
import Insupay from './pages/bill/insupay';
import Allocation from './pages/bill/allocation';
import HospitalNurseBillList from './pages/bill/hospitalnurselist';
import RenalBillList from './pages/bill/renallist';
import CasualtyNurseBillList from './pages/bill/casualtynurselist';
import Refund from './pages/bill/refund';
import InstitutionInvoice from './pages/bill/institutioninvoice';
//PATIENT MANAGEMENT
import CasualtyList from './pages/patientmanagement/casualtylist';
import GpList from './pages/patientmanagement/gplist'
import GpList1 from './pages/patientmanagement/gplist1';
import GpList2 from './pages/patientmanagement/gplist2';
import PaediatricsList from './pages/patientmanagement/paediatricslist';
import NeurosurgeryList from './pages/patientmanagement/neurosurgerylist';
import NeurologyList from './pages/patientmanagement/neurologylist'
import SpecialistPhysicianList from './pages/patientmanagement/specialistphysicianlist';
import UrologyList from './pages/patientmanagement/urologylist';
import GynaeobsList from './pages/patientmanagement/gynaeobslist';
import OptomList from './pages/patientmanagement/optomlist';
import DentistList from './pages/patientmanagement/dentistlist';
import NephrologistList from './pages/patientmanagement/nephrologistlist'
import NephrologistList1 from './pages/patientmanagement/nephrologistlist1'
import PaediatricsErList from './pages/patientmanagement/paediatricserlist';
import PaediatricCardiologistList from './pages/patientmanagement/paediatriccardiologistlist';
import DialysisRegister from './pages/patientmanagement/dialysisregister';
import RehabList from './pages/patientmanagement/rehablist'
import OpdList from './pages/patientmanagement/opdlist';
import OpdNurseList from './pages/nurse/opdnurselist';
import DentistRefList from './pages/patientmanagement/dentistreflist';
import HospitalDoctorList from './pages/patientmanagement/hospitaldoctorlist'
//SELL
import Otc from './pages/sell/otc';
import Dispense from './pages/sell/dispense';
import ReceiptWorkFlowList from './pages/sell/receiptworkflow';

//IS
import LisReportList from './pages/is/lisreportlist';
import LisVerificationList from './pages/is/lisverificationlist';
import RisReportList from './pages/is/risreportlist';
import RisVerificationList from './pages/is/risverificationlist';
import JobOrderList from './pages/is/joborderlist';
import ScriptBillList from './pages/is/scriptbilllist';

//STOCK
import CurrentStock from './pages/stock/currentstock';
import GrvList from './pages/stock/grvlist'
import BookStockList from './pages/stock/bookstocklist';
import StockCountList from './pages/stock/stockcountlist'; 
import StockUpdateList from './pages/stock/stockupdatelist';
import StockAdjustmentList from './pages/stock/stockadjustmentlist';
import StockConsumptionList from './pages/stock/stockconsumptionlist';
import StockRequestList from './pages/stock/stockrequestlist';
import StockIssueList from './pages/stock/stockissuelist';
import StockReceiveList from './pages/stock/stockreceivelist';

//INTERCHANGE
import ExportManager from './pages/interchange/exportmanager';
import Export from './pages/interchange/export';
import ImportService from './pages/interchange/importservice';
import ImportProduct from './pages/interchange/importproduct';
import ImportServiceCost from './pages/interchange/importservicecost';

//
import BroadcastList from './pages/sms/broadcast';
//https://react-icons.github.io/react-icons

const s = document.createElement('script');
    s.type = 'text/javascript';
    s.async = true;
    s.innerHTML = window.addEventListener('DOMContentLoaded', event => {     
      const sidebarToggle = document.body.querySelector('#sidebarToggle');     
      if (sidebarToggle) {     
          sidebarToggle.addEventListener('click', event => {     
              event.preventDefault();    
              document.body.classList.toggle('sb-sidenav-toggled');    
              localStorage.setItem('sb|sidebar-toggle', document.body.classList.contains('sb-sidenav-toggled'));
          });
      }
       });;
      document.head.appendChild(s);
function App() {
//  localStorage.setItem('host', 'https://test2.kimbah.co.zw');
  var url = process.env.REACT_APP_UCAREURL;
  if((window.location+"").includes('test') )
    {
      url = 'https://test2.kimbah.net' 
    }
  else if((window.location+"").includes('ucare.co.zw'))
      {
        url = 'https://ucare.kimbah.co.zw'
      }
  else if((window.location+"").includes('ucarezw.kimbah.net')  || (window.location+"").includes('ucare.kimbah.net'))
      {
        url = 'https://ucare.kimbah.net'
      }
  else  if((window.location+"").includes('localhost'))
     {
      url = 'http://localhost:8081'
     }  
  else  if((window.location+"").includes('192.168.1.203'))
     {
      url = 'http://192.168.1.203:8081'
     }  
  console.log("ENV ....."+window.location)
  localStorage.setItem('host',(url === undefined?"":url)+"/ucareis"); 
 // localStorage.setItem('host', 'http://192.168.1.203:8081/ucareis'); 
  localStorage.setItem('websocket', "https://bio.kimbah.net:9443")
  return (
    <Router>
    <div className="App">
    <Routes>
            <Route  path='/' element={<Login/>} />
            <Route path="/dashboard"  element={<PrivateRoute> <Dashboard /></PrivateRoute>} />
            <Route path="/auth/menugroup" element={<PrivateRoute> <MenuGroups/></PrivateRoute>} />
            <Route path="/auth/role" element={<PrivateRoute> <Role/></PrivateRoute>} />
            <Route path="/auth/user" element={<PrivateRoute> <Users/></PrivateRoute>} />
            <Route path="/auth/department" element={<PrivateRoute> <Departments/></PrivateRoute>} />
            <Route path="/auth/location" element={<PrivateRoute> <Locations/></PrivateRoute>} />
            <Route path="/auth/cluster" element={<PrivateRoute> <Clusters/></PrivateRoute>} />
            <Route path="/auth/clusteremr" element={<PrivateRoute> <ClusterEmrs/></PrivateRoute>} />
            <Route path="/auth/licence" element={<PrivateRoute> <Licences/></PrivateRoute>} />
            <Route path="/auth/company" element={<PrivateRoute> <Companys/></PrivateRoute>} />
            <Route path="/auth/changepassword" element={<PrivateRoute> <ChangePassword/></PrivateRoute>} />
            <Route path="/auth/emailsetting" element={<PrivateRoute> <EmailSetting/></PrivateRoute>} />
            <Route path="/auth/whatsappnotification" element={<PrivateRoute> <WhatsappNotification/></PrivateRoute>} />
            <Route path="/auth/emrsource" element={<PrivateRoute> <EmrSources/></PrivateRoute>} />


            <Route path="/report/reportmanager" element={<PrivateRoute> <Reports/></PrivateRoute>} />
            <Route path="/report/reportview" element={<PrivateRoute> <ReportView/></PrivateRoute>} />

            <Route path="/master/additional" element={<PrivateRoute> <Additional/></PrivateRoute>} />
            <Route path="/master/appointmenttime" element={<PrivateRoute> <AppointmentTime/></PrivateRoute>} />
            <Route path="/master/appointmenttype" element={<PrivateRoute> <AppointmentType/></PrivateRoute>} />
            <Route path="/master/attendingfrom" element={<PrivateRoute> <AttendingFrom/></PrivateRoute>} />
            <Route path="/master/cipher" element={<PrivateRoute> <Cipher/></PrivateRoute>} />
            <Route path="/master/colorrange" element={<PrivateRoute> <ColorRange/></PrivateRoute>} />
            <Route path="/master/country" element={<PrivateRoute> <Country/></PrivateRoute>} />
            <Route path="/master/currency" element={<PrivateRoute> <Currency/></PrivateRoute>} />
            <Route path="/master/diagnosis" element={<PrivateRoute> <Diagnosis/></PrivateRoute>} />
            <Route path="/master/doctor" element={<PrivateRoute> <Doctor/></PrivateRoute>} />
            <Route path="/master/duration" element={<PrivateRoute> <Duration/></PrivateRoute>} />
            <Route path="/master/form" element={<PrivateRoute> <Form/></PrivateRoute>} />
            <Route path="/master/formulation" element={<PrivateRoute> <Formulation/></PrivateRoute>} />
            <Route path="/master/frequency" element={<PrivateRoute> <Frequency/></PrivateRoute>} />
            <Route path="/master/institution" element={<PrivateRoute> <Institution/></PrivateRoute>} />
            <Route path="/master/joborderstatus" element={<PrivateRoute> <JobOrderStatus/></PrivateRoute>} />
            <Route path="/master/incubation" element={<PrivateRoute> <Incubation/></PrivateRoute>} />
            <Route path="/master/incubationcount" element={<PrivateRoute> <IncubationCount/></PrivateRoute>} />
            <Route path="/master/institutioncompany" element={<PrivateRoute> <InstitutionCompany/></PrivateRoute>} />
            <Route path="/master/listemplate" element={<PrivateRoute> <LisTemplate/></PrivateRoute>} />
            <Route path="/master/medicalcertificatetype" element={<PrivateRoute> <MedicalCertificateType/></PrivateRoute>} />
            <Route path="/master/organism" element={<PrivateRoute> <Organism/></PrivateRoute>} />
            <Route path="/master/parameter" element={<PrivateRoute> <Parameter/></PrivateRoute>} />
            <Route path="/master/paymenttype" element={<PrivateRoute> <PaymentType/></PrivateRoute>} />
            <Route path="/master/pcp" element={<PrivateRoute> <Pcp/></PrivateRoute>} />
            <Route path="/master/pdftemplate" element={<PrivateRoute> <PdfTemplate/></PrivateRoute>} />
            <Route path="/master/printernames" element={<PrivateRoute> <PrinterNames/></PrivateRoute>} />
            <Route path="/master/priceaction" element={<PrivateRoute> <Price/></PrivateRoute>} />
            <Route path="/master/product" element={<PrivateRoute> <Product/></PrivateRoute>} />
            <Route path="/master/productmapping" element={<PrivateRoute> <ProductMapping/></PrivateRoute>} />
            <Route path="/master/relationship" element={<PrivateRoute> <Relationship/></PrivateRoute>} />
            <Route path="/master/ristemplate" element={<PrivateRoute> <RisTemplate/></PrivateRoute>} />
            <Route path="/master/route" element={<PrivateRoute> <RouteMaster/></PrivateRoute>} />
            <Route path="/master/salutation" element={<PrivateRoute> <Salutation/></PrivateRoute>} />
            <Route path="/master/sampletype" element={<PrivateRoute> <SampleType/></PrivateRoute>} />
            <Route path="/master/service" element={<PrivateRoute> <Service/></PrivateRoute>} />
            <Route path="/master/servicecostaction" element={<PrivateRoute> <ServiceCost/></PrivateRoute>} />
            <Route path="/master/smstemplate" element={<PrivateRoute> <SmsTemplate/></PrivateRoute>} />
            <Route path="/master/suffix" element={<PrivateRoute> <Suffix/></PrivateRoute>} />
            <Route path="/master/ward" element={<PrivateRoute> <Ward/></PrivateRoute>} />

            
            <Route path="/patient/patient" element={<PrivateRoute> <Patient/></PrivateRoute>} />
            <Route path="/patient/samplelist" element={<PrivateRoute> <SampleList/></PrivateRoute>} />
            <Route path="/patient/visitopeningrequest" element={<PrivateRoute> <VisitOpeningRequest/></PrivateRoute>} />

            <Route path="/patientmanagement/nurselist" element={<PrivateRoute> <NurseList/></PrivateRoute>} />
            <Route path="/hospital/nurselist" element={<PrivateRoute> <HospitalNurseList/></PrivateRoute>} />
            <Route path="/casualty/nurselist" element={<PrivateRoute> <CasualtyNurseList/></PrivateRoute>} />
            <Route path="/opd/nurselist" element={<PrivateRoute> <OpdNurseList/></PrivateRoute>} />
            <Route path="/patientmanagement/renallist" element={<PrivateRoute> <RenalList/></PrivateRoute>} />
            <Route path="/patientmanagement/nephrologistnurselist" element={<PrivateRoute> <NephrologistNurseList/></PrivateRoute>} />
            <Route path="/patientmanagement/nurselistall" element={<PrivateRoute> <NurseListAll/></PrivateRoute>} />
            
            <Route path="/patientmanagement/gplist" element={<PrivateRoute> <GpList/></PrivateRoute>} />
            <Route path="/patientmanagement/gplist1" element={<PrivateRoute> <GpList1/></PrivateRoute>} />
            <Route path="/patientmanagement/gplist2" element={<PrivateRoute> <GpList2/></PrivateRoute>} />
            <Route path="/patientmanagement/specialistphysicianlist" element={<PrivateRoute> <SpecialistPhysicianList/></PrivateRoute>} />
            <Route path="/patientmanagement/paediatricslist" element={<PrivateRoute> <PaediatricsList/></PrivateRoute>} />
            <Route path="/patientmanagement/paediatricserlist" element={<PrivateRoute> <PaediatricsErList/></PrivateRoute>} />
            <Route path="/patientmanagement/urologylist" element={<PrivateRoute> <UrologyList/></PrivateRoute>} />
            <Route path="/patientmanagement/neurosurgerylist" element={<PrivateRoute> <NeurosurgeryList/></PrivateRoute>} />
            <Route path="/patientmanagement/neurologylist" element={<PrivateRoute> <NeurologyList/></PrivateRoute>} />
            <Route path="/patientmanagement/optomlist" element={<PrivateRoute> <OptomList/></PrivateRoute>} />
            <Route path="/patientmanagement/gynaeobslist" element={<PrivateRoute> <GynaeobsList/></PrivateRoute>} />
            <Route path="/patientmanagement/dentistlist" element={<PrivateRoute> <DentistList/></PrivateRoute>} />
            <Route path="/patientmanagement/dentistreflist" element={<PrivateRoute> <DentistRefList/></PrivateRoute>} />
            <Route path="/hospital/casualtylist" element={<PrivateRoute> <CasualtyList/></PrivateRoute>} />
            <Route path="/opd/doctorlist" element={<PrivateRoute> <OpdList/></PrivateRoute>} />
            <Route path="/patientmanagement/nephrologistlist" element={<PrivateRoute> <NephrologistList/></PrivateRoute>} />
            <Route path="/patientmanagement/nephrologistlist1" element={<PrivateRoute> <NephrologistList1/></PrivateRoute>} />
            <Route path="/patientmanagement/paediatriccardiologistlist" element={<PrivateRoute> <PaediatricCardiologistList/></PrivateRoute>} />
            <Route path="/patientmanagement/rehablist" element={<PrivateRoute> <RehabList/></PrivateRoute>} />
            <Route path="/patientmanagement/dialysisregister" element={<PrivateRoute> <DialysisRegister/></PrivateRoute>} />
            <Route path="/patientmanagement/hospitaldoctorlist" element={<PrivateRoute> <HospitalDoctorList/></PrivateRoute>} />

            <Route path="/bill/bill" element={<PrivateRoute> <Bill/></PrivateRoute>} />
            <Route path="/bill/receipt" element={<PrivateRoute> <Receipt/></PrivateRoute>} />
            <Route path="/bill/advancedreceipt" element={<PrivateRoute> <AdvancedReceiptList/></PrivateRoute>} />
            <Route path="/bill/advancedreceiptpending" element={<PrivateRoute> <AdvancedReceiptPending/></PrivateRoute>} />
            <Route path="/bill/settlement" element={<PrivateRoute> <Settlement/></PrivateRoute>} />
            <Route path="/bill/billopeningapproval" element={<PrivateRoute> <BillOpeningApproval/></PrivateRoute>} />
            <Route path="/bill/unlockvisit" element={<PrivateRoute> <UnlockVisit/></PrivateRoute>} />
            <Route path="/bill/insupay" element={<PrivateRoute> <Insupay/></PrivateRoute>} />
            <Route path="/bill/allocation" element={<PrivateRoute> <Allocation/></PrivateRoute>} />
            <Route path="/bill/hospitalnurselist" element={<PrivateRoute> <HospitalNurseBillList/></PrivateRoute>} />
            <Route path="/bill/renallist" element={<PrivateRoute> <RenalBillList/></PrivateRoute>} />
            <Route path="/bill/refund" element={<PrivateRoute> <Refund/></PrivateRoute>} />
            <Route path="/bill/casualtynurselist" element={<PrivateRoute> <CasualtyNurseBillList/></PrivateRoute>} />
            <Route path="/bill/institutioninvoice" element={<PrivateRoute> <InstitutionInvoice/></PrivateRoute>} />

            <Route path="/sell/otcaction" element={<PrivateRoute> <Otc/></PrivateRoute>} />
            <Route path="/sell/dispenseaction" element={<PrivateRoute> <Dispense/></PrivateRoute>} />
            <Route path="/sell/receiptworkflow" element={<PrivateRoute> <ReceiptWorkFlowList/></PrivateRoute>} />

            <Route path="/is/lisreportlist" element={<PrivateRoute> <LisReportList/></PrivateRoute>} />
            <Route path="/is/lisverificationlist" element={<PrivateRoute> <LisVerificationList/></PrivateRoute>} />
            <Route path="/is/risreportlist" element={<PrivateRoute> <RisReportList/></PrivateRoute>} />
           <Route path="/is/risverificationlist" element={<PrivateRoute> <RisVerificationList/></PrivateRoute>} />
            <Route path="/is/joborderlist" element={<PrivateRoute> <JobOrderList/></PrivateRoute>} />
            <Route path="/is/scriptbilllist" element={<PrivateRoute> <ScriptBillList/></PrivateRoute>} />

            <Route path="/stockmanagement/currentstock" element={<PrivateRoute> <CurrentStock/></PrivateRoute>} />
            <Route path="/stockmanagement/grv" element={<PrivateRoute> <GrvList/></PrivateRoute>} />
            <Route path="/stockmanagement/bookstock" element={<PrivateRoute> <BookStockList/></PrivateRoute>} />
            <Route path="/stockmanagement/stockcount" element={<PrivateRoute> <StockCountList/></PrivateRoute>} />
            <Route path="/stockmanagement/stockupdate" element={<PrivateRoute> <StockUpdateList/></PrivateRoute>} />
            <Route path="/stockmanagement/stockadjustment" element={<PrivateRoute> <StockAdjustmentList/></PrivateRoute>} />
            <Route path="/stockmanagement/stockconsumption" element={<PrivateRoute> <StockConsumptionList/></PrivateRoute>} />
            <Route path="/stockmanagement/stockrequest" element={<PrivateRoute> <StockRequestList/></PrivateRoute>} />
            <Route path="/stockmanagement/stockissue" element={<PrivateRoute> <StockIssueList/></PrivateRoute>} />
            <Route path="/stockmanagement/stockreceive" element={<PrivateRoute> <StockReceiveList/></PrivateRoute>} />
            
            <Route path="/interchange/importservice" element={<PrivateRoute> <ImportService/></PrivateRoute>} />
            <Route path="/interchange/exportmanager" element={<PrivateRoute> <ExportManager/></PrivateRoute>} />
            <Route path="/interchange/export" element={<PrivateRoute> <Export/></PrivateRoute>} />
            <Route path="/interchange/importproduct" element={<PrivateRoute> <ImportProduct/></PrivateRoute>} />
            <Route path="/interchange/importservicecost" element={<PrivateRoute> <ImportServiceCost/></PrivateRoute>} />

            <Route path="/sms/broadcast" element={<PrivateRoute> <BroadcastList/></PrivateRoute>} />
          </Routes>
    </div></Router>
  );
}

export default App;