import React, { Component } from "react";
import axios from "axios";
import '../../App.css';

export default class ClaimResponse extends Component {
    state = {  claimResponseLines: [], msg: '', error: '', billGrossAmount: 0.00, insurancePayableAmount: 0.00, shortfallPayabeAmount: 0.00 }
   
    getClaimDetails(id) {
            axios.get(localStorage.getItem('host') + '/interchange/gethealth263/' + id, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            }).then(
                res => {
                    this.setState({ error: res.data.error, msg: res.data.msg, billGrossAmount: res.data.billGrossAmount, insurancePayableAmount: res.data.insurancePayableAmount, shortfallPayabeAmount: res.data.shortfallPayabeAmount, claimResponseLines: res.data.claimResponseLines })
                    this.props.setLoader(false)
                }, err => {
                    this.setState({ error: err + "" })
                    this.props.setLoader(false)
                }
            )
    }
    currencyFormat(amount) {
        let val = (amount / 1).toFixed(2)
        return val.toString().split(/(?=(?:\d{3})+(?:\.|$))/g).join(" ").replace("-,", "-");
    }
    closePopup() {
        document.getElementById('h263ResponseMsg').style = 'display:none'
    }
    render() {
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        }
        if (this.state.error) {
            divMsg = (<div className='alert alert-danger'>{this.state.error}</div>)
        }
        return (<div id="h263ResponseMsg" className="page-popup page-popup-overlay">
            <div className="card">
                <div className="card-header bg-info"> Claim Response</div>
                <div className="card-body">
                    {divMsg}
                        <br />
                    <div className="row">
                            <div className="col"><span className="fw-bold">Bill Gross Amount:</span> { this.currencyFormat(this.state.billGrossAmount) }</div>
                           <div className="col"><span className="fw-bold">Insurance Payable Amount:</span> { this.currencyFormat(this.state.insurancePayableAmount) }</div>
                           <div className="col"><span className="fw-bold">Shortfall Payable Amount:</span> { this.currencyFormat(this.state.shortfallPayabeAmount) }</div>
                        </div>
                        <br />
                        <table className="table table-striped">
                            <tr>
                                <th>Code</th>
                                <th>Name</th>
                                <th>Gross Amount</th>
                                <th>Insurance Amount</th>
                                <th>Shortfall Amount</th>
                                <th>Message</th>
                            </tr>
                            <tbody>
                            {this.state.claimResponseLines.map((item, index) => (
                            <tr key={index}>
                                <td>{item.code}</td>
                                <td>{item.description}</td>
                                <td>{this.currencyFormat(item.grossAmount)}</td>
                                <td>{this.currencyFormat(item.netAmount)}</td>
                                <td>{this.currencyFormat(item.patientPay)}</td>
                                <td>{item.message}</td>
                            </tr>  ))}	
                            </tbody>
                        </table>
                        <div className="row">
                            <div className="col-lg-12" align="center" ><button onClick={() => this.closePopup()} className="btn btn-primary">Close</button></div>
                        </div>
                </div>
            </div>
        </div>)

    }
}    