import React, { Component } from "react";
import axios from "axios";
import '../../App.css';

export default class StockAdjustment extends Component {
    state = { reason: '',reasonError: '', quantity: '', maxQuantity: '', companyId: '', companys: [], typeError: '', type: '', companyIdError: '', locationIdError: '', locationId: 0, locations: [], productItems: [], productItemLocationId: '', manufacturers: [], suppliers: [], stockAdjustmentItems: [], stockAdjustmentItemsError: ''}
  
   
    openStockAdjustment(id) {
        document.getElementById('stockAdjustmentAction').style = 'display:block' 
        this.setState({ reason: '',reasonError: '',  id: id, quantity: '', maxQuantity: '', currencyId: 0, companyId: '', typeError: '', type: '', companys: [], companyIdError: '', remarks: '', locations: [], productItems: [], productItemLocationId: '',  locationIdError: '', locationId: '', stockAdjustmentItems: [], msg: '', error: ''});
        this.props.setLoader(true)
        axios.get(localStorage.getItem('host') + '/stockmanagement/getstockadjustment/' + id + '/' + sessionStorage.getItem('companyId'), {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({  companys: res.data.companys });
                this.props.setLoader(false)
                this.doValidate()
            }, err => {
                console.log(".....")
                this.setState({ error: err + "" })
                this.props.setLoader(false)
            }
        )
    }
    save() {
        this.props.setLoader(true)
        var data = { id: this.state.id, locationId: this.state.locationId, locationName: this.state.locationName, companyId: this.state.companyId, reason: this.state.reason , type: this.state.type, stockAdjustmentItems:  this.state.stockAdjustmentItems}
        axios.post(localStorage.getItem('host') + '/stockmanagement/savestockadjustment', data, {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(
            res => {
                console.log(res.data)
                this.close()
                this.props.getList()
                this.props.setMsg(res.data)
            }, err => {
                console.log(".....")
                this.setState({ error: err + "" })
                this.props.setLoader(false)
            }
        )
    }
    close() {
        document.getElementById('stockAdjustmentAction').style = 'display:none'
    } 
    add() {
        this.setState({ productItems: [] });
        this.props.setLoader(true)
        axios.get(localStorage.getItem('host') + '/stockmanagement/getbookstockitems/'+this.state.locationId, {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ productItems: res.data });
                this.props.setLoader(false)
            }, err => {
                console.log(".....")
                this.setState({ error: err + "" })
                this.props.setLoader(false)
            }
        )
    }
    doValidate() {
        setTimeout(() => {
            this.validate(this.state.stockAdjustmentItems.slice());
        }, 1000);
    }
    validate(stockAdjustmentItems) {
        var error = false;
        if (this.state.companyId === "") {
            this.setState({companyIdError: "Company cannot be empty"})
            error = true
        }
        else {
            this.setState({companyIdError : ""})
        }
        if (this.state.locationId === "") {
            this.setState({locationIdError: "Location cannot be empty"})
            error = true
        }
        else {
            this.setState({locationIdError : ""})
        }
        if (this.state.type === "") {
            this.setState({typeError: "Type cannot be empty"})
            error = true
        }
        else {
            this.setState({typeError : ""})
        }
        if (this.state.reason === "") {
            this.setState({reasonError: "Reason cannot be empty"})
            error = true
        }
        else {
            this.setState({reasonError : ""})
        }
        if(stockAdjustmentItems.length === 0)
           {
            this.setState({ error: "Please select at least one Item to Adjust", showSubmit: false })
           }
       else if (error === true) {
            this.setState({ error: "One or more of the mandatory field(s) is not filled in", showSubmit: false })
        }
        else {
            this.setState({ error: "", showSubmit: true })
        }
    }
    currencyFormat(amount) {
        let val = (amount / 1).toFixed(2)
        return val.toString().split(/(?=(?:\d{3})+(?:\.|$))/g).join(" ").replace("-,", "-");
    }
    setLocations(id) {
        for (var i in this.state.companys) {
            if (Number(this.state.companys[i].id) === Number(id)) {
                var locations = this.state.companys[i].locations;
                this.setState({ locations: locations, stockAdjustmentItems: [], productItems: [], productItemId: '',  maxQuantity: '', adjustedQuantity: '', type: '' })
            }
        }
    }
    setLocation(e)
      {
        for (var i in this.state.locations) {
            if (Number(this.state.locations[i].id) === Number(e.target.value)) {
                this.setState({ locationId: this.state.locations[i].id, locationName: this.state.locations[i].name , stockAdjustmentItems: [], productItems: [], productItemId: '',  maxQuantity: '', adjustedQuantity: '', type: '' })
            }
        }
      }
    setProductItem(e)
        {
          this.setState({ productItemId: '',  maxQuantity: '', adjustedQuantity: ''})  
          for (var i in this.state.productItems) {
              if (Number(this.state.productItems[i].productItemLocationId) === Number(e)) {
                  this.setState({productItemLocationId: this.state.productItems[i].productItemLocationId })
              }
          }
        }
    reset()
        {
            this.setState({ productItemId: '',  maxQuantity: '', adjustedQuantity: '', stockAdjustmentItems: []})     
        }    
    addToList()
      {
        var list =  this.state.productItems;
         var stockAdjustmentItems = this.state.stockAdjustmentItems
        for (var i in list) {
            if (Number(list[i].productItemLocationId) === this.state.productItemLocationId) {
                list[i].adjustedQuantity = this.state.quantity;
                list[i].varianceQuantity = this.state.quantity -  list[i].quantity
                stockAdjustmentItems.push(list[i])
                this.setState({ stockAdjustmentItems: stockAdjustmentItems })
                this.validate(stockAdjustmentItems)
            }
        }
      }
      deleteEntry(index)
       {
         var list = this.state.stockAdjustmentItems
         list.splice(index, 1)
         this.setState({stockAdjustmentItems: list}) 
       }
    render() {
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        }
        if (this.state.error) {
            divMsg = (<div className='alert alert-danger'>{this.state.error}</div>)
        }
        return (
                        <div id="stockAdjustmentAction" className="page-popup page-popup-overlay"   >
                        <div className="card" >
                            <div className="card-header bg-info">Stock Adjustment Action</div>
                            <div className="card-body" >
                                {divMsg}
                        <div className="card card-green">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col">
                                        <div className="input-group"><span className="input-group-text">Company</span>
                                            <select className={this.state.companyIdError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.companyId} onChange={e => { this.setState({ companyId: e.target.value }); this.setLocations(e.target.value); this.doValidate(); }}  >
                                                <option value="0">Choose Company</option>
                                                {this.state.companys.map((item, index) => (
                                                    <option key={index} value={item.id} >{item.name}</option>
                                                ))}
                                            </select><div className="invalid-feedback">{this.state.companyIdError}</div></div>
                                    </div>
                                    <div className="col">
                                    <div className="input-group has-validation"><span className="input-group-text">Locations</span><select className={this.state.locationIdError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.locationId} onChange={e => { this.setLocation(e); this.doValidate(); }}  >
                                                                <option value="0">Choose Location</option>
                                                                {this.state.locations.map((l, index) => (
                                                                    <option key={index} value={l.id} >{l.name}</option>
                                                                ))}
                                                            </select><div className="invalid-feedback">{this.state.locationIdError}</div></div>
                                    </div>
                                    <div className="col">
                                    { this.state.locationId > 0 &&(<button type="button" className="btn btn-success" title="Insert" onClick={() => { this.add() }}  ><i className="bi bi-plus-square"></i></button>)}
                                    </div>
                                </div>
                                <div className="row">
                                <div className="col"><div className="input-group"><span className="input-group-text">Type</span><select className={this.state.typeError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.type} onChange={(e) =>{ this.setState({ type: e.target.value }); this.reset(); this.doValidate(); }}  >
                                                   <option value="" >Choose</option>
                                                  <option value="NEGATIVE" >NEGATIVE</option>
                                                  <option value="POSITIVE" >POSITIVE</option>
                                        </select> <div className="invalid-feedback">{this.state.typeError}</div> </div></div>
                                    <div className="col">
                                        <div className="input-group"><span className="input-group-text">Reason</span>
                                            <textarea className={this.state.reasonError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.reason} onChange={e => { this.setState({ reason: e.target.value }); this.doValidate(); }}  ></textarea><div className="invalid-feedback">{this.state.reasonError}</div></div>
                                    </div>
                                     <div className="col"> </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="input-group"><span className="input-group-text">Products</span>
                                            <select className="form-control" value={this.state.productItemLocationId} onChange={e => { this.setProductItem(e.target.value);  }}  >
                                                <option value="0">Choose Product</option>
                                                {this.state.productItems.map((item, index) => (
                                                    <option key={index} value={item.productItemLocationId} >{item.name} [{item.quantity}] - {item.batchNumber} - {item.expiryDate}</option>
                                                ))}
                                            </select><div className="invalid-feedback">{this.state.companyIdError}</div></div>
                                    </div>
                                    <div className="col">
                                           <div className="input-group"><span className="input-group-text">Quantity</span>
                                           <input type="text" className="form-control" value={this.state.quantity} onChange={e => { this.setState({quantity: e.target.value});  }}  />
                                           </div>
                                        </div>
                                        <div className="col">
                                    {( this.state.quantity !== "" && this.state.type !== "" && Number(this.state.quantity >= 0) ) &&(<button type="button" className="btn btn-success" title="Insert" onClick={() => { this.addToList() }}  ><i className="bi bi-plus-square"></i></button>)}
                                    </div>
                                 </div>
                            </div></div>   
                        <br />
                        <div className="card card-green">
                            <div className="card-header">Items</div>
                            <div className="card-body">
                            
                                <table className="table table-striped" >
                                <tr>
                                                        <th>Action</th>
                                                        <th>Code</th>
                                                        <th>Name</th>
                                                        <th>Category</th>
                                                        <th>Type</th>
                                                        <th>System Quantity</th>
                                                        <th>Adjusted Quantity</th>
                                                        <th>Buying Price</th>
                                                        <th>Dispensing Fee</th>
                                                        <th>Batch Number</th>
                                                        <th>Expiry Date</th>
                                                        <th>Manufacturer</th>
                                                        <th>Supplier</th>
                                                    </tr>
                                                    <tbody>                                               
                                                     {this.state.stockAdjustmentItems.map((item, index) => (
                                                        <tr>
                                                            <td><input type="button" className="btn btn-danger" value="Delete" onClick={() => this.deleteEntry(index)} /></td>
                                                            <td>{item.code}</td>
                                                            <td>{item.name}</td>
                                                            <td>{item.category}</td>
                                                            <td>{item.type}</td>
                                                            <td>{item.quantity}</td>
                                                            <td>{item.adjustedQuantity}</td>
                                                            <td>{this.currencyFormat(item.buyingPrice)}</td>
                                                            <td>{item.dispensingFee}</td>
                                                            <td>{item.batchNumber}</td>
                                                            <td>{item.expiryDate}</td>
                                                            <td>{item.manufacturer} </td>
                                                            <td>{item.supplier} </td>
                                                        </tr>
                                                    ))}
                                                    </tbody>
                                </table>
                                <div className="row">
                                    <div className="input-group">
                                        <input type="hidden" className={this.state.stockAdjustmentItemsError !== "" ? "form-control is-invalid" : "form-control"} />
                                        <div className="invalid-feedback">{this.state.stockAdjustmentItemsError}</div> </div></div>  </div></div>
                        <div className="row">
                            <div className="col" align="right">{this.state.showSubmit === true && (<input type="button" className="btn btn-primary" onClick={() => this.save()} value="Save" />)}</div>
                            <div className="col"><input type="button" className="btn btn-primary" onClick={() => this.close()} value="Cancel" /></div>
                        </div>
                        </div>
                </div ></div >
        )

    }
}    