import React, { Component } from 'react'
import axios from 'axios'
import '../Login.css'
import UserRoleCompany from './userrolecompany'
export default class Login extends Component {
  state = { loader: false, error: '', username: '', usernameError: '', password: '', passwordError: '', ipAddress: '', country: '', isp: '', showSubmit: false }
  constructor(props) {
    super(props);
    this.setLoader = this.setLoader.bind(this)
    this.userRoleCompanyRef = React.createRef()
  }
  componentDidMount() {
    axios.get('https://api.ipdata.co?api-key=212ad9acd89c4d7e42b8b70db2317c75e051aa7f16c81fed265ef9bd').then(res => {
      this.setState({ ipAddress: res.data.ip, country: res.data.country_name, isp: res.data.asn.name });
      console.log(" ............." + res.data.ip + "...." + res.data.country_name + "" + res.data.asn.name + "")
      document.getElementById("submitButton").disabled = false;
    }, err => {
      console.log(".....")
    })

  }

  login() {
    this.setLoader(true)
    const data = {
      username: this.state.username,
      password: this.state.password,
      ipAddress: this.state.ipAddress,
      country: this.state.country,
      isp: this.state.isp
    };
    axios.post(localStorage.getItem('host') + '/auth/authenticate', data).then(
      res => {
        if (res.data.error) {
          this.setState({ error: res.data.error, loader: false });
        }
        else {
         
            sessionStorage.setItem('token', res.data.token);
            sessionStorage.setItem('userId', res.data.userId);
            sessionStorage.setItem('fullname', res.data.fullname);
            sessionStorage.setItem('username', res.data.username);
            sessionStorage.setItem('admin', res.data.admin);
            sessionStorage.setItem('refId', res.data.refId);
            if (res.data.userRoleCompanys.length === 1) {
              sessionStorage.setItem('companyId', res.data.companyId);
              sessionStorage.setItem('speciality', res.data.speciality);
              sessionStorage.setItem('roleId', res.data.roleId);
              sessionStorage.setItem('companyName', res.data.companyName);
              this.setLoader(false)
              window.location = "/dashboard";
            }
            else {
              this.userRoleCompanyRef.current.openUserRoleCompany(res.data.userRoleCompanys, res.data.userId)
            }
         
        }
      }, err => {
        this.setState({ error: err + "" , loader: false });
      }

    )
  }
  setLoader(loader) {
    this.setState({ loader: loader })
  }


  doValidate() {
    setTimeout(() => {
      this.validatePatient();
    }, 1000);
  }
  validatePatient() {

    var error = false;
    if (this.state.username === null) {
      this.setState({ usernameError: "Username cannot be empty" });
      error = true
    }
    else if (this.state.username === "") {
      this.setState({ usernameError: "Username cannot be empty" });
      error = true
    }
    else if (!(/^(?!\s*$)[-a-zA-Z ]*$/.test(this.state.username))) {
      this.setState({ usernameError: "Username should contain characters only and no spaces" })
      error = true
    }
    else {
      this.setState({ usernameError: "" })
    }

    if (this.state.password === null) {
      this.setState({ passwordError: "Password cannot be empty" })
      error = true
    }
    else if (this.state.password === "") {
      this.setState({ passwordError: "Password cannot be empty" })
      error = true
    }
    else {
      this.setState({ passwordError: "" })
    }

    if (error === true) {
      this.setState({ error: "One or more of the mandatory field(s) is not filled in", showSubmit: false })
    }
    else {
      this.setState({ error: "", showSubmit: true })
    }
  }
  render() {
    let divMsg = ''
    if (this.state.error) {
      divMsg = (<div className='alert alert-danger'>{this.state.error}</div>)
    }
    let loaderDiv = ''
    if (this.state.loader) {
      loaderDiv = (<div id="loader" className="loader-overlay"><div align="center" className="loader"></div></div>)
    }
    let year = new Date().getFullYear();
    return (
      <div id="loginPage"><div className="container-fluid">
        <div className="card" id="card">
          <div className="card-body">
            <div align="center"><i id="loginSvg" className="bi bi-heart-pulse-fill" ></i></div>
            <p className="h5 mb-4">Log In</p>
            {divMsg}
            <div className="row">
              <div className="input-group has-validation"><span className="input-group-text">Username </span>
                <input type="text" className={this.state.usernameError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.username} onChange={(e) => { this.setState({ username: e.target.value }); this.doValidate() }} />
                <div className="invalid-feedback">{this.state.usernameError}</div></div></div>

            <div className="row"><div className="input-group has-validation"><span className="input-group-text">Password</span>
              <input type="password" className={this.state.passwordError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.password} onChange={(e) => { this.setState({ password: e.target.value }); this.doValidate() }} />
              <div className="invalid-feedback">{this.state.passwordError}</div></div></div>

            <div className="row">
              <div  className="d-grid gap-2" align="center">{this.state.showSubmit === true && (<input type="button" className="btn btn-primary" id="submitButton" onClick={() => this.login()} value="Login" />)}  </div>
            </div>
            <p  align="center"> &copy; {year} Powered By <a target="_blank" rel="noreferrer" href="http://kimbah.net"> Kimbah Tech</a></p>
          </div>
        </div>
      </div>
        <UserRoleCompany ref={this.userRoleCompanyRef} setLoader={this.setLoader} />
        {loaderDiv}
      </div>
    )
  }
}