import React, { Component } from "react";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import '../../App.css';

const now = new Date();
const todayY = now.getFullYear();
const todayM = checkZero(now.getMonth() + 1);
const todayD = checkZero(now.getDate());
function checkZero(num) {
    return (num + "").length === 1 ? "0" + num : num;
}

export default class InsupayAction extends Component {
    state = { list: [], companyId: 0, clusterCompanys: [], insurances: [], insuranceId: '',  startDate: todayY + '-' + todayM + '-' + todayD, endDate: todayY + '-' + todayM + '-' + todayD , msg: '', error: '', selectedList: 0, number: '', paymentType: '', paidAmount: 0.00, convertedAmount: 0.00, refundedAmount: 0.00 }

    openInsupayAction(clusterCompanys, insurances) {
        this.props.setLoader(false)
        this.setState({ clusterCompanys: clusterCompanys, insurances: insurances })
    }
    save() {
        var error = "";
        if (error === "") {
            this.props.setLoader(true)
            var list = [];  
            for(var index in this.state.list)   
                   {
                    if(this.state.list[index].selected === true || this.state.list[index].selected === "true")
                         {
                         list.push(this.state.list[index]);     
                         }
                   }
            axios.post(localStorage.getItem('host') + "/bill/saveinsupay",list, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            })
                .then(res => {
                    this.props.setMsg(res.data);
                    this.closePopup();
                    this.props.getList();
                    this.props.setLoader(false)
                })
        } else {
            this.setState({ error: error })
        }
    }
    currencyFormat(amount) {
        let val = (amount / 1).toFixed(2)
        return val.toString().split(/(?=(?:\d{3})+(?:\.|$))/g).join(" ").replace("-,", "-");
    }
    closePopup() {
        document.getElementById('insupayAction').style = 'display:none'
    }
    calcSelected(index, checked)
     {
     var list = this.state.list;
     list[index].selected = checked;
     var selectedList = 0;  
                             for(var i in list)   
                                    {
                                     if(list[i].selected === true || list[i].selected === "true")
                                          {
                                          selectedList++;     
                                          }
                                    }
      this.setState({selectedList: selectedList, list: list})                              
     }
     setItem(i, name, value) 
      {
        var list = this.state.list;   
       switch(name)
            {
             case "insurancePaid":
                list[i].insurancePaid = value > list[i].insurancePayable?0: value
                break;
             default:
                list[i].remarks = value
                break;  
            }
            this.setState({list: list})  
      }
    getList()
    {
        if(this.state.companyId > 0 && this.state.insuranceId > 0)
         {
        this.props.setLoader(true)
        axios.get(localStorage.getItem('host') + '/bill/insupaydtolist/'+this.state.companyId+"/"+this.state.insuranceId+"/"+this.state.startDate+"/"+this.state.endDate, {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ list: res.data });
                this.props.setLoader(false)
            }, err => {
                console.log(".....")
                this.setState({ error: err.Error })
                this.props.setLoader(false)
            }
        )
         }
    }
    processDate(date)
      {
        const now = new Date(date);
        const todayY = now.getFullYear();
        const todayM = checkZero(now.getMonth() + 1);
        const todayD = checkZero(now.getDate());
        var date1 = todayY + '-' + todayM + '-' + todayD;
        console.log(date1)
       return date1;
      }
    render() {
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        }
        if (this.state.error) {
            divMsg = (<div className='alert alert-danger'>{this.state.error}</div>)
        }
        return (<div id="insupayAction" class="page-popup page-popup-overlay"   ><div class="card" >
            <div class="card-header bg-info">Insupay Action</div>
            <div class="card-body" >
                <div class="card border-success">
                    <div class="card-body">
                        {divMsg}
                        <div class="row">
                            <div className="col"><div className="input-group"><span className="input-group-text">Company</span><select className="form-control" onChange={(e) => this.setState({ companyId: e.target.value })}  >
                                <option value="0">Choose Company</option>
                                {this.state.clusterCompanys.map((item, index) => (
                                    <option key={index} value={item.id} >{item.name}</option>
                                ))}
                            </select>  </div></div>
                                            <div className="col">
                                                 <div className="input-group"><span className="input-group-text">Institutions</span>
                                                <select className="form-control" value={this.state.insuranceId} onChange={e => this.setState({ insuranceId: e.target.value })}  >
                                                    <option value="0">Choose Institution</option>
                                                    {this.state.insurances.map((insurance, index) => (
                                                        <option key={index} value={insurance.id} >{insurance.name}</option>
                                                    ))}
                                                </select></div>
                                            </div>
                            <div className="col"><div className="input-group"><span className="input-group-text">Start Date</span> <DatePicker className="form-control" selected={new Date(this.state.startDate)} maxDate={new Date(this.state.endDate)} onChange={d => { this.setState({ startDate: this.processDate(d) });} } dateFormat="yyyy-MM-dd" /></div></div>
                            <div className="col"><div className="input-group"><span className="input-group-text">End Date</span> <DatePicker className="form-control" selected={new Date(this.state.endDate)}  minDate={new Date(this.state.startDate)} maxDate={new Date()}  onChange={d => { this.setState({ endDate: this.processDate(d) }); } } dateFormat="yyyy-MM-dd" /></div></div>
                            <div className="col">    <input type="button" className="btn btn-success" onClick={() => this.getList()} value="Search" /></div>
                        </div>
                    </div>
                </div>
                <table class="table table-striped table-hover">
                <tr>
                    <th>Select [{this.state.selectedList}]</th>
                    <th>Name</th>
                    <th>Date</th>
                    <th>Number</th>
                    <th>Currency</th>
                    <th>Claim Ref</th>
                    <th style={{textAlign: "right"}}>Payable</th>
                    <th style={{textAlign: "right"}}>Owing</th>
                    <th style={{textAlign: "right"}}>Paid</th>
                    <th>Remarks</th>
                </tr> 
                                <tbody>
                                    {this.state.list.map((item, index) => (
                                        <tr key={index} >
                    <td><input type="checkbox" value={item.selected} onChange={e => this.calcSelected(index, e.target.checked)}  /></td>
                    <td>{item.fullname}</td>
                    <td>{item.billingStartDate}</td>
                    <td>{item.number}</td>
                    <td>{item.currencyName}</td>
                    <td>{item.ref}</td>
                    <td align="right" >{ this.currencyFormat(item.insurancePayable)}</td>                       
                    <td align="right" >{ this.currencyFormat(item.insuranceOwing)}</td>  
                    <td align="right" ><input type="text" class="form-control" value={item.insurancePaid} onChange={e => this.setItem(index, "insurancePaid", e.target.value)} /></td> 
                    <td><input type="text" class="form-control" value={item.remarks} onChange={e => this.setItem(index, "remarks", e.target.value)} /></td>
                </tr>
                ))}
            </tbody>
            </table>
                <div class="row">
                    <div class="col" align="center">{this.state.selectedList > 0 ? (<button onClick={() => this.save()} id="submitButton2" class="btn btn-primary" >Submit</button>) : (<span></span>)}</div>
                    <div class="col" align="center"><button onClick={() => this.closePopup()} class="btn btn-primary">Close</button></div>
                </div>
            </div>
        </div>
        </div>)

    }
}    