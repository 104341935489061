import React, { Component } from "react";
import axios from "axios";
import '../../App.css';

export default class PaidAction extends Component {
    state = { id: 0, cashPaid: 0, shortfallPaid: 0, msg: '', error: '', fullname: '', number: '', billCategory: '', totalPayable: 0.00, totalPaid: 0.00, totalOwing: 0.00 }

    getPaidAction(item) {
        this.props.setLoader(false)
        this.setState({ id: item.id, cashPaid: item.cashPaid, shortfallPaid: item.shortfallPaid, fullname: item.fullname, number: item.number, billCategory: item.billCategory, totalPayable: item.totalPayable, totalPaid: item.totalPaid, totalOwing: item.totalOwing })
    }
    save() {
        var error = "";
        if (error === "") {
            this.props.setLoader(true)
             var data = {id: this.state.id, cashPaid: this.state.cashPaid, shortfallPaid: this.state.shortfallPaid}
            axios.post(localStorage.getItem('host') + "/bill/applypaid", data, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            })
                .then(res => {
                    this.props.setMsg(res.data);
                    this.closePopup();
                    this.props.getList();
                    this.props.setLoader(false)
                })
        } else {
            this.setState({ error: error })
        }
    }
    currencyFormat(amount) {
        let val = (amount / 1).toFixed(2)
        return val.toString().split(/(?=(?:\d{3})+(?:\.|$))/g).join(" ").replace("-,", "-");
    }
    closePopup() {
        document.getElementById('paidAction').style = 'display:none'
    }
    render() {
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        }
        if (this.state.error) {
            divMsg = (<div className='alert alert-danger'>{this.state.error}</div>)
        }
        return (<div id="paidAction" class="page-popup page-popup-overlay"   ><div class="card" >
            <div class="card-header bg-info">Cancel Receipt</div>
            <div class="card-body" >
                <div class="card border-success">
                    <div class="card-body">
                        {divMsg}
                        <div class="row">
                            <div class="col"><span className="fw-bold">Fullname:</span> {this.state.fullname}</div>
                            <div class="col"><span className="fw-bold">Number:</span> {this.state.number}</div>
                            <div class="col"><span className="fw-bold">Category:</span> {this.state.billCategory}</div>
                        </div>
                        <div class="row">
                            <div class="col"><span className="fw-bold">Payable:</span> {this.currencyFormat(this.state.totalPayable)}</div>
                            <div class="col"><span className="fw-bold">Paid:</span> {this.currencyFormat(this.state.totalPaid)}</div>
                            <div class="col"><span className="fw-bold">Owing:</span> {this.currencyFormat(this.state.totalOwing)}</div>
                        </div>
                    </div>
                </div>
                <div class="row">
                 <div class="col" ><div className="input-group"><span className="input-group-text">Cash Paid:</span><input type="text" class="form-control" value={this.state.cashPaid} onChange={e => this.setState({ cashPaid: e.target.value })} autoComplete="no"  /></div> </div> 
                 <div class="col" ><div className="input-group"><span className="input-group-text">Shortfall Paid:</span><input type="text" class="form-control" value={this.state.shortfallPaid} onChange={e => this.setState({ shortfallPaid: e.target.value })} autoComplete="no"  /></div> </div>                 
             </div>
                <div class="row">
                    <div class="col" align="center">{this.state.cashPaid > 0 || this.state.shortfallPaid > 0 ? (<button onClick={() => this.save()} id="submitButton2" class="btn btn-primary" >Submit</button>) : (<span></span>)}</div>
                    <div class="col" align="center"><button onClick={() => this.closePopup()} class="btn btn-primary">Close</button></div>
                </div>
            </div>
        </div>
        </div>)

    }
}    