import React, { Component } from "react";
import axios from "axios";
import '../../App.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const now = new Date();
var date1 = (now.getFullYear()) + '-' + checkZero(now.getMonth() + 1) + '-' + checkZero(now.getDate()) + ' ' + checkZero(now.getHours()) + ':' + checkZero(now.getMinutes()) + ':' + checkZero(now.getSeconds());
function checkZero(num) {
    console.log((num + "").length)
    return (num + "").length === 1 ? "0" + num : num;
}
export default class Receipt extends Component {
   
    state = {
        error: '', currencyName: '', paymentTypeId: '', paidAmount: '', paymentTypeRate: 0, currencyId: 0, expectedAmount: 0, receiptDateTime: date1, paymentTypes: [], msg: ''
        , fullname: '', idNumber: '', cellNumber: '', gender: '', dateOfBirth: '', age: '', cashPayable: 0, shortfallPayable: 0, receiptPayable: 0
        , cashOwing: 0, shortfallOwing: 0, receiptOwing: 0, cashPaid: 0, shortfallPaid: 0, receiptPaid: 0, cashRefund: 0
        , shortfallRefund: 0, receiptRefund: 0, billId: 0, companyId: 0, cName: '', cPaid: 0.0, sPaid: 0.0, action: 1, id: 0, showButton: false
    }
    openReceiptAction(billId, action, otcId) {
        this.setState({
            id: otcId, error: '', currencyName: '', paymentTypeId: '', paidAmount: '', paymentTypeRate: 0, currencyId: 0, expectedAmount: 0, receiptDateTime: date1, paymentTypes: [], msg: ''
            , fullname: '', idNumber: '', cellNumber: '', gender: '', dateOfBirth: '', age: '', cashPayable: 0, shortfallPayable: 0, receiptPayable: 0
            , cashOwing: 0, shortfallOwing: 0, receiptOwing: 0, cashPaid: 0, shortfallPaid: 0, receiptPaid: 0, cashRefund: 0, cName: '', cPaid: 0.0, sPaid: 0.0
            , shortfallRefund: 0, receiptRefund: 0, billId: 0, companyId: 0, action: action
        })
        axios.get(localStorage.getItem('host') + '/patient/getreceiptdtoview/' + billId, {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({
                    billId: res.data.billId, companyId: res.data.companyId, fullname: res.data.fullname, idNumber: res.data.idNumber, cellNumber: res.data.cellNumber, gender: res.data.gender, dateOfBirth: res.data.dateOfBirth, age: res.data.age, cashPayable: res.data.cashPayable, shortfallPayable: res.data.shortfallPayable, receiptPayable: res.data.receiptPayable
                    , cashOwing: res.data.cashOwing, shortfallOwing: res.data.shortfallOwing, receiptOwing: res.data.receiptOwing, cashPaid: res.data.cashPaid, shortfallPaid: res.data.shortfallPaid, receiptPaid: res.data.receiptPaid, cashRefund: res.data.cashRefund
                    , shortfallRefund: res.data.shortfallRefund, receiptRefund: res.data.receiptRefund, paymentTypes: res.data.paymentTypes, rate: res.data.rate, cName: res.data.currencyName, cPaid: res.data.cashPaid, sPaid: res.data.shortfallPaid
                });
                this.props.setLoader(false)
            }, err => {
                this.setState({ error: err + "" })
                this.props.setLoader(false)
            }
        )
    }
    save() {
        var error = "";
        this.setState({showButton: false})
        if (this.state.receiptDateTime === "") {
            error = "Receipt Date Time cannot be empty";
        }
        if (this.state.receiptDateTime.match(/^(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})$/) === null && error === "") {
            error = "Receipt Date Time wrong date format";
        }
        if (this.state.paymentTypeId === "" && error === "") {
            error = "Payment Type cannot be empty";
        }
        if (this.state.paidAmount === "" && error === "") {
            error = "Paid Amount cannot be empty";
        }
        if (this.state.paidAmount < 0) {
            error = "Paid Amount cannot be less than 0";
        }
        if (error === "") {
            var data = {
                billId: this.state.billId, companyId: this.state.companyId, currencyId: this.state.currencyId, cashPayable: this.state.cashPayable, shortfallPayable: this.state.shortfallPayable, receiptPayable: this.state.receiptPayable, paymentTypeId: this.state.paymentTypeId, paidAmount: this.state.paidAmount, receiptDateTime: this.state.receiptDateTime
                , cashOwing: this.state.cashOwing, shortfallOwing: this.state.shortfallOwing, receiptOwing: this.state.receiptOwing, cashPaid: this.state.cashPaid, shortfallPaid: this.state.shortfallPaid, receiptPaid: this.state.receiptPaid, cashRefund: this.state.cashRefund
                , shortfallRefund: this.state.shortfallRefund, receiptRefund: this.state.receiptRefund, paymentTypes: this.state.paymentTypes, rate: this.state.rate, expectedAmount: this.state.expectedAmount, convertedAmount: this.state.converted
            };
            axios.post(localStorage.getItem('host') + '/patient/savereceipt', data, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            }).then(
                res => {
                    if (res.data.error) {
                        this.setState({ error: res.data.error })
                    }
                    else {
                        this.closePopup()
                        console.log('............action  '+this.state.action)
                        if(this.state.action === 1)
                          {
                            this.props.getList()
                          }
                        else  if(this.state.action === 2)
                            {
                            this.props.getForm(this.state.id, '')
                            }  
                        this.props.getPrintOut(0, '', res.data.printouts)
                    }
                }, err => {
                    this.setState({ error: err + "" })
                    this.props.setLoader(false)
                }
            )
        }
        else {
            this.setState({ error: error })
        }

    }
    setReceiptItem(index) {
        this.setState({ paymentTypeId: '', expectedAmount: 0, currencyName: '' })
        if (index >= 0) {
            var paymentType = this.state.paymentTypes[index]
            this.setState({ paymentTypeId: paymentType.id, expectedAmount: (paymentType.rate / this.state.rate * this.state.receiptOwing), currencyName: paymentType.currencyName, currencyId: paymentType.currencyId, paymentTypeRate: paymentType.rate })

        }
    }
    setPaidAmount(paidAmount) {
        if (paidAmount > Number(this.state.expectedAmount)) {
            this.setState({ paidAmount: '', error: "Paid amount cannot be greater than owing amount" })
        }
        else {
            var converted = this.state.rate / this.state.paymentTypeRate * Number(paidAmount);
            var cashPaid = this.state.cashPaid;
            var shortfallPaid = this.state.shortfallPaid;

            if (this.state.cashPayable > 0) {
                cashPaid = this.state.cPaid + converted;
            }
            else {
                shortfallPaid = this.state.sPaid + converted;
            }
            var cashOwing = this.state.cashPayable - cashPaid
            var shortfallOwing = this.state.shortfallPayable - shortfallPaid
            this.setState({ paidAmount: paidAmount, cashPaid: cashPaid, shortfallPaid: shortfallPaid, converted: converted, cashOwing: cashOwing, shortfallOwing: shortfallOwing, showButton: paidAmount > 0 })
        }
    }
    closePopup() {
        document.getElementById('receiptAction').style = 'display:none'
    }
    currencyFormat(amount) {
        let val = (amount / 1).toFixed(2)
        return val.toString().split(/(?=(?:\d{3})+(?:\.|$))/g).join(" ").replace("-,", "-");
    }
    processDate(date) {
        const now = new Date(date);
        const todayY = now.getFullYear();
        const todayM = checkZero(now.getMonth() + 1);
        const todayD = checkZero(now.getDate());
        const todayH = checkZero(now.getHours());
        const todayMM = checkZero(now.getMinutes());
        const todayS = checkZero(now.getSeconds());
        var date1 = todayY + '-' + todayM + '-' + todayD + ' ' + todayH + ':' + todayMM + ':' + todayS;
        console.log(date1)
        return date1;


    }
    render() {
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        } //v-bind:style="'height: '+heightDuplicateRecords+'px;max-height: '+heightDuplicateRecords+'px;overflow: auto;overflow-x: hidden;'"
        if (this.state.error) {
            divMsg = (<div className='alert alert-danger'>{this.state.error}</div>)
        }
        return (<div id="receiptAction" className="page-popup page-popup-overlay"   ><div className="card" >
            <div className="card-header bg-info">Receipt Action</div>
            {divMsg}
            <div className="card-body" >
                <div className="card border-success" >
                    <div className="card-body">
                        <div className="row">
                            <div className="col"><span className="fw-bold">Fullname:</span> {this.state.fullname}</div>
                            <div className="col"><span className="fw-bold">ID No:</span> {this.state.idNumber}</div>
                            <div className="col"><span className="fw-bold">Cell No:</span> {this.state.cellNumber}</div>
                        </div>
                        <div className="row">
                            <div className="col"><span className="fw-bold">Gender:</span> {this.state.gender}</div>
                            <div className="col"><span className="fw-bold">DOB:</span> {this.state.dateOfBirth}</div>
                            <div className="col"><span className="fw-bold">Age:</span> <span dangerouslySetInnerHTML={{ __html: this.state.age }}></span></div>
                        </div>
                    </div>
                </div>
                <div >
                    <table className="table table-striped">
                        <tr>
                            <th></th>
                            <th>CASH ({this.state.cName})</th>
                            <th>SHORTFALL ({this.state.cName})</th>
                            <th>TOTAL ({this.state.cName})</th>
                        </tr>
                        <tbody>
                            <tr>
                                <th>Bill Amount</th>
                                <td> {this.currencyFormat(this.state.cashPayable)}</td>
                                <td>{this.currencyFormat(this.state.shortfallPayable)}</td>
                                <td>{this.currencyFormat(this.state.receiptPayable)}</td>
                            </tr>
                            <tr>
                                <th>Receiptable</th>
                                <td>{this.currencyFormat(this.state.cashOwing)}</td>
                                <td>{this.currencyFormat(this.state.shortfallOwing)}</td>
                                <td>{this.currencyFormat(this.state.receiptOwing)}</td>
                            </tr>
                            <tr>
                                <th>Paid</th>
                                <td>{this.currencyFormat(this.state.cashPaid)}</td>
                                <td>{this.currencyFormat(this.state.shortfallPaid)}</td>
                                <td>{this.currencyFormat(this.state.receiptPaid)}</td>
                            </tr>
                            <tr>
                                <th>Change</th>
                                <td>{this.currencyFormat(this.state.cashRefund)}</td>
                                <td>{this.currencyFormat(this.state.shortfallRefund)}</td>
                                <td>{this.currencyFormat(this.state.receiptRefund)}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="row">
                        <div className="col">
                            <div className="input-group"><span className="input-group-text">Receipt Date</span>
                                <DatePicker selected={new Date(this.state.receiptDateTime)} onChange={(date) => this.setState({ receiptDateTime: this.processDate(date) })} timeInputLabel="Time:" showTimeInput dateFormat="yyyy-MM-dd HH:mm:ss" />
                            </div></div>
                        <div className="col">
                            <div className="input-group"><span className="input-group-text">Payment Type</span>
                                <select className="form-control" onChange={e => this.setReceiptItem(e.target.value)}  >
                                    <option value="">Choose Payment Type</option>
                                    {this.state.paymentTypes.map((item, index) => (
                                        <option value={index} >{item.name}</option>
                                    ))}
                                </select>
                            </div></div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="input-group"><span className="input-group-text">Currency</span>
                                <input type="text" readOnly="readOnly" className="form-control" value={this.state.currencyName} />
                            </div></div>
                        <div className="col">
                            <div className="input-group"><span className="input-group-text">Expected Amount</span>
                                <input type="text" readOnly="readOnly" className="form-control" value={this.currencyFormat(this.state.expectedAmount)} />
                            </div></div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="input-group"><span className="input-group-text">Paid Amount</span>
                                <input type="text" className="form-control" value={this.state.paidAmount} onChange={e => this.setPaidAmount(e.target.value)} />
                            </div></div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6" align="center">{this.state.showButton && (<button onClick={() => this.save()} id="submitButton2" className="btn btn-primary" >Submit</button>) }</div>
                        <div className="col-lg-6" align="center"><button onClick={() => this.closePopup()} className="btn btn-primary">Close</button></div>
                    </div>
                </div>
            </div>
        </div></div>)

    }
}      