import React, { Component } from "react";
import axios from "axios";
import '../../App.css';

export default class AllocateAdvancedReceipt extends Component {
    state = { id: 0, allocationAdvancedReceipts: [], msg: '', error: '', fullname: '', number: '', paymentType: '', paidAmount: 0.00, availableAmount: 0.00, usedAmount: 0.00, rate: 0.0, total: 0.0 }

    getAllocateAdvancedRceipt(advancedReceipt) {
        this.setState({id: 0, allocationAdvancedReceipts: [], msg: '', error: '', fullname: '', number: '', paymentType: '', paidAmount: 0.00, availableAmount: 0.00, usedAmount: 0.00, rate: 0.0, total: 0.0 })
        axios.get(localStorage.getItem('host') + "/bill/allocationadvancedreceiptdto/"+advancedReceipt.patientId+"/"+advancedReceipt.companyId, {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(
            res => {
                for(var i in res.data)
                    {
                     res.data[i].cashPaidMain = res.data[i].cashPaid
                     res.data[i].cashOwingMain = res.data[i].cashOwing
                     res.data[i].allocatedAmount = "";     
                    }
                this.setState({ id: advancedReceipt.id, allocationAdvancedReceipts: res.data, fullname: advancedReceipt.fullname, number: advancedReceipt.number, paymentType: advancedReceipt.paymentType, paidAmount: advancedReceipt.paidAmount, availableAmount: advancedReceipt.availableAmount, usedAmount: advancedReceipt.usedAmount, total: advancedReceipt.availableAmount, rate: advancedReceipt.rate })
                this.props.setLoader(false)
            }, err => {
                this.setState({ error: err + "" })
                this.props.setLoader(false)
            }
        )
    }
    calcDeduction(index, max, value)
    {
        this.setState({error: ""})
        //console.log("..........Before "+value)
        //value = Number(value)
        //console.log("..........Ater "+value)
        var list = this.state.allocationAdvancedReceipts;
        list[index].allocatedAmount = value
        if(Number(value) > Number(max))
           {
            list[index].allocatedAmount = "";     
            list[index].cashPaid = list[index].cashPaidMain;       
            list[index].cashOwing = list[index].cashOwingMain;              
            this.setState({allocationAdvancedReceipts: list, error: "Error allocated amount of "+this.currencyFormat(value)+" Cannot be greater than "+this.currencyFormat(max)})
           }
         else
           {  
                list[index].cashPaid = Number(list[index].cashPaidMain) + value

                list[index].cashOwing = Number(list[index].cashOwingMain) - value
            var total = this.state.total; 
            for(var i in list)
              {
            var deduct = (list[i].allocatedAmount * this.state.rate / list[i].rate);
            list[index].calcRate = this.state.rate / list[i].rate
            console.log( this.state.rate+"..."+list[i].rate+"..."+total+" .... "+deduct)    
                 if((Number(total) - Number(deduct)) >= 0)
                      {
                      total = total - deduct;  
                      console.log("......"+list[i].rate)    
                      this.setState({allocationAdvancedReceipts: list, availableAmount: total, usedAmount: this.state.paidAmount - total})  
                      }
                 else
                      {
                        list[i].allocatedAmount = "";  
                        list[index].cashPaid = list[index].cashPaidMain;       
                        list[index].cashOwing = list[index].cashOwingMain;      
                        this.setState({allocationAdvancedReceipts: list, error: "Error allocated amount of "+this.currencyFormat(deduct)+" Cannot be greater than "+this.currencyFormat(total)}) 
                      }
             }
            }
    }
save()
   {
    var error = "";
    if (error === "")
    {
        this.props.setLoader(true)
        
        axios.post(localStorage.getItem('host') +"/bill/saveallocateadvancedreceipt", {
            allocationAdvancedReceipts: this.state.allocationAdvancedReceipts, 
           id: this.state.id, availableAmount: this.state.availableAmount, usedAmount: this.state.usedAmount
        }, {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        })
                .then(res => {
                    this.props.setMsg(res.data);
                    this.closePopup();
                    this.props.getList();
                    this.props.setLoader(false)
                })     
    } else
    {
        this.setState({error: error})
    }     
   }
   setRate(index, value)
    {
    var list = this.state.allocationAdvancedReceipts; 
    list[index].rate = value
    this.setState({allocationAdvancedReceipts: list})  
    }
    currencyFormat(amount) {
        let val = (amount / 1).toFixed(2)
        return val.toString().split(/(?=(?:\d{3})+(?:\.|$))/g).join(" ").replace("-,", "-");
    }
    closePopup() {
        document.getElementById('allocationAdvancedReceiptAction').style = 'display:none'
    }
    render() {
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        }
        if (this.state.error) {
            divMsg = (<div className='alert alert-danger'>{this.state.error}</div>)
        }
        return (<div id="allocationAdvancedReceiptAction" class="page-popup page-popup-overlay"   ><div class="card" >
            <div class="card-header bg-info">Allocation Advanced Receipt Action</div>
            <div class="card-body" >
                <div class="card border-success">
                    <div class="card-body">
                        {divMsg}
                        <div class="row">
                            <div class="col"><span className="fw-bold">Fullname:</span> {this.state.fullname}</div>
                            <div class="col"><span className="fw-bold">Number:</span> {this.state.number}</div>
                            <div class="col"><span className="fw-bold">Payment Type:</span> {this.state.paymentType}</div>
                        </div>
                        <div class="row">
                            <div class="col"><span className="fw-bold">Paid Amount:</span> {this.currencyFormat(this.state.paidAmount)}</div>
                            <div class="col"><span className="fw-bold">Available Amount:</span> {this.currencyFormat(this.state.availableAmount)}</div>
                            <div class="col"><span className="fw-bold">Used Amount:</span> {this.currencyFormat(this.state.usedAmount)}</div>
                        </div>
                        <div className="row">
                        <div className="col"><div className="input-group"><span className="input-group-text">Bill Rate </span> <input type="text" className="form-control" value={this.state.rate} onChange={(e) => this.setState({ rate: e.target.value })} /></div></div>
                        <div className="col"></div>
                        <div className="col"></div>
                        </div>
                    </div>
                </div>
                <div >
                    <table class="table table-striped table-hover">
                        <tr>
                            <th>Date</th>
                            <th>Number</th>
                            <th>Paid</th>
                            <th>Owing</th>
                            <th>Rate</th>
                            <th>Allocated</th>
                        </tr>
                        <tbody>
                            {this.state.allocationAdvancedReceipts.map((item, index) => (
                                <tr key={index} >
                                    <td>{item.billingStartDateTime}</td>
                                    <td>{item.number}</td>
                                    <td>{this.currencyFormat(item.cashPaid)}</td>
                                    <td>{this.currencyFormat(item.cashOwing)}</td>
                                    <td><input type="text" class="form-control" value={item.rate} onChange={e => this.setRate(index, e.target.value)} /></td>
                                   <td><input type="text" class="form-control" value={item.allocatedAmount} onChange={e => this.calcDeduction(index, item.cashOwingMain, e.target.value)} /></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div class="row">
                    <div class="col" align="center">{this.state.usedAmount > 0?(<button onClick={()=>this.save()} id="submitButton2" class="btn btn-primary" >Submit</button>):(<span></span>)}</div>
                    <div class="col" align="center"><button onClick={()=>this.closePopup()} class="btn btn-primary">Close</button></div>
                </div>
            </div>
        </div>
        </div>)

    }
}    