import React, { Component } from "react";
import axios from "axios";
import '../../App.css';
import Top from '../../includes/top'
import Sidebar from '../../includes/sidebar'
import { MDBDataTable } from 'mdbreact';


export default class Doctor extends Component {
    state = { id: 0, fullname: '', ahfoz: '', address: '', email: '', username: '', mobile: '', activeStatus: 1, list: []}
    componentDidMount() {
        this.getList()
    }
    action(e) {
        var id = e.currentTarget.id;
        console.log("......." + id + " .... " + e.currentTarget.id)
        this.showForm(id);
    }
    showForm(id) {
        this.setState({ id: 0, fullname: '', address: '', email: '', mobile: '', ahfoz: '', activeStatus: 1})
        if (id > 0) {
            axios.get(localStorage.getItem('host') + '/master/doctoraction/' + id, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            }).then(
                res => {
                     this.setState({ fullname: res.data.fullname, username: res.data.username, id: res.data.id, ahfoz: res.data.ahfoz, address: res.data.address, email: res.data.email, mobile: res.data.mobile, activeStatus: res.data.activeStatus});
                     }, err => {
                    console.log(".....")
                }
            )
        }
        document.querySelector('#action').style = 'display:block'
    }
    closePopup() {
        document.querySelector('#action').style = 'display:none'
    }
    save() {
        var data = { id: this.state.id, activeStatus: this.state.activeStatus, username: this.state.username, ahfoz: this.state.ahfoz, address: this.state.address, email: this.state.email, mobile: this.state.mobile, fullname: this.state.fullname}
        axios.post(localStorage.getItem('host') + '/master/doctorform', data, {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(
            res => {
                console.log(res.data)
                this.setState({ msg: res.data });
                this.getList();
                this.closePopup();
                setTimeout(() => {
                    this.setState({ msg: '' });
                }, 3000);
            }, err => {
                console.log(".....")
            }
        )
    }
    getList() {
        axios.get(localStorage.getItem('host') + '/master/doctor' , {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(
            res => {
                for (var index in res.data) {
                    res.data[index].action = <span className="link" title="Edit Doctor" id={res.data[index].id} onClick={e => this.action(e)}><i className="bi bi-pencil-square"></i></span>
                }
                this.setState({ list: res.data });
            }, err => {
                console.log(".....")
            }
        )
    }


    render() {
        const data = {
            columns: [

                {
                    label: 'Action',
                    field: 'action',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Name',
                    field: 'fullname',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Usename',
                    field: 'username',
                    sort: 'asc',
                    width: 150
                },                
                {
                    label: 'Ahfoz',
                    field: 'ahfoz',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Action Status',
                    field: 'stringStatus',
                    sort: 'asc',
                    width: 150
                }
            ],
            rows: this.state.list
        };
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        }
        return (
            <div className="d-flex" id="wrapper">
                <Sidebar />
                <div id="page-content-wrapper">
                    <Top />

                    <div className="container-fluid">
                        <button className="btn btn-primary" onClick={() => this.showForm(0)} ><i className="bi bi-plus-square"></i></button>
                        {divMsg}
                        <MDBDataTable
                            button="true"
                            striped
                            bordered
                            small
                            data={data}
                        />
                        <div className="page-popup page-popup-overlay" id="action">
                                <div className="card">
                                    <div className="card-header bg-info">
                                        Action 
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col">
                                            <div className="input-group"><span className="input-group-text">Fullname</span>
                                                <input type="text" className="form-control" value={this.state.fullname} onChange={e => { this.setState({ fullname: e.target.value }) }} /></div>
                                            </div>
                                            <div className="col">
                                            <div className="input-group"><span className="input-group-text">Active Status</span>
                                                <select className="form-control" value={this.state.activeStatus} onChange={e =>  this.setState({ activeStatus: e.target.value })}  >
                                                    <option value="0">No</option>
                                                    <option value="1">Yes</option>
                                                </select></div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                            <div className="input-group"><span className="input-group-text">Ahfoz</span>
                                                <input type="text" className="form-control" value={this.state.ahfoz} onChange={e => { this.setState({ ahfoz: e.target.value }) }} /></div>
                                            </div>
                                            <div className="col"> <div className="input-group"><span className="input-group-text">Address</span>
                                                <input type="text" className="form-control" value={this.state.address} onChange={e => { this.setState({ address: e.target.value }) }} /></div>
                                           </div>
                                        </div>

                                        <div className="row">
                                            <div className="col">
                                            <div className="input-group"><span className="input-group-text">Email</span>
                                                <input type="text" className="form-control" value={this.state.email} onChange={e => { this.setState({ email: e.target.value }) }} /></div>
                                            </div>
                                            <div className="col"> <div className="input-group"><span className="input-group-text">Mobile</span>
                                                <input type="text" className="form-control" value={this.state.mobile} onChange={e => { this.setState({ mobile: e.target.value }) }} /></div>
                                           </div>
                                        </div>


                                        <div className="row">
                                            <div className="col">
                                            <div className="input-group"><span className="input-group-text">Username</span>
                                                <input type="text" className="form-control" value={this.state.username} onChange={e => { this.setState({ username: e.target.value }) }} /></div>
                                            </div>
                                            <div className="col"></div>
                                            </div>
                                        <div className="row">
                                            <div className="col" align="right"><input type="button" className="btn btn-primary" onClick={() => this.save()} value="Save" /></div>
                                            <div className="col"><input type="button" className="btn btn-primary" onClick={() => this.closePopup()} value="Close" /></div>
                                        </div>
                                    </div></div>
                        </div>
                    </div>
                </div ></div >
        )

    }
}    