import React, { Component } from "react";
import axios from "axios";
import '../../App.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';

const now = new Date();
const todayY = now.getFullYear();
const todayM = checkZero(now.getMonth() + 1);
const todayD = checkZero(now.getDate());
var date1 = todayY + '-' + todayM + '-' + todayD;
console.log(date1)
function checkZero(num) {
    console.log((num + "").length)
    return (num + "").length === 1 ? "0" + num : num;
}

export default class HospitalBillAction extends Component {
    
    state = { barcode: '',billId: '', companyId: '',locationId: '', plocationId: '', rate: 0, institutionId: '', nurseDetails: true, billingDetails: false, dialyser: false, weightT: false, profilesT:false, machinePressuresT:false, heparinT: false, cathetersT:false, patientAssessment:false, dialysisFlowchart: false, fileData: '', billCategory: '', results: [], locations: [], services: [], options:[], billItems: [], billItemShow: [], pages: [], pageSize: 100, htmlText: '', popupName: '', fullname: '', number: '', mobile: '', gender: '', idNumber: '', dateOfBirth: '', age: '', bedDetails: '', nurseNotes: '', vitals: [], notes: [], referralLetters: [], historyVisits: [], visitId: 0, msg: '', error: '' ,
    preSystolic:  '', preDiastolic:  '', preBloodSugar:  '', postSystolic:  '', postDiastolic:  '', postBloodSugar:  '', dialyserSize:  '', dialyserUFSet:  '', dialyserUFRate:  '', dialyserDry:  '',  dialyserPreDialysis:  '', dialyserPostDialysis:  '', dialyserPrevious:  '', dialyserGained:  '', dialyserLost:  '', weightDry:  '', weightPreDialysis:  '', weightPostDialysis:  '', weightPrevious:  '', weightGained:  '',  weightLost:  '',profiles:  [], machinePressures:  [], heparins:  [], catheters:  [], cardiovascular:  '', pulmonary:  '', git:  '', musculoskeletal:  '', neurological:  '', integuement:  '', urinary:  '', latestDryWeight:  '',   preDialysisAssessment:  '', healthEducation:  '', mealsTaken:  '', incidentsEvents:  '', postDialysisEvaluation:  '', doctorsOrders:  ''
    , cashPayable: 0, insurancePayable: 0, shortfallPayable: 0, specialContractPayable: 0, totalPayable: 0, procedures: [], drugAllergy: '', otherAllergy: '', medicationGivens: [], procedure: ''
    , cashPaid: 0, insurancePaid: 0, shortfallPaid: 0, specialContractPaid: 0, totalPaid: 0 
    , cashOwing: 0, insuranceOwing: 0, shortfallOwing: 0, specialContractOwing: 0, totalOwing: 0, authorisationNumber: '', authorisationAmount: ''
    , ph: '', ketones: '', sg: '', proteins: '', leucocytes: '', urobilinogen: '', blood: '', nitrate: '', glucose: '', otherUrineAnaysis: '', cashOnlyPcp: 0 , cashOnlyDs: 0, cashOnly: 0, showButton: true}
    componentDidMount() {

    }
    openHospitalBillAction(visitId) {
        this.setState({barcode: '', billId: '', companyId: '', locationId: '', rate: 0, institutionId: '', billCategory: '', locations: [], options:[], services: [], billItems: [], billItemShow: [], pageSize: 100, htmlText: '', popupName: '', fullname: '', number: '', mobile: '', gender: '', idNumber: '', dateOfBirth: '', age: '', bedDetails: '', visitId: visitId, msg: '', error: ''
        , cashPayable: 0, insurancePayable: 0, shortfallPayable: 0, specialContractPayable: 0, totalPayable: 0, procedures: [], drugAllergy: '', otherAllergy: '', medicationGivens: [], procedure: ''
        , cashPaid: 0, insurancePaid: 0, shortfallPaid: 0, specialContractPaid: 0, totalPaid: 0 
        , cashOwing: 0, insuranceOwing: 0, shortfallOwing: 0, specialContractOwing: 0, totalOwing: 0 ,authorisationNumber: '', authorisationAmount: ''
        , ph: '', ketones: '', sg: '', proteins: '', leucocytes: '', urobilinogen: '', blood: '', nitrate: '', glucose: '', otherUrineAnaysis: '', cashOnlyPcp: 0, cashOnlyDs: 0, cashOnly: 0, showButton: true})
        axios.get(localStorage.getItem('host') + '/bill/hospitalbill/' + visitId+'/'+sessionStorage.getItem('roleId'), {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(
            res => {
                var options = [];
                for(var i in res.data.services)
                    {
                        options.push({value: res.data.services[i].id, label: res.data.services[i].name})
                    }  
                var pages = []    
                var count =  (res.data.billItems.length / this.state.pageSize) 
                for( i = 0; i < count; i++)
                    {
                     pages.push(i)
                    }
                this.setState({pages: pages, billId: res.data.billId, companyId: res.data.companyId, rate: res.data.rate, institutionId: res.data.institutionId, billItems: res.data.billItems, billItemShow: res.data.billItems.slice(0, this.state.pageSize), billCategory: res.data.billCategory,locations: res.data.locations, services: res.data.services, options: options, fullname: res.data.fullname, number: res.data.number, mobile: res.data.mobile, gender: res.data.gender, idNumber: res.data.idNumber, dateOfBirth: res.data.dateOfBirth, age: res.data.age, bedDetails: res.data.bedDetails
                    , cashPayable: res.data.cashPayable, insurancePayable: res.data.insurancePayable, shortfallPayable: res.data.shortfallPayable, specialContractPayable: res.data.specialContractPayable, totalPayable: res.data.totalPayable
                    , cashPaid: res.data.cashPaid, insurancePaid: res.data.insurancePaid, shortfallPaid: res.data.shortfallPaid, specialContractPaid: res.data.specialContractPaid, totalPaid: res.data.totalPaid
                    , cashOwing: res.data.cashOwing, insuranceOwing: res.data.insuranceOwing, shortfallOwing: res.data.shortfallOwing, specialContractOwing: res.data.specialContractOwing, totalOwing: res.data.totalOwing
                    ,authorisationNumber: res.data.authorisationNumber, authorisationAmount: res.data.authorisationAmount
                   });
                //this.props.setLoader(false)
                this.doValidate();
            }, err => {
                console.log(".....")
                this.setState({ error: err })
                this.props.setLoader(false)
            }
        )
    }
    save() {
        this.props.setLoader(true)
        this.setState({showButton: false})
        var billItems = this.state.billItems
        for(var i in this.state.billItems)
           {
            billItems[i].billStartDate = this.processDateTime(billItems[i].billStartDate)
            billItems[i].billEndDate = this.processDateTime(billItems[i].billEndDate)
           }
        var data = { visitId: this.state.visitId, billItems: billItems, billId: this.state.billId
            , currencyId: this.state.currencyId, cashPayable: this.state.cashPayable, insurancePayable: this.state.insurancePayable, shortfallPayable: this.state.shortfallPayable, specialContractPayable: this.state.specialContractPayable, totalPayable: this.state.totalPayable
            , cashOwing: this.state.cashOwing, insuranceOwing: this.state.insuranceOwing, shortfallOwing: this.state.shortfallOwing, specialContractOwing: this.state.specialContractOwing, totalOwing: this.state.totalOwing, authorisationNumber: this.state.authorisationNumber, authorisationAmount: this.state.authorisationAmount }
        axios.post(localStorage.getItem('host') + '/bill/hospitalbillsave', data, {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.props.setLoader(false)
                this.setState({ msg: res.data, showButton: true }); 
                document.getElementById('topDiv').scrollIntoView()
                this.props.updateAfterSave(this.state.visitId, [])
            }, err => {
                console.log(".....")
                this.setState({ error: err, showButton: true })
                this.props.setLoader(false)
            }
        )

    }
    closePopup() {
        document.getElementById('hospitalBillAction').style = 'display:none'
        this.props.getList();
    }
    processDate(date) {
        const now = new Date(date);
        const todayY = now.getFullYear();
        const todayM = checkZero(now.getMonth() + 1);
        const todayD = checkZero(now.getDate());
        var date1 = todayY + '-' + todayM + '-' + todayD;
        console.log(date1)
        return date1;


    }
    genericClosePopup() {
        document.getElementById('genericPopup').style = 'display:none'
    }
    currencyFormat(amount) {
        let val = (amount / 1).toFixed(2)
        return val.toString().split(/(?=(?:\d{3})+(?:\.|$))/g).join(" ").replace("-,", "-");
    }

    addServices()
       {  console.log('......... service cost id '+JSON.stringify(this.state.serviceCostId)) 
        if(this.state.serviceCostId.value > 0)
           {  console.log('......... service cost id '+this.state.serviceCostId.value) 
            const now = new Date();
            var date1 = (now.getFullYear()) + '-' + checkZero(now.getMonth() + 1) + '-' + checkZero(now.getDate()) + ' ' + checkZero(now.getHours()) + ':' + checkZero(now.getMinutes()) + ':' + checkZero(now.getSeconds());
            let list = this.state.billItems.slice();
            var s = '';
            for (var i in this.state.services) {
                if (this.state.services[i].id === Number(this.state.serviceCostId.value)) {
                    s = this.state.services[i];
                }
            }
            if(this.state.cashOnly === 1 || this.state.cashOnly === "1")
            {
             s.cashOnly = 1;
            }
            list.push({id: 0, serviceId: s.id, name: s.name, billItemType: 'SERVICE', serviceType: s.serviceType, serviceClass: s.serviceClass
            , tariffCode: s.tariffCode, billStartDate: date1, billEndDate: date1, quantity: s.quantity, unitCost: s.fixedCost, min: this.getMargin(s.fixedCost, s.margin, 'MIN'), max: this.getMargin(s.fixedCost, s.margin, 'MAX'), cost: s.fixedCost, totalCost: s.fixedCost, cashOnly: s.cashOnly, allowEdit: s.allowEdit, vat: 0.0, dispensingFee: 0.0, authoAmount: s.authoAmount, wac: 0.0, lastReceiptCost: 0.0, activeStatus: 1, productItemId: 0});
                for (var index in s.services)
                {
                    var ss = s.services[index];
                    list.push({id: 0, serviceId: ss.id, name: ss.name, billItemType: 'SERVICE', serviceType: ss.serviceType, serviceClass: ss.serviceClass
                        , tariffCode: ss.tariffCode, billStartDate: date1, billEndDate: date1, quantity: ss.quantity, unitCost: ss.fixedCost, min: this.getMargin(ss.fixedCost, ss.margin, 'MIN'), max: this.getMargin(ss.fixedCost, ss.margin, 'MAX'), cost: ss.fixedCost, totalCost: ss.fixedCost, cashOnly: ss.cashOnly, allowEdit: ss.allowEdit, vat: 0.0, dispensingFee: 0.0, authoAmount: ss.authoAmount, wac: 0.0, lastReceiptCost: 0.0, activeStatus: 1, productItemId: 0});
                }
            this.setState({ billItems: list });
            this.calcBill(list, this.state.authorisationNumber, this.state.authorisationAmount)
            this.doValidate()
           }
       } 
       getMargin(unitCost, margin, type)
          {
           let val = 0
           if(type === 'MIN')
               {
                 if(unitCost < 0)
                   {
                   val = unitCost +  Math.ceil(unitCost * (margin / 100));  
                   }  
                else
                   {
                   val = unitCost -  Math.ceil(unitCost * (margin / 100));    
                   val = val < 0?0:val
                   }   
               }
           else{
               if(unitCost < 0)
                   {
                   val = unitCost -  Math.ceil(unitCost * (margin / 100));  
                   val = val > 0?0:val
                   }  
                else
                   {
                   val = unitCost +  Math.ceil(unitCost * (margin / 100));    
                   }   
               
               }    
   
          return val;    
          }     
    setBillItem(name, index, value)   
       {
        var list = this.state.billItems;   
       switch(name)
          {    
             case "billStartDate":
                list[index].billStartDate = value
                break;
            case "billEndDate":
                list[index].billEndDate = value
                break;   
            case "quantity":
                if(list[index].billItemType === 'PRODUCT')
                {
                  if(Number(list[index].quantity) > Number(value))
                     {
                        list[index].quantity = value  
                     }
                }
                else
                  {
                    list[index].quantity = value
                    this.calcQuantity(index, list[index].quantity)
                  }
                break;   
            case "unitCost":
                list[index].unitCost = value
                this.calcQuantity(index, list[index].quantity)
                break;   
            case "cashOnly":
                list[index].cashOnly = Number(value)
                this.calcQuantity(index, list[index].quantity)
                break;     
            case "authoAmount":
                list[index].authoAmount = value 
                this.calcQuantity(index, list[index].quantity)
                break;  
             default: 
                     console.log("....")
                     break;   
          }
      this.setState({ billItems: list })    
      this.doValidate() 
       }
    calcBill(list,authorisationNumber, authorisationAmount)
        {
            var cashPayable = 0;
            var insurancePayable = 0;
            var shortfallPayable = 0;
            var specialContractPayable = 0;
            var authoAmount = 0;
            for (var index in list)
            {
                if (list[index].activeStatus === "1" || list[index].activeStatus === 1)
                {

                    if (list[index].cashOnly)
                    {
                       cashPayable += list[index].totalCost;
                    } else
                    {
                        switch (this.state.billCategory)
                        {
                            case 'CASH':
                                cashPayable += list[index].totalCost;
                                break;
                            case 'INSURANCE':
                                insurancePayable += list[index].totalCost;
                                authoAmount += Number(list[index].authoAmount);
                                break;
                            case 'SPECIAL_CONTRACT':
                                specialContractPayable += list[index].totalCost;
                                break;
                                default: 
                                        console.log("....")
                                        break;   
                        }
                    }
                }
            }
            if (authorisationNumber !== null && authorisationNumber !== '' && authorisationAmount > 0)
            {
                cashPayable += Number(insurancePayable) - Number(authorisationAmount);
                insurancePayable = authorisationAmount;
            }
            if(authoAmount > 0)
             {
                shortfallPayable = Number(insurancePayable) - Number(authoAmount);
                insurancePayable = authoAmount;
             }
            var cashOwing = Number(cashPayable) - Number(this.state.cashPaid);
            var insuranceOwing = Number(insurancePayable) - Number(this.state.insurancePaid);
            var shortfallOwing = Number(shortfallPayable) - Number(this.state.shortfallPaid);
            var specialContractOwing = Number(specialContractPayable) - Number(this.state.specialContractPaid);
            var totalPayable = Number(cashPayable) + Number(insurancePayable) + Number(shortfallPayable) + Number(specialContractPayable);
            var totalOwing = Number(cashOwing) + Number(insuranceOwing) + Number(shortfallOwing) + Number(specialContractOwing);
            this.setState({cashPayable: cashPayable, insurancePayable: insurancePayable, shortfallPayable: shortfallPayable, specialContractPayable: specialContractPayable, totalPayable: totalPayable
                , cashOwing: cashOwing, insuranceOwing: insuranceOwing, shortfallOwing: shortfallOwing, specialContractOwing: specialContractOwing, totalOwing: totalOwing})
        }
        calcQuantity(index, value)
        {
            var list = this.state.billItems;  
            list[index].cost = Number(list[index].unitCost) * Number(value);
            list[index].totalCost = list[index].cost;
            this.setState({ billItems: list })  
            this.calcBill(list, this.state.authorisationNumber, this.state.authorisationAmount);
        }
          hideEntry(index)
           {
              let list = this.state.billItems.slice();
              list[index].activeStatus = 0
              this.setState({ billItems: list }); 
              this.calcBill(list, this.state.authorisationNumber, this.state.authorisationAmount);
           } 
           getProductItems() {
               this.setState({ productItems: [] });
               this.props.setLoader(true)
               var data = {companyId:  this.state.companyId, locationId: this.state.locationId, institutionId: this.state.institutionId, name: this.state.name, rate: this.state.rate, billCategory: this.state.billCategory}
               axios.post(localStorage.getItem('host') + '/patientmanagement/getproductitems', data, {
                   headers: {
                       'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                   }
               }).then(
                   res => {
                    var options = [];
                    for(var i in res.data)
                        {
                            options.push({value: res.data[i].productItemLocationId, label: res.data[i].name+" ["+res.data[i].quantity+"] - "+res.data[i].batchNumber+" - "+res.data[i].expiryDate})
                        } 
                    this.setState({productItems: res.data, productOptions: options});
                    this.props.setLoader(false)
                   }, err => {
                       console.log(".....")
                       this.setState({error: err+""})
                       this.props.setLoader(false)
                   }
               )
           }
        addProductItem()
           {
            if(this.state.productItemLocationId.value > 0)
               {  console.log('......... service cost id '+this.state.productItemLocationId.value) 
                const now = new Date();
                var date1 = (now.getFullYear()) + '-' + checkZero(now.getMonth() + 1) + '-' + checkZero(now.getDate()) + ' ' + checkZero(now.getHours()) + ':' + checkZero(now.getMinutes()) + ':' + checkZero(now.getSeconds());
                let list = this.state.billItems.slice();
                var s = '';
                for (var i in this.state.productItems) {
                    if (this.state.productItems[i].productItemLocationId === Number(this.state.productItemLocationId.value)) {
                        s = this.state.productItems[i];
                        s.cashOnly = this.state.cashOnlyDs === 1 || this.state.cashOnlyDs === "1"?1: s.cashOnly;
                    }
                }
             if(this.state.quantity <= s.quantity)
                 {   
                list.push({id: 0, serviceId: 0, name: s.name, billItemType: 'PRODUCT', serviceType: 'MULTIPLE_QUANTITY_SUM_COST', serviceClass: 'HOSPITAL', productType:s.type
                , tariffCode: s.tariffCode, billStartDate: date1, billEndDate: date1, quantity: this.state.quantity, unitCost: s.sellingPrice, min: this.getMargin(s.sellingPrice, s.margin, 'MIN'), max: this.getMargin(s.sellingPrice, s.margin, 'MAX'), cost: (s.sellingPrice * this.state.quantity), totalCost: (s.sellingPrice * this.state.quantity), cashOnly: s.cashOnly, allowEdit: s.allowEdit, vat: 0.0, dispensingFee: 0.0, authoAmount: s.authoAmount, wac: 0.0, lastReceiptCost: s.buyingPrice, activeStatus: 1, productItemId: s.id, productItemLocationId: s.productItemLocationId, locationId: this.state.locationId});
                this.setState({ billItems: list, cashOnlyDs: 0});
                this.calcBill(list, this.state.authorisationNumber, this.state.authorisationAmount)
                this.doValidate()
                 }
               else
                  {
                    window.scrollTo({top: 0, behavior: 'smooth'})
                    this.setState({error:"Entered quantity cannot be greater than available quantity of "+s.quantity})  
                  }  
               }
           } 
           doValidate() {
               setTimeout(() => {
                   this.validate();
               }, 1000);
           }
           async  validate() {
            this.props.setLoader(true)
               var error = false;
               var billItems = this.state.billItems;
               if (billItems === null) {
                   this.setState({ billItemsError: "Bill Items cannot be empty" })
                   error = true
               }
               else if (billItems.length === 0) {
                   this.setState({ billItemsError: "Bill Items cannot be empty" })
                   error = true
               }
               else {
                  
                   var i = billItems.length
                   while (i--)
                   {
                    billItems[i].unitCostError =  "" 
                    if(billItems[i].allowEdit)
                      {
                       if (billItems[i].unitCost === null) {
                           billItems[i].unitCostError =  "Unit Cost cannot be empty"
                           error = true
                       }
                       else if (billItems[i].unitCost === "") {
                           billItems[i].unitCostError =  "Unit Cost cannot be empty"
                           error = true
                       }
                       else if (billItems[i].unitCost >= 0 && billItems[i].unitCost > billItems[i].max) {
                        billItems[i].unitCostError =  "Unit Cost cannot be greater than "+billItems[i].max
                        error = true
                    }
                    else if (billItems[i].unitCost >= 0 && billItems[i].unitCost < billItems[i].min) {
                        billItems[i].unitCostError =  "Unit Cost cannot be less than "+billItems[i].min
                        error = true
                    }
                    else if (billItems[i].unitCost < 0 && billItems[i].unitCost > 0) {
                        billItems[i].unitCostError =  "Unit Cost cannot be greater than 0"
                        error = true
                    }
                    else if (billItems[i].unitCost < 0 && billItems[i].unitCost < billItems[i].min) {
                        billItems[i].unitCostError =  "Unit Cost cannot be less than "+billItems[i].min
                        error = true
                    }
                       else {
                           billItems[i].unitCostError =  "" 
                       }
       
                       console.log(billItems[i].unitCostError)
                      }
                   }
                   this.setState({ billItemsError: "", billItems: billItems })   
               }
               console.log(" error" + error)
               if(this.state.allowSave === false)
                 {
                   this.setState({ error: "The visit for this patient cannot be saved. Please contact accounts department for Help", showSubmit: false })
                 }
               else  if (error === true) {
                   this.setState({ error: "One or more of the mandatory field(s) is not filled in", showSubmit: false })
               }
               else {
                   this.setState({ error: "", showSubmit: true })
               }
               this.props.setLoader(false)
           } 
           doProductItemFromBarCode(val) {
               this.props.setLoader(true)
               setTimeout(() => {
                   if(this.state.barcode === val && this.state.barcode !== "")
                     {
                      this.setProductItemFromBarCode();
                     }
               }, 2000);
           }
           setProductItemFromBarCode()
                 {
                   const now = new Date();
                    var date1 = (now.getFullYear()) + '-' + checkZero(now.getMonth() + 1) + '-' + checkZero(now.getDate()) + ' ' + checkZero(now.getHours()) + ':' + checkZero(now.getMinutes()) + ':' + checkZero(now.getSeconds());
                    var data = {companyId:  this.state.companyId, locationId: this.state.locationId, institutionId: this.state.institutionId, barcode: this.state.barcode, rate: this.state.rate, billCategory: this.state.billCategory}
                    axios.post(localStorage.getItem('host') + '/patientmanagement/getproductitemsbarcode', data, {
                       headers: {
                           'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                       }
                   }).then(
                       res => {
                           this.setState({barcode: ""})
                           if(res.data.quantity > 0)
                             {
                           var quantity = 0;
                           var index = 0;
                           var list = this.state.billItems;
                           for(var i in list)
                              {
                               if(list[i].productItemLocationId === res.data.productItemLocationId)
                                   {
                                    quantity++;
                                    index = i;
                                   }
                              }
                           if(quantity === 0)
                              {
                                res.data.cashOnly = this.state.cashOnlyDs === 1 || this.state.cashOnlyDs === "1"?1: res.data.cashOnly;
                                list.push({id: 0, serviceId: 0, name: res.data.name, billItemType: 'PRODUCT', serviceType: 'MULTIPLE_QUANTITY_SUM_COST', serviceClass: 'HOSPITAL', productType:res.data.type
                                , tariffCode: res.data.tariffCode, billStartDate: date1, billEndDate: date1, quantity: 1, unitCost: res.data.sellingPrice, min: this.getMargin(res.data.sellingPrice, res.data.margin, 'MIN'), max: this.getMargin(res.data.sellingPrice, res.data.margin, 'MAX'), cost: res.data.sellingPrice , totalCost: res.data.sellingPrice, cashOnly: res.data.cashOnly, allowEdit: res.data.allowEdit, vat: 0.0, dispensingFee: 0.0, authoAmount: res.data.authoAmount, wac: 0.0, lastReceiptCost: res.data.buyingPrice, activeStatus: 1, productItemId: res.data.id, productItemLocationId: res.data.productItemLocationId, locationId: this.state.locationId});
                                this.setState({ billItems: list , cashOnlyDs: 0});
                                 this.calcBill(list, this.state.authorisationNumber, this.state.authorisationAmount)
                               this.doValidate()
                              }   
                           else if(quantity <= res.data.quantity)
                           {   
                           list[index].quantity = list[index].quantity + 1
                           list[index].cost =  list[index].quantity * list[index].unitCost
                           list[index].totalCost =  list[index].quantity * list[index].unitCost
                          this.calcBill(list, this.state.authorisationNumber, this.state.authorisationAmount)
                          this.doValidate()
                           }
                         else
                            {                              
                              document.getElementById('topDiv').scrollIntoView()
                              this.setState({error:"Entered quantity cannot be greater than available quantity of "+res.data.quantity})  
                            }
                          
                             } 
                          else
                             {
                                document.getElementById('topDiv').scrollIntoView()
                              this.setState({error: "Product was not found."}) 
                             }   
                             this.props.setLoader(false)
                       }, err => {
                           console.log(".....")
                           this.setState({error: err+"", barcode: ""})
                           document.getElementById('topDiv').scrollIntoView()
                           this.props.setLoader(false)
                       }
                   )
                   
                 } 
           processDateTime(date)
             {
               const now = new Date(date);
               const todayY = now.getFullYear();
               const todayM = checkZero(now.getMonth() + 1);
               const todayD = checkZero(now.getDate());
               var date1 = todayY + '-' + checkZero(todayM) + '-' + checkZero(todayD)+ ' ' + checkZero(now.getHours()) + ':' + checkZero(now.getMinutes()) + ':' + checkZero(now.getSeconds());;
               console.log(date1)
              return date1;
             }
    getPreConfigured(locationId)
        {
            this.setState({ procedures: [], locationId: locationId });
               this.props.setLoader(true)
              axios.get(localStorage.getItem('host') + '/patientmanagement/getprocedures/'+locationId+"/"+this.state.visitId,  {
                   headers: {
                       'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                   }
               }).then(
                   res => {
                    this.setState({procedures: res.data});
                    this.props.setLoader(false)
                   }, err => {
                       console.log(".....")
                       this.setState({error: err+""})
                       this.props.setLoader(false)
                   }
               )  
        }    
        viewHistory(visitId) {
    
            this.setState({ htmlText: '', popupName: '' })
             this.props.setLoader(true)
            axios.get(localStorage.getItem('host') + '/hospital/casualtysummaryview/' + visitId, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            }).then(
                res => {
                    this.setState({ htmlText: res.data, popupName: 'Doctor History' });
                    document.getElementById('genericPopup').style = 'display:block'
                    this.props.setLoader(false)
                }, err => {
                    console.log(".....")
                    this.setState({ error: err })
                    this.props.setLoader(false)
                }
            )
        }
  addPreconfigs()
        {
         if(this.state.procedure >= 0)
            {
             var procedures = this.state.procedures[this.state.procedure];
             let list = this.state.billItems.slice();
             for (var i in procedures.productItems) {
                var s = procedures.productItems[i];
                s.cashOnly = this.state.cashOnlyPcp === 1 || this.state.cashOnlyPcp === "1"?1: s.cashOnly
                list.push({id: 0, serviceId: 0, name: s.name, billItemType: 'PRODUCT', serviceType: 'MULTIPLE_QUANTITY_SUM_COST', serviceClass: 'HOSPITAL', productType:s.type
                , tariffCode: s.tariffCode, billStartDate: date1, billEndDate: date1, quantity: s.quantity, unitCost: s.sellingPrice, min: this.getMargin(s.sellingPrice, s.margin, 'MIN'), max: this.getMargin(s.sellingPrice, s.margin, 'MAX'), cost: (s.sellingPrice * s.quantity), totalCost: (s.sellingPrice * s.quantity), cashOnly: s.cashOnly, allowEdit: s.allowEdit, vat: 0.0, dispensingFee: 0.0, authoAmount: s.authoAmount, wac: 0.0, lastReceiptCost: s.buyingPrice, activeStatus: 1, productItemId: s.id, productItemLocationId: s.productItemLocationId, locationId: this.state.locationId});
               }
               for (i in procedures.services) {
                s = procedures.services[i];
                s.cashOnly = this.state.cashOnlyPcp === 1 || this.state.cashOnlyPcp === "1"?1: s.cashOnly
                list.push({id: 0, serviceId: s.id, name: s.name, billItemType: 'SERVICE', serviceType: s.serviceType, serviceClass: s.serviceClass
                , tariffCode: s.tariffCode, billStartDate: date1, billEndDate: date1, quantity: s.quantity, unitCost: s.fixedCost, min: this.getMargin(s.fixedCost, s.margin, 'MIN'), max: this.getMargin(s.fixedCost, s.margin, 'MAX'), cost: s.fixedCost, totalCost: s.fixedCost, cashOnly: s.cashOnly, allowEdit: s.allowEdit, vat: 0.0, dispensingFee: 0.0, authoAmount: s.authoAmount, wac: 0.0, lastReceiptCost: 0.0, activeStatus: 1, productItemId: 0});
                 }
           
                this.setState({ billItems: list , cashOnlyPcp: 0});
                this.calcBill(list, this.state.authorisationNumber, this.state.authorisationAmount)
                this.doValidate()
            }
        }   
        setPage(page)
         {   
         var s = (page * this.state.pageSize);
        // console.log(page + "-" +s +"-"+ this.state.pageSize)
         var e = s + this.state.pageSize 
         var list = this.state.billItems.slice( s,e)
        // console.log(JSON.stringify(list))
         this.setState({billItemShow: list})
         }          
    render() {
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        } //v-bind:style="'height: '+heightDuplicateRecords+'px;max-height: '+heightDuplicateRecords+'px;overflow: auto;overflow-x: hidden;'"
        if (this.state.error) {
            divMsg = (<div className='alert alert-danger'>{this.state.error}</div>)
        }
        return (<div id="hospitalBillAction" className="page-popup page-popup-overlay"   ><div className="card" >
            <div className="card-header  bg-info" id="topDiv">Bill Action</div>
            <div className="card-body" >
                {divMsg}
                <div className="row">
                    <div className="col">
                        <div className="card border-success">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col"><span className="fw-bold">Fullname:</span> {this.state.fullname}</div>
                                    <div className="col"><span className="fw-bold">ID No:</span> {this.state.idNumber}</div>
                                    <div className="col"><span className="fw-bold">Cell No:</span> {this.state.mobile}</div>
                                </div>
                                <div className="row">
                                    <div className="col"><span className="fw-bold">Gender:</span> {this.state.gender}</div>
                                    <div className="col"><span className="fw-bold">DOB:</span> {this.state.dateOfBirth}</div>
                                    <div className="col"><span className="fw-bold">Age:</span> <span dangerouslySetInnerHTML={{ __html: this.state.age }}></span></div>
                                </div>
                            </div>
                        </div>
                    <div className="card"><div className="card-body">
                                            <div className="row">
                                            <div className="col">
                                                 <div className="input-group"><span className="input-group-text">Location</span>
                                                <select className="form-control" onChange={e => this.getPreConfigured(e.target.value)}  >
                                                    <option value="0">Choose Location</option>
                                                    {this.state.locations.map((item, index) => (
                                                        <option key={index} value={item.id} >{item.name}</option>
                                                    ))}
                                                </select></div>
                                            </div>
                                                <div className="col "><div className="input-group">
                                                    <span className="input-group-text">Pre Configured Procedures</span>
                                                    <select className="form-control" value={this.state.procedure} onChange={e => this.setState({ procedure: e.target.value })}  >
                                                    <option value="">Choose Pre Configured Procedures</option>
                                                    {this.state.procedures.map((item, index) => (
                                                        <option key={index} value={index} >{item.name}</option>
                                                    ))}
                                                </select></div></div>
                                                <div className="col "><div className="input-group">
                                                    <span className="input-group-text">Cash Only</span><select className="form-control" data-live-search="true" value={this.state.cashOnlyPcp} onChange={e => this.setState({ cashOnlyPcp: e.target.value })}>
                                                        <option value="0">No</option>
                                                        <option value="1">Yes</option>
                                                    </select></div></div>
                                                <div className="col" align="left">{ this.state.procedure >= 0 && (<input type="button" onClick={() => this.addPreconfigs()} value="Add" className="btn btn-success" />)}</div>
                                            </div>
                                            </div></div>
                                            <br />
                                      <div className="card"><div className="card-body">
                                            <div className="row">
                                                <div className="col "><div className="input-group">
                                                    <span className="input-group-text">Service</span>
                                                    <Select
                                                    value={this.state.serviceCostId}
                                                    className="form-control"
                                                    onChange={e => this.setState({ serviceCostId: e })}
                                                    options={this.state.options}
                                                /></div></div>
                                                <div className="col "><div className="input-group">
                                                    <span className="input-group-text">Cash Only</span><select className="form-control" data-live-search="true" value={this.state.cashOnly} onChange={e => this.setState({ cashOnly: e.target.value })}>
                                                        <option value="0">No</option>
                                                        <option value="1">Yes</option>
                                                    </select></div></div>
                                                <div className="col" align="left">{ this.state.serviceCostId !== "" && (<input type="button" onClick={() => this.addServices()} value="Add" className="btn btn-success" />)}</div>
                                            </div>
                                            </div></div>
                                            <br />
                                            <div className="card"><div className="card-body">
                                            <div className="row">
                                            <div className="col">
                                                 <div className="input-group"><span className="input-group-text">Location</span>
                                                <select className="form-control" value={this.state.locationId} onChange={e => this.setState({ locationId: e.target.value })}  >
                                                    <option value="0">Choose Location</option>
                                                    {this.state.locations.map((item, index) => (
                                                        <option key={index} value={item.id} >{item.name}</option>
                                                    ))}
                                                </select></div>
                                            </div>
                                            <div className="col">
                                                 <div className="input-group"><span className="input-group-text">Name</span>
                                                 <input type="text" className="form-control" value={this.state.name} onChange={e => { this.setState({name: e.target.value}) }} /></div>
                                            </div>
                                            <div className="col"><button className="btn btn-success" onClick={() => this.getProductItems()} ><i class="bi bi-search"></i></button></div>
                                            <div className="col"> <div className="input-group"><span className="input-group-text">Barcode</span><input type="text" className="form-control" readOnly={this.state.locationId === ''?"readOnly":""} value={this.state.barcode} onChange={e => { this.setState({barcode: e.target.value}); this.doProductItemFromBarCode(e.target.value) }} /></div></div>
                                        </div>
                                            <div className="row">
                                            <div className="col">
                                                 <div className="input-group"><span className="input-group-text">Products</span>
                                                 <Select
                                                    value={this.state.productItemLocationId}
                                                    className="form-control"
                                                    onChange={e => this.setState({ productItemLocationId: e })}
                                                    options={this.state.productOptions}
                                                /></div>
                                            </div>
                                            <div className="col">
                                                 <div className="input-group"><span className="input-group-text">Quantity</span>
                                                 <input type="text" className="form-control" value={this.state.quantity} onChange={e => { this.setState({quantity: e.target.value}) }} /></div>
                                            </div>
                                            
                                            <div className="col "><div className="input-group">
                                                    <span className="input-group-text">Cash Only</span><select className="form-control" data-live-search="true" value={this.state.cashOnlyDs} onChange={e => this.setState({ cashOnlyDs: e.target.value })}>
                                                        <option value="0">No</option>
                                                        <option value="1">Yes</option>
                                                    </select></div></div>
                                             <div className="col">{ this.state.productItemLocationId !== "" && this.state.quantity > 0 && (<input type="button"  onClick={() => this.addProductItem()} value="Add" className="btn btn-success" />)}</div>

                                        </div>
                                        </div></div>
                                            <br />  
                                          <div className="row">
                                            <div className="col"><ul className="list-group list-group-horizontal">
                                            {this.state.pages.map((p, index) => (
                                                <li index={index} className="list-group-item"><span className="link" onClick={() => this.setPage(p)}>{(p + 1)}</span></li>
                                            ))}
                                                </ul></div>
                                            </div>                 
                                            <table className="table table-striped">
                                                <tr>
                                                    <th>Line</th>
                                                    <th>Name</th>
                                                    <th>Tariff</th>
                                                    <th>Start Date</th>
                                                    <th>End Date</th>
                                                    <th>Quantity</th>
                                                    <th>Unit Cost</th>
                                                    <th>Cost</th>
                                                    <th>Autho Amount</th>
                                                    <th>Total Cost</th>
                                                    <th>Cash Only</th>
                                                    <th>Action</th>
                                                </tr>
                                                <tbody>
                                                {this.state.billItems.map((billItem, index) => (
                                                    billItem.activeStatus === 1 &&(<tr index={index}>
                                                        <td >{index + 1}</td>
                                                        <td >{billItem.name}</td>
                                                        <td>{billItem.tariffCode}</td>
                                                        <td><DatePicker selected={new Date(billItem.billStartDate)} onChange={e => this.setBillItem("billStartDate", index, e)} timeInputLabel="Time:" showTimeInput dateFormat="yyyy-MM-dd HH:mm:ss" /></td>
                                                        <td><DatePicker selected={new Date(billItem.billEndDate)} onChange={e => this.setBillItem("billEndDate", index, e)} timeInputLabel="Time:" showTimeInput dateFormat="yyyy-MM-dd HH:mm:ss" /></td>
                                                        {billItem.serviceType === 'MULTIPLE_QUANTITY' || billItem.billItemType === "PRODUCT"?(<td><input type="text" className="form-control" value={billItem.quantity} onChange={e => {this.setBillItem("quantity", index, e.target.value); this.calcQuantity(index, e.target.value)}} /></td>):(<td>{billItem.quantity}</td>)}
                                                        {billItem.allowEdit === 1 ?(<td><div className="input-group has-validation"><input type="text" className={billItem.unitCostError !== "" ? "form-control is-invalid" : "form-control"} v-model="billItem.unitCost" value={billItem.unitCost} onChange={e => this.setBillItem("unitCost", index, e.target.value)} /><div className="invalid-feedback">{billItem.unitCostError}</div></div></td>):(<td>{this.currencyFormat(billItem.unitCost)}</td>)}
                                                        <td>{this.currencyFormat(billItem.cost)}</td>
                                                        <td>{billItem.authoAmount}</td>
                                                        <td>{this.currencyFormat(billItem.totalCost)}</td>
                                                        <td><select className="form-control" value={billItem.cashOnly} onChange={e => { this.setBillItem("cashOnly",index, e.target.value) }}  >
                                                        <option value="1" >Yes</option>
                                                            <option value="0" >No</option>
                                                        </select></td>
                                                        <td><span className="link-red" onClick={() => this.hideEntry(index)}><i className="bi bi-trash-fill"></i></span></td>
                                                    </tr>)
                                                ))}
                                                </tbody>
                                            </table>
                                            <div className="row">
                                            <div className="input-group">
                                                        <input type="hidden" className={this.state.billItemsError !== "" ? "form-control is-invalid" : "form-control"} />
                                                        <div className="invalid-feedback">{this.state.billItemsError}</div> </div></div>
                                        <div className="row">
                                                <div className="col"><div className="input-group"><span className="input-group-text">Authorisation Number</span><input type="text" className="form-control" data-live-search="true" onChange={e => {this.setState({ authorisationNumber: e.target.value }); this.calcBill(this.state.billItems, e.target.value, this.state.authorisationAmount);}} value={ this.state.authorisationNumber} /></div></div>
                                                <div className="col"><div className="input-group"><span className="input-group-text">Authorisation Amount</span><input type="text" className="form-control" data-live-search="true" onChange={e => {this.setState({ authorisationAmount: e.target.value }); this.calcBill(this.state.billItems, this.state.authorisationNumber, e.target.value);}} value={ this.state.authorisationAmount} /></div></div>
                                                </div>            
                                         
                                            <table className="table table-striped">
                                                <tr>
                                                    <th></th>
                                                    <th>Cash</th>
                                                    <th>Insurance</th>
                                                    <th>Shortfall</th>
                                                    <th>Special Contract</th>
                                                    <th>Total</th>
                                                </tr>
                                                <tbody>
                                                <tr>
                                                    <th>Payable</th>
                                                    <td>{this.currencyFormat(this.state.cashPayable)}</td>
                                                    <td>{this.currencyFormat(this.state.insurancePayable)}</td>
                                                    <td>{this.currencyFormat(this.state.shortfallPayable)}</td>
                                                    <td>{this.currencyFormat(this.state.specialContractPayable)}</td>
                                                    <td>{this.currencyFormat(this.state.totalPayable)}</td>
                                                </tr>
                                                <tr>
                                                    <th>Paid</th>
                                                    <td>{this.currencyFormat(this.state.cashPaid)}</td>
                                                    <td>{this.currencyFormat(this.state.insurancePaid)}</td>
                                                    <td>{this.currencyFormat(this.state.shortfallPaid)}</td>
                                                    <td>{this.currencyFormat(this.state.specialContractPaid)}</td>
                                                    <td>{this.currencyFormat(this.state.totalPaid)}</td>
                                                </tr>
                                                <tr>
                                                    <th>Owing</th>
                                                    <td>{this.currencyFormat(this.state.cashOwing)}</td>
                                                    <td>{this.currencyFormat(this.state.insuranceOwing)}</td>
                                                    <td>{this.currencyFormat(this.state.shortfallOwing)}</td>
                                                    <td>{this.currencyFormat(this.state.specialContractOwing)}</td>
                                                    <td>{this.currencyFormat(this.state.totalOwing)}</td>
                                                </tr></tbody>

                                            </table>
                                            </div>
                </div>
                <div className="row">
                    <div className="col" align="right">{ this.state.showButton && (<button onClick={() => this.save()} className="btn btn-primary">Save</button>)}</div>
                    <div className="col" align="left"><button onClick={() => this.closePopup()} className="btn btn-primary">Close</button></div>
                </div>
            </div>
        </div>
        </div>)

    }
}      