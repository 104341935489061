import React, { Component } from "react";
import axios from "axios";
import '../../App.css';
import Top from '../../includes/top'
import Sidebar from '../../includes/sidebar'
import PrintOut from '../patient/printout'
import HospitalNurseAction from "./hospitalnurse";
import ResultAction from "./resultaction";
import SmsAction from './smsaction'


function checkZero(num) {
    return (num + "").length === 1 ? "0" + num : num;
}

export default class HospitalNurseList extends Component {
    constructor(props) {
        super(props);
    this.setLoader = this.setLoader.bind(this)
    this.getResultAction = this.getResultAction.bind(this)
    this.updateAfterSave = this.updateAfterSave.bind(this)
    this.getList = this.getList.bind(this)
    this.printOutRef = React.createRef()
    this.nurseActionRef = React.createRef()
    this.resultActionRef = React.createRef()
    this.smsActionRef = React.createRef()
    }
    state = { id: 0, firstname: '', surname: '', visitStatus: 'PENDING' , companyId: sessionStorage.getItem('companyId'), list: [], clusterCompanys: [] }
    componentDidMount() {
        axios.get(localStorage.getItem('host') + '/auth/getclustercompanys/'+sessionStorage.getItem('companyId'), {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({clusterCompanys: res.data});
                this.getList()
                this.startAutoList();
            }, err => {
                console.log(".....")
                 this.setState({ error: err.Error })
            }
        )
    }
   startAutoList()
   {
    setInterval(() => {
        this.getListSilent();
    }, 1000 * 20)
   } 
    action(e) {
        var id = e.target.id;
        console.log("......." + id + " .... " + e.target.id)
        this.showForm(id);
    }
    showForm(id) {
        this.setState({ id: 0, name: '', activeStatus: 1 })
        if (id > 0) {
            axios.get(localStorage.getItem('host') + '/master/appointmenttimeaction/' + id, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            }).then(
                res => {
                    this.setState({ name: res.data.name, id: res.data.id, activeStatus: res.data.activeStatus });
                }, err => {
                    console.log(".....")
                }
            )
        }
        document.querySelector('#action').style = 'display:block'
    }
    closePopup() {
        document.querySelector('#action').style = 'display:none'
        console.log('......close');
    }
    save() {
        var data = { id: this.state.id, activeStatus: this.state.activeStatus, name: this.state.name, companyId: sessionStorage.getItem('companyId') }
        axios.post(localStorage.getItem('host') + '/master/appointmenttimeform', data, {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(
            res => {
                console.log(res.data)
                this.setState({ msg: res.data });
                this.getList();
                this.closePopup();
                setTimeout(() => {
                    this.setState({ msg: '' });
                }, 3000);
            }, err => {
                console.log(".....")
            }
        )
    }
    getList() {
        this.setLoader(true)
        var data = { firstname: this.state.firstname, surname: this.state.surname, visitStatus: this.state.visitStatus, companyId: this.state.companyId }
        axios.post(localStorage.getItem('host') + '/patientmanagement/pendingnurselist', data, {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ list: res.data });
                this.setLoader(false)
            }, err => {
                console.log(".....")
                 this.setState({ error: err.Error })
                this.setLoader(false)
            }
        )
    } 
    getListSilent() {
        var data = { firstname: this.state.firstname, surname: this.state.surname, visitStatus: this.state.visitStatus, companyId: this.state.companyId }
        axios.post(localStorage.getItem('host') + '/patientmanagement/pendingnurselist', data, {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ list: res.data });
            }, err => {
                console.log(".....")
                 this.setState({ error: err.Error })
            }
        )
    } 
    getPrintOut(id, type, printouts, companyId)
      {
        this.setState({loader: true})  
        this.printOutRef.current.getPrintOut(id, type, printouts, companyId)
        document.getElementById('printOutAction').style = 'display:block'
      }
    setLoader(loader)
    {
     this.setState({loader: loader})
    }
    openNurseAction(visitId)
    {
    this.nurseActionRef.current.openNurseAction(visitId)
    document.getElementById('nurseAction').style = 'display:block'   
    }
    getResultAction(visitId)
     {
        this.setState({loader: true})
        this.resultActionRef.current.getResultAction(visitId)
        document.getElementById('resultAction').style = 'display:block'     
     }
     openSmsAction(visitId)
     {
        this.setState({loader: true})
        this.smsActionRef.current.openSmsAction(visitId)
        document.getElementById('smsAction').style = 'display:block'   
     } 
     updateAfterSave(visitId, results)
     {

     }  
     processDate(date)
       {
         const now = new Date(date);
         const todayY = now.getFullYear();
         const todayM = checkZero(now.getMonth() + 1);
         const todayD = checkZero(now.getDate());
         var date1 = todayY + '-' + todayM + '-' + todayD;
         console.log(date1)
        return date1;
       }
    render() {
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        }
        let loaderDiv = ''
        if(this.state.loader)
           {
            loaderDiv = (  <div id="loader" className="loader-overlay"><div align="center" className="loader"></div></div>)
           }
        return (
            <div className="d-flex" id="wrapper">
                <Sidebar />
                <div id="page-content-wrapper">
                    <Top />

                    <div className="container-fluid">
                       {divMsg}
                     
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card border-primary">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col"><div className="input-group"><span className="input-group-text">Firstname </span> <input type="text" className="form-control" onChange={(e) => this.setState({ firstname: e.target.value })} /></div></div>
                                            <div className="col"><div className="input-group"><span className="input-group-text">Surname</span><input type="text" className="form-control" onChange={(e) => this.setState({ surname: e.target.value })} /></div></div>
                                            <div className="col"><div className="input-group"><span className="input-group-text">Visit Status</span><select className="form-control" value={this.state.visitStatus} onChange={(e) => this.setState({ visitStatus: e.target.value })}  >
                                                    <option  value="PENDING" >PENDING</option>
                                                    <option  value="COMPLETED" >COMPLETED</option>
                                                    <option  value="DISCHARGE" >DISCHARGE</option>
                                            </select>  </div></div>
                                            <div className="col">    <button className="btn btn-success" onClick={() => this.getList()}  ><i class="bi bi-search"></i></button></div>
                                        </div></div></div>
                            </div>
                        </div>
                        <div>
                            <table className="table table-striped table-hover">
                                <tr>
                                    <th>Action</th>
                                    <th>Number</th>
                                    <th>Name</th>
                                    <th>Date of Birth</th>
                                    <th>Gender</th>
                                    <th>Date</th>
                                    <th>Bill Category</th>
                                    <th>Consultant</th>
                                    <th>Visit Type</th>
                                    <th>Status</th>
                                    <th>Nurse Name</th>
                                    <th>Bed</th> 
                                </tr>
                                <tbody>
                                {this.state.list.map((item, index) => (
                                    <tr key={index} style={{backgroundColor:item.color}}>
                                        <td >
                                            <span className="link" onClick={() => this.openNurseAction(item.id)} title="Edit"><i className="bi bi-pencil-square"></i></span>
                                            <span className="link" onClick={() => this.getPrintOut(item.id, 'HOSPITALNURSE', [], item.companyId)} title="Print" ><i className="bi bi-printer-fill"></i></span>
                                            <span className="link" onClick={() => this.openSmsAction(item.id)} title="Send Result Collect Sms" ><i className="bi bi-chat-left-text-fill"></i></span>
                                            <span className="link" onClick={() => this.getResultAction(item.id)} title="Upload Patient Results" ><i className="bi bi-cloud-upload-fill"></i></span>
                                        </td>
                                        <td>{item.number}</td>
                                        <td>{item.fullname}</td>
                                        <td>{item.dateOfBirth}</td>
                                        <td>{item.gender}</td>
                                        <td>{item.treatmentDateTime}</td>
                                        <td>{item.billCategory}</td>
                                        <td>{item.consultant}</td>
                                        <td>{item.visitType}</td>
                                        <td>{item.visitStatus}</td>                                        
                                        <td>{item.nurseName}</td>
                                        <td>{item.bed}</td>
                                       </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                        <HospitalNurseAction ref={this.nurseActionRef}  setLoader={this.setLoader} updateAfterSave={this.updateAfterSave} getList={this.getList} />
                        <PrintOut ref={this.printOutRef} setLoader={this.setLoader} />
                        <ResultAction ref={this.resultActionRef} setLoader={this.setLoader} updateAfterSave={this.updateAfterSave} />
                        <SmsAction ref={this.smsActionRef} setLoader={this.setLoader} />
                       {loaderDiv}
                    </div>
                </div ></div >
        )

    }
}    