import React, { Component } from "react";
import axios from "axios";
import '../../App.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const now = new Date();
const todayY = now.getFullYear();
const todayM = checkZero(now.getMonth() + 1);
const todayD = checkZero(now.getDate());
function checkZero(num) {
    return (num + "").length === 1 ? "0" + num : num;
}
export default class InstitutionInvoiceAction extends Component {
    state = { list: [], firstname: '', surname: '', startDate: todayY + '-' + todayM + '-' + todayD, endDate: todayY + '-' + todayM + '-' + todayD, companyId: 0, institutionId: '', institutions: [], msg: '', error: '', selectedList: 0, number: '', paymentType: '', remarks: '' }

    openInstitutionInvoiceAction(institutions) {
        this.props.setLoader(false)
        this.setState({ institutions: institutions, remarks: '' })
    }
    save() {
        var error = "";
        if (error === "") {
            this.props.setLoader(true)
            var list = [];  
            for(var index in this.state.list)   
                   {
                    if(this.state.list[index].selected === true || this.state.list[index].selected === "true")
                         {
                         list.push(this.state.list[index]);     
                         }
                   }
            axios.post(localStorage.getItem('host') + "/bill/saveinstitutioninvoice",{institutionInvoiceItems: list, companyId: sessionStorage.getItem('companyId'), institutionId: this.state.institutionId, remarks: this.state.remarks}, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            })
                .then(res => {
                    this.props.setMsg(res.data);
                    this.closePopup();
                    this.props.getList();
                    this.props.setLoader(false)
                })
        } else {
            this.setState({ error: error })
        }
    }
    calcSelected(index, checked)
     {
     var list = this.state.list;
     list[index].selected = checked;
     var selectedList = 0;  
                             for(var i in list)   
                                    {
                                     if(list[i].selected === true || list[i].selected === "true")
                                          {
                                          selectedList++;     
                                          }
                                    }
      this.setState({selectedList: selectedList, list: list})                              
     }
    currencyFormat(amount) {
        let val = (amount / 1).toFixed(2)
        return val.toString().split(/(?=(?:\d{3})+(?:\.|$))/g).join(" ").replace("-,", "-");
    }
    closePopup() {
        document.getElementById('institutioninvoiceAction').style = 'display:none'
    }
    getList()
    {
        this.props.setLoader(true)
        var data = { firstname: this.state.firstname, surname: this.state.surname, startDate: this.state.startDate, endDate:this.state.endDate, institutionId: this.state.institutionId, companyId: sessionStorage.getItem('companyId') }
        axios.post(localStorage.getItem('host') + '/bill/institutioninvoicedtolist', data, {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ list: res.data });
                this.props.setLoader(false)
            }, err => {
                console.log(".....")
                this.setState({ error: err.Error })
                this.props.setLoader(false)
            }
        )
    }
    processDate(date)
      {
        const now = new Date(date);
        const todayY = now.getFullYear();
        const todayM = checkZero(now.getMonth() + 1);
        const todayD = checkZero(now.getDate());
        var date1 = todayY + '-' + todayM + '-' + todayD;
        console.log(date1)
       return date1;
      }
    render() {
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        }
        if (this.state.error) {
            divMsg = (<div className='alert alert-danger'>{this.state.error}</div>)
        }
        return (<div id="institutioninvoiceAction" class="page-popup page-popup-overlay"   ><div class="card" >
            <div class="card-header bg-info">Institution Invoice Action</div>
            <div class="card-body" >
                <div class="card border-success">
                    <div class="card-body">
                        {divMsg}
                        <div class="row">
                        <div className="col"><div className="input-group"><span className="input-group-text">Firstname </span> <input type="text" className="form-control" onChange={(e) => this.setState({ firstname: e.target.value })} /></div></div>
                                            <div className="col"><div className="input-group"><span className="input-group-text">Surname</span><input type="text" className="form-control" onChange={(e) => this.setState({ surname: e.target.value })} /></div></div>
                                            <div className="col"><div className="input-group"><span className="input-group-text">Start Date</span> <DatePicker className="form-control" selected={new Date(this.state.startDate)} maxDate={new Date(this.state.endDate)} onChange={d => { this.setState({ startDate: this.processDate(d) });} } dateFormat="yyyy-MM-dd" /></div></div>
                                            <div className="col"><div className="input-group"><span className="input-group-text">End Date</span> <DatePicker className="form-control" selected={new Date(this.state.endDate)}  minDate={new Date(this.state.startDate)} maxDate={new Date()}  onChange={d => { this.setState({ endDate: this.processDate(d) }); } } dateFormat="yyyy-MM-dd" /></div></div>
                            <div className="col"><div className="input-group"><span className="input-group-text">Institution</span><select className="form-control" onChange={(e) => this.setState({ institutionId: e.target.value })}  >
                                <option value="">Choose Institution</option>
                                {this.state.institutions.map((item, index) => (
                                    <option key={index} value={item.id} >{item.name}</option>
                                ))}
                            </select>  </div></div>
                            <div className="col">    <input type="button" className="btn btn-success" onClick={() => this.getList()} value="Search" /></div>
                        </div>
                    </div>
                </div>
                <table class="table table-striped table-hover">
                <tr>
                    <th>Select [{this.state.selectedList}]</th>
                    <th>Number</th>
                    <th>Name</th>
                    <th>Gender</th>
                    <th>Date Of Birth</th>
                    <th>Date</th>
                    <th>Total Amount</th>
                   </tr> 
                                <tbody>
                                    {this.state.list.map((item, index) => (
                                        <tr key={index} >
                    <td><input type="checkbox" value={item.selected} onChange={e => this.calcSelected(index, e.target.checked)}  /></td>
                    <td>{item.billNumber}</td>
                    <td>{item.patientName}</td>
                    <td>{item.gender}</td>
                    <td>{item.dateOfBirth}</td>
                    <td>{item.billDateTime}</td>  
                    <td align="right" >{ this.currencyFormat(item.totalAmount)}</td>
                      </tr>
                ))}
            </tbody>
            </table>
            {this.state.selectedList > 0 &&<div className="row">
                <div className="col"><div className="input-group"><span className="input-group-text">Remarks </span> <textarea value={this.state.remarks} onChange={e => this.setState({ remarks: e.target.value })} className="form-control"   ></textarea></div></div>
              </div>}
                <div class="row">
                    <div class="col" align="center">{this.state.selectedList > 0 ? (<button onClick={() => this.save()} id="submitButton2" class="btn btn-primary" >Submit</button>) : (<span></span>)}</div>
                    <div class="col" align="center"><button onClick={() => this.closePopup()} class="btn btn-primary">Close</button></div>
                </div>
            </div>
        </div>
        </div>)

    }
}    