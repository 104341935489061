import React, { Component } from "react";
import axios from "axios";
import '../../App.css';
import Top from '../../includes/top'
import Sidebar from '../../includes/sidebar'


export default class ImportService extends Component {



state = { id: 0, file: '', fileName: '', dateOfBirth: '', list: [], companyId: sessionStorage.getItem('companyId'), reloadPatientList: false, duplicateCount: 0, visitId: 0, visit: '', loader:false , companys: []}    
componentDidMount() {
    this.getCompanies();
    this.getList()
    setInterval(() => {
        this.getListSilent();
    }, 1000 * 20)
   }
getCompanies()
{
    this.setLoader(true)
    axios.get(localStorage.getItem('host') + '/auth/getcompanyactivelist', {
        headers: {
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        }
    }).then(
        res => {
            this.setState({companys: res.data});
        }, err => {
            console.log(".....")
             this.setState({error: err+""})
            this.setLoader(false)
        }
    )
}
uploadFile()
      {
    
        this.setLoader(true)
        this.setState({ msg: '', error: '' });
        var speciality = "";
        for(var i in this.state.companys)
            {
            if(Number(this.state.companyId) === Number(this.state.companys[i].id))
               {
                speciality = this.state.companys[i].speciality
               }
            }
        var data = new FormData();
        data.append("file", this.state.file)
        axios.post(localStorage.getItem('host') + '/interchange/importservices/'+speciality+'/'+sessionStorage.getItem('userId')+'/'+this.state.companyId , data, {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
                'content-type': 'multipart/form-data'
            }
        }).then(
            res => {
                this.setLoader(false)
                console.log(res.data)
                if(res.data.error)
                   {
                    this.setState({ error: res.data.error });
                   }
                else
                  {
                    this.setState({ msg: res.data.msg });
                    this.getList()
                    setTimeout(() => {
                        this.setState({ msg: '' });
                    }, 3000);
                  }   
               
            }, err => {
                console.log(".....")
                this.setLoader(false)
                this.setState({ error: err+"" });
            }
        )
      }
      getList() {

        this.setLoader(true)
        axios.get(localStorage.getItem('host') + '/interchange/importservices/'+sessionStorage.getItem('userId'),  {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(
            res => {
    
                this.setLoader(false)
                this.setState({ list: res.data });
            }, err => {
                console.log(".....")
                 this.setState({error: err+""})
                this.setLoader(false)
            }
        )
    }     

    getListSilent() {

        axios.get(localStorage.getItem('host') + '/interchange/importservices/'+sessionStorage.getItem('userId'),  {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(
            res => {
    
                this.setState({ list: res.data });
            }, err => {
                console.log(".....")
                 this.setState({error: err+""})
            }
        )
    }     
setLoader(loader)
{
 this.setState({loader: loader})
}
render() {
    let divMsg = ''
    if (this.state.msg) {
        divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
    }
    if (this.state.error) {
        divMsg = (<div className='alert alert-danger'>{this.state.error}</div>)
    }
    let loaderDiv = ''
    if(this.state.loader)
       {
        loaderDiv = (  <div id="loader" className="loader-overlay"><div align="center" className="loader"></div></div>)
       }
    return (
        <div className="d-flex" id="wrapper">
            <Sidebar />
            <div id="page-content-wrapper">
                <Top />
                <div className="container-fluid">
                    {divMsg}
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col"><div className="input-group"><span className="input-group-text">Upload File</span> <input type="file" className="form-control"  onChange={e => { this.setState({ file: e.target.files[0] }) }} /></div></div>
                                <div className="col"><div className="input-group"><span className="input-group-text">Company</span><select className="form-control" value={this.state.companyId} onChange={(e) => this.setState({ companyId: e.target.value })}  >
                                                {this.state.companys.map((item, index) => (
                                                    <option key={index} value={item.id} >{item.name}</option>
                                                ))}
                                            </select>  </div></div>
                                <div className="col"><button className="btn btn-success" onClick={() => this.uploadFile()} >Upload</button></div>
                            </div>

                        </div></div>
                    <table className="table table-striped">
                        <tr>
                            <th>File Name</th>
                            <th>Status</th>
                            <th>Line Items</th>
                            <th>Processed Line Items</th>
                            <th>Date</th>
                        </tr>
                        <tbody>
                            {this.state.list.map((item, index) => (
                                <tr key={index}>
                                     <td>{item.fileName}</td>
                                    <td>{item.status}</td>
                                    <td>{item.lineCount}</td>
                                    <td>{item.processedCount}</td>
                                    <td>{item.creationDateTime}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                   {loaderDiv}
                </div>
            </div ></div >
    )

}
}    