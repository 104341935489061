import React, { Component } from "react";
import axios from "axios";
import '../../App.css';
import Top from '../../includes/top'
import Sidebar from '../../includes/sidebar'
import PatientAction from './patientaction';
import PatientQuickAction from "./patientquickaction";
import DuplicateRecords from './duplicaterecords';
import VisitList from './visitlist';
import MemberLookup from './memberlookup';
import MemberLookupQuick from './memberlookupquick';
import AdvancedReceipt from './advancedreceipt';
import VisitAction from './visitaction'
import AppointmentAction from './appointmentaction'
import PrintOut from './printout'
import Presignbio from './presignbio'
import Enrol from './enrol'
import ClaimResponse from './claimresponse'
import Receipt from './receipt'
import ResultAction from '../nurse/resultaction'
import JobOrderCreate from "../is/jobordercreate";
import CalendarView from "./calendar";
import QuotationAction from "./quotation";
import QuotationList from "./quotationlist";
import MyDatePicker from "../mydatepicker";
import RemainderView from "./remainder";
import CalendarRenalView from "./calendarrenal"
import InvestRequestsList from "./investrequests"

export default class Patient extends Component {
    constructor(props) {
        super(props);
        this.actionSavePatient = this.actionSavePatient.bind(this);
        this.setMessage = this.setMessage.bind(this);
        this.openAppointmentAction = this.openAppointmentAction.bind(this);
        this.getPatientRecordsCount = this.getPatientRecordsCount.bind(this);
        this.openVisitAction = this.openVisitAction.bind(this);
        this.getPrintOut = this.getPrintOut.bind(this)
        this.getMemberLookuPi = this.getMemberLookuPi.bind(this)
        this.getPresignPi = this.getPresignPi.bind(this)
        this.setLoader = this.setLoader.bind(this)
        this.updateVisitAction = this.updateVisitAction.bind(this)
        this.getClaimDetails = this.getClaimDetails.bind(this)
        this.getEnrol = this.getEnrol.bind(this)
        this.openReceiptAction = this.openReceiptAction.bind(this)
        this.getList = this.getList.bind(this)
        this.getResultAction = this.getResultAction.bind(this)
        this.getQuotationList = this.getQuotationList.bind(this)
        this.openAppointmentByEntry = this.openAppointmentByEntry.bind(this)
        this.openPatient = this.openPatient.bind(this)
        this.openPatientApp = this.openPatientApp.bind(this)
        this.patientRef = React.createRef()
        this.advancedReceiptRef = React.createRef()
        this.visitActionRef = React.createRef()
        this.appointmentActionRef = React.createRef()
        this.duplicateRecordsRef = React.createRef()
        this.memberLookupQuickRef = React.createRef()
        this.visitListRef = React.createRef()
        this.appointmentListRef = React.createRef()
        this.printOutRef = React.createRef()
        this.memberLookupRef = React.createRef()
        this.presignbioRef = React.createRef()
        this.enrolRef = React.createRef()
        this.claimResponseRef = React.createRef()
        this.receiptRef = React.createRef()
        this.resultActionRef = React.createRef()
        this.jobOrderCretaeActionRef = React.createRef()
        this.calendarViewRef = React.createRef()
        this.calendarRenalViewRef = React.createRef()
        this.quotationListRef = React.createRef()
        this.quotationActionRef = React.createRef()
        this.patientQuickRef = React.createRef()
        this.remainderViewRef = React.createRef()
        this.investRequestsListRef = React.createRef()
    }
    state = { id: 0, firstname: '', surname: '', dateOfBirth: '', idNumber: '', list: [], patientId: 0, reloadPatientList: false, duplicateCount: 0, visitId: 0, visit: '', loader:false, fullname: '', h263companys: false, remainderCount: 0, remainders: [], canEditInsurance: false }
    componentDidMount() {
     this.getPatientRecordsCount();
   //  this.getRemainderDetails()
     this.getEditInsuranceDetails()
    }
    getPatientRecordsCount()
     {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let id = Number(params.get('id'));
        if(id === 1)
           {
            this.openVisitList()
           }
         else if(id === 2)
          {
           sessionStorage.getItem('speciality') === 'RENAL'? this.calendarRenalViewRef.current.calendar():this.calendarViewRef.current.calendar()
          }  
        axios.get(localStorage.getItem('host') + '/patient/getpatientmenu/'+sessionStorage.getItem('companyId'), {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ duplicateCount: res.data.patientDuplicateCount, h263companys: res.data.h263companys});
            }, err => {
                console.log(".....")
                 this.setState({error: err+""})
                this.props.setLoader(false)
            }
        )      
     }
     getRemainderDetails()
     {
        axios.get(localStorage.getItem('host') + '/patientmanagement/getremainderview/'+sessionStorage.getItem('companyId'), {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ remainders: res.data.remainders, remainderCount: res.data.remainderCount});
            }, err => {
                console.log(".....")
                 this.setState({error: err+""})
                this.props.setLoader(false)
            }
        )      
     }
     getEditInsuranceDetails()
     {
        axios.get(localStorage.getItem('host') + '/patient/getcaneditinsurancedetails/'+sessionStorage.getItem('userId'), {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ canEditInsurance: res.data === 1?true:false});
              //  console.log("..... Can Edit ["+res.data+"]  "+(res.data === 1?true:false))
            }, err => {
                console.log(".....")
                 this.setState({error: err+""})
                this.props.setLoader(false)
            }
        )       
     }
    openPatient(id) {
        this.setLoader(true)
        this.setState({ patientId: id , loader: true})
        console.log(".... Patient Id " + id)
        this.patientRef.current.getPatient(id, this.state.canEditInsurance)
        document.getElementById('action').style = 'display:block'
    }
    openPatientApp(id)
     {
        this.setLoader(true)
        this.setState({ patientId: 0 , loader: true})
        console.log(".... Patient Id " + id)
        this.patientRef.current.getPatientApp(id, this.state.canEditInsurance)
        document.getElementById('action').style = 'display:block' 
     }
    openAppointmentAction(appointmentId, patientId) {
        this.setState({loader: true})
        this.appointmentActionRef.current.openAppointmentAction(appointmentId, patientId, sessionStorage.getItem('companyId'), 0, 0, '')
        document.getElementById('appointmentAction').style = 'display:block'
    }
    openVisitList() {
        this.setState({loader: true})
        this.visitListRef.current.getListLoad()
        document.getElementById('visitListAction').style = 'display:block'
    }
    setMessage(msg) {
        this.setState({ msg: msg })
    }
    openMergeRecords() {
        this.setState({loader: true})
        this.duplicateRecordsRef.current.getDuplicateRecords();
        document.getElementById('duplicateRecordAction').style = 'display:block'
    }
    openMemberLookupQuickAction()
    {
        this.setState({loader: true})
        this.memberLookupQuickRef.current.getMemberLookup()
        document.getElementById('memberLookupQuickAction').style = 'display:block' 
    }
    getMemberLookuPi(patientId, visitId)
     { 
        this.setState({loader: true})
        this.memberLookupRef.current.getMemberLookuPi(patientId, visitId)
        document.getElementById('memberLookupAction').style = 'display:block'    
     }
    openVisitAction(visitId, patientId, msg, oldVisitId)
    {
        this.setState({loader: true})
        this.visitActionRef.current.getVisit(visitId, patientId, msg, oldVisitId)
    }
    updateVisitAction(billId)
      {
        this.setState({loader: true})
        this.visitActionRef.current.updateVisit(billId)
      }
    advancedReceiptAction(id)
    {
        this.setState({loader: true})  
        document.getElementById('advancedReceiptAction').style = 'display:block'
        this.advancedReceiptRef.current.getAdvancedReceiptRef(id)
    }
    getPrintOut(id, type, printouts, companyId)
      {
        this.setState({loader: true})  
        this.printOutRef.current.getPrintOut(id, type, printouts, companyId)
        document.getElementById('printOutAction').style = 'display:block'
      }
    getPresignPi(patientId, visitId)
      {
        this.setState({loader: true})
        this.presignbioRef.current.getPresignPi(patientId, visitId)
        document.getElementById('presignBioAction').style = 'display:block'    
      }  
    getEnrol(patientId, visitId)
      {
        this.setState({loader: true})
        this.enrolRef.current.getEnrol(patientId, visitId)
        document.getElementById('enrolAction').style = 'display:block'    
      }  
    getClaimDetails(billId)
      {
        this.setState({loader: true})
        this.claimResponseRef.current.getClaimDetails(billId)
        document.getElementById('h263ResponseMsg').style = 'display:block'       
      }  
    setLoader(loader)
    {
     this.setState({loader: loader})
    }
    openReceiptAction(visitId, visitType, page)
      {
        this.setState({loader: true})
        this.receiptRef.current.openReceiptAction(visitId, visitType, page)
        document.getElementById('receiptAction').style = 'display:block'       
      }
      getResultAction(visitId)
      {
        this.setState({loader: true})
        this.resultActionRef.current.getResultAction(visitId)
        document.getElementById('resultAction').style = 'display:block'   
      }  
      getList()
        {
         this.visitListRef.current.getVisitListSilent()    
        }   
    createJobOrder(patient)
        {
            this.setState({loader: true})
            this.jobOrderCretaeActionRef.current.createJobOrder(patient)
        document.getElementById('jobOrderCreateAction').style = 'display:block'  
        }    
    savePatient() {
        var data = { id: this.state.id, activeStatus: this.state.activeStatus, name: this.state.name }
        axios.post(localStorage.getItem('host') + '/master/suffixform', data, {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(
            res => {
                console.log(res.data)
                this.setState({ msg: res.data });
                this.getList();
                this.closePopup();
                setTimeout(() => {
                    this.setState({ msg: '' });
                }, 3000);
            }, err => {
                console.log(".....")
                 this.setState({error: err+""})
                this.props.setLoader(false)
            }
        )
    }
    actionSavePatient(id, msg, action) {
        this.setState({ patientId: id, msg: msg })
        switch (action) {
            case "VISIT":
                this.openVisitAction(0, id, '',0)
                break;
            case "APPOINTMENT":
                this.openAppointmentAction(0, id)
                break;
            case "ADVANCEDRECEIPT":
                this.advancedReceiptAction(id)
                break;
            default:
                   console.log("default") 
                   break;   
        }
    }
    getPatientList() {

        this.setLoader(true)
        var data = { firstname: this.state.firstname, surname: this.state.surname, dateOfBirth: this.state.dateOfBirth, idNumber: this.state.idNumber }
        axios.post(localStorage.getItem('host') + '/patient/patientlist/'+sessionStorage.getItem("companyId")+'/'+sessionStorage.getItem("speciality"), data, {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(
            res => {

                this.setLoader(false)
                for (var index in res.data) {
                    res.data[index].action = <button className="btn btn-our" style={{ backgroundColor: '#36BEEE', color: '#FFF' }} id={res.data[index].id} onClick={e => this.action(e)}>Edit</button>
                }
                this.setState({ list: res.data });
            }, err => {
                console.log(".....")
                 this.setState({error: err+""})
                 window.scrollTo(0, 0)
                this.props.setLoader(false)
                setTimeout(() => {
                    this.setState({error: ""})
                }, 5000);
            }
        )
    }
    getQuotationList(msg)
     {
        this.quotationListRef.current.getQuotationListLoad(msg)  
     }
     openAppointmentByEntry(id, companyId, consultantId, appointmentTimeId, appointmentDate)
        {
            this.appointmentActionRef.current.openAppointmentAction(id, 0, companyId, consultantId, appointmentTimeId, appointmentDate)
            document.getElementById('calendarViewAction').style = 'display:none'
            document.getElementById('calendarViewRenalAction').style = 'display:none'
            document.getElementById('appointmentAction').style = 'display:block'
        }
    closePopupPatientCancel()
        {
            document.getElementById('patientCancelAction').style = 'display:none'   
        } 
        showRemainders()
        {
            document.getElementById('remainder').style = 'display:block' 
        }     
        remainderPopup()
        {
            document.getElementById('remainder').style = 'display:none' 
        }            
    deactivatePatient(fullname, id)
          {
            this.setState({fullname: fullname, id: id})
            document.getElementById('patientCancelAction').style = 'display:block'    
          }  
    sendCancelPatient(id)
       {
        axios.get(localStorage.getItem('host') + '/patient/deactivate/'+id, {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ msg: res.data});
                this.closePopupPatientCancel()
                this.getPatientList()
            }, err => {
                console.log(".....")
                 this.setState({error: err+""})
                this.props.setLoader(false)
            }
        )    
       }    
       patientQuickAction()
       {
        this.setLoader(true)
        this.setState({ loader: true})
        this.patientQuickRef.current.getPatient(0)
        document.getElementById('patientquickaction').style = 'display:block'
       }  
       getInvestigationList()
       {
        this.investRequestsListRef.current.getInvestigationList()
       }  
    render() {
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        }
        if (this.state.error) {
            divMsg = (<div className='alert alert-danger'>{this.state.error}</div>)
        }
        let loaderDiv = ''
        if(this.state.loader)
           {
            loaderDiv = (  <div id="loader" className="loader-overlay"><div align="center" className="loader"></div></div>)
           }
        return (
            <div className="d-flex" id="wrapper">
                <Sidebar />
                <div id="page-content-wrapper">
                    <Top />

                    <div className="container-fluid">
                        <div className="row">
                            <div className="col"><button className="btn btn-success" onClick={() => this.openPatient(0)} >Create New Patient</button></div>
                            <div className="col">{ (sessionStorage.getItem('speciality') !== 'CASUALTY' && sessionStorage.getItem('speciality') !== 'HOSPITAL' && sessionStorage.getItem('speciality') !== 'RENAL') && (<button onClick={() => this.calendarViewRef.current.calendar()} className="btn btn-success">Calendar</button>)}{ (sessionStorage.getItem('speciality') !== 'CASUALTY' && sessionStorage.getItem('speciality') !== 'HOSPITAL' && sessionStorage.getItem('speciality') === 'RENAL') && (<button onClick={() => this.calendarRenalViewRef.current.calendar()} className="btn btn-success">Calendar</button>)}</div>
                            <div className="col"><button onClick={() => this.openVisitList()} className="btn btn-success">Visit List</button></div>
                            <div className="col"><button onClick={() => this.openMergeRecords()} className="btn btn-success">Duplicate Records ({this.state.duplicateCount})</button></div>
                           </div>
                        <div className="row">  
                            <div className="col">{ (sessionStorage.getItem('speciality') === 'CASUALTY' || sessionStorage.getItem('speciality') === 'ANAESTHETIST' || sessionStorage.getItem('speciality') === 'HOSPITAL') && (<button onClick={() => this.patientQuickAction()} className="btn btn-success">Quick Patient Create</button>)}
                            { (sessionStorage.getItem('speciality') === 'DENTAL1' ) && <button onClick={() => this.remainderViewRef.current.remainder()} className="btn btn-success">Remainders</button>} { (sessionStorage.getItem('speciality') === 'RADIOLOGY' || sessionStorage.getItem('speciality') === 'LABORATORY') && <button onClick={() => this.getInvestigationList()} className="btn btn-success">Investigation List</button>}
                            </div>
                           <div className="col"><button onClick={() => this.quotationActionRef.current.openQuotationAction()} className="btn btn-success">Create Quotation</button></div>
                            <div className="col"><button onClick={() => this.quotationListRef.current.getQuotationListLoad('')} className="btn btn-success">Quotation List</button></div>
                            <div className="col">{ this.state.h263companys === true && (<button onClick={() => this.openMemberLookupQuickAction()} className="btn btn-success">Member Lookup</button>)}</div> 
                            </div>
                          { this.state.h263companys === true && (
                          <div className="row">
                        <div className="alert alert-info">If you search for a medical aid patient and you cannot find them, you can do member lookup to get the patient record created from H263 and enter the remaining fields like next of kin and relationship to member</div>
                    </div>)}
                    <div className="row">
                        <div className="alert alert-warning">For patients that would have passed on, please search for the patient below and click the last red icon to cancel. This will stop the system from sending out Whatsapp or SMS messages </div>
                    </div>
                        {divMsg}
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col"><div className="input-group"><span className="input-group-text">Firstname</span> <input type="text" className="form-control" value={this.state.firstname} onChange={e => { this.setState({ firstname: e.target.value }) }} /></div></div>
                                    <div className="col"><div className="input-group"><span className="input-group-text">Surname</span> <input type="text" className="form-control" value={this.state.surname} onChange={e => { this.setState({ surname: e.target.value }) }} /></div></div>
                                    <div className="col"><div className="input-group"><span className="input-group-text">Date Of Birth</span> <input type="text" className="form-control" value={this.state.dateOfBirth} onChange={e => { this.setState({ dateOfBirth: e.target.value }) }} /> <MyDatePicker onChange={(d)=> {
                                                        this.setState({dateOfBirth: d})
                                                    }} /></div></div>
                                   <div className="col"><div className="input-group"><span className="input-group-text">Id Number</span> <input type="text" className="form-control" value={this.state.idNumber} onChange={e => { this.setState({ idNumber: e.target.value }) }} /></div></div>
                                   <div className="col"><button className="btn btn-success" onClick={() => this.getPatientList()} ><i class="bi bi-search"></i></button></div>
                                </div>

                            </div></div>
                            <div className="table-responsive-lg"> 
                        <table className="table table-striped">
                            <tr>
                                <th>Action</th>
                                <th>Fullname</th>
                                <th>Number</th>
                                <th>Gender</th>
                                <th>Date Of Birth</th>
                                <th>Id Number</th>
                                <th>File Numbers</th>
                            </tr>
                            <tbody>
                                {this.state.list.map((item, index) => (
                                    <tr key={index}>
                                      <td><span className="link" title="Edit" id={item.id} onClick={e => this.openPatient(e.currentTarget.id)}><i className="bi bi-pencil-square"></i></span>
                                        <span className="link" onClick={() => this.getPrintOut(item.id, 'PATIENT_INFORMATION', [], 0)} title="Print" ><i className="bi bi-printer-fill"></i></span>
                                               <span className="link" title="Create Appointment" id={item.id} onClick={e => this.openAppointmentAction(0, e.currentTarget.id)}><i className="bi bi-alarm-fill"></i></span>
                                            <span className="link" title="New Visit" id={item.id} onClick={e => this.openVisitAction(0, e.currentTarget.id, '', 0)}><i className="bi bi-file-earmark-fill"></i></span>
                                            <span className="link" title="Advanced Receipt" id={item.id} onClick={e => this.advancedReceiptAction(e.currentTarget.id)}><i className="bi bi-cash-coin"></i></span>
                                            { sessionStorage.getItem('speciality') === 'OPTOMETRY' &&(<span className="link" title="Create Job Order"  onClick={e => this.createJobOrder(item)}><i class="bi bi-wrench-adjustable-circle"></i></span>)}
                                            <span className="link-red" title="Cancel" id={item.id} onClick={e => this.deactivatePatient(item.fullname, e.currentTarget.id)}><i className="bi bi-x-square-fill"></i></span>
                                        </td>
                                        <td>{item.fullname}</td>
                                        <td>{item.number}</td>
                                        <td>{item.gender}</td>
                                        <td>{item.dateOfBirth}</td>
                                        <td>{item.idNumber}</td>
                                        <td>{item.fileNumber}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        </div>
                        <div id="patientCancelAction" className="page-popup page-popup-overlay" >
                            <div className="card" >
                                <div className="card-header bg-info">Cancel Action</div>
                                <div className="card-body" >
                                <p>Are you sure you want to cancel  {this.state.fullname}?</p>
                                    <div className="row">
                                    <div className="col" align="center" ><button onClick={() => this.sendCancelPatient(this.state.id)} className="btn btn-primary">Yes</button></div> 
                                    <div className="col" align="center" ><button onClick={() => this.closePopupPatientCancel()} className="btn btn-primary">No</button></div> 
                                    </div>
                                </div>
                            </div>
                        </div>
                        <MemberLookupQuick ref={this.memberLookupQuickRef} setLoader={this.setLoader} openVisitAction={this.openVisitAction} getEnrol={this.getEnrol} openPatient={this.openPatient} getPresignPi={this.getPresignPi} />
                        <VisitList ref={this.visitListRef} setLoader={this.setLoader} getPrintOut={this.getPrintOut} openVisitAction={this.openVisitAction} openReceiptAction={this.openReceiptAction} getResultAction={this.getResultAction} getClaimDetails={this.getClaimDetails} openPatient={this.openPatient} />
                        <PatientQuickAction patientId={this.state.patientId} actionSavePatient={this.actionSavePatient} ref={this.patientQuickRef} setLoader={this.setLoader}  getMemberLookuPi={this.getMemberLookuPi} getPresignPi={this.getPresignPi} getEnrol={this.getEnrol} />
                        <DuplicateRecords getPatientRecordsCount={this.getPatientRecordsCount} ref={this.duplicateRecordsRef} setLoader={this.setLoader} />
                         <AdvancedReceipt ref={this.advancedReceiptRef} setLoader={this.setLoader} getPrintOut={this.getPrintOut} />
                        <CalendarView ref={this.calendarViewRef} setLoader={this.setLoader} openAppointmentByEntry={this.openAppointmentByEntry} openVisitAction={this.openVisitAction} openPatientApp={this.openPatientApp} />
                        <CalendarRenalView ref={this.calendarRenalViewRef} setLoader={this.setLoader} openAppointmentByEntry={this.openAppointmentByEntry} openVisitAction={this.openVisitAction} openPatientApp={this.openPatientApp} />
                        <PatientAction patientId={this.state.patientId} actionSavePatient={this.actionSavePatient} ref={this.patientRef} setLoader={this.setLoader}  getMemberLookuPi={this.getMemberLookuPi} getPresignPi={this.getPresignPi} getEnrol={this.getEnrol} />
                        <VisitAction ref={this.visitActionRef} setLoader={this.setLoader} getMemberLookuPi={this.getMemberLookuPi} getPresignPi={this.getPresignPi} getEnrol={this.getEnrol} getClaimDetails={this.getClaimDetails} />
                        <MemberLookup ref={this.memberLookupRef} setLoader={this.setLoader} updateVisitAction={this.updateVisitAction} />
                        <Presignbio ref={this.presignbioRef} setLoader={this.setLoader} updateVisitAction={this.updateVisitAction} />
                        <Enrol ref={this.enrolRef} setLoader={this.setLoader} updateVisitAction={this.updateVisitAction} />
                        <ClaimResponse ref={this.claimResponseRef} setLoader={this.setLoader} />
                        <Receipt ref={this.receiptRef} setLoader={this.setLoader} getPrintOut={this.getPrintOut} getList={this.getList} />
                        <ResultAction ref={this.resultActionRef} setLoader={this.setLoader} />
                        <JobOrderCreate ref={this.jobOrderCretaeActionRef} setLoader={this.setLoader}  getList={this.getList} />
                        <AppointmentAction ref={this.appointmentActionRef} setMessage={this.setMessage} setLoader={this.setLoader} />
                         <QuotationList ref={this.quotationListRef} setLoader={this.setLoader} getPrintOut={this.getPrintOut} />
                        <QuotationAction ref={this.quotationActionRef} setLoader={this.setLoader} getQuotationList={this.getQuotationList} />
                        <PrintOut ref={this.printOutRef} setLoader={this.setLoader} />
                        <RemainderView ref={this.remainderViewRef} setLoader={this.setLoader} />
                        <InvestRequestsList ref={this.investRequestsListRef} setLoader={this.setLoader} openVisitAction={this.openVisitAction} />
                       {loaderDiv}
                    </div>
                </div ></div >
        )

    }
}    