import React, { Component } from "react";
import axios from "axios";
import '../../App.css';
import Top from '../../includes/top'
import Sidebar from '../../includes/sidebar'
import PrintOut from '../patient/printout'
import AllocateAdvancedReceipt from './allocationadvancedreceipt'
import CancelAdvancedRceipt from './canceladvancedreceipt'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const now = new Date();
function checkZero(num) {
    return (num + "").length === 1 ? "0" + num : num;
}
var date1 = (now.getFullYear()) + '-' + checkZero(now.getMonth() + 1) + '-' + checkZero(now.getDate()) + ' ' + checkZero(now.getHours()) + ':' + checkZero(now.getMinutes()) + ':' + checkZero(now.getSeconds());
export default class AdvancedReceiptPending extends Component {
    constructor(props) {
        super(props);
    this.setLoader = this.setLoader.bind(this)
    this.setMsg = this.setMsg.bind(this)
    this.getList = this.getList.bind(this)
    this.printOutRef = React.createRef()
    this.allocateAdvancedRceiptRef = React.createRef()
    this.cancelAdvancedRceiptRef = React.createRef()
    }
    state = { id: 0, firstname: '', surname: '', companyId: sessionStorage.getItem('companyId'), list: [], clusterCompanys: [] , fullname: '', number: '', paymentType: '', paidAmount: 0, availableAmount: 0, usedAmount: 0,  refundAmountError: '', refundAmount: '', refundReason: '', refundReasonError: '', currencyId: '', refundDateTime: date1, refundDateTimeError: ''}
    componentDidMount() {
        axios.get(localStorage.getItem('host') + '/auth/getclustercompanys/'+sessionStorage.getItem('companyId'), {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({clusterCompanys: res.data});
                this.getList()
            }, err => {
                console.log(".....")
                 this.setState({ error: err.Error })
            }
        )
    }
    getAllocateAdvancedRceipt(advancedReceipt) {
        this.setState({loader: true})
        this.allocateAdvancedRceiptRef.current.getAllocateAdvancedRceipt(advancedReceipt)
        document.getElementById('allocationAdvancedReceiptAction').style = 'display:block' 
    }
    openCancelReceipt(advancedReceipt)
    {
        this.setState({loader: true})
        this.cancelAdvancedRceiptRef.current.getCancelAdvancedRceipt(advancedReceipt)
        document.getElementById('advancedReceiptCancelAction').style = 'display:block' 
    }
    getList() {
        this.setLoader(true)
        var data = { firstname: this.state.firstname, surname: this.state.surname, companyId: this.state.companyId }
        axios.post(localStorage.getItem('host') + '/bill/advancedreceiptlist/'+sessionStorage.getItem('roleId'), data, {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ list: res.data });
                this.setLoader(false)
            }, err => {
                console.log(".....")
                 this.setState({ error: err.Error })
                this.setLoader(false)
            }
        )
    } 
    getPrintOut(id, type, printouts, companyId)
      {
        this.setState({loader: true})  
        this.printOutRef.current.getPrintOut(id, type, printouts, companyId)
        document.getElementById('printOutAction').style = 'display:block'
      }
    setLoader(loader)
    {
     this.setState({loader: loader})
    }
    setMsg(msg)
    {
        this.setState({msg: msg})  
    }
    currencyFormat(amount) {
        let val = (amount / 1).toFixed(2)
        return val.toString().split(/(?=(?:\d{3})+(?:\.|$))/g).join(" ").replace("-,", "-");
    }

    refundReceiptAction(receipt)
    {
      this.setState({ id: receipt.id, fullname: receipt.fullname, number: receipt.number, paymentType: receipt.paymentType, paidAmount: receipt.paidAmount, availableAmount: receipt.availableAmount, usedAmount: receipt.usedAmount,  refundAmountError: '', refundAmount: '', refundReason: '', refundReasonError: '', currencyId: receipt.currencyId})
      document.getElementById('refundAction').style = 'display:block'
      this.doValidate()
    }
     
  closeRefundPopup() {
      document.getElementById('refundAction').style = 'display:none'
  }  
  doValidate()
  {
    setTimeout(() => {
        this.validate();
    }, 1000);   
  }
  processDateTime(date) {
    const now = new Date(date);
    const todayY = now.getFullYear();
    const todayM = checkZero(now.getMonth() + 1);
    const todayD = checkZero(now.getDate());
    const todayH = checkZero(now.getHours());
    const todayMM = checkZero(now.getMinutes());
    const todayS = checkZero(now.getSeconds());
    var date1 = todayY + '-' + todayM + '-' + todayD + ' ' + todayH + ':' + todayMM + ':' + todayS;
    console.log(date1)
    return date1;


}
  validate()
    {
      
      var error = false;
      if (this.state.refundDateTime === null) {
        this.setState({ refundDateTimeError: "Refund Date Time cannot be empty" });
        error = true
    }
    else if (this.state.refundDateTime === "") {
        this.setState({ refundDateTimeError: "Refund Date Time cannot be empty" });
        error = true
    }
    else if(!(/^(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})$/.test(this.state.refundDateTime)))
        {
        this.setState({ refundDateTimeError: "Refund Date Time wrong date format"})
        error = true
        }
    else {
        this.setState({ refundDateTimeError: "" })
    }


      if (this.state.refundAmount === null || this.state.refundAmount === "") {
          this.setState({ refundAmountError: "Paid Amount cannot be empty" })
          error = true
      }
      else if(!(/^[0-9]*$/.test(this.state.refundAmount)))
          {
          this.setState({ refundAmountError: "Paid Amount should be a number"})
          error = true
          }
      else if ((Number(this.state.availableAmount) - Number(this.state.refundAmount)) < 0) {
              this.setState({ refundAmountError: "Paid Amount cannot be more than available" })
              error = true
          }
      else if(this.state.refundAmount  < 0)
             {
              this.setState({ refundAmountError:"Paid Amount cannot be less than 0"})
              error = true
             }
      else {
          this.setState({ refundAmountError: "" })
      }
      if (this.state.refundReason === null || this.state.refundReason === "") {
          this.setState({ refundReasonError: "Refund Reason cannot be empty" })
          error = true
      }
      else if (this.state.refundReason.length <  4) {
              this.setState({ refundReasonError: "Refund Reason cannot be less than 3 characters" })
              error = true
          }
      else {
          this.setState({ refundReasonError: "" })
      }
      if(error === true)
         {
          this.setState({ error: "One or more of the mandatory field(s) is not filled in", showSubmit: false}) 
         }
      else
         {
          this.setState({error: "", showSubmit: true}) 
         }  
    } 
    save() {
        var error = "";
        if (error === "") {
            this.setLoader(true)
            var data = {advancedReceiptId: this.state.id, refundDateTime: this.state.refundDateTime, refundAmount: this.state.refundAmount, refundReason: this.state.refundReason, companyId: this.state.companyId, doneBy: sessionStorage.getItem('fullname'), currencyId: this.state.currencyId, availableAmount: this.state.availableAmount}
            axios.post(localStorage.getItem('host') + "/bill/saverefundadvancedreceipt", data, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            })
                .then(res => {
                    this.setState({msg: res.data, loader: false});
                    this.closeRefundPopup();
                    this.getList()
                })
        } else {
            this.setState({ error: error })
        }
    }
    render() {
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        }
        let loaderDiv = ''
        if(this.state.loader)
           {
            loaderDiv = (  <div id="loader" className="loader-overlay"><div align="center" className="loader"></div></div>)
           }
        return (
            <div className="d-flex" id="wrapper">
                <Sidebar />
                <div id="page-content-wrapper">
                    <Top />

                    <div className="container-fluid">
                    <div id="refundAction" className="page-popup page-popup-overlay"   ><div className="card" >
            <div className="card-header bg-info">Refund Action</div>
            <div className="card-body" >
                <div className="card border-success">
                    <div className="card-body">
                        {divMsg}
                        <div className="row">
                            <div className="col"><span className="fw-bold">Fullname:</span> {this.state.fullname}</div>
                            <div className="col"><span className="fw-bold">Number:</span> {this.state.number}</div>
                            <div className="col"><span className="fw-bold">Payment Type:</span> {this.state.paymentType}</div>
                        </div>
                        <div className="row">
                            <div className="col"><span className="fw-bold">Receipted Amount:</span> {this.currencyFormat(this.state.paidAmount)}</div>
                            <div className="col"><span className="fw-bold">Available Amount:</span> {this.currencyFormat(this.state.availableAmount)}</div>
                            <div className="col"><span className="fw-bold">Used Amount:</span> {this.currencyFormat(this.state.usedAmount)}</div>
                        </div>
                    </div>
                </div>
                <div className="row">
                <div className="col">
                            <div className="input-group"><span className="input-group-text">Refund Date</span>
                                <DatePicker selected={new Date(this.state.refundDateTime)} onChange={(date) => this.setState({ refundDateTime: this.processDateTime(date) })} timeInputLabel="Time:" showTimeInput dateFormat="yyyy-MM-dd HH:mm:ss" />
                            </div></div>
                            <div className="col"></div>
                </div>
                <div className="row">
                <div className="col">
                            <div className="input-group has-validation"><span className="input-group-text">Refund Amount</span>
                                <input type="text" className={this.state.refundAmountError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.refundAmount} onChange={e => {this.setState({ refundAmount: e.target.value }); this.doValidate()}} />
                                <div className="invalid-feedback">{this.state.refundAmountError}</div></div></div>
                                <div className="col"><div className="input-group has-validation"><span className="input-group-text">Reason:</span>
                                <textarea className={this.state.refundReasonError !== "" ? "form-control is-invalid" : "form-control"} cols="5" value={this.state.refundReason} onChange={e => {this.setState({ refundReason: e.target.value }); this.doValidate()}} ></textarea>
                                <div className="invalid-feedback">{this.state.refundReasonError}</div></div> </div>

                </div>
                <div className="row">
                    <div className="col" align="center">{this.state.showSubmit === true? (<button onClick={() => this.save()} id="submitButton2" className="btn btn-primary" >Submit</button>) : (<span></span>)}</div>
                    <div className="col" align="center"><button onClick={() => this.closeRefundPopup()} className="btn btn-primary">Close</button></div>
                </div>
            </div>
        </div>
        </div>
                       {divMsg}
                     
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card border-primary">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col"><div className="input-group"><span className="input-group-text">Firstname </span> <input type="text" className="form-control" onChange={(e) => this.setState({ firstname: e.target.value })} /></div></div>
                                            <div className="col"><div className="input-group"><span className="input-group-text">Surname</span><input type="text" className="form-control" onChange={(e) => this.setState({ surname: e.target.value })} /></div></div>
                                           <div className="col"><div className="input-group"><span className="input-group-text">Company</span><select className="form-control" onChange={(e) => this.setState({ companyId: e.target.value })}  >
                                                {this.state.clusterCompanys.map((item, index) => (
                                                    <option key={index} value={item.id} >{item.name}</option>
                                                ))}
                                            </select>  </div></div>
                                            <div className="col">    <input type="button" className="btn btn-success" onClick={() => this.getList()} value="Search" /></div>
                                        </div></div></div>
                            </div>
                        </div>
                        <div>
                            <table className="table table-striped table-hover">
                                <tr>
                                    <th>Action</th>
                                    <th>Number</th>
                                    <th>Name</th>
                                    <th>Done By</th>
                                    <th>Date</th>
                                    <th>Payment Type</th>
                                    <th>Paid Amount</th>
                                    <th>Available Amount</th>
                                    <th>Used Amount</th>
                                </tr>
                                <tbody>
                                {this.state.list.map((item, index) => (
                                    <tr key={index} >
                                        <td >
                                        { item.activeStatus === 1 ?(<span className="link" onClick={() => this.getPrintOut(item.id, 'ADVANCED_RECEIPT', [], item.companyId)} title="Print" ><i className="bi bi-printer-fill"></i></span>):(<span></span>)}
                                            { item.activeStatus === 1 && item.showCancel  === true?(<span className="link-red" onClick={() => this.openCancelReceipt(item)} title="Cancel Receipt" ><i class="bi bi-x-square-fill"></i></span>):(<span></span>)}
                                          { item.activeStatus === 1 && item.availableAmount > 0?(<span className="link" onClick={() => this.getAllocateAdvancedRceipt(item)} title="Allocate Receipt" ><i class="bi bi-front"></i></span>):(<span></span>)}
                                          { item.activeStatus === 1 && item.doRefund === true?(<span className="link" onClick={() => this.refundReceiptAction(item)} title="Refund" ><i class="bi bi-arrow-counterclockwise"></i></span>):(<span></span>)}
                                        </td>
                                        <td>{item.number}</td>
                                        <td>{item.fullname}</td>
                                        <td>{item.createdByUser}</td>
                                        <td>{item.creationDateTime}</td>
                                        <td>{item.paymentType}</td>
                                        <td align="right">{this.currencyFormat(item.paidAmount)}</td>
                                        <td align="right">{this.currencyFormat(item.availableAmount)}</td>
                                        <td align="right">{this.currencyFormat(item.usedAmount)}</td>
                                       </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                        <PrintOut ref={this.printOutRef} setLoader={this.setLoader} />
                        <AllocateAdvancedReceipt ref={this.allocateAdvancedRceiptRef} setLoader={this.setLoader} setMsg={this.setMsg} getList={this.getList} />
                        <CancelAdvancedRceipt ref={this.cancelAdvancedRceiptRef} setLoader={this.setLoader} setMsg={this.setMsg} getList={this.getList} />
                       {loaderDiv}
                    </div>
                </div ></div >
        )

    }
}    