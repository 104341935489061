import React, { Component } from "react";
import axios from "axios";
import '../../App.css';
import Top from '../../includes/top'
import Sidebar from '../../includes/sidebar'
import { MDBDataTable } from 'mdbreact';


export default class ColorRange extends Component {
    state = { id: 0, name: '',startRange: '', endRange: '', color: '', activeStatus: 1, list: [] }
    componentDidMount() {
        this.getList()
    }
    action(e) {
        var id = e.currentTarget.id;
        console.log("......." + id + " .... " + e.currentTarget.id)
        this.showForm(id);
    }
    showForm(id) {
        this.setState({ id: 0, name: '',startRange: '', endRange: '', color: '', activeStatus: 1 })
        if (id > 0) {
            axios.get(localStorage.getItem('host') + '/master/colorrangeaction/' + id, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            }).then(
                res => {
                    this.setState({ name: res.data.name, id: res.data.id,startRange: res.data.startRange, endRange: res.data.endRange, color: res.data.color, activeStatus: res.data.activeStatus });
                }, err => {
                    console.log(".....")
                }
            )
        }
        document.querySelector('#action').style = 'display:block'
    }
    closePopup() {
        document.querySelector('#action').style = 'display:none'
        console.log('......close');
    }
    save() {
        var data = { id: this.state.id, activeStatus: this.state.activeStatus,startRange: this.state.startRange, endRange: this.state.endRange, color: this.state.color, name: this.state.name, companyId: sessionStorage.getItem('companyId') }
        axios.post(localStorage.getItem('host') + '/master/colorrangeform', data, {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(
            res => {
                console.log(res.data)
                this.setState({ msg: res.data });
                this.getList();
                this.closePopup();
                setTimeout(() => {
                    this.setState({ msg: '' });
                }, 3000);
            }, err => {
                console.log(".....")
            }
        )
    }
    getList() {
        axios.get(localStorage.getItem('host') + '/master/colorrange/' + sessionStorage.getItem('companyId'), {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(
            res => {
                for (var index in res.data) {
                    res.data[index].action = <span className="link" title="Edit Color Range" id={res.data[index].id} onClick={e => this.action(e)}><i className="bi bi-pencil-square"></i></span>
                }
                this.setState({ list: res.data });
            }, err => {
                console.log(".....")
            }
        )
    }

    render() {
        const data = {
            columns: [
                
                {
                    label: 'Action',
                    field: 'action',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Name',
                    field: 'name',
                    sort: 'asc',
                    width: 150
                },{
                    label: 'Start',
                    field: 'startRange',
                    sort: 'asc',
                    width: 150
                },{
                    label: 'End',
                    field: 'endRange',
                    sort: 'asc',
                    width: 150
                },{
                    label: 'Color',
                    field: 'color',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Active Status',
                    field: 'stringStatus',
                    sort: 'asc',
                    width: 100
                }
            ],
            rows: this.state.list
        };
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        }
        return (
            <div className="d-flex" id="wrapper">
                <Sidebar />
                <div id="page-content-wrapper">
                    <Top />

                    <div className="container-fluid">
                    <button className="btn btn-primary" onClick={() => this.showForm(0)} ><i className="bi bi-plus-square"></i></button>
                        {divMsg}
                        <MDBDataTable
                            button="true"
                            striped
                            bordered
                            small
                            data={data}
                        />
                        <div className="page-popup page-popup-overlay" id="action">
                            <form  >
                                <div className="card">
                                    <div className="card-header bg-info">
                                        Action
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col">
                                            <div className="input-group"><span className="input-group-text">Name</span>
                                                <input type="text" className="form-control" value={this.state.name} onChange={e => { this.setState({ name: e.target.value }) }} /></div>
                                            </div>
                                            <div className="col">
                                            <div className="input-group"><span className="input-group-text">Active Status</span>
                                                <select className="form-control" value={this.state.activeStatus} onChange={e => this.setState({ activeStatus: e.target.value })}  >
                                                    <option value="0">No</option>
                                                    <option value="1">Yes</option>
                                                </select></div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                            <div className="input-group"><span className="input-group-text">Start Range</span>
                                                <input type="text" className="form-control" value={this.state.startRange} onChange={e => { this.setState({ startRange: e.target.value }) }} /></div>
                                            </div>
                                            <div className="col">
                                            <div className="input-group"><span className="input-group-text">End Range</span>
                                                <input type="text" className="form-control" value={this.state.endRange} onChange={e => { this.setState({ endRange: e.target.value }) }} /></div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                            <div className="input-group"><span className="input-group-text">Color</span>
                                                <select className="form-control" value={this.state.color} onChange={e => this.setState({ color: e.target.value })}  >
                                                    <option value="#ff0000">Red</option>
                                                    <option value="#478a36">Green</option>
                                                    <option value="#232675">Purple</option>
                                                    <option value="#d1bd21">Yellow</option>
                                                    <option value="#d14521">Orange</option>
                                                </select></div>
                                            </div>
                                            <div className="col">
                                                
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col" align="right"><input type="button" className="btn btn-primary" onClick={() => this.save()} value="Save" /></div>
                                            <div className="col"><input type="button" className="btn btn-primary" onClick={() => this.closePopup()} value="Close" /></div>
                                        </div>




                                    </div></div>
                            </form>
                        </div>
                    </div>
                </div ></div >
        )

    }
}    