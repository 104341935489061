import React, { Component } from "react";
import axios from "axios";
import '../../App.css';

export default class BookStock extends Component {
    state = { name: '', rate: 1, companyId: '', companys: [], companyIdError: '', locationIdError: '', locationId: 0, locations: [], productOptions: [], products: [], manufacturers: [], suppliers: [], bookStockItems: [], bookStockItemsError: '', totalAmount: 0.00 , productTypes: [ "ACCESSORIES", "ANAESTHETIC_DRUG", "CONSUMABLES", "DRUG", "LENSES", "FRAMES",  "OTC","STATIONERY","SUNDRIES"]}
  
   
    openBookStock(id) {
        document.getElementById('bookStockAction').style = 'display:block' 
        this.setState({ id: id, rate: 1, currencyId: 0, companyId: '', companys: [], companyIdError: '', remarks: '', locations: [], productOptions: [], products: [],  manufacturers: [], suppliers: [], bookStockItems: [], msg: '', error: '', totalAmount: 0.00, productTypes: [ "ACCESSORIES", "ANAESTHETIC_DRUG", "CONSUMABLES", "DRUG", "LENSES", "FRAMES",  "OTC","STATIONERY","SUNDRIES"] });
        this.props.setLoader(true)
        axios.get(localStorage.getItem('host') + '/stockmanagement/getbookstock/' + id + '/' + sessionStorage.getItem('companyId'), {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(
            res => {
                var productOptions = [];
                for(var i in res.data.products)
                  {
                    productOptions.push({ value: res.data.products[i].id, label:res.data.products[i].name})
                  }
                this.setState({ productOptions: productOptions, products: res.data.products, companys: res.data.companys, remarks: res.data.remarks, bookStockItems: res.data.bookStockItems, totalAmount: res.data.totalAmount });
                this.props.setLoader(false)
                this.doValidate()
            }, err => {
                console.log(".....")
                this.setState({ error: err + "" })
                this.props.setLoader(false)
            }
        )
    }
    save() {
        this.props.setLoader(true)
        var list = []
        for(var i in this.state.bookStockItems)
            {
             if(this.state.bookStockItems[i].checked === 1)
                {
                 list.push(this.state.bookStockItems[i])
                }
            }
        var data = { id: this.state.id, locationId: this.state.locationId, locationName: this.state.locationName, companyId: this.state.companyId, bookStockItems:  list}
        axios.post(localStorage.getItem('host') + '/stockmanagement/savebookstock', data, {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(
            res => {
                console.log(res.data)
                this.close()
                this.props.getList()
                this.props.setMsg(res.data)
            }, err => {
                console.log(".....")
                this.setState({ error: err + "" })
                this.props.setLoader(false)
            }
        )
    }
    close() {
        document.getElementById('bookStockAction').style = 'display:none'
    } 
    add() {
        this.setState({ bookStockItems: [] });
        this.props.setLoader(true)
        axios.get(localStorage.getItem('host') + '/stockmanagement/getbookstockitems/'+this.state.locationId, {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ bookStockItems: res.data });
                this.props.setLoader(false)
            }, err => {
                console.log(".....")
                this.setState({ error: err + "" })
                this.props.setLoader(false)
            }
        )
    }
    doValidate() {
        setTimeout(() => {
            this.validate(this.state.bookStockItems.slice());
        }, 1000);
    }
    validate(bookStockItems) {
        var error = false;
        if (this.state.companyId === "") {
            this.setState({companyIdError: "Company cannot be empty"})
            error = true
        }
        else {
            this.setState({companyIdError : ""})
        }
        if (this.state.locationId === "") {
            this.setState({locationIdError: "Location cannot be empty"})
            error = true
        }
        else {
            this.setState({locationIdError : ""})
        }
        var checked = false;
        for (var index in bookStockItems) {
            if(bookStockItems[index].checked === 1){
                checked = true
        }
        }
        if(checked === false)
           {
            this.setState({ error: "Please select at least one Item to do book stock", showSubmit: false })
           }
       else if (error === true) {
            this.setState({ error: "One or more of the mandatory field(s) is not filled in", showSubmit: false })
        }
        else {
            this.setState({ error: "", showSubmit: true })
        }
    }
    currencyFormat(amount) {
        let val = (amount / 1).toFixed(2)
        return val.toString().split(/(?=(?:\d{3})+(?:\.|$))/g).join(" ").replace("-,", "-");
    }
    setLocations(id) {
        for (var i in this.state.companys) {
            if (Number(this.state.companys[i].id) === Number(id)) {
                var locations = this.state.companys[i].locations;
                this.setState({ locations: locations })
            }
        }
    }
    setLocation(e)
      {
        for (var i in this.state.locations) {
            if (Number(this.state.locations[i].id) === Number(e.target.value)) {
                this.setState({ locationId: this.state.locations[i].id, locationName: this.state.locations[i].name })
            }
        }
      }
    setItem(index, value)
      {
      var list = this.state.bookStockItems;
      list[index].checked = value
      this.setState({bookStockItems: list})
      this.doValidate()
      }
      setChecked(value)
       {
        var list = this.state.bookStockItems
        for(var i in list)
            {
             list[i].checked = value
            }
         this.setState({bookStockItems: list, checked: value})   
         this.doValidate()
       }
    render() {
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        }
        if (this.state.error) {
            divMsg = (<div className='alert alert-danger'>{this.state.error}</div>)
        }
        return (
                        <div id="bookStockAction" className="page-popup page-popup-overlay"   >
                        <div className="card" >
                            <div className="card-header bg-info">Book Stock Action</div>
                            <div className="card-body" >
                                {divMsg}
                        <div className="card card-green">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col">
                                        <div className="input-group"><span className="input-group-text">Company</span>
                                            <select className={this.state.companyIdError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.companyId} onChange={e => { this.setState({ companyId: e.target.value }); this.setLocations(e.target.value); this.doValidate(); }}  >
                                                <option value="0">Choose Company</option>
                                                {this.state.companys.map((item, index) => (
                                                    <option key={index} value={item.id} >{item.name}</option>
                                                ))}
                                            </select><div className="invalid-feedback">{this.state.companyIdError}</div></div>
                                    </div>
                                    <div className="col">
                                    <div className="input-group has-validation"><span className="input-group-text">Locations</span><select className={this.state.locationIdError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.locationId} onChange={e => { this.setLocation(e); this.doValidate(); }}  >
                                                                <option value="0">Choose Location</option>
                                                                {this.state.locations.map((l, index) => (
                                                                    <option key={index} value={l.id} >{l.name}</option>
                                                                ))}
                                                            </select><div className="invalid-feedback">{this.state.locationIdError}</div></div>
                                    </div>
                                    <div className="col">
                                    { this.state.locationId > 0 &&(<button type="button" className="btn btn-success" title="Insert" onClick={() => { this.add() }}  ><i className="bi bi-plus-square"></i></button>)}
                                    </div>
                                </div>
                            </div></div>   
                        <br />
                        <div className="card card-green">
                            <div className="card-header">Items</div>
                            <div className="card-body">
                            
                                <table className="table table-striped" >
                                <tr>
                                                        <th><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.checked === 1} onChange={e => this.setChecked(e.target.checked === true ? 1 : 0)} /></th>
                                                        <th>Code</th>
                                                        <th>Name</th>
                                                        <th>Category</th>
                                                        <th>Type</th>
                                                        <th>Quantity</th>
                                                        <th>Buying Price</th>
                                                        <th>Dispensing Fee</th>
                                                        <th>Batch Number</th>
                                                        <th>Expiry Date</th>
                                                        <th>Manufacturer</th>
                                                        <th>Supplier</th>
                                                    </tr>
                                                    <tbody>                                               
                                                     {this.state.bookStockItems.map((item, index) => (
                                                        <tr>
                                                            <td><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={item.checked === 1} onChange={e => this.setItem(index, e.target.checked === true ? 1 : 0 )} /></td>
                                                            <td>{item.code}</td>
                                                            <td>{item.name}</td>
                                                            <td>{item.category}</td>
                                                            <td>{item.type}</td>
                                                            <td>{item.quantity}</td>
                                                            <td>{this.currencyFormat(item.buyingPrice)}</td>
                                                            <td>{item.dispensingFee}</td>
                                                            <td>{item.batchNumber}</td>
                                                            <td>{item.expiryDate}</td>
                                                            <td>{item.manufacturer} </td>
                                                            <td>{item.supplier}</td>
                                                        </tr>
                                                    ))}
                                                    </tbody>
                                </table>
                                <div className="row">
                                    <div className="input-group">
                                        <input type="hidden" className={this.state.bookStockItemsError !== "" ? "form-control is-invalid" : "form-control"} />
                                        <div className="invalid-feedback">{this.state.bookStockItemsError}</div> </div></div>  </div></div>
                        <div className="row">
                            <div className="col" align="right">{this.state.showSubmit === true && (<input type="button" className="btn btn-primary" onClick={() => this.save()} value="Save" />)}</div>
                            <div className="col"><input type="button" className="btn btn-primary" onClick={() => this.close()} value="Cancel" /></div>
                        </div>
                        </div>
                </div ></div >
        )

    }
}    