import React, { Component } from "react";
import '../../App.css';

export default class Cancel extends Component {
    constructor(props) {
        super(props);
        console.log('.......')
    }
     state={number: '', id : '', reason: ''}
    cancel(number, id)
    {
     this.setState({number: number, id: id})
     document.getElementById('cancelAction').style = 'display:block'
    }  
    closePopup() {
        document.getElementById('cancelAction').style = 'display:none'
    }
    render() {
        return (<div id="cancelAction" className="page-popup page-popup-overlay" >
        <div className="card" >
            <div className="card-header bg-info">Cancel Action</div>
            <div className="card-body" >
               <p>Are you sure you want to cancel  {this.state.number}?</p> <div className="row">
                            <div className="col"> <div className="input-group"><span className="input-group-text">Reason</span>
                                            <textarea className="form-control" value={this.state.reason} onChange={e => { this.setState({ reason: e.target.value }) }} ></textarea></div> </div>       
                            </div>  
                <div className="row">
                <div className="col" align="center" >{ this.state.reason !== '' && this.state.reason.length > 4 &&(<button onClick={() => this.props.sendCancelRequest(this.state.id, this.state.reason)} className="btn btn-primary">Yes</button>)}</div> 
                <div className="col" align="center" ><button onClick={() => this.closePopup()} className="btn btn-primary">No</button></div> 
                </div>
            </div>
        </div>
    </div>)

    }
}    