import React, { Component } from "react";
import axios from "axios";
import '../../App.css';

export default class Whatsapp extends Component {
    state = { companyId: 0, patientId: 0, speciality: '', mobileNumber: '', mobileNumberError: '', phoneNumberId: '', authToken: '', senderMobile: '', patientName: '', doctorName: '', mobiles: [] , printoutDtos: [], printoutDtosError: '', showSubmit: false , msg: '', error: '', body: ''}
     toggleTabs(index) {
        let list = this.state.printIndex.slice();
        for(var i  in list)
          {
            list[i] = Number(i) === Number(index)?true:false;  
            //console.log((i == index?true:false)+'...'+i+'....'+index) 
          }
        this.setState({ printIndex: list })
        
    }
    getWhatsapp(id, type) {
        this.setState({ printIndex: [] , printoutDtos: [], mobileNumber: '', mobileNumberError: '', phoneNumberId: '', authToken: '', senderMobile: '', patientName: '', doctorName: '', mobiles: [] , printoutDtosError: '', showSubmit: false , msg: '', error: '', body: ''})
            axios.get(localStorage.getItem('host') + '/interchange/getwhatsappemr/'+id+'/'+type, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            }).then(
                res => {
                    this.setState({ companyId: res.data.companyId, patientId: res.data.patientId, speciality: res.data.speciality, printoutDtos: res.data.printOuts, phoneNumberId: res.data.phoneNumberId, authToken: res.data.authToken, senderMobile: res.data.senderMobile, mobiles: res.data.mobiles, patientName: res.data.patientName, doctorName: res.data.doctorName })
                    this.props.setLoader(false)
                }, err => {
                     this.setState({error: err+""})
                    this.props.setLoader(false)
                }
            )
    }
    
    closePopup() {
        document.getElementById('whatsappAction').style = 'display:none'
    }
    setItem(value, index)
    {
        let list = this.state.printoutDtos.slice();
        list[index].selected = value
        this.setState({ printoutDtos: list });
        this.doValidate()
    }
    doValidate() {
        setTimeout(() => {
            this.validate();
        }, 1000);
    }
    validate() {

        var error = false;
        if (this.state.mobileNumber === null) {
            this.setState({ mobileNumberError: "Mobile Number cannot be empty" });
            error = true
        }
        else if (this.state.mobileNumber === "") {
            this.setState({ mobileNumberError: "Mobile Number cannot be empty" });
            error = true
        }
        else if (!(/^[2][6][3][7]\d{8}$/.test(this.state.mobileNumber)) && !(/^[2][7]\d{9}$/.test(this.state.mobileNumber))) {
            this.setState({ mobileNumberError: "Wrong mobile number format. Should start with 2637XXXXXXXX or 27XXXXXXXXX" });
            error = true
        }
        else {
            this.setState({ mobileNumberError: "" })
        }
        var selected = 0;
          for(var i  in this.state.printoutDtos.slice())
        {
            if(this.state.printoutDtos[i].selected === "1")
            {
                selected++
            }   
        }
        if (selected === 0) {
            this.setState({ printoutDtosError: "Please select one print out to send" })
            error = true
        }
        else
           {
            this.setState({ printoutDtosError: "" })
           }
        if(error === false)
        {
            this.setState({showSubmit: true}) 
           }   
    }
    save()
    {
        this.props.setLoader(true)
        var fileUploads = []
        for(var i  in this.state.printoutDtos.slice())
        {
            if(this.state.printoutDtos[i].selected === "1")
            {
             //   var url = "https://kimbah.net/SAMCLINIC01.pdf";
                var url  = localStorage.getItem('host') +'/pdf/view/'+this.state.printoutDtos[i].data;
               fileUploads.push({filename: this.state.printoutDtos[i].name+".pdf", url})
               // urls.push("https://bocdn.ecotree.green/blog/0001/01/ad46dbb447cd0e9a6aeecd64cc2bd332b0cbcb79.jpeg?d=960x540")
            }   
        }
        var data = {companyId: this.state.companyId, patientId: this.state.patientId, phoneNumberId: this.state.phoneNumberId,  authToken: this.state.authToken, senderMobile: this.state.senderMobile, mobileNumber: this.state.mobileNumber, body: this.state.body, fileUploads, patientName: this.state.patientName, doctorName: this.state.doctorName}
        axios.post(localStorage.getItem('host') + '/interchange/sendwhatsapp', data, {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.props.setLoader(false)
                console.log(res.data)
                if(res.data.error)
                  {
                    this.setState({ error: res.data.error , msg: ''});  
                  }
                  else
                  {
                    this.setState({msg: res.data.msg, error: '', showSubmit: false})  
                  }
            }, err => {
                 this.setState({error: err+""})
                this.props.setLoader(false)
            }
        )
    }
    render() {
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        } 
        if (this.state.error) {
            divMsg = (<div className='alert alert-danger'>{this.state.error}</div>)
        }
        return (<div id="whatsappAction" className="page-popup page-popup-overlay" >
        <div className="card" >
            <div className="card-header bg-info">Whatsapp Action</div>
            <div className="card-body" >
            {divMsg}
            <div className="row">
                                        <div className="col"><div className="input-group has-validation"><span className="input-group-text"> Mobile Number </span><input type="text" list="mobileList" value={this.state.mobileNumber} onChange={e => { this.setState({ mobileNumber: e.target.value }); this.doValidate() }} className={this.state.mobileNumberError !== "" ? "form-control is-invalid" : "form-control"} />
                                        <datalist id="mobileList">
                                        {this.state.mobiles.map((m, index) => (
                                                                <option key={index} value={m} >{m}</option>
                                                            ))}
                                                            </datalist>
                                                            <div className="invalid-feedback">{this.state.mobileNumberError}</div>
                                        </div></div>
                                        <div className="col" align="right"><div className="input-group"><span className="input-group-text">Message </span><textarea value={this.state.body} onChange={e => this.setState({ body: e.target.value })} className="form-control" rows="10" ></textarea></div></div>
                                     </div>
                                     <table className="table table-striped">
                                                <tr>
                                                    <th>Action</th>
                                                    <th>Option</th>
                                                </tr>
                                                <tbody>
                                                {this.state.printoutDtos.map((item, index) => (
                                                    <tr key={index}>
                                                        <td><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={item.selected === "1"} onChange={e => this.setItem(e.target.checked === true ? "1" : "0" , index)} /></td>
                                                        <td>{item.name}</td>
                                                    </tr>))}
                                                    </tbody>
                                            </table>
                                            <div className="row">
                                            <div className="input-group">
                                                        <input type="hidden" className={this.state.printoutDtosError !== "" ? "form-control is-invalid" : "form-control"} />
                                                        <div className="invalid-feedback">{this.state.printoutDtosError}</div> </div></div>
                <div className="row">
                <div className="col" align="center">{this.state.showSubmit === true ?(<input type="button" className="btn btn-primary" onClick={() => { this.save() }} value="Save" />):(<span></span>)}</div>
                <div className="col" align="center" ><button onClick={() => this.closePopup()} className="btn btn-primary">Close</button></div> 
                </div>
            </div>
        </div>
    </div>)

    }
}    