import React, { Component } from "react";
import axios from "axios";
import '../../App.css';

export default class ViewProducts extends Component {
 
    state = {
       companyId: sessionStorage.getItem('companyId'), list: [], clusterCompanys: [], patientId: 0, reloadPatientList: false, duplicateCount: 0
         
    }

    getList(patientId) {
        this.props.setLoader(true)
        this.setState({ list: [], msg: '' });
        axios.get(localStorage.getItem('host') + '/sell/getscripts/'+patientId,  {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ list: res.data });
                this.props.setLoader(false)
            }, err => {
                this.setState({ error: err + "" })
                this.props.setLoader(false)
            }
        )
    }
    close() {
        document.getElementById('viewProductsAction').style = 'display:none'
    }
    currencyFormat(amount) {
        let val = (amount / 1).toFixed(2)
        return val.toString().split(/(?=(?:\d{3})+(?:\.|$))/g).join(" ").replace("-,", "-");
    }
    render() {
        return (
            <div id="viewProductsAction" className="page-popup page-popup-overlay"   >
                <div className="card" >
                    <div className="card-header bg-info">Product List</div>
                    <div className="card-body" >
                        <div>
                        <table className="table table-striped" >
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Pack Size</th>
                                                    <th>Uom</th>
                                                    <th>Form</th>
                                                    <th>Type</th>
                                                    <th>Tariff Code</th>
                                                    <th>Date</th>
                                                    <th>Cost</th>
                                                    <th>Dispensing Fee</th>
                                                    <th>Total Cost</th>
                                                    <th>Instruction</th>
                                                    <th>Quantity</th>
                                                    <th>Collected</th>
                                                    <th>Action</th>
                                                 </tr><tbody>
                                                {this.state.list.map((l, index) =>  (
                                                    <tr key={index}>
                                                        <td>{l.name}</td>
                                                        <td>{l.packSize}</td>
                                                        <td>{l.uom}</td>
                                                        <td>{l.form}</td>
                                                        <td>{l.type}</td>
                                                        <td>{l.tariffCode}</td>
                                                        <td>{l.billStartDate}</td>
                                                        <td>{this.currencyFormat(l.cost)}</td>
                                                        <td>{this.currencyFormat(l.dispensingFee)}</td>
                                                        <td>{this.currencyFormat(l.totalCost)}</td>
                                                        <td>{l.instruction}</td>
                                                        <td>{l.quantity}</td>
                                                        <td>{l.collected}</td>
                                                        <td><span className="link" onClick={() => this.props.viewCollected(l.id, l.available)} title="Bill Opening Request"><i className="bi bi-list-task"></i></span></td>
                                                        </tr>
                                                ))}
                                                </tbody>
                                            </table> 
                        </div>
                        <div className="row">
                            <div className="col-lg-12" align="center"><button onClick={() => this.close()} className="btn btn-primary">Close Window (Esc)</button></div>
                        </div>
                    </div>
                </div>
            </div>
        )

    }
}      