import React, { Component } from "react";
import axios from "axios";
import '../../App.css';
import Top from '../../includes/top'
import Sidebar from '../../includes/sidebar'
import { MDBDataTable } from 'mdbreact';


export default class Clusters extends Component {
    state = { list: [], id: 0, name: '', showOther: 0, activeStatus: 1, clusterCompanys: [], companys: [], menu: '', msg: '', error: '' }
    componentDidMount() {
        this.getList()
        axios.get(localStorage.getItem('host') + '/auth/getcompanyactivelist', {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ companys: res.data });
            }, err => {
                console.log(".....")
            }
        )
    }
    clusterAction(e) {
        var id = e.currentTarget.id;
        console.log("......." + id + " .... " + e.currentTarget.id)
        this.showForm(id);
    }
    showForm(id) {
        this.setState({id: 0, name: '', showOther: 0, activeStatus: 1, msg: '', clusterCompanys:[]})   
        if (id > 0) {
            axios.get(localStorage.getItem('host') + '/auth/clusteraction/' + id, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            }).then(
                res => {
                    this.setState({ id: res.data.id, name: res.data.name, showOther: res.data.showOther, activeStatus: res.data.activeStatus, clusterCompanys: res.data.clusterCompanys });
                }, err => {
                    console.log(".....")
                }
            )
        }
        document.querySelector('#clusterAction').style = 'display:block'
    }
    closePopup() {
        document.querySelector('#clusterAction').style = 'display:none'
        console.log('......close');
    }
    saveCluster() {
        var data = { id: this.state.id, name: this.state.name, showOther: this.state.showOther, activeStatus: this.state.activeStatus, clusterCompanys: this.state.clusterCompanys }
        axios.post(localStorage.getItem('host') + '/auth/clusterform', data, {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(
            res => {
                console.log(res.data)
                this.setState({ msg: res.data });
                this.getList();
                this.closePopup();
                setTimeout(() => {
                    this.setState({ msg: '' });
                }, 3000);
            }, err => {
                console.log(".....")
            }
        )
    }
    getList() {
        axios.get(localStorage.getItem('host') + '/auth/cluster', {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(
            res => {
                for (var index in res.data) {
                    res.data[index].action = <span className="link" title="Edit Cluster" id={res.data[index].id} onClick={e => this.clusterAction(e)}><i className="bi bi-pencil-square"></i></span>
                }
                this.setState({ list: res.data });
            }, err => {
                console.log(".....")
            }
        )
    }
    addCompany()
      {
        let list = this.state.clusterCompanys.slice();
        list.push({id:0, companyId: '',  activeStatus: 1});
        this.setState({clusterCompanys:list })
      }
      setRowValue(index, e, name) {
          let list = this.state.clusterCompanys.slice();
          switch (name) {
            case "companyId":
                           list[index].companyId = e.target.value;
                           break;
           case "activeStatus":
                          list[index].activeStatus = e.target.value;
                          break;
                 default:
                         console.log("default")
                          break;
                        }       
          this.setState({ clusterCompanys: list })
        }
    render() {
        const data = {
            columns: [
                
                {
                    label: 'Action',
                    field: 'action',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Name',
                    field: 'name',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Status',
                    field: 'stringStatus',
                    sort: 'asc',
                    width: 100
                }
            ],
            rows: this.state.list
        };
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        }
        return (
            <div className="d-flex" id="wrapper">
            <Sidebar />
            <div id="page-content-wrapper">
                <Top />

                <div className="container-fluid">
                <button className="btn btn-primary" onClick={() => this.showForm(0)} title="Add Cluster" ><i className="bi bi-plus-square"></i></button>
                        {divMsg}
                        <MDBDataTable
                            button="true"
                            striped
                            bordered
                            small
                            data={data}
                        />
                        <div className="page-popup page-popup-overlay" id="clusterAction">
                            <form  >
                                <div className="card">
                                    <div className="card-header bg-info">
                                        Cluster Action {this.props.idFromParent}
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col">
                                            <div className="input-group"><span className="input-group-text">Name</span>
                                                <input type="text" className="form-control" value={this.state.name} onChange={e => { this.setState({ name: e.target.value }) }} /></div>
                                            </div>
                                            <div className="col">
                                            <div className="input-group"><span className="input-group-text">Status</span>
                                                <select className="form-control" value={this.state.activeStatus} onChange={e => { this.setState({ activeStatus: e.target.value }) }}  >
                                                    <option value="1" >Active</option>
                                                    <option value="0" >Deactivate</option>
                                                </select></div>
                                            </div></div>
                                        <div className="row">
                                            <div className="col"></div>
                                            <div className="col">
                                            <div className="input-group"><span className="input-group-text">Show Other</span>
                                                <select className="form-control" value={this.state.showOther} onChange={e => { this.setState({ showOther: e.target.value }) }}  >
                                                    <option value="1" >Active</option>
                                                    <option value="0" >Deactivate</option>
                                                </select></div>
                                            </div></div>
                                            <div className="card card-green">
                                            <div className="card-header">Menus</div>
                                            <div className="card-body">                                        
                                            <table className="table table-striped">
                                         <tr>
                                             <th>Name</th>
                                             <th>Status</th>
                                             </tr> 
                                         <tr>
                                             <td> </td>
                                                <td><button type="button" className="btn btn-success" title="Insert Cluster" onClick={()=>{this.addCompany()}}  ><i className="bi bi-plus-square"></i></button></td>
                                             </tr>    
                                             <tbody>
                                         {this.state.clusterCompanys.map((clusterCompany, index) => (
                                         <tr>
                                             <td> <select className="form-control" value={clusterCompany.companyId} onChange={e => { this.setRowValue(index, e, "companyId") }}  >
                                                    {this.state.companys.map((company, index) => (
                                                        <option key={index} value={company.id} >{company.name}</option>
                                                    ))}
                                                </select></td>
                                             <td><select className="form-control" value={clusterCompany.activeStatus} onChange={e => { this.setRowValue(index, e, "activeStatus") }}  >
                                             <option value="1" >Active</option>
                                             <option value="0" >Disable</option>
                                         </select></td>
                                             </tr>  
                                             ))}    
                                             </tbody> 
                                         </table>   </div></div>
                                        <div className="row">
                                            <div className="col" align="right"><input type="button" className="btn btn-primary" span onClick={() => this.saveCluster()} value="Save" /></div>
                                            <div className="col"><input type="button" className="btn btn-primary" span onClick={() => this.closePopup()} value="Close" /></div>
                                        </div>
                                    </div></div>
                            </form>
                        </div>
                    </div>
                </div></div>
        )

    }
}    