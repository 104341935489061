import React, { Component } from "react";
import axios from "axios";
import '../../App.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const now = new Date();
const todayY = now.getFullYear();
const todayM = checkZero(now.getMonth() + 1);
const todayD = checkZero(now.getDate());
var date1 = todayY + '-' + todayM + '-' + todayD;
console.log(date1)
function checkZero(num) {
    console.log((num + "").length)
    return (num + "").length === 1 ? "0" + num : num;
}

export default class RehabAction extends Component {
    state = {
        loader: false,htmlText: '', id: 0, referringDoctorName: '', companyId: 0, patientId: 0, patientDiagnosis: [], diagnosisList: [], searchDiagnosis: '', diagnosisText: '', reviews: [], medicalCertificateTypes: [], medicalCertificates: [], referrals: [],appointmentTimes: [], notes: [],
        fullname: '', number: '', mobile: '', gender: '', idNumber: '', dateOfBirth: '', age: '', historyVisits: [], visitId: 0, msg: '', error: '', diagnosis: '',
        /* HISTORY */
        pediatricsHistory: '', developmentalMilestone: '', presentingComplaints: '',
        /*EXAMINATION */
        spineAndPeripheralJoints: [] ,spineAssessments: [] ,muscleStrengthOrJointAssessment: '' ,chestAssessment: '' ,vision: '' ,hearing: '' ,comprehension: '' ,communication: '' ,bladderBowels: '' , respiratory: '' ,place: '' ,time: '' ,person: '' ,sensation: '' ,coordination: '' ,proprioception: '' ,fatigue: '' ,balance: '', posture: '' ,handFunction: '' ,steregnosis: '' ,memory: '' ,bodyImage: '' ,apraxia: '' ,figureGround: '' ,formConstruction: '' ,positionInSpace: '' ,
        spatialRelationships: '' ,visualAgnosia: '' ,rollingRight: '' ,rollingLeft: '' ,bridging: '', sittingBalance: '' ,sittingToSupine: '' ,fourPointKneeling: '' ,twoPointKneeling: '' ,sitToStand: '' ,standingBalance: '' ,schoolAssessment: '' ,workAssessment: '' ,adlAssessment: '' ,therapistAssessment: '' ,shortTerm: '' , longTerm: '',
        /*MANAGEMENT */
        orthopedicTreatmentPlans: [], orthopedicTreatmentPlanComment: '', spinalJointMobilizationTreatments: [],neurologicalTreatmentPlan: '', deepBreathingExercises: '', activeCycleOfBreathingTechnique: '', passiveMovements: '', splintedCoughing: '', bagging: '', nebulization: '', suctioning: '', percussions: '', vibrations: '', incentiveSpirometry: '', thoracicExpansionExercises: '',posturalDrainage: '', mobilazation: '', sitOutInChair: '', coughStimulation: '', assistedCough: '', progressReport: '',
        positions: [], vertebraOrJoints: [], grades: [], repetitions: [],
        presentingComplaintsT: true, pediatricsHistoryT: false, developmentalMilestonesT: false,
        spineAndPeripheralJointT: false, spineAssessmentT: false, muscleStrengthT: false, chestAssessmentT: false, generalAssessmentT: false, otherAssessmentT: false, mobilityExaminationT: false, schoolAssessmentT: false, workAssessmentT: false, adlAssessmentT: false, therapistAssessmentT: false, goalAssessmentT: false,
        diagnosisTab: false, history: true, examination: false, orthopedicTreatmentPlanT: false, spinalAndJointMobilisationTreatmentT: false, treatmentPlanForChestT: false, referral: false, medicalCertificate: false, review: false, progressReportT: false , neurologicalTreatmentPlanT: false
    }
    componentDidMount() {

    }
    openAction(visitId, msg) {
        if(msg === '')
           {
            this.setLoader(true) 
           }
        this.setState({
            loader: false,htmlText: '',  id: 0, referringDoctorName: '', companyId: 0, patientId: 0, patientDiagnosis: [], diagnosisList: [], searchDiagnosis: '', diagnosisText: '', reviews: [], medicalCertificateTypes: [], medicalCertificates: [], referrals: [],appointmentTimes: [], notes: [],
        fullname: '', number: '', mobile: '', gender: '', idNumber: '', dateOfBirth: '', age: '', historyVisits: [], visitId: 0, msg: '', error: '', diagnosis: '',
        /* HISTORY */
        pediatricsHistory: '', developmentalMilestone: '', presentingComplaints: '',
        /*EXAMINATION */
        spineAndPeripheralJoints: [] ,spineAssessments: [] ,muscleStrengthOrJointAssessment: '' ,chestAssessment: '' ,vision: '' ,hearing: '' ,comprehension: '' ,communication: '' ,bladderBowels: '' ,respiratory: '', place: '' ,time: '' ,person: '' ,sensation: '' ,coordination: '' ,proprioception: '' ,fatigue: '' ,balance: '', posture: '' ,handFunction: '' ,steregnosis: '' ,memory: '' ,bodyImage: '' ,apraxia: '' ,figureGround: '' ,formConstruction: '' ,positionInSpace: '' ,
        spatialRelationships: '' ,visualAgnosia: '' ,rollingRight: '' ,rollingLeft: '' ,bridging: '', sittingBalance: '' ,sittingToSupine: '' ,fourPointKneeling: '' ,twoPointKneeling: '' ,sitToStand: '' ,standingBalance: '' ,schoolAssessment: '' ,workAssessment: '' ,adlAssessment: '' ,therapistAssessment: '' ,shortTerm: '' , longTerm: '',
        /*MANAGEMENT */
        orthopedicTreatmentPlans: [], orthopedicTreatmentPlanComment: '', spinalJointMobilizationTreatments: [],neurologicalTreatmentPlan: '', deepBreathingExercises: '', activeCycleOfBreathingTechnique: '', passiveMovements: '', splintedCoughing: '', bagging: '', nebulization: '', suctioning: '', percussions: '', vibrations: '', incentiveSpirometry: '', thoracicExpansionExercises: '',posturalDrainage: '', mobilazation: '', sitOutInChair: '', coughStimulation: '', assistedCough: '', progressReport: '',
        positions: [], vertebraOrJoints: [], grades: [], repetitions: [],
        presentingComplaintsT: true, pediatricsHistoryT: false, developmentalMilestonesT: false,
        spineAndPeripheralJointT: false, spineAssessmentT: false, muscleStrengthT: false, chestAssessmentT: false, generalAssessmentT: false, otherAssessmentT: false, mobilityExaminationT: false, schoolAssessmentT: false, workAssessmentT: false, adlAssessmentT: false, therapistAssessmentT: false, goalAssessmentT: false,
        diagnosisTab: false, history: true, examination: false, orthopedicTreatmentPlanT: false, spinalAndJointMobilisationTreatmentT: false, treatmentPlanForChestT: false, referral: false, medicalCertificate: false, review: false, progressReportT: false , neurologicalTreatmentPlanT: false
        })
        axios.get(localStorage.getItem('host') + '/patientmanagement/rehab/' + visitId, {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({
                    msg: msg, id: res.data.id, diagnosisText: res.data.diagnosisText, companyId: res.data.companyId, patientId: res.data.patientId, patientDiagnosis: res.data.patientDiagnosis, reviews: res.data.reviews, medicalCertificateTypes: res.data.medicalCertificateTypes, medicalCertificates: res.data.medicalCertificates, referrals: res.data.referrals,appointmentTimes: res.data.appointmentTimes, notes: res.data.notes,
                    fullname: res.data.fullname, number: res.data.number, mobile: res.data.mobile, gender: res.data.gender, idNumber: res.data.idNumber, dateOfBirth: res.data.dateOfBirth, age: res.data.age, historyVisits: res.data.historyVisits, visitId: res.data.visitId,
                    /* HISTORY */
                    pediatricsHistory: res.data.history.pediatricsHistory, developmentalMilestone: res.data.history.developmentalMilestone, presentingComplaints: res.data.history.presentingComplaints,
                    /*EXAMINATION */
                    spineAndPeripheralJoints: res.data.examination.spineAndPeripheralJoints ,spineAssessments: res.data.examination.spineAssessments ,muscleStrengthOrJointAssessment: res.data.examination.muscleStrengthOrJointAssessment ,chestAssessment: res.data.examination.chestAssessment ,vision: res.data.examination.vision ,hearing: res.data.examination.hearing ,comprehension: res.data.examination.comprehension,communication: res.data.examination.communication ,bladderBowels: res.data.examination.bladderBowels, respiratory: res.data.examination.respiratory ,place: res.data.examination.place ,time: res.data.examination.time ,person: res.data.examination.person ,sensation: res.data.examination.sensation ,coordination: res.data.examination.coordination ,proprioception: res.data.examination.proprioception ,fatigue: res.data.examination.fatigue ,balance: res.data.examination.balance, posture: res.data.examination.posture ,handFunction: res.data.examination.handFunction ,steregnosis: res.data.examination.steregnosis ,memory: res.data.examination.memory ,bodyImage: res.data.examination.bodyImage,apraxia: res.data.examination.apraxia,figureGround: res.data.examination.figureGround ,formConstruction: res.data.examination.formConstruction ,positionInSpace: res.data.examination.positionInSpace ,
                    spatialRelationships: res.data.examination.spatialRelationships ,visualAgnosia: res.data.examination.visualAgnosia ,rollingRight: res.data.examination.rollingRight ,rollingLeft: res.data.examination.rollingLeft ,bridging: res.data.examination.bridging, sittingBalance: res.data.examination.sittingBalance ,sittingToSupine: res.data.examination.sittingToSupine ,fourPointKneeling: res.data.examination.fourPointKneeling ,twoPointKneeling: res.data.examination.twoPointKneeling ,sitToStand: res.data.examination.sitToStand ,standingBalance: res.data.examination.standingBalance ,schoolAssessment: res.data.examination.schoolAssessment ,workAssessment: res.data.examination.workAssessment ,adlAssessment: res.data.examination.adlAssessment ,therapistAssessment: res.data.examination.therapistAssessment ,shortTerm: res.data.examination.shortTerm , longTerm: res.data.examination.longTerm,
                    /*MANAGEMENT */
                    orthopedicTreatmentPlans: res.data.management.orthopedicTreatmentPlans, orthopedicTreatmentPlanComment: res.data.management.orthopedicTreatmentPlanComment, spinalJointMobilizationTreatments: res.data.management.spinalJointMobilizationTreatments,neurologicalTreatmentPlan: res.data.management.neurologicalTreatmentPlan, deepBreathingExercises: res.data.management.deepBreathingExercises, activeCycleOfBreathingTechnique: res.data.management.activeCycleOfBreathingTechnique, passiveMovements: res.data.management.passiveMovements, splintedCoughing: res.data.management.splintedCoughing, bagging: res.data.management.bagging, nebulization: res.data.management.nebulization, suctioning: res.data.management.suctioning, percussions: res.data.management.percussions, vibrations: res.data.management.vibrations, incentiveSpirometry: res.data.management.incentiveSpirometry, thoracicExpansionExercises: res.data.management.thoracicExpansionExercises,posturalDrainage: res.data.management.posturalDrainage, mobilazation: res.data.management.mobilazation, sitOutInChair: res.data.management.sitOutInChair, coughStimulation: res.data.management.coughStimulation, assistedCough: res.data.management.assistedCough, progressReport: res.data.management.progressReport
                });
                setTimeout(() => { this.setLoader(false)  }, 2000);
            }, err => {
                console.log(".....")
                this.setState({ error: err + '' })
                this.setLoader(false)
            }
        )
    }
    save() {
        this.setLoader(true)
        var data = {
            id: this.state.id, diagnosisText: this.state.diagnosisText, companyId: this.state.companyId, patientId: this.state.patientId, patientDiagnosis: this.state.patientDiagnosis, reviews: this.state.reviews, medicalCertificates: this.state.medicalCertificates, referrals: this.state.referrals, visitId: this.state.visitId,
                    /* HISTORY */
                    history: {pediatricsHistory: this.state.pediatricsHistory, developmentalMilestone: this.state.developmentalMilestone, presentingComplaints: this.state.presentingComplaints},
                    /*EXAMINATION */
                    examination: {spineAndPeripheralJoints: this.state.spineAndPeripheralJoints ,spineAssessments: this.state.spineAssessments ,muscleStrengthOrJointAssessment: this.state.muscleStrengthOrJointAssessment ,chestAssessment: this.state.chestAssessment ,vision: this.state.vision ,hearing: this.state.hearing ,comprehension: this.state.comprehension,communication: this.state.communication ,bladderBowels: this.state.bladderBowels, respiratory: this.state.respiratory ,place: this.state.place ,time: this.state.time ,person: this.state.person ,sensation: this.state.sensation ,coordination: this.state.coordination ,proprioception: this.state.proprioception ,fatigue: this.state.fatigue ,balance: this.state.balance, posture: this.state.posture ,handFunction: this.state.handFunction ,steregnosis: this.state.steregnosis ,memory: this.state.memory ,bodyImage: this.state.bodyImage,apraxia: this.state.apraxia,figureGround: this.state.figureGround ,formConstruction: this.state.formConstruction ,positionInSpace: this.state.positionInSpace ,
                    spatialRelationships: this.state.spatialRelationships ,visualAgnosia: this.state.visualAgnosia ,rollingRight: this.state.rollingRight ,rollingLeft: this.state.rollingLeft ,bridging: this.state.bridging, sittingBalance: this.state.sittingBalance ,sittingToSupine: this.state.sittingToSupine ,fourPointKneeling: this.state.fourPointKneeling ,twoPointKneeling: this.state.twoPointKneeling ,sitToStand: this.state.sitToStand ,standingBalance: this.state.standingBalance ,schoolAssessment: this.state.schoolAssessment ,workAssessment: this.state.workAssessment ,adlAssessment: this.state.adlAssessment ,therapistAssessment: this.state.therapistAssessment ,shortTerm: this.state.shortTerm , longTerm: this.state.longTerm},
                    /*MANAGEMENT */
                    management: {orthopedicTreatmentPlans: this.state.orthopedicTreatmentPlans, orthopedicTreatmentPlanComment: this.state.orthopedicTreatmentPlanComment, spinalJointMobilizationTreatments: this.state.spinalJointMobilizationTreatments,neurologicalTreatmentPlan: this.state.neurologicalTreatmentPlan, deepBreathingExercises: this.state.deepBreathingExercises, activeCycleOfBreathingTechnique: this.state.activeCycleOfBreathingTechnique, passiveMovements: this.state.passiveMovements, splintedCoughing: this.state.splintedCoughing, bagging: this.state.bagging, nebulization: this.state.nebulization, suctioning: this.state.suctioning, percussions: this.state.percussions, vibrations: this.state.vibrations, incentiveSpirometry: this.state.incentiveSpirometry, thoracicExpansionExercises: this.state.thoracicExpansionExercises,posturalDrainage: this.state.posturalDrainage, mobilazation: this.state.mobilazation, sitOutInChair: this.state.sitOutInChair, coughStimulation: this.state.coughStimulation, assistedCough: this.state.assistedCough, progressReport: this.state.progressReport},
        }
        localStorage.setItem('nuero_data', JSON.stringify(data))
        axios.post(localStorage.getItem('host') + '/patientmanagement/rehabsave', data, {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.props.getList()
                document.getElementById('topDiv').scrollIntoView()
                this.openAction(this.state.visitId,  res.data )
            }, err => {
                console.log(".....")
                this.setState({ error: err })
                this.setLoader(false)
            }
        )

    }
    getFromLocalStorage()
     {
      var data = JSON.parse(localStorage.getItem('nuero_data'));  
      this.setState({
        id: data.id, diagnosisText: data.diagnosisText, companyId: data.companyId, patientId: data.patientId, patientDiagnosis: data.patientDiagnosis, reviews: data.reviews, medicalCertificateTypes: data.medicalCertificateTypes, medicalCertificates: data.medicalCertificates, referrals: data.referrals,appointmentTimes: data.appointmentTimes,
        fullname: data.fullname, number: data.number, mobile: data.mobile, gender: data.gender, idNumber: data.idNumber, dateOfBirth: data.dateOfBirth, age: data.age, historyVisits: data.historyVisits, visitId: data.visitId,
        /* HISTORY */
        pediatricsHistory: data.history.pediatricsHistory, developmentalMilestone: data.history.developmentalMilestone, presentingComplaints: data.history.presentingComplaints,
        /*EXAMINATION */
        spineAndPeripheralJoints: data.examination.spineAndPeripheralJoints ,spineAssessments: data.examination.spineAssessments ,muscleStrengthOrJointAssessment: data.examination.muscleStrengthOrJointAssessment ,chestAssessment: data.examination.chestAssessment ,vision: data.examination.vision ,hearing: data.examination.hearing ,comprehension: data.examination.comprehension,communication: data.examination.communication ,bladderBowels: data.examination.bladderBowels, respiratory: data.examination.respiratory ,place: data.examination.place ,time: data.examination.time ,person: data.examination.person ,sensation: data.examination.sensation ,coordination: data.examination.coordination ,proprioception: data.examination.proprioception ,fatigue: data.examination.fatigue ,balance: data.examination.balance , posture: data.examination.posture ,handFunction: data.examination.handFunction ,steregnosis: data.examination.steregnosis ,memory: data.examination.memory ,bodyImage: data.examination.bodyImage,apraxia: data.examination.apraxia,figureGround: data.examination.figureGround ,formConstruction: data.examination.formConstruction ,positionInSpace: data.examination.positionInSpace ,
        spatialRelationships: data.examination.spatialRelationships ,visualAgnosia: data.examination.visualAgnosia ,rollingRight: data.examination.rollingRight ,rollingLeft: data.examination.rollingLeft ,bridging: data.examination.bridging, sittingBalance: data.examination.sittingBalance ,sittingToSupine: data.examination.sittingToSupine ,fourPointKneeling: data.examination.fourPointKneeling ,twoPointKneeling: data.examination.twoPointKneeling ,sitToStand: data.examination.sitToStand ,standingBalance: data.examination.standingBalance ,schoolAssessment: data.examination.schoolAssessment ,workAssessment: data.examination.workAssessment ,adlAssessment: data.examination.adlAssessment ,therapistAssessment: data.examination.therapistAssessment ,shortTerm: data.examination.shortTerm , longTerm: data.examination.longTerm,
        /*MANAGEMENT */
        orthopedicTreatmentPlans: data.management.orthopedicTreatmentPlans, orthopedicTreatmentPlanComment: data.management.orthopedicTreatmentPlanComment, spinalJointMobilizationTreatments: data.management.spinalJointMobilizationTreatments,neurologicalTreatmentPlan: data.management.neurologicalTreatmentPlan, deepBreathingExercises: data.management.deepBreathingExercises, activeCycleOfBreathingTechnique: data.management.activeCycleOfBreathingTechnique, passiveMovements: data.management.passiveMovements, splintedCoughing: data.management.splintedCoughing, bagging: data.management.bagging, nebulization: data.management.nebulization, suctioning: data.management.suctioning, percussions: data.management.percussions, vibrations: data.management.vibrations, incentiveSpirometry: data.management.incentiveSpirometry, thoracicExpansionExercises: data.management.thoracicExpansionExercises,posturalDrainage: data.management.posturalDrainage, mobilazation: data.management.mobilazation, sitOutInChair: data.management.sitOutInChair, coughStimulation: data.management.coughStimulation, assistedCough: data.management.assistedCough, progressReport: data.management.progressReport
    });
     }
    closePopup() {
        document.getElementById('rehabAction').style = 'display:none'
    }
    processDate(date) {
        const now = new Date(date);
        const todayY = now.getFullYear();
        const todayM = checkZero(now.getMonth() + 1);
        const todayD = checkZero(now.getDate());
        var date1 = todayY + '-' + todayM + '-' + todayD;
        console.log(date1)
        return date1;


    }
    viewHistory(visitId) {

        this.setState({ htmlText: '', popupName: '' })
        this.setLoader(true)
        axios.get(localStorage.getItem('host') + '/patientmanagement/rehabsummaryview/' + visitId, {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ htmlText: res.data, popupName: 'Doctor History' });
                document.getElementById('genericPopup').style = 'display:block'
                this.setLoader(false)
            }, err => {
                console.log(".....")
                this.setState({ error: err })
                this.setLoader(false)
            }
        )
    }
    viewConsolidated(visitId) {

        this.setState({ htmlText: '', popupName: '' })
        this.setLoader(true)
        axios.get(localStorage.getItem('host') + '/patientmanagement/rehabsummaryviewconsolidated/' + visitId, {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ htmlText: res.data, popupName: 'Doctor Consolidated' });
                document.getElementById('genericPopup').style = 'display:block'
                this.setLoader(false)
            }, err => {
                console.log(".....")
                this.setState({ error: err })
                this.setLoader(false)
            }
        )
    }
    genericClosePopup() {
        document.getElementById('genericPopup').style = 'display:none'
    }
    uploadFile(fileType, file) {
        if (file !== null || file !== '') {
            var data = new FormData();
            data.append("file", file)
            data.append("fileType", fileType)
            data.append("visitId", this.state.visitId)
            axios.post(localStorage.getItem('host') + '/file/filesave', data, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
                    'content-type': 'multipart/form-data'

                }
            }).then(
                res => {
                    console.log(res.data)
                    if (fileType === "REFERRAL_LETTERS") {
                        this.setState({ referralLetters: res.data, msg: 'Successfully uploaded Referral Letter' });
                    }
                    else {
                        this.setState({ notes: res.data, msg: 'Successfully uploaded Notes' });
                    }

                    setTimeout(() => {
                        this.setState({ msg: '' });
                    }, 3000);
                }, err => {
                    console.log(".....")
                }
            )

        }
    }
    deleteFile(id, fileExtension, fileType) {
        this.setLoader(true)
        axios.get(localStorage.getItem('host') + '/file/deletefile/' + id + '/' + fileType + '/' + fileExtension + '/' + this.state.visitId, {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(
            res => {
                if (fileType === "REFERRAL_LETTERS") {
                    this.setState({ referralLetters: res.data, msg: 'Successfully deleted Referral Letter' });
                }
                else {
                    this.setState({ notes: res.data, msg: 'Successfully deleted Notes' });
                }
                this.setLoader(false)
            }, err => {
                this.setState({ error: err + "" })
                this.setLoader(false)
            }
        )
    }
    showFile(id, fileExtension) {
        this.setState({ fileData: localStorage.getItem('host') + '/file/pdfview/' + id + '/' + fileExtension });
        document.getElementById('showFileNurseAction').style = 'display:block'

    }
    fileNurseClosePopup() {
        this.setState({ fileData: '' });
        document.getElementById('showFileNurseAction').style = 'display:none'
    }
    showOldNurse(oldPatient) {
        this.setState({ htmlText: '', popupName: '' })
        this.setLoader(true)
        axios.get('https://ucare.kimbah.net/old/nurselistview/' + oldPatient + '/500', {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(
            res => {
                //   this.setState({ htmlText: res.data, popupName: 'Nurse History'});
                document.getElementById('genericPopup').style = 'display:block'
                this.setLoader(false)
            }, err => {
                console.log(".....")
                this.setState({ error: err })
                this.setLoader(false)
            }
        )
    }
    showOldResults(oldPatient) {
        this.setState({ htmlText: '', popupName: '' })
        this.setLoader(true)
        axios.get('https://ucare.kimbah.net/old/nurselistview/' + oldPatient + '/500', {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ htmlText: res.data, popupName: 'Nurse History' });
                document.getElementById('genericPopup').style = 'display:block'
                this.setLoader(false)
            }, err => {
                console.log(".....")
                this.setState({ error: err })
                this.setLoader(false)
            }
        )
    }
    toggleTabs(name) {
        switch (name) {
            case "diagnosis":
                this.setState({ diagnosisTab: true, history: false, examination: false, orthopedicTreatmentPlanT: false, spinalAndJointMobilisationTreatmentT: false, treatmentPlanForChestT: false, referral: false, medicalCertificate: false, review: false, progressReportT: false, neurologicalTreatmentPlanT: false })
                break
            case "examination":
                this.setState({ diagnosisTab: false, history: false, examination: true, orthopedicTreatmentPlanT: false, spinalAndJointMobilisationTreatmentT: false, treatmentPlanForChestT: false, referral: false, medicalCertificate: false, review: false, progressReportT: false , neurologicalTreatmentPlanT: false, spineAndPeripheralJointT: true, spineAssessmentT: false, muscleStrengthT: false, chestAssessmentT: false, generalAssessmentT: false, otherAssessmentT: false, mobilityExaminationT: false, schoolAssessmentT: false, workAssessmentT: false, adlAssessmentT: false, therapistAssessmentT: false, goalAssessmentT: false})
                break
            case "orthopedicTreatmentPlanT":
                this.setState({ diagnosisTab: false, history: false, examination: false, orthopedicTreatmentPlanT: true, spinalAndJointMobilisationTreatmentT: false, treatmentPlanForChestT: false, referral: false, medicalCertificate: false, review: false, progressReportT: false , neurologicalTreatmentPlanT: false})
                break
            case "spinalAndJointMobilisationTreatmentT":
                this.setState({ diagnosisTab: false, history: false, examination: false, orthopedicTreatmentPlanT: false, spinalAndJointMobilisationTreatmentT: true, treatmentPlanForChestT: false, referral: false, medicalCertificate: false, review: false, progressReportT: false , neurologicalTreatmentPlanT: false})
                break
            case "neurologicalTreatmentPlanT":
                    this.setState({ diagnosisTab: false, history: false, examination: false, orthopedicTreatmentPlanT: false, spinalAndJointMobilisationTreatmentT: false, treatmentPlanForChestT: false, referral: false, medicalCertificate: false, review: false, progressReportT: false , neurologicalTreatmentPlanT: true})
                    break
            case "treatmentPlanForChestT":
                this.setState({ diagnosisTab: false, history: false, examination: false, orthopedicTreatmentPlanT: false, spinalAndJointMobilisationTreatmentT: false, treatmentPlanForChestT: true, referral: false, medicalCertificate: false, review: false, progressReportT: false , neurologicalTreatmentPlanT: false})
                break
            case "referral":
                this.setState({ diagnosisTab: false, history: false, examination: false, orthopedicTreatmentPlanT: false, spinalAndJointMobilisationTreatmentT: false, treatmentPlanForChestT: false, referral: true, medicalCertificate: false, review: false, progressReportT: false , neurologicalTreatmentPlanT: false})
                break
            case "medicalCertificate":
                this.setState({ diagnosisTab: false, history: false, examination: false, orthopedicTreatmentPlanT: false, spinalAndJointMobilisationTreatmentT: false, treatmentPlanForChestT: false, referral: false, medicalCertificate: true, review: false, progressReportT: false, neurologicalTreatmentPlanT: false })
                break
            case "review":
                this.setState({ diagnosisTab: false, history: false, examination: false, orthopedicTreatmentPlanT: false, spinalAndJointMobilisationTreatmentT: false, treatmentPlanForChestT: false, referral: false, medicalCertificate: false, review: true, progressReportT: false, neurologicalTreatmentPlanT: false })
                break
            case "progressReportT":
                this.setState({ diagnosisTab: false, history: false, examination: false, orthopedicTreatmentPlanT: false, spinalAndJointMobilisationTreatmentT: false, treatmentPlanForChestT: false, referral: false, medicalCertificate: false, review: false, progressReportT: true, neurologicalTreatmentPlanT: false })
                break
            default:
                this.setState({ diagnosisTab: false, history: true, examination: false, orthopedicTreatmentPlanT: false, spinalAndJointMobilisationTreatmentT: false, treatmentPlanForChestT: false, referral: false, medicalCertificate: false, review: false, progressReportT: false, neurologicalTreatmentPlanT: false, presentingComplaintsT: true, pediatricsHistoryT: false, developmentalMilestonesT: false })
                break

        }
    }
    addReviewTable(reviewDate, reviewTime) {
        let list = this.state.reviews.slice();
        list.push({ id: 0, reviewDate: reviewDate, reviewTime: reviewTime, remarks: '', activeStatus: 1 });
        this.setState({ reviews: list });
    }

    addMedicalCertificateTable() {
        let list = this.state.medicalCertificates.slice();
        list.push({ id: 0, name: '', startDate: date1, endDate: date1, activeStatus: 1 });
        this.setState({ medicalCertificates: list });
    }
    addOrthopedicTreatmentPlanTable() {
        let list = this.state.orthopedicTreatmentPlans.slice();
        list.push({ plan: '', remarks: '' });
        this.setState({ orthopedicTreatmentPlans: list });
    }
    addSpinalJointMobilizationTreatmentTable() {
        let list = this.state.spinalJointMobilizationTreatments.slice();
        list.push({ position: '', vertebraOrJoint: '', grade: '', repetition: '' });
        this.setState({ spinalJointMobilizationTreatments: list });
    }
    addReferralTable() {
        let list = this.state.referrals.slice();
        list.push({ id: 0, consultant: '', referralNotes: '', activeStatus: 1 });
        this.setState({ referrals: list });
    }
    addSpineAndPeripheralJoint() {
        let list = this.state.spineAndPeripheralJoints.slice();
        list.push({ signsAndSymptoms: '', value: '', painType: '', painIndex: '' });
        this.setState({ spineAndPeripheralJoints: list });
    }
    addSpineAssessment() {
        let list = this.state.spineAssessments.slice();
        list.push({ remarks: '', vertebra: '', palpitationFinding: '', position: '' });
        this.setState({ spineAssessments: list });
    }
    deleteReview(index) {
        let list = this.state.reviews.slice();
        list[index].activeStatus = 0
        this.setState({ reviews: list });
    }

    deleteMedicalCertificate(index) {
        let list = this.state.medicalCertificates.slice();
        list[index].activeStatus = 0
        this.setState({ medicalCertificates: list });
    }
    deleteSpinalJointMobilizationTreatment(index) {
        let list = this.state.spinalJointMobilizationTreatments.slice();
        list[index].splice(index, 1); 
        this.setState({ spinalJointMobilizationTreatments: list });
    }
    deleteOrthopedicTreatmentPlan(index) {
        let list = this.state.orthopedicTreatmentPlans.slice();
        list[index].splice(index, 1); 
        this.setState({ orthopedicTreatmentPlans: list });
    }
    deleteReferral(index) {
        let list = this.state.referrals.slice();
        list[index].activeStatus = 0
        this.setState({ referrals: list });
    }
    deleteSpineAndPeripheralJoint(index) {
        let list = this.state.spineAndPeripheralJoints.slice();
        list[index].splice(index, 1); 
        this.setState({ spineAndPeripheralJoints: list });
    }
    deleteSpineAssessment(index) {
        let list = this.state.spineAssessments.slice();
        list[index].splice(index, 1); 
        this.setState({ spineAssessments: list });
    }
    setReview(index, name, value) {
        let list = this.state.reviews.slice();
        switch (name) {
            case "reviewDate":
                list[index].reviewDate = value
                break;
            default:
                list[index].remarks = value
                break;
        }
        this.setState({ reviews: list });
    }

    setMedicalCertificate(index, name, value) {
        let list = this.state.medicalCertificates.slice();
        switch (name) {
            case "name":
                list[index].name = value
                break;
            case "startDate":
                list[index].startDate = value
                break;
            default:
                list[index].endDate = value
                break;
        }
        this.setState({ medicalCertificates: list });
    }
    setSpineAndPeripheralJoint(index, name, value) {
        let list = this.state.spineAndPeripheralJoints.slice();
        switch (name) {
            case "signsAndSymptoms":
                list[index].signsAndSymptoms = value
                break;
            case "value":
                list[index].value = value
                break;
            case "painType":
                list[index].painType = value
                break;
            default:
                list[index].painIndex = value
                break;
        }
        this.setState({ spineAndPeripheralJoints: list });
    }
    setSpineAssessment(index, name, value) {
        let list = this.state.spineAssessments.slice();
        switch (name) {
            case "remarks":
                list[index].remarks = value
                break;
            case "vertebra":
                list[index].vertebra = value
                break;
            case "palpitationFinding":
                list[index].palpitationFinding = value
                break;
            default:
                list[index].position = value
                break;
        }
        this.setState({ spineAssessments: list });
    }
    setReferral(index, name, value) {
        let list = this.state.referrals.slice();
        switch (name) {
            case "consultant":
                list[index].consultant = value
                break;
            default:
                list[index].referralNotes = value
                break;
        }
        this.setState({ referrals: list });
    }
    setSpinalJointMobilizationTreatment(index, name, value) {
        let list = this.state.spinalJointMobilizationTreatments.slice();
        switch (name) {
            case "position":
                list[index].position = value
                break;
            case "vertebraOrJoint":
                list[index].vertebraOrJoint = value
                break;
            case "grade":
                list[index].grade = value
                break;
            default:
                list[index].repetition = value
                break;
        }
        this.setState({ spinalJointMobilizationTreatments: list });
    }
    setOrthopedicTreatmentPlan(index, name, value) {
        let list = this.state.orthopedicTreatmentPlans.slice();
        switch (name) {
            case "plan":
                list[index].plan = value
                break;
            default:
                list[index].remarks = value
                break;
        }
        this.setState({ orthopedicTreatmentPlans: list });
    }
    toggleHistoryTabs(name) {
        switch (name) {
            case "presentingComplaintsT":
                this.setState({ presentingComplaintsT: true, pediatricsHistoryT: false, developmentalMilestonesT: false })
                break
            case "pediatricsHistoryT":
                this.setState({ presentingComplaintsT: false, pediatricsHistoryT: true, developmentalMilestonesT: false })
                break
            default:
                this.setState({ presentingComplaintsT: false, pediatricsHistoryT: false, developmentalMilestonesT: true })
                break

        }
    }
    toggleExaminationTabs(name) {
        switch (name) {
            case "spineAndPeripheralJointT":
                this.setState({ spineAndPeripheralJointT: true, spineAssessmentT: false, muscleStrengthT: false, chestAssessmentT: false, generalAssessmentT: false, otherAssessmentT: false, mobilityExaminationT: false, schoolAssessmentT: false, workAssessmentT: false, adlAssessmentT: false, therapistAssessmentT: false, goalAssessmentT: false })
                break
            case "spineAssessmentT":
                this.setState({ spineAndPeripheralJointT: false, spineAssessmentT: true, muscleStrengthT: false, chestAssessmentT: false, generalAssessmentT: false, otherAssessmentT: false, mobilityExaminationT: false, schoolAssessmentT: false, workAssessmentT: false, adlAssessmentT: false, therapistAssessmentT: false, goalAssessmentT: false})
                break
            case "muscleStrengthT":
                this.setState({ spineAndPeripheralJointT: false, spineAssessmentT: false, muscleStrengthT: true, chestAssessmentT: false, generalAssessmentT: false, otherAssessmentT: false, mobilityExaminationT: false, schoolAssessmentT: false, workAssessmentT: false, adlAssessmentT: false, therapistAssessmentT: false, goalAssessmentT: false})
                break
            case "chestAssessmentT":
                this.setState({ spineAndPeripheralJointT: false, spineAssessmentT: false, muscleStrengthT: false, chestAssessmentT: true, generalAssessmentT: false, otherAssessmentT: false, mobilityExaminationT: false, schoolAssessmentT: false, workAssessmentT: false, adlAssessmentT: false, therapistAssessmentT: false, goalAssessmentT: false})
                break
            case "generalAssessmentT":
                this.setState({ spineAndPeripheralJointT: false, spineAssessmentT: false, muscleStrengthT: false, chestAssessmentT: false, generalAssessmentT: true, otherAssessmentT: false, mobilityExaminationT: false, schoolAssessmentT: false, workAssessmentT: false, adlAssessmentT: false, therapistAssessmentT: false, goalAssessmentT: false })
                break
            case "otherAssessmentT":
                this.setState({ spineAndPeripheralJointT: false, spineAssessmentT: false, muscleStrengthT: false, chestAssessmentT: false, generalAssessmentT: false, otherAssessmentT: true, mobilityExaminationT: false, schoolAssessmentT: false, workAssessmentT: false, adlAssessmentT: false, therapistAssessmentT: false, goalAssessmentT: false })
                break
            case "mobilityExaminationT":
                this.setState({ spineAndPeripheralJointT: false, spineAssessmentT: false, muscleStrengthT: false, chestAssessmentT: false, generalAssessmentT: false, otherAssessmentT: false, mobilityExaminationT: true, schoolAssessmentT: false, workAssessmentT: false, adlAssessmentT: false, therapistAssessmentT: false, goalAssessmentT: false })
                break
            case "schoolAssessmentT":
                this.setState({ spineAndPeripheralJointT: false, spineAssessmentT: false, muscleStrengthT: false, chestAssessmentT: false, generalAssessmentT: false, otherAssessmentT: false, mobilityExaminationT: false, schoolAssessmentT: true, workAssessmentT: false, adlAssessmentT: false, therapistAssessmentT: false, goalAssessmentT: false })
                break
            case "workAssessmentT":
                this.setState({ spineAndPeripheralJointT: false, spineAssessmentT: false, muscleStrengthT: false, chestAssessmentT: false, generalAssessmentT: false, otherAssessmentT: false, mobilityExaminationT: false, schoolAssessmentT: false, workAssessmentT: true, adlAssessmentT: false, therapistAssessmentT: false, goalAssessmentT: false })
                break
            case "adlAssessmentT":
                this.setState({ spineAndPeripheralJointT: false, spineAssessmentT: false, muscleStrengthT: false, chestAssessmentT: false, generalAssessmentT: false, otherAssessmentT: false, mobilityExaminationT: false, schoolAssessmentT: false, workAssessmentT: false, adlAssessmentT: true, therapistAssessmentT: false, goalAssessmentT: false })
                break
            case "therapistAssessmentT":
                this.setState({ spineAndPeripheralJointT: false, spineAssessmentT: false, muscleStrengthT: false, chestAssessmentT: false, generalAssessmentT: false, otherAssessmentT: false, mobilityExaminationT: false, schoolAssessmentT: false, workAssessmentT: false, adlAssessmentT: false, therapistAssessmentT: true, goalAssessmentT: false })
                break
            default:
                this.setState({ spineAndPeripheralJointT: false, spineAssessmentT: false, muscleStrengthT: false, chestAssessmentT: false, generalAssessmentT: false, otherAssessmentT: false, mobilityExaminationT: false, schoolAssessmentT: false, workAssessmentT: false, adlAssessmentT: false, therapistAssessmentT: false, goalAssessmentT: true })
                break

        }
    }
    getDiagnosisList() {
        this.setState({ diagnosisList: [], diagnosis: '' })
        console.log('..... i am ')
        if (this.state.searchDiagnosis !== null && this.state.searchDiagnosis !== "") {
            console.log('..... i am ')
            this.setLoader(true)
            axios.get(localStorage.getItem('host') + '/master/diagnosislist?text=' + this.state.searchDiagnosis, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            }).then(
                res => {
                    this.setState({ diagnosisList: res.data });
                    this.setLoader(false)
                }, err => {
                    this.setState({ error: err + "" })
                    this.setLoader(false)
                }
            )

        }
    }
    insertDiagnosis() {
        if (this.state.diagnosis !== null && this.state.diagnosis !== '') {
            let list = this.state.patientDiagnosis.slice();
            for (var i in this.state.diagnosisList) {
                if(this.state.diagnosisList[i].code === this.state.diagnosis)
                   {
                    list.push({ id: 0, code: this.state.diagnosis, description: this.state.diagnosis+" - "+this.state.diagnosisList[i].description, activeStatus: 1 });
                   }
            }
            this.setState({ patientDiagnosis: list });
        }

    }
    deleteDiagnosis(index) {
        let list = this.state.patientDiagnosis.slice();
        list[index].activeStatus = 0
        this.setState({ patientDiagnosis: list });
    }
    setFeedbackItem(index, value)
     {
        let list = this.state.dateLists.slice();
        list[index].checked = value
        this.setState({ dateLists: list }); 
     }
    updateAfterSave(visitId, results) {
        if (results.length > 0) {
            this.setState({ results: results })
        }
        else {
            axios.get(localStorage.getItem('host') + '/patientmanagement/rehabnursetrimmed/' + visitId, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            }).then(
                res => {
                    this.setState({ weight: res.data.weight, height: res.data.height, waist: res.data.waist, headCircumference: res.data.headCircumference, muac: res.data.muac, bmi: res.data.bmi, waistHeightRatio: res.data.waistHeightRatio, nurseNotes: res.data.nurseNotes, vitalColors: res.data.vitalColors });
                }, err => {
                    this.setState({ error: err + "" })
                }
            )
        }
    }
    openGraphAction(type) {
        this.setState({ line: { labels: [], datasets: [], }, lineName: type === "HEIGHT" ? "Height Graph" : "Weight Graph" })
        this.setLoader(true)
        axios.get(localStorage.getItem('host') + '/patientmanagement/getgraphdata/' + this.state.patientId + '/' + type, {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ line: res.data });
                document.getElementById('graphPopup').style = 'display:block'
                this.setLoader(false)
            }, err => {
                this.setState({ error: err + "" })
            }
        )
    }
    graphClosePopup() {
        this.setState({ line: { labels: [], datasets: [], }, lineName: '' });
        document.getElementById('graphPopup').style = 'display:none'
    }
    showHideFromCheckBox(checked, id) {
        document.getElementById(id).style = 'display:' + (checked === true ? 'block' : 'none')
    }
    insertPresentingComplaintList()
    {
        let list = this.state.presentingComplaintList.slice();
        list.push({complaint: '', duration: ''})
        this.setState({ presentingComplaintList: list });  
    }
    setPresentingComplaintList(index, name, value) {
        let list = this.state.presentingComplaintList.slice();
        switch (name) {
            case "complaint":
                list[index].complaint = value
                break;
            default:
                list[index].duration = value
                break;
        }
        this.setState({ presentingComplaintList: list });
    }
    deletePresentingComplaint(i)
    {
    let list = this.state.presentingComplaintList.slice();   
    list.splice(i, 1);
    this.setState({ presentingComplaintList: list }); 
    }
    setLoader(loader)
      {
       this.setState({loader: loader})
      }
      getReviewTime(reviewTime)
         {
          var time = ''
          for(var j in this.state.appointmentTimes)
          {
           if(Number(reviewTime) === Number(this.state.appointmentTimes[j].id))
              {
               time = this.state.appointmentTimes[j].name
              }
          }
          return time
         }   
    render() {
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        } //v-bind:style="'height: '+heightDuplicateRecords+'px;max-height: '+heightDuplicateRecords+'px;overflow: auto;overflow-x: hidden;'"
        if (this.state.error) {
            divMsg = (<div className='alert alert-danger'>{this.state.error}</div>)
        }
        let loaderDiv = ''
        if(this.state.loader)
           {
            loaderDiv = (  <div id="loader" className="loader-overlay"><div align="center" className="loader"></div></div>)
           }
        return (<div id="rehabAction" className="page-popup page-popup-overlay"   ><div className="card" >
            <div className="card-header  bg-info" id="topDiv">Rehab Action</div>
            <div className="card-body" >
                {divMsg}
                {loaderDiv}
                <div id="genericPopup" className="page-popup page-popup-overlay" >
                    <div className="card" >
                        <div className="card-header  bg-info">{this.state.popupName}</div>
                        <div className="card-body" >
                            <span dangerouslySetInnerHTML={{ __html: this.state.htmlText }}></span>
                            <div className="row">
                                <div className="col" align="center" ><button onClick={() => this.genericClosePopup()} className="btn btn-primary">Close</button></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="showFileNurseAction" className="page-popup page-popup-overlay" >
                    <div className="card" >
                        <div className="card-header  bg-info">File View</div>
                        <div className="card-body" >
                        <iframe title="File View" src={this.state.fileData} type="application/pdf" style={{ width: 100 + '%', height: 500 + 'px' }}></iframe>
                            <div className="row">
                                <div className="col" align="center" ><button onClick={() => this.fileNurseClosePopup()} className="btn btn-primary">Close</button></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-2 col-md-2">
                        {this.state.id > 0 && (<div ><span className="link" onClick={() => this.props.getPrintOut(this.state.visitId, 'REHAB', [], this.state.companyId)} title="Print" ><i className="bi bi-printer-fill" style={{ fontSize: 3 + 'em' }}></i></span></div>)}
                        <div class="row">
                            <div class="col">
                                <ul class="nav nav-pills  d-flex flex-column" id="pills-tab" role="tablist">
                                    <li className="nav-item"><span className={this.state.history ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("history") }}>History</span></li>
                                    <li className="nav-item"><span className={this.state.examination ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("examination") }}>Examination</span></li>
                                    <li className="nav-item"><span className={this.state.diagnosisTab ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("diagnosis") }}>Diagnosis</span></li>
                                    <li className="nav-item"><span className={this.state.orthopedicTreatmentPlanT ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("orthopedicTreatmentPlanT") }}>Orthopedic Treatment Plan</span></li>
                                    <li className="nav-item"><span className={this.state.spinalAndJointMobilisationTreatmentT ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("spinalAndJointMobilisationTreatmentT") }}>Spinal / Joint Mobilization Treatment Position</span></li>
                                    <li className="nav-item"><span className={this.state.neurologicalTreatmentPlanT ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("neurologicalTreatmentPlanT") }}>Neurological Treatment Plan</span></li>
                                    <li className="nav-item"><span className={this.state.treatmentPlanForChestT ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("treatmentPlanForChestT") }}>Treatment Plan for Chest</span></li>
                                    <li className="nav-item"><span className={this.state.progressReportT ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("progressReportT") }}>Progress Report</span></li>
                                    <li className="nav-item"><span className={this.state.review ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("review") }}>Review</span></li>
                                    <li className="nav-item"><span className={this.state.medicalCertificate ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("medicalCertificate") }}>Medical Certificate</span></li>
                                    <li className="nav-item"><span className={this.state.referral ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("referral") }}>Referral</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 col-md-8">
                        <div className="card border-success">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col"><span className="fw-bold">Fullname:</span> {this.state.fullname}</div>
                                    <div className="col"><span className="fw-bold">Bill:</span> {this.state.idNumber}</div>
                                    <div className="col"><span className="fw-bold">Cell No:</span> {this.state.mobile}</div>
                                </div>
                                <div className="row">
                                    <div className="col"><span className="fw-bold">Gender:</span> {this.state.gender}</div>
                                    <div className="col"><span className="fw-bold">DOB:</span> {this.state.dateOfBirth}</div>
                                    <div className="col"><span className="fw-bold">Age:</span> <span dangerouslySetInnerHTML={{ __html: this.state.age }}></span></div>
                                </div>
                                <div className="row">
                                    <div className="col"><span className="fw-bold">Referring Doctor:</span> {this.state.referringDoctorName}</div>
                                    <div className="col"></div>
                                    <div className="col"></div>
                                </div>
                            </div>
                        </div>
                        <div className="card border-success">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col"><div className="input-group">
                                        <span className="input-group-text">Problems</span><textarea className="form-control" value={this.state.diagnosisText} onChange={e => { this.setState({ diagnosisText: e.target.value }) }} ></textarea> </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col" align="right"><button onClick={() => this.save()} className="btn btn-primary">Save</button></div>
                            <div className="col" align="left"><button onClick={() => this.closePopup()} className="btn btn-primary">Close</button></div>
                          { localStorage.getItem('nuero_data') !== null && localStorage.getItem('nuero_data') !== "" && localStorage.getItem('nuero_data').length > 5 &&( <div className="col" align="left"><button onClick={() => this.getFromLocalStorage()} className="btn btn-primary">Retrive From Temp</button></div>)}
                        </div>
                        <br />
                        <div className="tab-content" id="myTabContent">
                            <div className={this.state.diagnosisTab ? "tab-pane fade show active" : "tab-pane fade"} >
                                <br />
                                <div className="card card-blue">
                                    <div className="card-header  text-dark">
                                        Diagnosis
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-xs-12 col-md-12 col-lg-12">
                                                <a href="https://icd.who.int/browse10/2019/en" target="_blank" rel="noreferrer">Check Online</a>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col"><div className="input-group">
                                                <input type="text" value={this.state.searchDiagnosis} onChange={e => { this.setState({ searchDiagnosis: e.target.value }) }} className="form-control" placeholder="Search ICD10 Diagnosis Here" />
                                                <button className="btn btn-success" onClick={() => this.getDiagnosisList()} ><i class="bi bi-search"></i></button>
                                            </div>
                                            </div>
                                            </div>
                                    { this.state.diagnosisList.length > 0 &&(<div className="row">
                                        <div className="col"><div className="input-group"> 
                                        <select class="form-select" size={this.state.diagnosisList.length}  onChange={e => this.setState({ diagnosis: e.target.value })} aria-label="size 3 select example">
                                     <option value="">Choose Diagnosis</option>
                                        {this.state.diagnosisList.map((item, index) =>(  
  <option key={index} value={item.code}>{item.code} - {item.description}</option>
  ))}
</select><button onClick={() => this.insertDiagnosis()} className="btn btn-success" ><i class="bi bi-plus-circle-fill"></i></button></div></div>
                                    </div> )}
                                        <table className="table">
                                            <tr>
                                                <th>Description</th>
                                                <th>Active Status</th>
                                            </tr>
                                            {this.state.patientDiagnosis.map((item, index) => (item.activeStatus === 1 && (
                                                <tr>
                                                    <td>{item.description}</td>
                                                    <td><span className="link-red" onClick={() => this.deleteDiagnosis(index)} ><i className="bi bi-x-square-fill"></i></span></td>
                                                </tr>)))}
                                        </table>
                                    </div></div>
                            </div>
                            <div className={this.state.history ? "tab-pane fade show active" : "tab-pane fade"} >
                                <br />
                                <div className="card card-blue">
                                    <div className="card-header  text-dark">
                                        History
                                    </div>
                                    <div className="card-body">
                                        <ul className="nav nav-tabs">
                                            <li className="nav-item"><span className={this.state.presentingComplaintsT ? "nav-link active" : "nav-link"} onClick={() => { this.toggleHistoryTabs("presentingComplaintsT") }}>Presenting Complaints</span></li>
                                            <li className="nav-item"><span className={this.state.pediatricsHistoryT ? "nav-link active" : "nav-link"} onClick={() => { this.toggleHistoryTabs("pediatricsHistoryT") }}>Paediatrics History</span></li>
                                            <li className="nav-item"><span className={this.state.developmentalMilestonesT ? "nav-link active" : "nav-link"} onClick={() => { this.toggleHistoryTabs("developmentalMilestonesT") }}>Developmental Milestones</span></li>
                                       </ul>
                                        <br />
                                        <div className="tab-content">
                                            <div className={this.state.pediatricsHistoryT ? "tab-pane fade show active" : "tab-pane fade"}>
                                                <div className="row">
                                                    <div className="col"><div className="input-group">
                                                        <textarea className="form-control" value={this.state.pediatricsHistory} onChange={e => { this.setState({ pediatricsHistory: e.target.value }) }} ></textarea> </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={this.state.developmentalMilestonesT ? "tab-pane fade show active" : "tab-pane fade"}>
                                                <div className="row">
                                                    <div className="col"><div className="input-group">
                                                        <textarea className="form-control" value={this.state.developmentalMilestone} onChange={e => { this.setState({ developmentalMilestone: e.target.value }) }} ></textarea> </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={this.state.presentingComplaintsT ? "tab-pane fade show active" : "tab-pane fade"}>
                                                <div className="row">
                                                    <div ><div className="input-group">
                                                        <textarea className="form-control" value={this.state.presentingComplaints} onChange={e => { this.setState({ presentingComplaints: e.target.value }) }} ></textarea> </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={this.state.examination ? "tab-pane fade show active" : "tab-pane fade"}>
                                <br />
                                <div className="card card-blue">
                                    <div className="card-header  text-dark">
                                        Physical and General Examination
                                    </div>
                                    <div className="card-body">
                                    <ul className="nav nav-tabs">
                                            <li className="nav-item"><span className={this.state.spineAndPeripheralJointT ? "nav-link active" : "nav-link"} onClick={() => { this.toggleExaminationTabs("spineAndPeripheralJoint") }}>Spine and Peripheral Joint</span></li>
                                            <li className="nav-item"><span className={this.state.spineAssessmentT ? "nav-link active" : "nav-link"} onClick={() => { this.toggleExaminationTabs("spineAssessmentT") }}>Spine Assessment</span></li>
                                            <li className="nav-item"><span className={this.state.muscleStrengthT ? "nav-link active" : "nav-link"} onClick={() => { this.toggleExaminationTabs("muscleStrengthT") }}>Muscle Strength/ Join Assessment</span></li>
                                            <li className="nav-item"><span className={this.state.chestAssessmentT ? "nav-link active" : "nav-link"} onClick={() => { this.toggleExaminationTabs("chestAssessmentT") }}>Chest Assessment</span></li>
                                            <li className="nav-item"><span className={this.state.generalAssessmentT ? "nav-link active" : "nav-link"} onClick={() => { this.toggleExaminationTabs("generalAssessmentT") }}>General Assessment</span></li>
                                            <li className="nav-item"><span className={this.state.otherAssessmentT ? "nav-link active" : "nav-link"} onClick={() => { this.toggleExaminationTabs("otherAssessmentT") }}>Other Assessment</span></li>
                                            <li className="nav-item"><span className={this.state.mobilityExaminationT ? "nav-link active" : "nav-link"} onClick={() => { this.toggleExaminationTabs("mobilityExaminationT") }}>Mobility Examination</span></li>
                                            <li className="nav-item"><span className={this.state.schoolAssessmentT ? "nav-link active" : "nav-link"} onClick={() => { this.toggleExaminationTabs("schoolAssessmentT") }}>School Assessment</span></li>
                                            <li className="nav-item"><span className={this.state.workAssessmentT ? "nav-link active" : "nav-link"} onClick={() => { this.toggleExaminationTabs("workAssessmentT") }}>Work Assessment</span></li>
                                            <li className="nav-item"><span className={this.state.adlAssessmentT ? "nav-link active" : "nav-link"} onClick={() => { this.toggleExaminationTabs("adlAssessmentT") }}>ADL Assessment</span></li>
                                            <li className="nav-item"><span className={this.state.therapistAssessmentT ? "nav-link active" : "nav-link"} onClick={() => { this.toggleExaminationTabs("therapistAssessmentT") }}>Therapist Remarks</span></li>
                                            <li className="nav-item"><span className={this.state.goalAssessmentT ? "nav-link active" : "nav-link"} onClick={() => { this.toggleExaminationTabs("goalAssessmentT") }}>Goals</span></li>
                                       </ul>
                                       <br />
                                        <div className="tab-content">
                                        <div className={this.state.spineAndPeripheralJointT ? "tab-pane fade show active" : "tab-pane fade"}>
                                <br />
                                    <div className="table-responsive-lg"> 
                                        <table className="table" id="table">
                                            <tr>
                                                <th>Signs and Symptoms</th>
                                                <th>Value</th>
                                                <th>Pain Type</th>
                                                <th>Pain Index</th>
                                                <th>Action</th>
                                            </tr>
                                            <tbody>
                                                {this.state.spineAndPeripheralJoints.map((item, index) =>  (
                                                    <tr key={index}>  
                                                        <td><input type="text" className="form-control" value={item.signsAndSymptoms} onChange={e => { this.setSpineAndPeripheralJoint(index, 'signsAndSymptoms', e.target.value); }} /></td>                                                     
                                                       <td><input type="text" className="form-control" value={item.value} onChange={e => { this.setSpineAndPeripheralJoint(index, 'value', e.target.value); }} /></td>
                                                       <td><input type="text" className="form-control" value={item.painType} onChange={e => { this.setSpineAndPeripheralJoint(index, 'painType', e.target.value); }} /></td>
                                                        <td><input type="text" className="form-control" value={item.painIndex} onChange={e => { this.setSpineAndPeripheralJoint(index, 'painIndex', e.target.value); }} /></td>
                                                        <td><span className="link-red" onClick={() => this.deleteSpineAndPeripheralJoint(index)} ><i className="bi bi-x-square-fill"></i></span></td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        </div>
                                        <button onClick={() => this.addSpineAndPeripheralJoint()} className="btn btn-primary" >Insert New Line</button>
                                
                            </div>
                            <div className={this.state.spineAssessmentT ? "tab-pane fade show active" : "tab-pane fade"}>
                                <br />
                                    <div className="table-responsive-lg"> 
                                        <table className="table" id="table">
                                            <tr>
                                                <th>Remarks</th>
                                                <th>Vertebra</th>
                                                <th>Palpitation Funding</th>
                                                <th>Position</th>
                                                <th>Action</th>
                                            </tr>
                                            <tbody>
                                                {this.state.spineAssessments.map((item, index) =>  (
                                                    <tr key={index}>  
                                                        <td><input type="text" className="form-control" value={item.remarks} onChange={e => { this.setSpineAssessment(index, 'remarks', e.target.value); }} /></td>                                                     
                                                       <td><input type="text" className="form-control" value={item.vertebra} onChange={e => { this.setSpineAssessment(index, 'vertebra', e.target.value); }} /></td>
                                                       <td><input type="text" className="form-control" value={item.palpitationFinding} onChange={e => { this.setSpineAssessment(index, 'palpitationFinding', e.target.value); }} /></td>
                                                        <td><input type="text" className="form-control" value={item.position} onChange={e => { this.setSpineAssessment(index, 'position', e.target.value); }} /></td>
                                                        <td><span className="link-red" onClick={() => this.deleteSpineAssessment(index)} ><i className="bi bi-x-square-fill"></i></span></td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        </div>
                                        <button onClick={() => this.addSpineAssessment()} className="btn btn-primary" >Insert New Line</button>
                            </div>
                             <div className={this.state.muscleStrengthT ? "tab-pane fade show active" : "tab-pane fade"}>
                                                <div className="row">
                                                    <div className="col"><div className="input-group">
                                                        <textarea className="form-control" value={this.state.muscleStrengthOrJointAssessment} onInput={e => { this.setState({ muscleStrengthOrJointAssessment: e.target.value }) }} rows="10"></textarea> </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={this.state.chestAssessmentT ? "tab-pane fade show active" : "tab-pane fade"}>
                                                <div className="row">
                                                    <div className="col"><div className="input-group">
                                                        <textarea className="form-control" value={this.state.chestAssessment} onInput={e => { this.setState({ chestAssessment: e.target.value }) }} rows="10"></textarea> </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={this.state.generalAssessmentT ? "tab-pane fade show active" : "tab-pane fade"}>
                                            <div className="row">
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Vision</span><input type="text" value={this.state.vision} onChange={e => this.setState({ vision: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Hearing</span><input type="text" value={this.state.hearing} onChange={e => this.setState({ hearing: e.target.value })} className="form-control" /></div></div>
                                             </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Comprehension</span><input type="text" value={this.state.comprehension} onChange={e => this.setState({ comprehension: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Communication</span><input type="text" value={this.state.communication} onChange={e => this.setState({ communication: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Bladder Bowels</span><input type="text" value={this.state.bladderBowels} onChange={e => this.setState({ bladderBowels: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Respiratory</span><input type="text" value={this.state.respiratory} onChange={e => this.setState({ respiratory: e.target.value })} className="form-control" /></div></div>
                                             </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Skin Condition</span><input type="text" value={this.state.skinCondition} onChange={e => this.setState({ skinCondition: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"></div>
                                                </div>
                                                <div className="row">
                                                <div className="col">Place</div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.place === "oriented"} onChange={e => this.setState({ place: e.target.checked === true ? "oriented" : "disoriented" })} /><span className="input-group-text">Oriented</span></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.place === "disoriented"} onChange={e => this.setState({ place: e.target.checked === true ? "disoriented" : "oriented" })} /><span className="input-group-text">Disoriented</span></div></div>
                                                </div>
                                                <div className="row">
                                                <div className="col">Time</div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.time === "oriented"} onChange={e => this.setState({ time: e.target.checked === true ? "oriented" : "disoriented" })} /><span className="input-group-text">Oriented</span></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.time === "disoriented"} onChange={e => this.setState({ time: e.target.checked === true ? "disoriented" : "oriented" })} /><span className="input-group-text">Disoriented</span></div></div>
                                                </div>
                                                <div className="row">
                                                <div className="col">Person</div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.person === "oriented"} onChange={e => this.setState({ person: e.target.checked === true ? "oriented" : "disoriented" })} /><span className="input-group-text">Oriented</span></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.person === "disoriented"} onChange={e => this.setState({ person: e.target.checked === true ? "disoriented" : "oriented" })} /><span className="input-group-text">Disoriented</span></div></div>
                                                </div>
                                            </div>    

                                            <div className={this.state.otherAssessmentT ? "tab-pane fade show active" : "tab-pane fade"}>
                                            <div className="row">
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Sensation</span><input type="text" value={this.state.sensation} onChange={e => this.setState({ sensation: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Coordination</span><input type="text" value={this.state.coordination} onChange={e => this.setState({ coordination: e.target.value })} className="form-control" /></div></div>
                                             </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Proprioception</span><input type="text" value={this.state.proprioception} onChange={e => this.setState({ proprioception: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Fatigue</span><input type="text" value={this.state.fatigue} onChange={e => this.setState({ fatigue: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Balance</span><input type="text" value={this.state.balance} onChange={e => this.setState({ balance: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Posture</span><input type="text" value={this.state.posture} onChange={e => this.setState({ posture: e.target.value })} className="form-control" /></div></div>
                                             </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Hand Function</span><input type="text" value={this.state.handFunction} onChange={e => this.setState({ handFunction: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Steregnosis</span><input type="text" value={this.state.steregnosis} onChange={e => this.setState({ steregnosis: e.target.value })} className="form-control" /></div></div>
                                             </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Memory</span><input type="text" value={this.state.memory} onChange={e => this.setState({ memory: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Body Image</span><input type="text" value={this.state.bodyImage} onChange={e => this.setState({ bodyImage: e.target.value })} className="form-control" /></div></div>
                                             </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Apraxia</span><input type="text" value={this.state.apraxia} onChange={e => this.setState({ apraxia: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Figure Ground</span><input type="text" value={this.state.figureGround} onChange={e => this.setState({ figureGround: e.target.value })} className="form-control" /></div></div>
                                             </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Form Construction</span><input type="text" value={this.state.formConstruction} onChange={e => this.setState({ formConstruction: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Position in Space</span><input type="text" value={this.state.positionInSpace} onChange={e => this.setState({ positionInSpace: e.target.value })} className="form-control" /></div></div>
                                             </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Spatial Relationships</span><input type="text" value={this.state.spatialRelationships} onChange={e => this.setState({ spatialRelationships: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Visual Agnosia</span><input type="text" value={this.state.visualAgnosia} onChange={e => this.setState({ visualAgnosia: e.target.value })} className="form-control" /></div></div>
                                             </div>
                                            </div>
                                            <div className={this.state.mobilityExaminationT ? "tab-pane fade show active" : "tab-pane fade"}>
                                <br />
                                    <div className="table-responsive-lg"> 
                                        <table className="table" id="table">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>Complete Dependency</th>
                                                <th>Maximum Assistance</th>
                                                <th>Moderate Assistance</th>
                                                <th>Minimal Assistance</th>
                                                <th>Required Supervision</th>
                                                <th>Modified</th>
                                                <th>Normal</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                                    <tr >  
                                                        <th>Rolling Right</th>
                                                        <td><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.rollingRight === "Complete Dependency"} onChange={e => this.setState({ rollingRight: e.target.checked === true ? "Complete Dependency" : "" })} /></td>                                                     
                                                        <td><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.rollingRight === "Maximum Assistance"} onChange={e => this.setState({ rollingRight: e.target.checked === true ? "Maximum Assistance" : "" })} /></td>
                                                        <td><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.rollingRight === "Moderate Assistance"} onChange={e => this.setState({ rollingRight: e.target.checked === true ? "Moderate Assistance" : "" })} /></td>
                                                        <td><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.rollingRight === "Minimal Assistance"} onChange={e => this.setState({ rollingRight: e.target.checked === true ? "Minimal Assistance" : "" })} /></td>
                                                        <td><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.rollingRight === "Required Supervision"} onChange={e => this.setState({ rollingRight: e.target.checked === true ? "Required Supervision" : "" })} /></td>
                                                        <td><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.rollingRight === "Modified"} onChange={e => this.setState({ rollingRight: e.target.checked === true ? "Modified" : "" })} /></td>
                                                        <td><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.rollingRight === "Normal"} onChange={e => this.setState({ rollingRight: e.target.checked === true ? "Normal" : "" })} /></td>
                                                    </tr>
                                                    <tr >  
                                                        <th>Rolling Left</th>
                                                        <td><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.rollingLeft === "Complete Dependency"} onChange={e => this.setState({ rollingLeft: e.target.checked === true ? "Complete Dependency" : "" })} /></td>                                                     
                                                        <td><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.rollingLeft === "Maximum Assistance"} onChange={e => this.setState({ rollingLeft: e.target.checked === true ? "Maximum Assistance" : "" })} /></td>
                                                        <td><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.rollingLeft === "Moderate Assistance"} onChange={e => this.setState({ rollingLeft: e.target.checked === true ? "Moderate Assistance" : "" })} /></td>
                                                        <td><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.rollingLeft === "Minimal Assistance"} onChange={e => this.setState({ rollingLeft: e.target.checked === true ? "Minimal Assistance" : "" })} /></td>
                                                        <td><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.rollingLeft === "Required Supervision"} onChange={e => this.setState({ rollingLeft: e.target.checked === true ? "Required Supervision" : "" })} /></td>
                                                        <td><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.rollingLeft === "Modified"} onChange={e => this.setState({ rollingLeft: e.target.checked === true ? "Modified" : "" })} /></td>
                                                        <td><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.rollingLeft === "Normal"} onChange={e => this.setState({ rollingLeft: e.target.checked === true ? "Normal" : "" })} /></td>
                                                    </tr>
                                                    <tr >  
                                                        <th>Bridging</th>
                                                        <td><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.bridging === "Complete Dependency"} onChange={e => this.setState({ bridging: e.target.checked === true ? "Complete Dependency" : "" })} /></td>                                                     
                                                        <td><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.bridging === "Maximum Assistance"} onChange={e => this.setState({ bridging: e.target.checked === true ? "Maximum Assistance" : "" })} /></td>
                                                        <td><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.bridging === "Moderate Assistance"} onChange={e => this.setState({ bridging: e.target.checked === true ? "Moderate Assistance" : "" })} /></td>
                                                        <td><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.bridging === "Minimal Assistance"} onChange={e => this.setState({ bridging: e.target.checked === true ? "Minimal Assistance" : "" })} /></td>
                                                        <td><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.bridging === "Required Supervision"} onChange={e => this.setState({ bridging: e.target.checked === true ? "Required Supervision" : "" })} /></td>
                                                        <td><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.bridging === "Modified"} onChange={e => this.setState({ bridging: e.target.checked === true ? "Modified" : "" })} /></td>
                                                        <td><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.bridging === "Normal"} onChange={e => this.setState({ bridging: e.target.checked === true ? "Normal" : "" })} /></td>
                                                    </tr>
                                                    <tr >  
                                                        <th>Sitting Balance</th>
                                                        <td><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.sittingBalance === "Complete Dependency"} onChange={e => this.setState({ sittingBalance: e.target.checked === true ? "Complete Dependency" : "" })} /></td>                                                     
                                                        <td><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.sittingBalance === "Maximum Assistance"} onChange={e => this.setState({ sittingBalance: e.target.checked === true ? "Maximum Assistance" : "" })} /></td>
                                                        <td><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.sittingBalance === "Moderate Assistance"} onChange={e => this.setState({ sittingBalance: e.target.checked === true ? "Moderate Assistance" : "" })} /></td>
                                                        <td><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.sittingBalance === "Minimal Assistance"} onChange={e => this.setState({ sittingBalance: e.target.checked === true ? "Minimal Assistance" : "" })} /></td>
                                                        <td><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.sittingBalance === "Required Supervision"} onChange={e => this.setState({ sittingBalance: e.target.checked === true ? "Required Supervision" : "" })} /></td>
                                                        <td><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.sittingBalance === "Modified"} onChange={e => this.setState({ sittingBalance: e.target.checked === true ? "Modified" : "" })} /></td>
                                                        <td><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.sittingBalance === "Normal"} onChange={e => this.setState({ sittingBalance: e.target.checked === true ? "Normal" : "" })} /></td>
                                                    </tr>
                                                    <tr >  
                                                        <th>Sitting To Supine</th>
                                                        <td><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.sittingToSupine === "Complete Dependency"} onChange={e => this.setState({ sittingToSupine: e.target.checked === true ? "Complete Dependency" : "" })} /></td>                                                     
                                                        <td><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.sittingToSupine === "Maximum Assistance"} onChange={e => this.setState({ sittingToSupine: e.target.checked === true ? "Maximum Assistance" : "" })} /></td>
                                                        <td><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.sittingToSupine === "Moderate Assistance"} onChange={e => this.setState({ sittingToSupine: e.target.checked === true ? "Moderate Assistance" : "" })} /></td>
                                                        <td><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.sittingToSupine === "Minimal Assistance"} onChange={e => this.setState({ sittingToSupine: e.target.checked === true ? "Minimal Assistance" : "" })} /></td>
                                                        <td><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.sittingToSupine === "Required Supervision"} onChange={e => this.setState({ sittingToSupine: e.target.checked === true ? "Required Supervision" : "" })} /></td>
                                                        <td><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.sittingToSupine === "Modified"} onChange={e => this.setState({ sittingToSupine: e.target.checked === true ? "Modified" : "" })} /></td>
                                                        <td><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.sittingToSupine === "Normal"} onChange={e => this.setState({ sittingToSupine: e.target.checked === true ? "Normal" : "" })} /></td>
                                                    </tr>
                                                    <tr >  
                                                        <th>4 Point Kneeling</th>
                                                        <td><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.fourPointKneeling === "Complete Dependency"} onChange={e => this.setState({ fourPointKneeling: e.target.checked === true ? "Complete Dependency" : "" })} /></td>                                                     
                                                        <td><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.fourPointKneeling === "Maximum Assistance"} onChange={e => this.setState({ fourPointKneeling: e.target.checked === true ? "Maximum Assistance" : "" })} /></td>
                                                        <td><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.fourPointKneeling === "Moderate Assistance"} onChange={e => this.setState({ fourPointKneeling: e.target.checked === true ? "Moderate Assistance" : "" })} /></td>
                                                        <td><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.fourPointKneeling === "Minimal Assistance"} onChange={e => this.setState({ fourPointKneeling: e.target.checked === true ? "Minimal Assistance" : "" })} /></td>
                                                        <td><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.fourPointKneeling === "Required Supervision"} onChange={e => this.setState({ fourPointKneeling: e.target.checked === true ? "Required Supervision" : "" })} /></td>
                                                        <td><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.fourPointKneeling === "Modified"} onChange={e => this.setState({ fourPointKneeling: e.target.checked === true ? "Modified" : "" })} /></td>
                                                        <td><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.fourPointKneeling === "Normal"} onChange={e => this.setState({ fourPointKneeling: e.target.checked === true ? "Normal" : "" })} /></td>
                                                    </tr>
                                                    <tr >  
                                                        <th>2 Point Kneeling</th>
                                                        <td><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.twoPointKneeling === "Complete Dependency"} onChange={e => this.setState({ twoPointKneeling: e.target.checked === true ? "Complete Dependency" : "" })} /></td>                                                     
                                                        <td><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.twoPointKneeling === "Maximum Assistance"} onChange={e => this.setState({ twoPointKneeling: e.target.checked === true ? "Maximum Assistance" : "" })} /></td>
                                                        <td><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.twoPointKneeling === "Moderate Assistance"} onChange={e => this.setState({ twoPointKneeling: e.target.checked === true ? "Moderate Assistance" : "" })} /></td>
                                                        <td><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.twoPointKneeling === "Minimal Assistance"} onChange={e => this.setState({ twoPointKneeling: e.target.checked === true ? "Minimal Assistance" : "" })} /></td>
                                                        <td><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.twoPointKneeling === "Required Supervision"} onChange={e => this.setState({ twoPointKneeling: e.target.checked === true ? "Required Supervision" : "" })} /></td>
                                                        <td><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.twoPointKneeling === "Modified"} onChange={e => this.setState({ twoPointKneeling: e.target.checked === true ? "Modified" : "" })} /></td>
                                                        <td><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.twoPointKneeling === "Normal"} onChange={e => this.setState({ twoPointKneeling: e.target.checked === true ? "Normal" : "" })} /></td>
                                                    </tr>
                                                    <tr >  
                                                        <th>Sit to Stand</th>
                                                        <td><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.sitToStand === "Complete Dependency"} onChange={e => this.setState({ sitToStand: e.target.checked === true ? "Complete Dependency" : "" })} /></td>                                                     
                                                        <td><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.sitToStand === "Maximum Assistance"} onChange={e => this.setState({ sitToStand: e.target.checked === true ? "Maximum Assistance" : "" })} /></td>
                                                        <td><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.sitToStand === "Moderate Assistance"} onChange={e => this.setState({ sitToStand: e.target.checked === true ? "Moderate Assistance" : "" })} /></td>
                                                        <td><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.sitToStand === "Minimal Assistance"} onChange={e => this.setState({ sitToStand: e.target.checked === true ? "Minimal Assistance" : "" })} /></td>
                                                        <td><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.sitToStand === "Required Supervision"} onChange={e => this.setState({ sitToStand: e.target.checked === true ? "Required Supervision" : "" })} /></td>
                                                        <td><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.sitToStand === "Modified"} onChange={e => this.setState({ sitToStand: e.target.checked === true ? "Modified" : "" })} /></td>
                                                        <td><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.sitToStand === "Normal"} onChange={e => this.setState({ sitToStand: e.target.checked === true ? "Normal" : "" })} /></td>
                                                    </tr>
                                                    <tr >  
                                                        <th>Standing Balance</th>
                                                        <td><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.standingBalance === "Complete Dependency"} onChange={e => this.setState({ standingBalance: e.target.checked === true ? "Complete Dependency" : "" })} /></td>                                                     
                                                        <td><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.standingBalance === "Maximum Assistance"} onChange={e => this.setState({ standingBalance: e.target.checked === true ? "Maximum Assistance" : "" })} /></td>
                                                        <td><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.standingBalance === "Moderate Assistance"} onChange={e => this.setState({ standingBalance: e.target.checked === true ? "Moderate Assistance" : "" })} /></td>
                                                        <td><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.standingBalance === "Minimal Assistance"} onChange={e => this.setState({ standingBalance: e.target.checked === true ? "Minimal Assistance" : "" })} /></td>
                                                        <td><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.standingBalance === "Required Supervision"} onChange={e => this.setState({ standingBalance: e.target.checked === true ? "Required Supervision" : "" })} /></td>
                                                        <td><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.standingBalance === "Modified"} onChange={e => this.setState({ standingBalance: e.target.checked === true ? "Modified" : "" })} /></td>
                                                        <td><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.standingBalance === "Normal"} onChange={e => this.setState({ standingBalance: e.target.checked === true ? "Normal" : "" })} /></td>
                                                    </tr>
                                            </tbody>
                                        </table>
                                        </div>
                            </div>
                                            <div className={this.state.schoolAssessmentT ? "tab-pane fade show active" : "tab-pane fade"}>
                                                <div className="row">
                                                    <div className="col"><div className="input-group">
                                                        <textarea className="form-control" value={this.state.schoolAssessment} onInput={e => { this.setState({ schoolAssessment: e.target.value }) }} rows="10"></textarea> </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={this.state.workAssessmentT ? "tab-pane fade show active" : "tab-pane fade"}>
                                                <div className="row">
                                                    <div className="col"><div className="input-group">
                                                        <textarea className="form-control" value={this.state.workAssessment} onInput={e => { this.setState({ workAssessment: e.target.value }) }} rows="10"></textarea> </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={this.state.adlAssessmentT ? "tab-pane fade show active" : "tab-pane fade"}>
                                                <div className="row">
                                                    <div className="col"><div className="input-group">
                                                        <textarea className="form-control" value={this.state.adlAssessment} onInput={e => { this.setState({ adlAssessment: e.target.value }) }} rows="10"></textarea> </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={this.state.therapistAssessmentT ? "tab-pane fade show active" : "tab-pane fade"}>
                                                <div className="row">
                                                    <div className="col"><div className="input-group">
                                                        <textarea className="form-control" value={this.state.therapistAssessment} onInput={e => { this.setState({ therapistAssessment: e.target.value }) }} rows="10"></textarea> </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={this.state.goalAssessmentT ? "tab-pane fade show active" : "tab-pane fade"}>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><span className="input-group-text"> Short Term Goals</span>
                                                        <textarea className="form-control" value={this.state.shortTerm} onInput={e => { this.setState({ shortTerm: e.target.value }) }} rows="10"></textarea> </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><span className="input-group-text"> Long Term Goals</span>
                                                        <textarea className="form-control" value={this.state.longTerm} onInput={e => { this.setState({ longTerm: e.target.value }) }} rows="10"></textarea> </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>                                       
                                       </div>
                                    </div>
                            </div>
                            <div className={this.state.neurologicalTreatmentPlanT ? "tab-pane fade show active" : "tab-pane fade"} >
  <br />
  <div className="card  card-blue">
                                    <div className="card-header">Neurological Treatment Plan</div>
                                    <div className="card-body">
        <div className="row">
                                                    <div className="col"><div className="input-group">
                                                        <textarea className="form-control" value={this.state.neurologicalTreatmentPlan} onChange={e => { this.setState({ neurologicalTreatmentPlan: e.target.value }) }} ></textarea> </div>
                                                    </div>
                                                </div>
       
    </div></div>
</div> 
                            <div className={this.state.review ? "tab-pane fade show active" : "tab-pane fade"}>
                                <br />
                                <div className="card  card-blue">
                                    <div className="card-header">Review</div>
                                    <div className="card-body">
                                    <div className="table-responsive-lg"> 
                                        <table className="table" id="table">
                                            <tr>
                                                <th>Date</th>
                                                <th>Time</th>
                                                <th>Comments</th>
                                                <th>Action</th>
                                            </tr>
                                            <tbody>
                                                {this.state.reviews.map((item, index) => (item.activeStatus === 1 && (
                                                    <tr key={index}>
                                                       
                                                    <td>{item.reviewDate}</td>
                                                    <td> {this.getReviewTime(item.reviewTime)} </td>
                                                        <td><textarea className="form-control" value={item.remarks} onChange={e => { this.setReview(index, 'remarks', e.target.value); }} ></textarea></td>
                                                        <td><span className="link-red" onClick={() => this.deleteReview(index)} ><i className="bi bi-x-square-fill"></i></span></td>
                                                    </tr>
                                                )))}
                                            </tbody>
                                        </table>
                                        </div>
                                        <button onClick={() => this.props.showCalenderReview()} className="btn btn-primary" >Insert New Review Line</button>
                                    </div></div>
                            </div>
                            <div className={this.state.medicalCertificate ? "tab-pane fade show active" : "tab-pane fade"}>
                                <br />
                                <div className="card  card-blue">
                                    <div className="card-header">Medical Certificate</div>
                                    <div className="card-body">
                                    <div className="table-responsive-lg"> 
                                        <table className="table" id="table">
                                            <tr>
                                                <th>Type</th>
                                                <th>Start Date</th>
                                                <th>End Date</th>
                                                <th>Action</th>
                                            </tr>
                                            <tbody>
                                                {this.state.medicalCertificates.map((item, index) => (item.activeStatus === 1 && (
                                                    <tr key={index}>
                                                        <td><select className="form-control" value={item.name} onChange={e => { this.setMedicalCertificate(index, 'name', e.target.value); }} >
                                                            <option value="">Choose Type</option>
                                                            {this.state.medicalCertificateTypes.map((m, index) => (
                                                                <option key={index} value={m} >{m}</option>
                                                            ))}
                                                        </select></td>
                                                        <td><DatePicker className="form-control" selected={new Date(item.startDate)} onChange={d => { this.setMedicalCertificate(index, "startDate", this.processDate(d)) }} dateFormat="yyyy-MM-dd" /></td>
                                                        <td><DatePicker className="form-control" selected={new Date(item.endDate)} onChange={d => { this.setMedicalCertificate(index, "endDate", this.processDate(d)) }} dateFormat="yyyy-MM-dd" /></td>
                                                        <td><span className="link-red" onClick={() => this.deleteMedicalCertificate(index)} ><i className="bi bi-x-square-fill"></i></span></td>
                                                    </tr>
                                                )))}
                                            </tbody>
                                        </table>
                                        </div>
                                        <button onClick={() => this.addMedicalCertificateTable()} className="btn btn-primary" >Insert New Medical Certificate Line</button>
                                    </div></div>
                            </div>
                            <div className={this.state.treatmentPlanForChestT ? "tab-pane fade show active" : "tab-pane fade"}>
                                <br />
                                <div className="card  card-blue">
                                    <div className="card-header">Treatment Plan For Chest</div>
                                    <div className="card-body">
                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.deepBreathingExercises === "1"} onChange={e => this.setState({ deepBreathingExercises: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Deep Breathing Exercises</span></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.activeCycleOfBreathingTechnique === "1"} onChange={e => this.setState({ activeCycleOfBreathingTechnique: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Active Cycle of Breathing Technique</span></div></div>
                                                </div>
                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.passiveMovements === "1"} onChange={e => this.setState({ passiveMovements: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Passive Movements</span></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.splintedCoughing === "1"} onChange={e => this.setState({ splintedCoughing: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Splinted Coughing</span></div></div>
                                                </div>
                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.bagging === "1"} onChange={e => this.setState({ bagging: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Bagging</span></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.nebulization === "1"} onChange={e => this.setState({ nebulization: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Nebulization</span></div></div>
                                                </div>
                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.suctioning === "1"} onChange={e => this.setState({ suctioning: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Suctioning</span></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.percussions === "1"} onChange={e => this.setState({ percussions: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Percussions</span></div></div>
                                                </div>
                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.vibrations === "1"} onChange={e => this.setState({ vibrations: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Vibrations</span></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.incentiveSpirometry === "1"} onChange={e => this.setState({ incentiveSpirometry: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Incentive Spirometry</span></div></div>
                                                </div>
                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.thoracicExpansionExercises === "1"} onChange={e => this.setState({ thoracicExpansionExercises: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Thoracic Expansion Exercises</span></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.posturalDrainage === "1"} onChange={e => this.setState({ posturalDrainage: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Postural Drainage</span></div></div>
                                                </div>
                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.mobilazation === "1"} onChange={e => this.setState({ mobilazation: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Mobilazation</span></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.sitOutInChair === "1"} onChange={e => this.setState({ sitOutInChair: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Sit out in chair</span></div></div>
                                                </div>
                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.coughStimulation === "1"} onChange={e => this.setState({ coughStimulation: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Cough Stimulation</span></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.assistedCough === "1"} onChange={e => this.setState({ assistedCough: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Assisted Cough</span></div></div>
                                                </div>
                                                </div></div>
                            </div>
                            <div className={this.state.referral ? "tab-pane fade show active" : "tab-pane fade"}>
                                <br />
                                <div className="card  card-blue">
                                    <div className="card-header">Referral</div>
                                    <div className="card-body">
                                    <div className="table-responsive-lg"> 
                                        <table className="table" id="table">
                                            <tr>
                                                <th>Type</th>
                                                <th>Remarks</th>
                                                <th>Action</th>
                                            </tr>
                                            <tbody>
                                                {this.state.referrals.map((item, index) => (item.activeStatus === 1 && (
                                                    <tr key={index}>
                                                        <td><input type="text" className="form-control" value={item.consultant} onChange={e => { this.setReferral(index, 'consultant', e.target.value); }} /></td>
                                                        <td><textarea className="form-control" value={item.referralNotes} onChange={e => { this.setReferral(index, 'referralNotes', e.target.value); }} ></textarea></td>
                                                        <td><span className="link-red" onClick={() => this.deleteReferral(index)} ><i className="bi bi-x-square-fill"></i></span></td>
                                                    </tr>
                                                )))}
                                            </tbody>
                                        </table>
                                        </div>
                                        <button onClick={() => this.addReferralTable()} className="btn btn-primary" >Insert New Referral Line</button>
                                    </div></div>
                            </div>
                            <div className={this.state.spinalAndJointMobilisationTreatmentT ? "tab-pane fade show active" : "tab-pane fade"}>
                                <br />
                                <div className="card  card-blue">
                                    <div className="card-header">Spinal / Joint Mobilization Treatment Position</div>
                                    <div className="card-body">
                                    <div className="table-responsive-lg"> 
                                        <table className="table" id="table">
                                            <tr>
                                            <th>Position</th>
                                            <th>Vetrebra/Joints</th>
                                            <th>Grade</th>
                                            <th>Repetitions</th>
                                                <th>Action</th>
                                            </tr>
                                            <tbody>
                                                {this.state.spinalJointMobilizationTreatments.map((item, index) =>  (
                                                    <tr key={index}>
                                                        <td><input type="text" list={'positionList' + index} className="form-control" value={item.position} onChange={e => { this.setSpinalJointMobilizationTreatment(index, 'position', e.target.value); }} />
                                                            <datalist id={'positionList' + index}>
                                                                {this.state.positions.map((m, index) => (
                                                                    <option key={index} value={m}>{m}</option>
                                                                ))}
                                                            </datalist> </td>
                                                        <td><input type="text" list={'vertebraOrJointList' + index} className="form-control" value={item.vertebraOrJoint} onChange={e => { this.setSpinalJointMobilizationTreatment(index, 'vertebraOrJoint', e.target.value); }} />
                                                            <datalist id={'vertebraOrJointList' + index}>
                                                                {this.state.vertebraOrJoints.map((m, index) => (
                                                                    <option key={index} value={m}>{m}</option>
                                                                ))}
                                                            </datalist> </td>
                                                        <td><input type="text" list={'gradeList' + index} className="form-control" value={item.grade} onChange={e => { this.setSpinalJointMobilizationTreatment(index, 'grade', e.target.value); }} />
                                                            <datalist id={'gradeList' + index}>
                                                                {this.state.grades.map((m, index) => (
                                                                    <option key={index} value={m}>{m}</option>
                                                                ))}
                                                            </datalist> </td>
                                                        <td><input type="text" list={'repetitionList' + index} className="form-control" value={item.repetition} onChange={e => { this.setSpinalJointMobilizationTreatment(index, 'repetition', e.target.value); }} />
                                                            <datalist id={'repetitionList' + index}>
                                                                {this.state.repetitions.map((m, index) => (
                                                                    <option key={index} value={m}>{m}</option>
                                                                ))}
                                                            </datalist> </td>                                                       
                                                         <td><span className="link-red" onClick={() => this.deleteSpinalJointMobilizationTreatment(index)} ><i className="bi bi-x-square-fill"></i></span></td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        </div>
                                        <button onClick={() => this.addSpinalJointMobilizationTreatmentTable()} className="btn btn-primary" >Insert New Treatment Line</button>
                                    </div></div>
                            </div>
                            <div className={this.state.orthopedicTreatmentPlanT ? "tab-pane fade show active" : "tab-pane fade"}>
                                <br />
                                <div className="card  card-blue">
                                    <div className="card-header">Orthopedic Treatment Plan</div>
                                    <div className="card-body">
                                    <div className="row">
                                                    <div className="col"><div className="input-group"><span className="input-group-text"> Comment</span>
                                                        <textarea className="form-control" value={this.state.orthopedicTreatmentPlanComment} onInput={e => { this.setState({ orthopedicTreatmentPlanComment: e.target.value }) }} rows="10"></textarea> </div>
                                                    </div>
                                                </div>
                                    <div className="table-responsive-lg"> 
                                        <table className="table" id="table">
                                            <tr>
                                                <th>Plan</th>
                                                <th>Remarks</th>
                                                <th>Action</th>
                                            </tr>
                                            <tbody>
                                                {this.state.orthopedicTreatmentPlans.map((item, index) => (
                                                    <tr key={index}>
                                                         <td><input type="text" className="form-control" value={item.plan} onChange={e => { this.setOrthopedicTreatmentPlan(index, 'plan', e.target.value); }} /></td>
                                                         <td><input type="text" className="form-control" value={item.remarks} onChange={e => { this.setOrthopedicTreatmentPlan(index, 'remarks', e.target.value); }} /></td>
                                                        <td><span className="link-red" onClick={() => this.deleteOrthopedicTreatmentPlan(index)} ><i className="bi bi-x-square-fill"></i></span></td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        </div>
                                        <button onClick={() => this.addOrthopedicTreatmentPlanTable()} className="btn btn-primary" >Insert New Line</button>
                                    </div></div>
                            </div>
                            <div className={this.state.progressReportT ? "tab-pane fade show active" : "tab-pane fade"}>
                                <br />
                                <div className="card  card-blue">
                                    <div className="card-header">Progress Report</div>
                                    <div className="card-body">
                                <div className="row">
                                                    <div className="col"><div className="input-group">
                                                        <textarea className="form-control" value={this.state.progressReport} onChange={e => { this.setState({ progressReport: e.target.value }) }} ></textarea> </div>
                                                    </div>
                                                </div>
                                                </div></div>
                            </div>
                        </div>

                    </div>
                    <div className="col-lg-2 col-md-2">

                        {this.state.allergies && (<div className="card card-red">
                            <div className="card-header text-dark">
                                Allergies
                            </div>
                            <div className="card-body">
                                <span>{this.state.allergies}</span>
                            </div>
                        </div>)}
                        {this.state.id > 0 && (<div className="card card-green">
                            <div className="card-header text-dark">Current</div>
                            <div className="card-body">
                                <ul>
                                    <li ><span className="link" onClick={() => this.viewHistory(this.state.visitId)} >Current</span></li>
                                </ul>
                            </div>
                        </div>)}
                        <div className="card card-green">
                            <div className="card-header  text-dark">Consolidated</div>
                            <div className="card-body">
                                <ul>
                                    <li ><span className="link" onClick={() => this.viewConsolidated(this.state.visitId)}>Consolidated</span></li>
                                </ul>
                            </div>
                        </div>
                        {this.state.historyVisits.length > 0 && (<div className="card card-green" >
                            <div className="card-header  text-dark">History</div>
                            <div className="card-body">
                                <ul>
                                    {this.state.historyVisits.map((item, index) => (
                                        <li key={index}><span className="link" onClick={() => this.viewHistory(item.id)} >{item.name}</span></li>
                                    ))}
                                </ul>
                            </div>
                        </div>)}
                        <div className="card  card-green"  >
                            <div className="card-header  text-dark">Upload Notes</div>
                            <div className="card-body">
                                <label>Upload Notes [15MB]</label>
                                <input type="file" accept=".pdf" className="form-control" onChange={e => this.uploadFile('NOTES', e.target.files[0])} />
                            </div>
                        </div>
                        {this.state.notes.length > 0 && (<div className="card card-green"  >
                            <div className="card-header  text-dark">Notes</div>
                            <div className="card-body">
                                <table >
                                    {this.state.notes.map((item, index) => (
                                        <tr key={index} ><td width="90%"><span className="link" onClick={() => this.showFile(item.id, item.fileExtension)} >{item.fileName}</span></td><td> <span className="link-red" onClick={() => this.deleteFile(item.id, item.fileExtension, 'NOTES')}><i className="bi bi-x-square-fill"></i></span></td></tr>
                                    ))}
                                </table>

                            </div>
                        </div>)}
                    </div>
                </div>


            </div >
        </div >
        </div >)

    }
}      