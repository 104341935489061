import React, { Component } from 'react'
import axios from "axios";

export default class SideBar extends Component {
  state = { menuGroups: [] }

  componentDidMount() {
    axios.get(localStorage.getItem('host') + '/auth/getmymenus/'+sessionStorage.getItem('userId')+'/'+sessionStorage.getItem('roleId')+'/'+sessionStorage.getItem('companyId'), {
      headers: {
        'Authorization': `Bearer ${sessionStorage.getItem('token')}`
      }
    }).then(
      res => {
        /*  for (var index in res.data) {
              if(window.location.pathname+window.location.search === res.data[index].url)
              {
                  res.data[index].active = true
              }
             else
              {
                  res.data[index].active = false
              } 
          }*/
        this.setState({ menuGroups: res.data });
      }, err => {
        console.log(".....")
      }
    )

  }

  toggleSideLine(id) {
    if (document.getElementById(id).classList.contains("show")) {
      document.getElementById(id).classList.remove("show");
    }
    else {
      document.getElementById(id).classList.add("show");
    }
  }



  render() {
    return (
      
        <div id="sidebar-wrapper">
          
            <a href="/dashboard" className="d-flex align-items-center text-white pb-3 mb-3 text-decoration-none border-bottom">
             
              <h3 className="fs-5 fw-semibold">Dashboard</h3>
            </a>
            <ul className="list-group list-unstyled ps-0">
              {this.state.menuGroups.map((menuGroup, index) => (
                <li className="border-top mb-3" key={index}>
                  <button className="btn btn-toggle align-items-center rounded collapsed text-white" data-bs-toggle="collapse" data-bs-target="#home-collapse" aria-expanded="false" onClick={e => this.toggleSideLine('m' + index)}>
                    {menuGroup.name}
                  </button>
                  <div className="collapse" id={'m' + index}>
                    <ul className="list-group btn-toggle-nav list-unstyled fw-normal pb-1 small">
                      {menuGroup.menus.map((menu, i) => (
                        <li key={i}><a href={menu.menuFunction} className="list-group-item text-white rounded sidebar-link-item"><i className="bi bi-arrow-bar-right"></i> {menu.name}</a></li>
                      ))}
                    </ul>
                  </div>
                </li>
                
              ))}
              
            </ul>
          </div>

    
        
      

    )
  }
}