import React, { Component } from "react";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const now = new Date();
const todayY = now.getFullYear();
const todayM = checkZero(now.getMonth() + 1);
const todayD = checkZero(now.getDate());

function checkZero(num) {
    return (num + "").length === 1 ? "0" + num : num;
}


export default class PrescriptionList extends Component {
    state = { id: 0, firstname: '', surname: '', startDate: todayY + '-' + todayM + '-' + todayD, endDate: todayY + '-' + todayM + '-' + todayD, companyId: 0, list: [], clusterCompanys: [] }
    componentDidMount() {
        axios.get(localStorage.getItem('host') + '/auth/getemrsources/'+sessionStorage.getItem('companyId')+'/PRESCRIPTION', {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(
            res => {
                let id = 0
                if(res.data.length === 1)
                {
                 id=   res.data[0].id
                }
                this.setState({clusterCompanys: res.data, companyId: id});
                this.getList()
            }, err => {
                console.log(".....")
                 this.setState({error: err+""})
                this.props.setLoader(false)
            }
        )
    }
    getList() {
        this.props.setLoader(true)
        var data = { firstname: this.state.firstname, surname: this.state.surname,dateRange: this.state.startDate+","+this.state.endDate, companyId:  this.state.companyId }
        axios.post(localStorage.getItem('host') + '/sell/prescriptionlist', data, {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ list: res.data });
                this.props.setLoader(false)
            }, err => {
                console.log(".....")
                 this.setState({ error: err.Error })
                this.props.setLoader(false)
            }
        )
    } 

    close() {
        document.getElementById('prescriptionListAction').style = 'display:none'
    } 
    openDispense(visitId, patientId)
      {
       this.close()
       this.props.getDispense(0, patientId, visitId, '')   
      }
      processDate(date)
        {
          const now = new Date(date);
          const todayY = now.getFullYear();
          const todayM = checkZero(now.getMonth() + 1);
          const todayD = checkZero(now.getDate());
          var date1 = todayY + '-' + todayM + '-' + todayD;
          console.log(date1)
         return date1;
        }
    render() {
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        }
        return (
            <div id="prescriptionListAction" className="page-popup page-popup-overlay"   >
            <div className="card" >
                <div className="card-header bg-info">Prescription List</div>
                <div className="card-body" >
                    {divMsg}
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card border-primary">
                                <div className="card-body">
                                    <div className="row">
                                    <div className="col"><div className="input-group"><span className="input-group-text">Firstname</span> <input type="text" className="form-control" value={this.state.firstname} onChange={e => { this.setState({ firstname: e.target.value }) }} /></div></div>
                                    <div className="col"><div className="input-group"><span className="input-group-text">Surname</span> <input type="text" className="form-control" value={this.state.surname} onChange={e => { this.setState({ surname: e.target.value }) }} /></div></div>
                                    <div className="col"><div className="input-group"><span className="input-group-text">Start Date</span> <DatePicker className="form-control" selected={new Date(this.state.startDate)} maxDate={new Date(this.state.endDate)} onChange={d => { this.setState({ startDate: this.processDate(d) });} } dateFormat="yyyy-MM-dd" /></div></div>
                                            <div className="col"><div className="input-group"><span className="input-group-text">End Date</span> <DatePicker className="form-control" selected={new Date(this.state.endDate)}  minDate={new Date(this.state.startDate)} maxDate={new Date()}  onChange={d => { this.setState({ endDate: this.processDate(d) }); } } dateFormat="yyyy-MM-dd" /></div></div>
                                            <div className="col"><div className="input-group"><span className="input-group-text">Company</span><select className="form-control" value={this.state.companyId} onChange={(e) => this.setState({ companyId: e.target.value })}  >
                                            <option value="0" >Choose</option>
                                            {this.state.clusterCompanys.map((item, index) => (
                                                <option value={item.id} key={index} >{item.name}</option>
                                            ))}
                                        </select>  </div></div>
                                        <div className="col">    <input type="button" className="btn btn-success" onClick={() => this.getList()} value="Search" /></div>
                                    </div></div></div>
                        </div>
                    </div>
                    <div>
                        <table className="table table-striped table-hover">
                            <tr>
                                <th>Action</th>
                                <th>Patient Name</th>
                                <th>Gender</th>
                                <th>Date of Birth</th>
                                <th>Date</th>
                            </tr>
                            <tbody>
                                {this.state.list.map((item, index) => (
                                    <tr style={{ backgroundColor: item.color }} key={index}>
                                  <td >
                                            {item.dispenseId === 0 && (<span className="link" onClick={() => this.openDispense(item.visitId, item.patientId)} title="Dispense"><i className="bi bi-pencil-square"></i></span>)}
                                               </td>
                                        <td>{item.fullname}</td>
                                        <td>{item.gender}</td>
                                        <td>{item.dateOfBirth}</td>
                                        <td>{item.dateTime}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="row">
                        <div className="col-lg-12" align="center"><button onClick={() => this.close()} className="btn btn-primary">Close Window (Esc)</button></div>
                    </div>
                </div>
            </div>
        </div>
        )

    }



}