import React, { Component } from "react";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const now = new Date();
const todayY = now.getFullYear();
const todayM = checkZero(now.getMonth() + 1);
const todayD = checkZero(now.getDate());
var date1 = todayY + '-' + todayM + '-' + todayD;

function checkZero(num) {
    return (num + "").length === 1 ? "0" + num : num;
}


export default class OtcList extends Component {
    state = { id: 0, firstname: '', surname: '', startDate: date1, endDate: date1, companyId: sessionStorage.getItem('companyId'), list: [], clusterCompanys: [] }
    componentDidMount() {
        axios.get(localStorage.getItem('host') + '/auth/getclustercompanys/'+sessionStorage.getItem('companyId'), {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({clusterCompanys: res.data});
                this.getList()
            }, err => {
                console.log(".....")
                 this.setState({error: err+""})
                this.props.setLoader(false)
            }
        )
    }
    getList() {
        this.props.setLoader(true)
        var data = { dateRange: this.state.startDate+","+this.state.endDate, companyId:  this.state.companyId }
        axios.post(localStorage.getItem('host') + '/sell/otclist', data, {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ list: res.data });
                this.props.setLoader(false)
            }, err => {
                console.log(".....")
                 this.setState({ error: err.Error })
                this.props.setLoader(false)
            }
        )
    } 

    close() {
        document.getElementById('otcListAction').style = 'display:none'
    } 
    openOtcAction(id)
      {
       this.close()
       this.props.getForm(id, '')   
      }
      currencyFormat(amount) {
          let val = (amount / 1).toFixed(2)
          return val.toString().split(/(?=(?:\d{3})+(?:\.|$))/g).join(" ").replace("-,", "-");
      }
      processDate(date)
        {
          const now = new Date(date);
          const todayY = now.getFullYear();
          const todayM = checkZero(now.getMonth() + 1);
          const todayD = checkZero(now.getDate());
          var date1 = todayY + '-' + todayM + '-' + todayD;
          console.log(date1)
         return date1;
        }
    render() {
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        }
        return (
            <div id="otcListAction" className="page-popup page-popup-overlay"   >
            <div className="card" >
                <div className="card-header bg-info">Otc List</div>
                <div className="card-body" >
                    {divMsg}
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card border-primary">
                                <div className="card-body">
                                    <div className="row">
                                    <div className="col"><div className="input-group"><span className="input-group-text">Start Date</span> <DatePicker className="form-control" selected={new Date(this.state.startDate)} maxDate={new Date(this.state.endDate)} onChange={d => { this.setState({ startDate: this.processDate(d) });} } dateFormat="yyyy-MM-dd" /></div></div>
                                            <div className="col"><div className="input-group"><span className="input-group-text">End Date</span> <DatePicker className="form-control" selected={new Date(this.state.endDate)}  minDate={new Date(this.state.startDate)} maxDate={new Date()}  onChange={d => { this.setState({ endDate: this.processDate(d) }); } } dateFormat="yyyy-MM-dd" /></div></div>
                                            <div className="col"><div className="input-group"><span className="input-group-text">Company</span><select className="form-control" onChange={(e) => this.setState({ companyId: e.target.value })}  >
                                            {this.state.clusterCompanys.map((item, index) => (
                                                <option value={item.id} >{item.name}</option>
                                            ))}
                                        </select>  </div></div>
                                        <div className="col">    <input type="button" className="btn btn-success" onClick={() => this.getList()} value="Search" /></div>
                                    </div></div></div>
                        </div>
                    </div>
                    <div>
                        <table className="table table-striped table-hover">
                            <tr>
                                <th>Action</th>
                                <th>Number</th>
                                <th>Date</th>
                                <th>Total</th>
                                <th>Paid</th>
                                <th>Owing</th>
                                <th>Status</th>
                            </tr>
                            <tbody>
                                {this.state.list.map((item, index) => (
                                    <tr style={{ backgroundColor: item.color }} key={index}>
                                        {item.itemStatus !== 'CANCELLED' ? (<td >
                                            {item.status === 'CREATED' && (<span className="link" onClick={() => this.openOtcAction(item.id)} title="Edit Visit"><i className="bi bi-pencil-square"></i></span>)}
                                            <span className="link" onClick={() => this.props.getPrintOut(item.id, 'OTC', [], item.companyId)} title="Print" ><i className="bi bi-printer-fill"></i></span>
                                            {item.cashOwing > 0 && (<span className="link" onClick={() => this.props.openReceiptAction(item.billId, 1, 0)} title="Receipt Bill"><i className="bi bi-clipboard-check-fill"></i></span>)}
                                            {item.status === 'CREATED' && (<span className="link" onClick={() => this.approveBill(item.billNumber, item.billId)} title="Close Bill"><i className="bi bi-file-earmark-lock2-fill"></i></span>)}
                                            {item.status === 'CREATED' && (<span className="link-red" onClick={() => this.cancelVisit(item.number, item.billId)} title="Cancel Visit"><i className="bi bi-x-square-fill"></i></span>)}
                                            {item.status === 'CREATED' && (<span className="link" onClick={() => this.props.getResultAction(item.id)} title="Upload Patient Results" ><i className="bi bi-cloud-upload-fill"></i></span>)}
                                            {item.status === 'PENDING' && (<span className="link" onClick={() => this.dischargeSummaryAction(item.billId)} title="Discharge Summary"><i className="bi bi-file-arrow-up-fill"></i></span>)}
                                            {item.status === 'CLOSED' && (<span className="link" onClick={() => this.openBillOpeningRequestAction(item.billId)} title="Bill Opening Request"><i className="bi bi-list-task"></i></span>)}
                                        </td>) : (<td ></td>)}
                                        <td>{item.number}</td>
                                        <td>{item.date}</td>
                                        <td>{this.currencyFormat(item.cashPayable)}</td>
                                        <td>{this.currencyFormat(item.cashPaid)}</td>
                                        <td>{this.currencyFormat(item.cashOwing)}</td>
                                        <td>{item.status}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="row">
                        <div className="col-lg-12" align="center"><button onClick={() => this.close()} className="btn btn-primary">Close Window (Esc)</button></div>
                    </div>
                </div>
            </div>
        </div>
        )

    }



}