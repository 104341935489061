import React, { Component } from "react";
import axios from "axios";
import '../../App.css';
import Top from '../../includes/top'
import Sidebar from '../../includes/sidebar'
import { MDBDataTable } from 'mdbreact';


export default class Pcp extends Component {
    state = { id: 0, name: '', currencyId: '', activeStatus: 1, list: [], services: [], productItems: [], pcpServices: [], pcpProductItems: [] , msg: '', error: ''}
    componentDidMount() {
        this.getList()
    }
    action(e) {
        var id = e.currentTarget.id;
        console.log("......." + id + " .... " + e.currentTarget.id)
        this.showForm(id);
    }
    showForm(id) {
        this.setState({ id: 0, name: '', activeStatus: 1, services: [], productItems: [], pcpServices: [], pcpProductItems: [] })
            axios.get(localStorage.getItem('host') + '/master/pcpaction/' + id+'/' + sessionStorage.getItem('companyId')+'/'+ sessionStorage.getItem('speciality'), {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            }).then(
                res => {
                    this.setState({ name: res.data.name, id: res.data.id, activeStatus: res.data.activeStatus, services: res.data.services, productItems: res.data.productItems, pcpServices: res.data.pcpServices, pcpProductItems: res.data.pcpProductItems });
                }, err => {
                    console.log(".....")
                }
            )
        document.querySelector('#action').style = 'display:block'
    }
    closePopup() {
        document.querySelector('#action').style = 'display:none'
        console.log('......close');
    }
    save() {
        var data = { id: this.state.id, activeStatus: this.state.activeStatus, pcpServices: this.state.pcpServices, pcpProductItems: this.state.pcpProductItems , name: this.state.name, companyId: sessionStorage.getItem('companyId') }
        axios.post(localStorage.getItem('host') + '/master/pcpform', data, {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(
            res => {    
                    this.setState({ msg: res.data });
                    this.getList();
                    this.closePopup();
                    setTimeout(() => {
                        this.setState({ msg: '' });
                    }, 3000);
            }, err => {
                console.log(".....")
            }
        )
    }
    getList() {
        axios.get(localStorage.getItem('host') + '/master/pcp/' + sessionStorage.getItem('companyId'), {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(
            res => {
                for (var index in res.data) {
                    res.data[index].action = <span className="link" title="Edit" id={res.data[index].id} onClick={e => this.action(e)}><i className="bi bi-pencil-square"></i></span>
                }
                this.setState({ list: res.data });
            }, err => {
                console.log(".....")
            }
        )
    }

    setPcpServices(index, e, name) {
        let list = this.state.pcpServices.slice();
        switch (name) {
            case "serviceId":
                list[index].serviceId = e.target.value
                break;
            default:
                list[index].remarks = e.target.value
                break;
        }
        this.setState({ pcpServices: list });
    }
 
    deletePcpServices(index) {
        let list = this.state.pcpServices.slice();
        list[index].activeStatus = 0
        this.setState({ pcpServices: list });
    }
    addPcpServices() {
        let list = this.state.pcpServices.slice();
        list.push({ id: 0, serviceId: '', activeStatus: 1 });
        this.setState({ pcpServices: list });
    }
    setPcpProductItems(index, e, name) {
        let list = this.state.pcpProductItems.slice();
        switch (name) {
            case "productItemId":
                list[index].productItemId = e.target.value
                break;
            default:
                list[index].quantity = e.target.value
                break;
        }
        this.setState({ pcpProductItems: list });
    }
 
    deletePcpProductItems(index) {
        let list = this.state.pcpProductItems.slice();
        list[index].activeStatus = 0
        this.setState({ pcpProductItems: list });
    }
    addPcpProductItems() {
        let list = this.state.pcpProductItems.slice();
        list.push({ id: 0, productItemId: '', quantity: 1, activeStatus: 1 });
        this.setState({ pcpProductItems: list });
    }
    render() {
        const data = {
            columns: [
                 
                {
                    label: 'Action',
                    field: 'action',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Name',
                    field: 'name',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Active Status',
                    field: 'stringStatus',
                    sort: 'asc',
                    width: 100
                }
            ],
            rows: this.state.list
        };
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        }
        let divError = ''
        if (this.state.error) {
            divError = (<div className='alert alert-danger'>{this.state.error}</div>)
        }
        return (
            <div className="d-flex" id="wrapper">
                <Sidebar />
                <div id="page-content-wrapper">
                    <Top />

                    <div className="container-fluid">
                        <button className="btn btn-primary" onClick={() => this.showForm(0)} ><i className="bi bi-plus-square"></i></button>
                        {divMsg}
                        <MDBDataTable
                            button="true"
                            striped
                            bordered
                            small
                            data={data}
                        />
                        <div className="page-popup page-popup-overlay" id="action">
                                <div className="card">
                                    <div className="card-header bg-info">
                                        Action
                                    </div>
                                    <div className="card-body">
                                        {divError}
                                        <div className="row">
                                            <div className="col">
                                                 <div className="input-group"><span className="input-group-text">Name</span>
                                                <input type="text" className="form-control" value={this.state.name} onChange={e => { this.setState({ name: e.target.value }) }} /></div>
                                            </div>
                                            <div className="col">
                                                 <div className="input-group"><span className="input-group-text">Active Status</span>
                                                <select className="form-control" value={this.state.activeStatus} onChange={e => this.setState({ activeStatus: e.target.value })}  >
                                                    <option value="0">No</option>
                                                    <option value="1">Yes</option>
                                                </select></div>
                                            </div>
                                        </div>
                                        <div className="card card-green">
                                            <div className="card-header">Procedures</div>
                                            <div className="card-body">
                                                <table  className="table table-striped">
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Action</th>
                                                    </tr>
                                                    <tr>
                                                        <td > </td>
                                                        <td><button type="button" className="btn btn-success" title="Insert Procedure" onClick={() => { this.addPcpServices() }} ><i className="bi bi-plus-square"></i></button></td>
                                                    </tr>
                                                    <tbody>
                                                    {this.state.pcpServices.map((pcpService, index) => (pcpService.activeStatus === 1 && (
                                                        <tr>
                                                            <td><select className="form-control" value={pcpService.serviceId} onChange={e => { this.setPcpServices(index, e, "serviceId") }}  >
                                                            <option value="">Choose</option>
                                                            {this.state.services.map((item, index) => (
                                                        <option key={index} value={item.id} >{item.name}</option>
                                                    ))}
                                                            </select></td>
                                                            <td><span className="link-red" onClick={() => this.deletePcpServices(index)} ><i className="bi bi-x-square-fill"></i></span></td>
                                                        </tr>
                                                    )))}</tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <br />

                                        <div className="card card-green">
                                            <div className="card-header">Drugs and Sundries</div>
                                            <div className="card-body">
                                                <table  className="table table-striped">
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Quantity</th>
                                                        <th>Action</th>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan="2"> </td>
                                                        <td><button type="button" className="btn btn-success" title="Insert Drug / Sundries" onClick={() => { this.addPcpProductItems() }} ><i className="bi bi-plus-square"></i></button></td>
                                                    </tr>
                                                    <tbody>
                                                    {this.state.pcpProductItems.map((pcpProductItem, index) => (pcpProductItem.activeStatus === 1 && (
                                                        <tr>
                                                            <td><select className="form-control" value={pcpProductItem.productItemId} onChange={e => { this.setPcpProductItems(index, e, "productItemId") }}  >
                                                            <option value="">Choose</option>
                                                            {this.state.productItems.map((item, index) => (
                                                        <option key={index} value={item.id} >{item.name}</option>
                                                    ))}
                                                            </select></td>
                                                            <td><input type="text" className="form-control" value={pcpProductItem.quantity} onChange={e => { this.setPcpProductItems(index, 'quantity', e); }} /></td>
                                                            <td><span className="link-red" onClick={() => this.deletePcpProductItems(index)} ><i className="bi bi-x-square-fill"></i></span></td>
                                                        </tr>
                                                    )))}</tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col" align="right"><input type="button" className="btn btn-primary" onClick={() => this.save()} value="Save" /></div>
                                            <div className="col"><input type="button" className="btn btn-primary" onClick={() => this.closePopup()} value="Close" /></div>
                                        </div>
                                    </div></div>
                        </div>
                    </div>
                </div ></div >
        )

    }
}    